import { isValidDate } from "@/helpers/dates";
import {
  checkIfNextActionModeGroupKeyIsVisible,
  checkIfSelectedTaskCateHasOnlyArea,
} from "@/helpers/tasks";
import store from "@/store";
export const sortOpts = [
  [
    // {
    //   column: "CalendarGroupShowLast",
    //   order: "asc",
    // },
    {
      column: "dueAsDate",
      order: "asc",
      defaultValue: new Date("1990-01-01"),
      transformFunc: (val) => (isValidDate(val) ? val : null),
    },
    // {
    //   column: "modifiedAsDate",
    //   order: "desc",
    // },
  ],
  [],
  [
    {
      column: "dueAsDate",
      order: "asc",
    },

    {
      column: "ReviewGroup",
      order: "asc",
    },
  ],
  [
    // {
    //   column: "createdAsDate",
    //   order: "desc",
    // },
    {
      column: "modifiedAsDate",
      order: "desc",
      defaultValue: new Date("1990-01-01"),
      transformFunc: (val) => (isValidDate(val) ? val : null),
    },
  ],
  [
    // {
    //   column: "contingentOrder",
    //   order: "asc",
    // },

    {
      column: "dueAsDate",
      order: "asc",
      defaultValue: new Date("1990-01-01"),
      transformFunc: (val) => (isValidDate(val) ? val : null),
    },
    {
      column: "modifiedAsDate",
      order: "desc",
      defaultValue: new Date("2022-08-02"),
      transformFunc: (val) => (isValidDate(val) ? val : null),
      // skipMissing: true,
    },
  ],

  [
    {
      column: "dueAsDate",
      order: "desc",
    },
    {
      column: "CTIGroup",
      order: "asc",
    },
  ],
  [
    {
      column: "reminderTaskOrder",
      order: "desc",
    },
    {
      column: "dueAsDate",
      order: "desc",
      defaultValue: new Date("1990-01-01"),
      transformFunc: (val) => {
        return isValidDate(val) ? val : null;
      },
    },
    {
      column: "ReviewGroup",
      order: "asc",
    },
    // {
    //   column: "modifiedAsDate",
    //   order: "desc",
    //   defaultValue: new Date("1990-01-01"),
    //   transformFunc: (val) => (isValidDate(val) ? val : null),
    // },
  ],
  [
    {
      column: "order",
      order: "asc",
    },
    {
      column: "userPosition",
      order: "asc",
    },
  ],
  [
    {
      column: "reminderTaskOrder",
      order: "asc",
    },
    // {
    //   column: "contingentOrder",
    //   order: "asc",
    // },
    {
      column: "dueAsDate",
      order: "desc",
      // defaultValue: new Date("1970-01-01"),
      transformFunc: (val) => {
        return isValidDate(val) ? val : null;
      },
    },
    {
      column: "modifiedAsDate",
      order: "desc",
      defaultValue: new Date("1970-01-01"),
      transformFunc: (val) => (isValidDate(val) ? val : null),
    },
  ],
  [
    {
      column: "contingentOrder",
      order: "asc",
    },

    {
      column: "dueAsDate",
      order: "desc",
      defaultValue: new Date("1990-01-01"),
      transformFunc: (val) => (isValidDate(val) ? val : null),
    },

    {
      column: "customPosition",
      order: "asc",
      defaultValue: 0,
      transformFunc: (val, rowData) =>
        rowData.isCustomPositioned && typeof val === "number" ? val : null,
    },
    {
      column: "ReviewGroup",
      order: "asc",
    },

    {
      column: "customPositionIndex",
      order: "asc",
      defaultValue: 0,
      transformFunc: (val, rowData) =>
        rowData.isCustomPositioned && typeof val === "number" ? val : null,
    },
    {
      column: "modifiedAsDate",
      order: "desc",
      defaultValue: new Date("1990-01-01"),
      transformFunc: (val) => (isValidDate(val) ? val : null),
    },
  ],
  [
    {
      column: "dueAsDate",
      order: "desc",
      defaultValue: new Date("1970-01-01"),
      transformFunc: (val) => (isValidDate(val) ? val : null),
    },
  ],
  [
    {
      column: "order",
      order: "asc",
    },
  ],
  [
    {
      column: "customPosition",
      order: "asc",
      defaultValue: 0,
      transformFunc: (val, rowData) =>
        rowData.isCustomPositioned && typeof val === "number" ? val : null,
    },
    // {
    //   column: "ReviewGroup",
    //   order: "asc",
    // },
    {
      column: "customPositionIndex",
      order: "asc",
      defaultValue: 0,
      transformFunc: (val, rowData) =>
        rowData.isCustomPositioned && typeof val === "number" ? val : null,
    },

    // {
    //   column: "modifiedAsDate",
    //   order: "desc",
    //   defaultValue: new Date("1970-01-01"),
    //   transformFunc: (val) => (isValidDate(val) ? val : null),
    // },
  ],
];

export const emptyRowGroupsAllowedKeys = ["2", "3", "1", "0"];
export const emptyContingentGroupsAllowedKeys = ["00000ContingentGroup"];
export const emptyRowGroupsNextActionsKeys = ["1", "2"];
export const groupOpts = [
  "CalendarGroup",
  {
    key: "CTIGroup",
    groupKeys: ["0", "1", "2"],
    isEmptyRowAllowed: (groupKey) =>
      groupKey && emptyRowGroupsNextActionsKeys.includes(groupKey),
    emptyRowCreator: (groupKey) => {
      const emptyRowData = {
        key: `CTIGroup_${groupKey}_empty-row`,
        isEmptyRow: true,
        text: "No Tasks  under this group",
      };

      if (groupKey === "1") {
        emptyRowData.CTIGroup = "1";
        emptyRowData.CTIGroupCaption = "Flagged";
      }

      if (groupKey === "2") {
        emptyRowData.CTIGroup = "2";
        emptyRowData.CTIGroupCaption = "Unflagged";
      }

      return emptyRowData;
    },
  },
  "ReviewGroup",
  "",
  "TodayGroup",
  {
    key: "CTICustomGroup",
    // groupKeys: ["0", "1", "2", "3"],
    groupKeys: ["0", "1", "2"],
    isEmptyRowAllowed: (groupKey) =>
      groupKey && emptyRowGroupsAllowedKeys.includes(groupKey),
    checkForGroupVisiblity: true,
    groupVisiblityChecker: (groupKey) => {
      return checkIfNextActionModeGroupKeyIsVisible(groupKey);
    },
    emptyRowCreator: (groupKey) => {
      const emptyRowData = {
        key: `CTICustomGroup_${groupKey}_empty-row`,
        isEmptyRow: true,
        text: "No Tasks  under this group",
      };

      if (groupKey === "0") {
        emptyRowData.CTICustomGroup = "0";
        emptyRowData.CTICustomGroupCaption = "Scheduled";
      }
      if (groupKey === "1") {
        const selectedTaskCateList = store.getters["task/selectedOptsList"];
        const isProjectsModeEnabled =
          store.getters["task/isProjectsResourceModeEnabled"];
        const currView = store.getters["currActiveView"];

        if (
          !isProjectsModeEnabled &&
          currView !== "resources" &&
          checkIfSelectedTaskCateHasOnlyArea(selectedTaskCateList)
        ) {
          emptyRowData.text = "Drag to order tasks here";
        }

        emptyRowData.CTICustomGroup = "1";
        emptyRowData.CTICustomGroupCaption = "Next Actions (drag to order)";
      }

      if (groupKey === "2") {
        emptyRowData.CTICustomGroup = "2";
        emptyRowData.CTICustomGroupCaption = "Pending";
        // emptyRowData.CTICustomGroupCaption = "Flagged";
      }

      // if (groupKey === "3") {
      //   emptyRowData.CTICustomGroup = "3";
      //   emptyRowData.CTICustomGroupCaption = "Unflagged";
      // }
      return emptyRowData;
    },
  },
  "CategoryGroup",
  {
    key: "CTICustomDateGroup",
    checkGroups: true,
    isEmptyRowAllowed: (groupKey) =>
      groupKey && emptyContingentGroupsAllowedKeys.includes(groupKey),
    checkForGroupVisiblity: true,
    groupVisiblityChecker: () => {
      return true;
    },
    emptyRowCreator: (groupKey) => {
      const emptyRowData = {
        key: `CTICustomDateGroup${groupKey}_empty-row`,
        isEmptyRow: true,
        text: "No Tasks  under this group",
      };

      if (groupKey === "00000ContingentGroup") {
        emptyRowData.CTICustomDateGroup = "00000ContingentGroup";
        emptyRowData.CTICustomDateGroupCaption = "Contingents";
      }

      return emptyRowData;
    },
  },
];
export const fieldsToReprocessTask = [
  "dueAsDate",
  "project",
  "flag",
  "isContingent",
  "title",
  "completed",
  "pausedReminderDays",
];
export const taskPropsToRemove = [
  "CTIGroup",
  "CTIGroupCaption",
  "CTISortDate",
  "CalendarGroup",
  "CTIGroupShowLast",
  "CalendarGroupCaption",
  "ReviewGroup",
  "ReviewGroupCaption",
  "ReviewGroupCaptionShow",
  "category",
  "categoryTitle",
  "class",
  "dueAsDate",
  "flagDisplay",
  "projectName",
  "projectTitle",
  "subcategory",
  "actions",
  "editing",
  "group",
  "handler",
  "selected",
  "cleared",
  "restoreOldIdData",
  "type",
];

// export const taskRestoreProps = {
//   completed: "changedCompleted",
//   taskType: "changedTaskType",
//   project: "changedProject",
//   flag: "changedFlag",
//   priority: "changedPriority",
//   status: "changedStatus",
//   title: "changedTitle",
//   created: "changedCreated",
//   description: "changedDescription",
//   order: "changedOrder",
//   due: "changedDue",
//   time: "changedTime",
// };

export const taskRestoreProps = [
  // "completed",
  "taskType",
  "project",
  // "flag",
  "priority",
  "status",
  "title",
  "created",
  "description",
  "order",
  "due",
  "time",
  "frequency",
  "userPosition",
  "objective",
  "isCustomPositioned",
  "customPosition",
  "customPositionIndex",
];

export const orderPropsOfTask = [
  "order",
  "userPosition",
  "customPosition",
  "customPositionIndex",
];

export const booleanPropsOfTask = [
  "completed",
  "flag",
  "isContingent",
  "positionChanged",
  "isCustomPositioned",
];

export const TASK_PROPS_TO_PARSE = {
  "Due Date": {
    field: "due",
    type: "date",
  },
  Flagged: {
    field: "flag",
    type: "boolean",
  },
  Name: {
    field: "title",
    type: "string",
  },
  Project: {
    field: "project",
    type: "string",
  },
  "Completion Date": {
    field: "completed",
    type: "date",
  },
  Notes: {
    field: "description",
    type: "string",
  },
  Priority: {
    field: "priority",
    type: "string",
  },
  "Modified Date": {
    field: "modified",
    type: "date",
  },
  "Created Date": {
    field: "created",
    type: "date",
  },
};

export const TASK_PARSE_DATE_PROPS_NEEDS_EXTRA = ["completed", "due"];

export const TASK_PROPS_FOR_GOOGLE_CAL = [
  {
    field: "title",
    key: "summary",
  },
  {
    field: "dueAsDate",
  },
  {
    field: "description",
    key: "description",
  },
  { field: "recurrence", key: "recurrence" },
  { field: "status", key: "status" },
  // { field: "linkedTo", key: "recurringEventId" },
];

export const CALENDER_TASK_PROPS = [
  "dueAsDate",
  "title",
  "description",
  "recurrence",
];
export const TASK_PROPS_FROM_GOOGLE_EVENT = [
  {
    field: "summary",
    key: "title",
  },
  {
    field: "description",
    key: "description",
  },
  {
    field: "start",
    key: ["due", "time"],
  },
  {
    field: "recurrence",
    key: "recurrence",
  },
];

export const TASK_PROPS_TO_CHECK = [
  "completed",
  "taskType",
  "project",
  "priority",
  "title",
  "description",
  "due",
  "time",
];

export const BASIC_TASK_PROPS_TO_VERIFY = [
  "completed",
  "taskType",
  "project",
  "priority",
  "title",
  "description",
];

export const TASK_FREQ_FROM_RRULE_MAP = {
  0: "yearly",
  1: "monthly",
  2: "weekly",
  3: "daily",
  4: "hourly",
  5: "minutely",
  6: "secondly",
};

export const INLINE_EDIT_TASK_PROPS = [
  {
    field: "title",
    key: "title",
  },
  {
    field: "project",
    key: "project",
  },
  {
    field: "taskType",
    key: "taskType",
  },
  {
    field: "dueAsDate",
    key: ["due", "time"],
  },
  {
    field: "flag",
    key: "flag",
  },
  {
    field: "priority",
    key: "priority",
  },
  {
    field: "completed",
    key: "completed",
  },
  {
    field: "due",
    key: "due",
  },
  {
    field: "time",
    key: "time",
  },
  {
    field: "isContingent",
    key: "isContingent",
  },
  {
    field: "pausedReminderDays",
    key: "pausedReminderDays",
  },
  {
    field: "description",
    key: "description",
  },
  {
    field: "resources",
    key: "resources",
  },
  {
    field: "isCustomPositioned",
    key: "isCustomPositioned",
  },
  {
    field: "reminderDays",
    key: "reminderDays",
  },
  {
    field: "repeatInfo",
    key: "repeatInfo",
  },
];

export const RECURRENCE_TASK_PROPS = [
  "recurrence",
  "frequency",
  "coRelationalId",
];
export const TASK_PROPS_FOR_FORM = [
  "dueAsDate",
  "description",
  "title",
  "completed",
  "isContingent",
  "taskType",
  // "priority",
  "project",
  "flag",
  "objective",
  "reminderDays",
  "pausedReminderDays",
];

export const MAX_TASK_TITLE_WORDS_COUNT = 40;

export const MAX_TASK_TITLE_CHARACTER_COUNT = 240;

export const TASK_ACTIONS_TYPES = {
  DELETE: "delete_task",
  DUPLICATE: "duplicate_task",
  COMPLETE: "complete_task",
  CONTINGENT: "toggle_contingent",
  REMINDER: "reminder",
  REPEAT: "repeat",
  REPEAT_DAY: "repeat_daily",
  REPEAT_WEEK: "repeat_weekly",
  REPEAT_MONTH: "repeat_monthly",
  REPEAT_YEAR: "repeat_yearly",
};

const getDaysOptions = () => [
  { text: 0 },
  { text: 1 },
  { text: 2 },
  { text: 3 },
  { text: 4 },
  { text: 5 },
  { text: 6 },
  { text: 7 },
  { text: 8 },
  { text: 9 },
  { text: 10 },
];
const repeatFrequency = [
  { text: "Day", id: TASK_ACTIONS_TYPES.REPEAT_DAY, items: getDaysOptions() },
  { text: "Week", id: TASK_ACTIONS_TYPES.REPEAT_WEEK, items: getDaysOptions() },
  {
    text: "Month",
    id: TASK_ACTIONS_TYPES.REPEAT_MONTH,
    items: getDaysOptions(),
  },
  { text: "Year", id: TASK_ACTIONS_TYPES.REPEAT_YEAR, items: getDaysOptions() },
];
export const TASK_MENU_ACTIONS = [
  { text: "Complete", id: TASK_ACTIONS_TYPES.COMPLETE },
  { text: "Duplicate", id: TASK_ACTIONS_TYPES.DUPLICATE },
  { text: "Repeat In", id: TASK_ACTIONS_TYPES.REPEAT, items: repeatFrequency },
  {
    text: "Remind For",
    id: TASK_ACTIONS_TYPES.REMINDER,
    items: getDaysOptions(),
  },
  { text: "Contingent On/Off", id: TASK_ACTIONS_TYPES.CONTINGENT },
  { text: "Delete", id: TASK_ACTIONS_TYPES.DELETE },
];

export const OBJECTIVE_TASKS_SORT_CONFIG = [
  {
    column: "modifiedAsDate",
    order: "desc",
    defaultValue: new Date("1990-01-01"),
    transformFunc: (val) => (isValidDate(val) ? val : null),
  },
];

export const REMINDER_TASK_EDIT_PROPS = [
  "pausedReminderDays",
  "dueAsDate",
  "completed",
  "title",
  "project",
  "taskType",
];

export const nextActionsGroupsDragAllowed = ["1", "2", "3"];
export const nextActionsAnyCateGroupsAllowed = ["2", "3"];

export const nextActionsGroupFilter = {
  field: "CTICustomGroup",
  type: "=",
  value: "1",
};

export const taskfieldWhichRequiresProcessing = [
  "project",
  "flag",
  "due",
  "isContingent",
  "time",
  "reminderDays",
  "pausedReminderDays",
  "completed",
  "isCustomPositioned",
  "customPosition",
  "customPositionIndex",
  "taskType",
  "title",
];

export const taskfiltersWithCustomStyles = ["resourceTypes", "priority"];

export const taskContingentFilter = [
  {
    field: "showContingentInToday",
    type: "=",
    value: true,
  },
];

export const DEFAULT_NEXT_ACTIONS_VIEW_COLLAPSED_GROUPS = [
  { key: "CTICustomGroup", value: "0" },
  { key: "CTICustomGroup", value: "1" },
  { key: "CTICustomGroup", value: "2" },
];

export const RECURRING_TASK_EDIT_OPTS = [
  {
    label: "This Task",
    value: "self",
  },
  {
    label: "This task and following tasks",
    value: "withFollowing",
  },
  {
    label: "All Tasks",
    value: "all",
  },
];

export const TASK_GOOGLE_EVENT_VALID_STATUS = ["confirmed", "cancelled"];

export const TASK_NEXT_ACTIONS_GROUP_FILTERS_OPTS = [
  {
    label: "Scheduled",
    value: "scheduled",
  },
  {
    label: "Next Actions",
    value: "next-actions",
  },
  {
    label: "Pending",
    value: "pending",
  },
];

export const TASK_NEXT_ACTIONS_GROUP_FILTERS_VALUES = {
  scheduled: "0",
  "next-actions": "1",
  pending: "2",
};

/**
 * The default group value which is used when there is not selection by user
 */
export const DEFAULT_TASK_GROUP_FILTER_VALUE = "scheduled";
// export const DEFAULT_TASK_GROUP_FILTER_VALUE = "default";

/**
 * Placeholder text which is display when group filter selection is "default" and no list is shown
 */
export const TASKS_PLACEHOLDER_TEXT_FOR_FILTER_GROUP =
  "Select a group tab from above to view";

/**
 * Name of the key in task data which is used to check for group
 */
export const TASK_GROUP_KEY_FOR_GROUP_FILTER = "CTICustomGroup";

/**
 * Map of groups and their values which are used to set group filter
 */
export const TASK_NEXT_ACTION_GROUP_KEY_VALUES_FOR_FILTER = {
  0: "scheduled",
  1: "next-actions",
  2: "pending",
};
