<template>
  <div class="d-flex flex-wrap align-center flex-shrink-1 filters-wrapper">
    <ClearFilterBtn
      :isEmpty="isFiltersEmpty"
      :count="finalTaskCount"
      @clear-btn:click="clearListFiltersList"
      @clear-btn:dblClick="clearAllFilters"
    />

    <!-- <div v-show="showToggleBtn">
      <ToggleOrderedTasksBtnVue />
    </div> -->

    <div v-show="contingentBtnData.showBtn">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :class="contingentBtnData.btnClasses"
            x-small
            depressed
            v-bind="attrs"
            v-on="on"
            @click="handleContingentToggle"
          >
            <v-icon :size="13" :color="contingentBtnData.iconColor">
              {{ contingentBtnData.icon }}
            </v-icon>
          </v-btn>
        </template>
        <span> {{ contingentBtnData.tooltipText }} </span>
      </v-tooltip>
    </div>
    <div v-show="showProjectsViewBtn" class="projects-view-toggle-btn-wrapper">
      <ProjectsViewToggleBtnVue />
    </div>
    <v-btn-toggle
      v-for="filter in createdFiltersMap"
      :key="filter.hash"
      :label="filter.title"
      multiple
      borderless
      class="pt-0 filter-toggle-btns flex-wrap"
      :class="filter.title + '-filters'"
    >
      <template>
        <v-tooltip
          bottom
          v-for="filterItem in filter.data"
          :key="filterItem.order"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="filter.title !== 'category'"
              v-bind="attrs"
              v-on="on"
              :class="createFilterBtnStyles(filterItem, filter.title)"
              v-on:click="updateFilters(filterItem, false, false, $event)"
              x-small
            >
              <v-icon
                :size="filterItem.iconSize || 13"
                :color="getFilterIconColor(filterItem, filter.title)"
              >
                {{ filterItem.icon }}
              </v-icon>
            </v-btn>
            <v-btn
              v-else
              v-bind="attrs"
              v-on="on"
              :class="createCategoryBtnStyles(filterItem)"
              v-on:click="updateFilters(filterItem, false, false, $event)"
              @selected="handleSelectedFilter(filterItem)"
              :data-filter-cate-id="filterItem.key"
              x-small
            >
              {{ filterItem.title }}
            </v-btn>
          </template>
          <span> Filter: {{ filterItem.title }} </span>
        </v-tooltip>
      </template>
    </v-btn-toggle>
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { priorityTypesMap } from "@/data/priorityTypes";
import { taskTypesMap, typesMap } from "@/data/taskTypes";
import {
  createLabelForProjectOrCategoriesFilter,
  createLabelForSelectedDatesFilter,
  // createProjectsOrCategoriesFilters,
  // createTaskSearchQueryFilter,
  isProjectInbox,
  addDueDateFilter,
  createAndAddTodayFilter,
  createViewDataForDb,
  createShowClearedTasksFilters,
  getTaskTreeView,
  addProjectOrCategoriesFilter,
  createSearchQueryFilters,
  createTaskListFiltersFromFiltersMap,
  checkIfSelectedTaskFiltersHasCategory,
  checkIfSelectedTaskCateHasOnlyArea,
  checkTaskAdditionalFiltersEmpty,
} from "@/helpers/tasks";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import filterTasks from "@/core/components/DataTable/lib/pandas/filter";
import DataFrame from "@/core/components/DataTable/lib/pandas/pandas";
import {
  navViewFiltersConfig,
  viewsWithDateFilter,
  clearedTaskFiltersToRemove,
  treeSideBarModes,
  calModes,
} from "@/variables/viewConfigs";
import blueimpMD5 from "blueimp-md5";
import isEmpty from "lodash/isEmpty";
import DatabaseInterface from "@/services/DatabaseInterface";
import userDetailsMixin from "@/mixins/userDetailsMixin";
import EventEmitter from "@/helpers/eventEmitter";
import {
  REFRESH_TASKS_EVENT,
  SET_SELECTED_NODE_EVENT,
  TOGGLE_ORDERED_TASKS,
  TOGGLE_STORE_OPEN_CLOSE_NODE_EVENT,
  CLEAR_ALL_FILTERS,
} from "@/variables/events";
import { storeValueInArray } from "@/utils/array";
import ClearFilterBtn from "@/components/ClearFilterBtn";
import { DARK_ICON_COLOR, LIGHT_GREY_BG_CLASSNAME } from "@/variables/colors";
import tasksHelpersMixin from "@/mixins/tasksHelpersMixin";

import {
  DEFAULT_TASK_GROUP_FILTER_VALUE,
  taskfiltersWithCustomStyles as filtersWithCustomStyles,
} from "@/variables/tasks";
import ToggleOrderedTasksBtnVue from "@/components/ToggleOrderedTasksBtn/ToggleOrderedTasksBtn.vue";
import ProjectsViewToggleBtnVue from "@/components/ProjectsViewToggleBtn.vue";
import { getUserResources } from "@/helpers/resources";
// import { resourceTypesMap } from "@/data/resources";
const taskTypesList = Object.keys(taskTypesMap);
const typeList = Object.keys(typesMap);

export default {
  refreshFiltersOnChange: true,
  components: {
    ClearFilterBtn,
    ToggleOrderedTasksBtnVue,
    ProjectsViewToggleBtnVue,
  },
  props: {
    dataGridRef: {
      type: Object,
    },
    mode: {
      type: String,
    },
  },
  mixins: [userDetailsMixin, tasksHelpersMixin],
  data() {
    return {
      selectedMainCateIds: [],
      createdFiltersMap: {},
    };
  },
  mounted() {
    EventEmitter.on(CLEAR_ALL_FILTERS, this.clearAllFilters);
  },
  beforeDestroy() {
    EventEmitter.off(CLEAR_ALL_FILTERS, this.clearAllFilters);
  },
  methods: {
    handleContingentToggle() {
      this.toggleContingentTasks(!this.showContingentTasks);
    },
    async handleSelectedFilter(filterData) {
      await this.updateFilters(filterData, true, true);

      this.setFocusOnListWrapper();
    },
    createFilterBtnStyles(filterData, filterName) {
      const currFilters =
        this.filters[filterName] || this.filters[filterData.type];

      const priorityBtn = filterName === "priority";

      return {
        "filter-btn": true,
        [LIGHT_GREY_BG_CLASSNAME]: !currFilters.includes(filterData.key),
        "selected-filter": !filtersWithCustomStyles.includes(filterName)
          ? currFilters.includes(filterData.key)
          : false,
        "priority-filters": priorityBtn,
        [`${filterData.key}-filter-btn`]: priorityBtn,
        selected:
          filtersWithCustomStyles.includes(filterName) && priorityBtn
            ? currFilters.includes(filterData.key)
            : false,
        [`selected-resource-${filterData.key}-filter`]:
          filtersWithCustomStyles.includes(filterName) &&
          filterName === "resourceTypes"
            ? currFilters.includes(filterData.key)
            : false,
      };
    },
    getFilterIconColor(filerData, filterName) {
      if (filtersWithCustomStyles.includes(filterName)) {
        return filerData.color;
      }
      return this.filters[filterName].includes(filerData.key)
        ? "#fff"
        : "#676767";
    },
    async clearTreeView() {
      const activeCatTreeView = this.getTreeviewEl()?.ej2_instances[0];
      const inactiveTreeView = this.getInActiveTreeInstance();
      const currExpandedNodes =
        cloneDeep(activeCatTreeView.expandedNodes) || [];

      activeCatTreeView?.collapseAll();
      inactiveTreeView?.collapseAll();

      if (this.isProjectsResourceModeEnabled) {
        const dataToSet = {
          mode: "project",
          selection: [],
        };

        getUserResources().set(dataToSet, "selectedCategories");
      }
      await this.closeCategoriesInDb(
        !isEmpty(this.prevExpandedCateIds)
          ? this.prevExpandedCateIds
          : currExpandedNodes
      );
    },
    async clearAllFilters(
      _,
      clearFirstCall = true,
      ignoreInspectMode,
      doNotClearSelection,
      doNotClearViewData
    ) {
      if (clearFirstCall && this.filterClearFunc !== undefined) {
        clearTimeout(this.filterClearFunc);
      }
      // if (this.isInspectModeEnabled) {
      //   return;
      // }
      let updateRemote = false;
      // let clearProjectsSelection = false;
      let dbUpdates = {};
      let localUpdates = {};
      let rootStateUpdates = {
        searchVal: "",
      };
      const currNav = this.currNav;
      const currSelectedOptsListIsEmpty = isEmpty(this.selectedOptsList);
      const currTaskFilter = this.selectedTaskFilter;
      const currTaskDateFilter = this.selectedTaskDateFilter;
      let sendClearEvent = false;
      if (currNav === 0) {
        dbUpdates = {
          "/view/0/selectedTypes": [],
        };

        if (!doNotClearSelection) {
          dbUpdates = {
            ...dbUpdates,
            "/view/0/selectedDates": [],
            "/view/0/selectedForecastType": "",
          };

          localUpdates = {
            selectedDates: [],
            selectedCalForecastType: "",
          };
        }

        // Remove dates
      }

      if (currNav === 3) {
        dbUpdates = {
          "/view/3/selectedTypes": [],
        };
        localUpdates = {
          contingentFilter: [],
        };
      }

      if (currNav === 1 || currNav === 4) {
        const navToUse = 1;

        if (!doNotClearSelection) {
          dbUpdates[`/view/${navToUse}/selectedOptsList`] = [];
          // clearProjectsSelection = true;
        }

        dbUpdates[`/view/${navToUse}/openedCategories`] = [];
        dbUpdates[`/view/4/selectedFilter`] = "";
        dbUpdates[`/view/4/selectedDateFilter`] = "";
        localUpdates = {
          selectedTaskFilter: "",
          selectedTaskDateFilter: "",
          selectedTaskGroupFilter: DEFAULT_TASK_GROUP_FILTER_VALUE,
        };

        if (!doNotClearSelection) {
          localUpdates.selectedOptsList = [];
        }
        sendClearEvent = !doNotClearSelection;
        // Remove all selected categories
      }
      if (!this.isInboxViewEnabled) {
        updateRemote = true;
      }

      if (!ignoreInspectMode && this.isInspectModeEnabled) {
        dbUpdates["inspectModeEnabled"] = false;
        localUpdates.inspectModeEnabled = false;
      }

      if (updateRemote && !isEmpty(dbUpdates)) {
        DatabaseInterface.update(dbUpdates, this.userInfo.uid);
        if (sendClearEvent) {
          EventEmitter.emit(TOGGLE_STORE_OPEN_CLOSE_NODE_EVENT, false);
          EventEmitter.emit(SET_SELECTED_NODE_EVENT, []);
          this.clearTreeView();
        }
        this.updateState(localUpdates);
      }
      if (!updateRemote && !isEmpty(localUpdates)) {
        this.updateState(localUpdates);
        if (sendClearEvent) {
          EventEmitter.emit(TOGGLE_STORE_OPEN_CLOSE_NODE_EVENT, false);
          EventEmitter.emit(SET_SELECTED_NODE_EVENT, []);
          this.clearTreeView();
        }
      }
      if (this.userSelectedCate) {
        this.userSelectedCate = false;
        this.prevExpandedCateIds = [];
      }

      if (!doNotClearViewData) {
        rootStateUpdates = {
          ...rootStateUpdates,
          currViewData: {},
          itemInspectEnabled: false,
        };
      }
      this.updateRootState({
        ...rootStateUpdates,
      });

      if (!doNotClearSelection) {
        this.toggleContingentTasks(false);
      }

      await Vue.nextTick();

      if (
        currSelectedOptsListIsEmpty &&
        (currTaskFilter || currTaskDateFilter)
      ) {
        EventEmitter.emit(REFRESH_TASKS_EVENT);
      }
      this.setFocusOnTree();
      // this.setFocusOnListWrapper();
    },
    async closeCategoriesInDb(expandedCategories) {
      if (!isEmpty(expandedCategories)) {
        const dbUpdates = expandedCategories.reduce((dbData, cate) => {
          if (this.categories[cate]) {
            dbData["/categories/" + cate + "/open"] = false;
          } else if (this.projects[cate]) {
            dbData["/projects/" + cate + "/open"] = false;
          }
          return dbData;
        }, {});
        if (!isEmpty(dbUpdates)) {
          await DatabaseInterface.update(dbUpdates, this.userInfo.uid);
        }
      }
    },
    isFilterEmpty() {
      let res = true;

      // if (this.currNav === 0 && !isEmpty(this.selectedDates)) {
      //   res = false;
      // } else

      if (this.isInTreeViewMode && !isEmpty(this.selectedOptsList)) {
        res = false;
      }

      if (res && !this.checkIfFiltersMapIsEmpty()) {
        res = false;
      }
      return res;
    },
    checkIfFiltersMapIsEmpty() {
      return checkTaskAdditionalFiltersEmpty(this.filters);
    },
    setFocusOnListWrapper() {
      setTimeout(() => {
        const filterBar = document.querySelector(".tasks-list-wrapper");
        filterBar?.focus();
      }, 0);
    },
    setFocusOnTree() {
      setTimeout(() => {
        const treeBar = this.getTreeviewEl();
        treeBar?.focus();
      }, 0);
    },
    async clearListFiltersList(e) {
      if (e.detail !== 1) {
        return;
      }

      this.filterClearFunc = setTimeout(async () => {
        let localUpdates = {};
        let dbUpdates = {};
        if (calModes.includes(this.currNav)) {
          if (this.isInspectModeEnabled) {
            localUpdates.inspectModeEnabled = false;
            dbUpdates = createViewDataForDb(this.currNav);
            dbUpdates["inspectModeEnabled"] = false;
          }
        }

        if (!isEmpty(localUpdates)) {
          this.updateState({
            ...localUpdates,
          });
        }

        EventEmitter.emit(TOGGLE_ORDERED_TASKS, true);
        this.clearListFilters();
        this.toggleContingentTasks(false);

        this.updateRootState({
          currViewData: {},
          itemInspectEnabled: false,
        });
        if (!isEmpty(dbUpdates)) {
          DatabaseInterface.update(dbUpdates, this.userInfo.uid);
        }

        if (treeSideBarModes.includes(this.currNav)) {
          this.clearAllFilters(undefined, false);
          // EventEmitter.emit(TOGGLE_STORE_OPEN_CLOSE_NODE_EVENT, false);
          // await this.clearTreeView();
          // let selectedCategories = [];
          // if (!isEmpty(this.selectedOptsList)) {
          //   const includedIDs = [];
          //   selectedCategories = this.selectedOptsList.reduce((accu, opt) => {
          //     let mainCateId;
          //     if (this.categories[opt.id]) {
          //       mainCateId = opt.id;
          //     } else if (
          //       this.projects[opt.id] &&
          //       this.projects[opt.id].category
          //     ) {
          //       mainCateId = this.projects[opt.id].category;
          //     }
          //     if (mainCateId && !includedIDs.includes(mainCateId)) {
          //       includedIDs.push(mainCateId);
          //       accu.push(mainCateId);
          //     }
          //     return accu;
          //   }, []);
          // }
          // EventEmitter.emit(
          //   SET_SELECTED_NODE_EVENT,
          //   selectedCategories,
          //   true,
          //   true
          // );
        }

        await Vue.nextTick();
        this.setFocusOnTree();
        // this.setFocusOnListWrapper();
      }, 150);
    },
    getTreeviewEl() {
      const activeTreeViewData = getTaskTreeView();
      // const treeTarget = this.showClearedTasks
      //   ? "#treeview-all-data"
      //   : "#treeview";
      return activeTreeViewData?.element;
    },
    getAllExpandedNodes() {
      const catTree = document.querySelector("#treeview")?.ej2_instances[0];
      const allDataCatTree =
        document.querySelector("#treeview-all-data")?.ej2_instances[0];
      let nodes = [];
      if (catTree) {
        const visibleExpandedNodes = catTree.expandedNodes;
        const allDataExpandedNodes = allDataCatTree.expandedNodes;
        nodes = new Set([...visibleExpandedNodes, ...allDataExpandedNodes]);
        nodes = Array.from(nodes);
      }

      return nodes;
    },
    getInActiveTreeInstance() {
      const treeViewElData = getTaskTreeView("inactive");
      // const treeTarget = this.showClearedTasks
      //   ? "#treeview"
      //   : "#treeview-all-data";

      return treeViewElData.treeInstance;
    },
    async updateFilters(data, useOne, doNotFocusOnTree, event) {
      let localUpdates = {};
      let closeAllNodes = true;
      let changeTreeNodes = false;
      let cateFilterList = [];
      let dbUpdates = {};
      let currOpenedNodes = cloneDeep(this.getAllExpandedNodes()) || [];
      let doNotPushItem = false;
      let checkEmptyAndRestoreSelection = false;
      let refreshSelection = false;
      let processCategoriesOnAltPress = false;

      const isAltKeyPressed = event && event.altKey;
      const filterData = {
        filter: data.type,
      };
      let currActiveCateFilters = !isEmpty(this.filters.category)
        ? cloneDeep(this.filters.category)
        : [];
      if (data.type === "category") {
        if (treeSideBarModes.includes(this.currNav)) {
          checkEmptyAndRestoreSelection = true;
          // changeTreeNodes = true;

          doNotPushItem = true;
          this.selectedMainCateIds = [];
          if (!this.userSelectedCate) {
            this.prevExpandedCateIds = !isEmpty(this.prevExpandedCateIds)
              ? [...this.prevExpandedCateIds, ...currOpenedNodes]
              : [...currOpenedNodes];
            this.userSelectedCate = true;
          }

          if (
            !isEmpty(this.selectedOptsList) &&
            isEmpty(currActiveCateFilters)
          ) {
            this.storedSelectedOptsList = [...this.selectedOptsList];
            cateFilterList = this.selectedOptsList.reduce((accu, opt) => {
              let mainCateId;
              if (this.categories[opt.id]) {
                mainCateId = opt.id;
              } else if (
                this.projects[opt.id] &&
                this.projects[opt.id].category
              ) {
                mainCateId = this.projects[opt.id].category;
              }
              if (mainCateId) {
                accu.push(mainCateId);
                // if (!currActiveCateFilters.includes(mainCateId)) {
                //   currActiveCateFilters.push(mainCateId);
                // }
              }
              return accu;
            }, []);
          }

          const mergedSet = new Set([
            ...currActiveCateFilters,
            ...cateFilterList,
          ]);

          // console.debug("EM<HJJ", mergedSet);

          const mergedList = Array.from(mergedSet);

          if (!isAltKeyPressed) {
            if (!mergedList.includes(data.key)) {
              // console.debug("DATA KEY NOT FOUND");
              mergedList.unshift(data.key);
            } else {
              // console.debug("DATA KEY FOUND");
              mergedList.splice(mergedList.indexOf(data.key), 1);
            }
          }

          cateFilterList = [...mergedList];

          // console.debug("CAEJKD", cateFilterList);

          if (!isEmpty(currOpenedNodes)) {
            closeAllNodes = false;
            currOpenedNodes = currOpenedNodes.filter(
              (node) => !currActiveCateFilters.includes(node)
            );
          }

          if (this.isInspectModeEnabled) {
            localUpdates.inspectModeEnabled = false;
            dbUpdates = createViewDataForDb(this.currNav);

            dbUpdates["inspectModeEnabled"] = false;

            this.inspectModeWasDisabled = true;
          }

          // if (this.isInTreeViewMode) {
          //   localUpdates.selectedOptsList = [];
          // }
        } else if (calModes.includes(this.currNav)) {
          if (this.isInspectModeEnabled) {
            localUpdates.inspectModeEnabled = false;
            dbUpdates = createViewDataForDb(this.currNav, true);
            dbUpdates["inspectModeEnabled"] = false;

            this.inspectModeWasDisabled = true;
          }
        }

        processCategoriesOnAltPress = isAltKeyPressed;
      }

      if (
        processCategoriesOnAltPress &&
        !isEmpty(this.createdFiltersMap.category.data) &&
        this.createdFiltersMap.category.data.length > 1
      ) {
        let cateList =
          cloneDeep(
            !isEmpty(cateFilterList) ? cateFilterList : currActiveCateFilters
          ) || [];

        if (cateList.includes(data.key)) {
          cateList.splice(cateList.indexOf(data.key), 1);
        } else {
          const allCategories = this.createdFiltersMap.category.data;
          cateList = allCategories.reduce((accu, cate) => {
            if (cate.key !== data.key) {
              accu.push(cate.key);
            }
            return accu;
          }, []);
        }

        filterData.list = [...cateList];

        filterData.overwrite = true;
      } else if (!isEmpty(cateFilterList) && !useOne) {
        let listToUse = [...cateFilterList];

        if (!doNotPushItem) {
          listToUse.push(data.key);
        }
        filterData.list = [...listToUse];

        filterData.overwrite = true;
      } else {
        if (
          checkEmptyAndRestoreSelection &&
          isEmpty(cateFilterList) &&
          !isEmpty(this.storedSelectedOptsList)
        ) {
          refreshSelection = true;
        }
        filterData.key = data.key;
        filterData.overwrite = !!useOne;
      }

      this.updateState({
        ...localUpdates,
      });
      this.updateRootState({
        currViewData: {},
        itemInspectEnabled: false,
      });

      this.changeListFilter(filterData);
      if (!isEmpty(dbUpdates)) {
        DatabaseInterface.update(dbUpdates, this.userInfo.uid);
      }
      await this.$nextTick();

      if (refreshSelection) {
        this.storedSelectedOptsList = undefined;
        this.checkAndSetMainCateIds();
      }

      if (changeTreeNodes) {
        if (useOne) {
          currActiveCateFilters = [currActiveCateFilters[0]];
          closeAllNodes = true;
        }

        const treeViewEl = this.getTreeviewEl();
        const catTreeView = treeViewEl?.ej2_instances[0];
        const inactiveTree = this.getInActiveTreeInstance();
        EventEmitter.emit(TOGGLE_STORE_OPEN_CLOSE_NODE_EVENT, false);
        catTreeView.collapseAll(closeAllNodes ? undefined : currOpenedNodes);
        catTreeView.expandAll(currActiveCateFilters);
        inactiveTree?.collapseAll(closeAllNodes ? undefined : currOpenedNodes);
        inactiveTree?.expandAll(currActiveCateFilters);
        await this.$nextTick();

        if (!doNotFocusOnTree) {
          treeViewEl.focus();
        }

        EventEmitter.emit(
          SET_SELECTED_NODE_EVENT,
          [...currActiveCateFilters],
          true,
          true,
          true
        );
        catTreeView.ensureVisible(currActiveCateFilters[0]);
      }

      // if (doNotFocusOnTree) {
      //   this.setFocusOnListWrapper();
      // }

      return true;
    },
    addProjectOrCategoriesFilter(list, currFilters, isCategoryFilterEnabled) {
      // const filters = cloneDeep(currFilters);
      // const createdFilters = [...createProjectsOrCategoriesFilters(list)];

      // const existingFilterIndex = filters.findIndex((filter) =>
      //   isEqual(filter, createdFilters)
      // );
      // if (existingFilterIndex === -1) {
      //   filters.push(createdFilters);
      // }

      return addProjectOrCategoriesFilter(
        list,
        currFilters,
        this.isGlobalViewEnabled,
        isCategoryFilterEnabled,
        this.showAllOrderedTasks
      );
    },
    checkIfCategoryIsSelected(categoryId) {
      const currMainSelectedIds = this.selectedMainCateIds;

      return currMainSelectedIds.includes(categoryId);
    },
    isCategoryFilterSelected(category) {
      let isCategorySelected = false;
      const currFilters = this.filters;
      if (!isEmpty(currFilters)) {
        isCategorySelected = currFilters["category"].includes(category.key);
      }

      if (!isCategorySelected && !isEmpty(this.selectedMainCateIds)) {
        isCategorySelected = this.checkIfCategoryIsSelected(category.key);
      }
      return isCategorySelected;
    },
    createCategoryBtnStyles(categoryFilterData) {
      const isCategorySelected =
        this.isCategoryFilterSelected(categoryFilterData);
      return {
        "cate-filter-btn": true,
        "cate-selected": isCategorySelected,
      };
    },
    checkAndSetMainCateIds() {
      let currSelectedIds = [];

      if (this.inspectModeWasDisabled) {
        this.inspectModeWasDisabled = false;
        return;
      }

      if (this.isInTreeViewMode) {
        const selectedOptsList = this.selectedOptsList;
        if (!isEmpty(selectedOptsList)) {
          currSelectedIds = selectedOptsList.reduce((accu, i) => {
            let cateId;
            if (this.categories[i.id]) {
              cateId = i.id;
            } else if (this.projects[i.id]) {
              cateId = this.projects[i.id].category;
            }
            if (cateId) {
              storeValueInArray({
                valueToStore: cateId,
                list: accu,
              });
            }
            return accu;
          }, []);
        }
      }

      this.selectedMainCateIds = currSelectedIds;
    },
    ...mapActions("task", [
      "changeListFilter",
      "clearListFilters",
      "toggleContingentTasks",
    ]),
    ...mapActions(["updateRootState"]),
    ...mapMutations("task", ["updateState"]),
  },
  computed: {
    ...mapGetters("task", [
      "isGlobalViewEnabled",
      "isInboxViewEnabled",
      "isInspectModeEnabled",
      "filters",
      "groupMode",
      "currNav",
      "selectedDates",
      "selectedOptsList",
      "tasks",
      "projects",
      "categories",
      "tasksCount",
      "searchQuery",
      "showClearedTasks",
      "contingentFilter",
      "selectedTaskFilter",
      "selectedTaskDateFilter",
      "showContingentTasks",
      "isProjectsResourceModeEnabled",
    ]),
    ...mapGetters("listOpts", ["taskTypeOptsMap"]),

    selectOptsListEmpty() {
      if (
        calModes.includes(this.currNav) ||
        (treeSideBarModes.includes(this.currNav) && this.isGlobalViewEnabled)
      ) {
        return true;
      }

      return (
        treeSideBarModes.includes(this.currNav) &&
        !this.isInboxViewEnabled &&
        !this.isGlobalViewEnabled &&
        isEmpty(this.selectedOptsList)
      );
    },
    isInNextActionsMode() {
      return this.groupMode === 1 && this.currNav === 4;
    },
    finalTaskCount() {
      return this.areFiltersVisible ? this.tasksCount : undefined;
    },
    isFiltersEmpty() {
      return this.isFilterEmpty();
    },
    taskDataFrame() {
      let currTasks = null;
      if (this.tasks && this.tasks.length) {
        const columns = new Set([
          ...Object.keys(this.tasks[0]),
          "due",
          "project",
        ]);

        currTasks = new DataFrame(this.tasks, [...columns]);
      }
      return currTasks;
    },
    isInTreeViewMode() {
      return (
        treeSideBarModes.includes(this.currNav) && !this.isInboxViewEnabled
      );
    },

    areFiltersVisible() {
      // eslint-disable-next-line no-unused-vars
      let isVisible = true;

      if (this.isInTreeViewMode && isEmpty(this.selectedOptsList)) {
        isVisible = false;
      }

      return true;
    },
    filterList() {
      const rtn = {
        category: {
          title: "category",
          data: [],
        },

        taskType: {
          title: "taskType",
          data: [],
        },
        priority: {
          title: "priority",
          data: [],
        },

        type: {
          title: "type",
          data: [],
        },
        // resourceTypes: {
        //   title: "resourceTypes",
        //   data: [],
        // },
      };

      // const priorityOrder = {
      //   purpose: 1,
      //   impact: 2,
      //   priority: 3,
      //   power: 4,
      // };

      const usedCategories = [];
      const usedPriority = [];
      const usedTaskType = [];
      const usedTypes = [];
      // const usedResourceTypes = [];
      const currFilters = this.currNavFilters;

      let allFilteredTasks = [];
      // let useAllFilteredTasks = false;
      let currTasks = this.tasks;

      if (this.tasks && this.tasks.length) {
        currTasks = filterTasks(this.taskDataFrame, currFilters).toCollection();
        currTasks = currTasks.filter((t) => !t.isReminderTask);

        if (
          this.dataGridRef &&
          this.isInNextActionsMode &&
          !this.isInboxViewEnabled
        ) {
          const sortBy = this.dataGridRef.sortBy || [];
          currTasks = this.processTasksOfNextActionsMode(currTasks, sortBy);
        }
        if (checkIfSelectedTaskFiltersHasCategory(this.filters)) {
          allFilteredTasks = [...currTasks];

          const categoryFilters = createTaskListFiltersFromFiltersMap({
            category: [...this.filters.category],
          });

          const columns = new Set([
            ...Object.keys(allFilteredTasks[0]),
            "due",
            "project",
          ]);

          currTasks = filterTasks(
            new DataFrame(currTasks, [...columns]),
            categoryFilters
          ).toCollection();
        }
      }

      for (const task of currTasks) {
        //get priorities
        if (task.priority && !usedPriority.includes(task.priority)) {
          usedPriority.push(task.priority);
          rtn.priority.data.push({
            key: task.priority,
            title: priorityTypesMap[task.priority]?.title,
            icon: priorityTypesMap[task.priority]?.icon,
            iconSize: 13,
            order: priorityTypesMap[task.priority]?.displayOrder,
            color: priorityTypesMap[task.priority]?.color,
            type: "priority",
          });
        }
        //get task types
        if (
          task.taskType &&
          taskTypesList.includes(task.taskType) &&
          !usedTaskType.includes(task.taskType)
        ) {
          usedTaskType.push(task.taskType);
          rtn.taskType.data.push({
            key: task.taskType,
            title: taskTypesMap[task.taskType]?.title,
            icon: taskTypesMap[task.taskType]?.icon,
            color: DARK_ICON_COLOR,
            iconSize:
              taskTypesMap[task.taskType].key === "work"
                ? taskTypesMap[task.taskType].iconSize
                : 13,
            type: "taskType",
          });
        }

        if (
          task.type &&
          typeList.includes(task.type) &&
          !usedTypes.includes(task.type)
        ) {
          usedTypes.push(task.type);
          rtn.type.data.push({
            key: task.type,
            title: typesMap[task.type]?.title,
            icon: typesMap[task.type]?.icon,
            order: typesMap[task.type]?.filterOrder,
            color: DARK_ICON_COLOR,
            iconSize: typesMap[task.type]?.iconSize,
            type: "type",
          });
        }

        // if (!isEmpty(task.resourceTypes)) {
        //   task.resourceTypes.forEach((r) => {
        //     if (!usedResourceTypes.includes(r)) {
        //       usedResourceTypes.push(r);
        //       rtn.resourceTypes.data.push({
        //         key: r,
        //         title: resourceTypesMap[r]?.title,
        //         icon: resourceTypesMap[r]?.icon,
        //         order: resourceTypesMap[r]?.order,
        //         color: resourceTypesMap[r].color,
        //         iconSize: 13,
        //         type: "resourceTypes",
        //       });
        //     }
        //   });
        // }
      }

      if (!this.isInTreeViewMode) {
        //get categories

        const listToUse = !isEmpty(allFilteredTasks)
          ? allFilteredTasks
          : currTasks;

        for (const task of listToUse) {
          if (task.project && !isProjectInbox(task.project)) {
            if (
              this.projects[task.project]?.category &&
              !usedCategories.includes(this.projects[task.project].category)
            ) {
              usedCategories.push(this.projects[task.project].category);
              rtn.category.data.push({
                key: this.projects[task.project].category,
                title:
                  this.categories[this.projects[task.project].category]?.title,
                order:
                  this.categories[this.projects[task.project].category]?.order,
                icon: this.categories[this.projects[task.project].category]
                  ?.icon,
                color: DARK_ICON_COLOR,
                type: "category",
                hidden:
                  this.categories[this.projects[task.project].category]
                    ?.hidden || false,
              });
            }
          }
        }
      } else {
        const categoriesKeys = Object.keys(this.categories || {});

        categoriesKeys.forEach((cateKey) => {
          rtn.category.data.push({
            key: cateKey,
            title: this.categories[cateKey]?.title,
            order: this.categories[cateKey]?.order,
            icon: this.categories[cateKey]?.icon,
            color: DARK_ICON_COLOR,
            type: "category",
            hidden: this.categories[cateKey]?.hidden || false,
            deleted: this.categories[cateKey]?.deleted || false,
          });
        });
      }

      if (!this.showClearedTasks) {
        rtn.category.data = rtn.category.data.filter(
          (c) => !c.hidden && !c.deleted
        );
      }

      if (this.isInTreeViewMode && this.selectedOptsList.length > 0) {
        // console.log("selectedOptsList", this.selectedOptsList);
        rtn.category.data = rtn.category.data.filter((category) =>
          this.isCategoryFilterSelected(category)
        );
      }

      rtn.priority.data.sort((a, b) => {
        return a.order - b.order;
      });

      rtn.taskType.data.sort((a, b) => {
        return taskTypesMap[a.key].order - taskTypesMap[b.key].order;
      });
      rtn.category.data.sort((a, b) => {
        return a.order - b.order;
      });

      rtn.type.data.sort((a, b) => a.order - b.order);
      // rtn.resourceTypes.data.sort((a, b) => a.order - b.order);

      rtn.taskType.hash = !isEmpty(rtn.taskType.data)
        ? blueimpMD5(JSON.stringify(rtn.taskType.data))
        : "taskType";

      rtn.priority.hash = !isEmpty(rtn.priority.data)
        ? blueimpMD5(JSON.stringify(rtn.priority.data))
        : "priority";
      rtn.category.hash = !isEmpty(rtn.category.data)
        ? blueimpMD5(JSON.stringify(rtn.category.data))
        : "categories";

      rtn.type.hash = !isEmpty(rtn.type.data)
        ? blueimpMD5(JSON.stringify(rtn.type.data))
        : "types";

      // rtn.resourceTypes.hash = !isEmpty(rtn.resourceTypes.data)
      //   ? blueimpMD5(JSON.stringify(rtn.resourceTypes.data))
      //   : "resourceTypes";
      return rtn;
    },
    listHeader() {
      let sort = "";
      let headerText = "";
      if (this.groupMode === 0) {
        sort = "(Schedule Group)";
      } else if (this.groupMode === 1) {
        sort = "(Review Group)";
      } else if (this.groupMode === 2) {
        sort = "(Prioritize Group)";
      } else if (this.groupMode === 3) {
        sort = "(History Group)";
      }

      if (!this.isGlobalViewEnabled && !this.isInboxViewEnabled) {
        let view = "";
        let status = "";
        let info = "";

        if (viewsWithDateFilter.includes(this.currNav)) {
          view = "Calendar";
          status = "Scheduled Tasks";
          info = createLabelForSelectedDatesFilter(this.selectedDates);
        } else if (this.currNav === 1) {
          view = "Tree";
          status = "Incomplete Tasks";
          info = createLabelForProjectOrCategoriesFilter(
            this.selectedOptsList,
            this.projects,
            this.categories
          );
        }

        headerText =
          view +
          " of " +
          this.tasksCount +
          " " +
          status +
          " " +
          info +
          " " +
          sort;
      } else if (this.isGlobalViewEnabled) {
        headerText = "All Tasks Ever " + sort;

        if (this.isInspectModeEnabled) {
          let view = "";
          let status = "";
          let info = "";
          if (viewsWithDateFilter.includes(this.currNav)) {
            view = "Calendar";
            status = "Scheduled Tasks";
            info = createLabelForSelectedDatesFilter(this.selectedDates);
          } else if (this.currNav === 1) {
            view = "Tree";
            status = "Incomplete Tasks";
            info = createLabelForProjectOrCategoriesFilter(
              this.selectedOptsList,
              this.projects,
              this.categories
            );
          }

          headerText =
            view +
            " of " +
            this.tasksCount +
            " " +
            status +
            " " +
            info +
            " " +
            sort;
        }
      } else if (this.isInboxViewEnabled) {
        headerText = "Viewing " + this.tasksCount + " Tasks in Inbox";
      }
      return headerText;
    },
    navFilter() {
      let navIndex = this.currNav;

      if (navIndex === 3) {
        navIndex = 5;
      }
      if (this.isInboxViewEnabled) {
        navIndex = 3;
      }
      if (this.isGlobalViewEnabled) {
        navIndex = 2;
      }

      if (navIndex === 4) {
        navIndex = 7;
      }

      return navViewFiltersConfig[navIndex].filters;
    },

    currNavFilters() {
      let currFilters = cloneDeep(this.navFilter);
      let searchQuery = this.searchQuery;
      const selectedDates = this.selectedDates;
      const selectedOptsList = this.selectedOptsList;
      const contingentFilter = this.contingentFilter;
      const showContingentTasks = this.showContingentTasks;
      // const categoryFilter = this.filters["category"];
      let isCategoryFilterEnabled = checkIfSelectedTaskFiltersHasCategory(
        this.filters
      );
      searchQuery = searchQuery && searchQuery.trim();

      if (viewsWithDateFilter.includes(this.currNav)) {
        if (
          (!this.isGlobalViewEnabled && !this.isInboxViewEnabled) ||
          this.isInspectModeEnabled
        ) {
          // if (selectedDates && selectedDates.length) {
          currFilters = addDueDateFilter(
            selectedDates,
            contingentFilter,
            currFilters,
            this.currNav,
            showContingentTasks,
            this.isGlobalViewEnabled
          );
          // }

          if (this.currNav === 3) {
            currFilters = createAndAddTodayFilter(
              contingentFilter,
              selectedDates,
              currFilters,
              showContingentTasks
            );
          }
        }
        //
      } else if (treeSideBarModes.includes(this.currNav)) {
        if (!this.isInboxViewEnabled || this.isInspectModeEnabled) {
          // if (selectedOptsList && selectedOptsList.length) {
          currFilters = this.addProjectOrCategoriesFilter(
            selectedOptsList,
            currFilters,
            isCategoryFilterEnabled
          );
          // }
        }
      }

      currFilters = createSearchQueryFilters(
        currFilters,
        searchQuery,
        this.isInNextActionsMode && !this.isGlobalViewEnabled
      );

      // if (isCategoryFilterEnabled) {
      //   const createdFilters = createTaskListFiltersFromFiltersMap(
      //     this.filters
      //   );

      //   if (!isEmpty(createdFilters)) {
      //     currFilters = [...currFilters, ...createdFilters];
      //   }
      // }

      currFilters = createShowClearedTasksFilters(
        currFilters,
        clearedTaskFiltersToRemove,
        this.showClearedTasks
      );

      return currFilters;
    },
    changeMainCateIds() {
      return (
        this.isInTreeViewMode ||
        (this.isInspectModeEnabled && this.isInTreeViewMode)
      );
    },
    showToggleBtn() {
      return (
        this.isInTreeViewMode &&
        !checkIfSelectedTaskCateHasOnlyArea(this.selectedOptsList)
      );
    },
    contingentBtnData() {
      let iconColor = "#676767";
      let tooltip = "Show Contingents";
      let btnClasses = {
        [LIGHT_GREY_BG_CLASSNAME]: true,
      };

      if (this.showContingentTasks) {
        iconColor = "#fff";
        btnClasses = {
          "contingent-filter-btn-active": true,
        };
        tooltip = "Hide Contingents";
      }

      return {
        showBtn: this.isInCalModes,
        btnClasses: {
          "filter-btn": true,
          "contingent-filter-btn": true,

          ...btnClasses,
        },
        iconColor,
        icon: "$ctiCalendarCross",
        tooltipText: tooltip,
      };
    },
    isInCalModes() {
      return calModes.includes(this.currNav) && !this.isGlobalViewEnabled;
    },
    showProjectsViewBtn() {
      return this.isInTreeViewMode && !this.isInboxViewEnabled;
    },
  },
  watch: {
    changeMainCateIds: {
      handler() {
        this.checkAndSetMainCateIds();
      },
      immediate: true,
    },
    selectedOptsList: {
      handler(newVal, oldVal) {
        if (!isEqual(newVal, oldVal)) {
          this.checkAndSetMainCateIds();
        }
      },
      deep: true,
    },
    filterList: {
      handler(n, o) {
        if (!isEqual(n, o)) {
          this.createdFiltersMap = n;
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.cate-filter-btn,
.resource-filter-btn {
  background: var(--category-bg-color) !important;
  color: #403a31 !important;
  font-size: 0.692rem !important;
  line-height: 11px !important;
  padding: 5px 8px !important;
  //opacity: 0.8 !important;
}
.resources-filter-btn {
  font-size: 0.692rem !important;
  color: #403a31 !important;
}

.filter-btn {
  background: #f5f5f5 !important;
  border-radius: 6px !important;
  padding: 5px 12px !important;
}
.filter-text {
  font-size: 12px;
}
.filter-toggle-btns {
  margin: 0px;
  border-radius: 6px !important;
}

.filter-toggle-btns:not(.category-filters) {
  // background: #efefef !important;
}

.filter-toggle-btns.v-btn-toggle > .v-btn:last-child {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.filter-toggle-btns.v-btn-toggle > .v-btn.v-btn:last-of-type {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.selected-filter {
  background: var(--primary-color) !important;
}
.taskType-filters > button.filter-btn.v-btn {
  border-radius: 6px !important;
}

.type-filters > button.filter-btn.v-btn {
  border-radius: 6px !important;
}

.filters-wrapper {
  gap: 8px;
}

.v-btn-toggle > .v-btn.v-btn.filter-btn {
  opacity: 1 !important;
}

.priority-filters {
  &.selected {
    .v-icon {
      color: #fff !important;
    }
  }
  &.impact-filter-btn.selected {
    background-color: var(--place-muted) !important;
  }
  &.priority-filter-btn.selected {
    background-color: var(--primary-muted) !important;
  }
  &.power-filter-btn.selected {
    background-color: var(--people-muted) !important;
  }
}

.cate-selected {
  background: var(--primary-color) !important;
  color: #fff !important;
}

.contingent-filter-btn {
  background-color: #d9d9d9 !important;
  color: #676767;
}
.contingent-filter-btn-active {
  background-color: var(--primary-color) !important;
}

.projects-view-toggle-btn-wrapper {
  margin-top: -1px;
}
</style>
