<template>
  <TasksGroupFiltersRow
    :options="groupFiltersOpts"
    @input="handleFilterChange"
    :value="valToUse"
  />
</template>
<script>
import TasksGroupFiltersRow from "./TasksGroupFiltersRow.vue";
import tasksHelpersMixin from "@/mixins/tasksHelpersMixin";
import { TASK_NEXT_ACTIONS_GROUP_FILTERS_OPTS } from "@/variables/tasks";
export default {
  mixins: [tasksHelpersMixin],
  props: {
    value: {
      type: [String, Array],
      default: "",
    },
    isControlled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TasksGroupFiltersRow,
  },
  methods: {
    async handleFilterChange(filterVal) {
      if (!filterVal) return;
      if (this.isControlled) {
        this.$emit("change", filterVal);
      } else {
        this.setTaskGroupFilter(filterVal);
        await this.$nextTick();
        // callRefreshList();
        // refreshSubTasksTable();
      }
    },
  },
  computed: {
    groupFiltersOpts() {
      return TASK_NEXT_ACTIONS_GROUP_FILTERS_OPTS;
    },
    valToUse() {
      return this.isControlled ? this.value : this.selectedTaskGroupFilter;
    },
  },
};
</script>
