var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.areTasksVisible),expression:"areTasksVisible"}],staticClass:"d-flex flex-column",staticStyle:{"height":"100%"}},[_c('NextActionsGroupFiltersRow',{directives:[{name:"show",rawName:"v-show",value:(_vm.isInNextActionsMode),expression:"isInNextActionsMode"}]}),_c('div',{class:_vm.tableWrapperStyles,staticStyle:{"height":"100%"},attrs:{"id":"data-table-wrapper"},on:{"contextmenu":_vm.handleContextMenuTrigger}},[_c('DataTable',{ref:"dataGrid",attrs:{"list":_vm.tasks,"columnsList":_vm.tableColumns,"itemSize":36,"options":_vm.options,"renderGroupCaption":_vm.createGroupItemsCaptions,"rowClassNamesCreator":_vm.createRowClassNames,"rowSelectionChecker":_vm.checkIfRowSelectionIsAllowed,"allowDragAndDrop":_vm.allowDragAndDrop,"rowDragVerifier":_vm.verifyRowDrag,"whiteListBtns":[
          'project-form',
          'data-table-popup-menu',
          'keep-task-selected',
          'add-resource-pop-up',
          'resource-type-selector',
          'resource-type-cate-selector',
          'resource-save-btn',
          'resource-title-editor-wrapper',
          'resource-error-pop-up',
          'convert-dialog',
          'note-path-selector-popup',
          'v-overlay',
          'area-priority-selector-wrapper',
          'area-priority-setter-dialog' ],"collapsedGroupsList":_vm.collapsedGroupsList,"postSortProcessor":_vm.processTasksAfterSort,"postGroupProcessor":_vm.processTasksAfterGrouping,"verifyColumnSortablity":_vm.checkColumnIsSortable,"checkRowDragEnabled":_vm.checkIfRowDragEnabled,"processFinalList":_vm.processFinalTaskList,"placeholderText":_vm.placeholderTxt},on:{"filter":_vm.handleFilterSuccess,"edit:enabled":_vm.handleEditEnabled,"list:processed":_vm.handleListProcessed,"list:post-grouping":_vm.handlePostGrouping,"row:clicked":_vm.handleRowClicked,"row:added":_vm.handleRowAdd,"row:updated":_vm.handleRowUpdated,"rows:selected":_vm.handleRowsSelected,"row:reordered":_vm.handleRowDragStop,"row:focused":_vm.handleRowFocused,"row:dragging":_vm.handleRowDragging,"group:toggled":_vm.handleGroupToggle,"keydown":_vm.handleKeydown,"key-pressed:delete":_vm.handleDeleteCall,"row:touch:long":_vm.handleLongTouch}})],1),_c('ContextMenu',{staticClass:"data-table-popup-menu",attrs:{"target":"#data-table-wrapper","items":_vm.menuItems,"menuId":"data-table-menu-opts"},on:{"menu-item:selected":_vm.handleMenuSelection,"beforeMenuOpen":_vm.handleBeforeMenuOpen}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }