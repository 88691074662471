import DatabaseInterface from "@/services/DatabaseInterface";
import { RRule } from "rrule";
import {
  addHours,
  formatISO,
  isSameDay,
  add,
  isBefore,
  set,
  addWeeks,
  addDays,
  addMonths,
  addYears,
  parseISO,
  subDays,
  isAfter,
  parseJSON,
} from "date-fns";
import { cloneDeep, difference, isEqual } from "lodash";
import blueimpMD5 from "blueimp-md5";
import isEmpty from "lodash/isEmpty";
import {
  getCurrDate,
  makeDateFormat,
  makeTimeFormat,
  parseAndFormatDate,
  parseDate,
  formatDate,
  getComponentsFromDate,
  getCurrentTimeZoneName,
  convertDateToUTCWithLocalTime,
  // isDateBetweenDates,
  createDates,
  isSameDates,
  extractDateFromString,
  isBetweenInterval,
  subFromDate,
  createDateRange,
  parseISODate,
} from "../helpers/dates";
import { getUserActions, TASK_ACTIONS } from "./actionHistory";
// makeTimeFormat
import { isValidDate, diffGetters } from "./dates";
import EventEmitter from "@/helpers/eventEmitter";
import removeProps from "@/utils/removeProps";
import {
  booleanPropsOfTask,
  taskPropsToRemove,
  taskRestoreProps,
  TASK_PROPS_TO_PARSE,
  fieldsToReprocessTask,
  TASK_PROPS_FOR_GOOGLE_CAL,
  CALENDER_TASK_PROPS,
  TASK_PROPS_FROM_GOOGLE_EVENT,
  TASK_PARSE_DATE_PROPS_NEEDS_EXTRA,
  TASK_PROPS_TO_CHECK,
  TASK_FREQ_FROM_RRULE_MAP,
  INLINE_EDIT_TASK_PROPS,
  TASK_PROPS_FOR_FORM,
  orderPropsOfTask,
  MAX_TASK_TITLE_CHARACTER_COUNT,
  nextActionsGroupsDragAllowed,
  nextActionsGroupFilter,
  taskContingentFilter,
  TASK_GOOGLE_EVENT_VALID_STATUS,
  TASK_NEXT_ACTIONS_GROUP_FILTERS_VALUES,
} from "@/variables/tasks";
import store from "@/store";
import createUniqueId from "@/utils/createUniqueId";
import {
  // DATE_FORMAT_IN_USE,
  DATE_TIME_FORMAT_IN_FILE,
  DAY_FORMAT,
  GOOGLE_ID_ALL_DAY_FORMAT,
  GOOGLE_ID_DAY_FORMAT,
  INTERNAL_DATE_FORMAT,
  INTERNAL_TIME_FORMAT,
  MONTH_DAY_FORMAT,
} from "@/variables/dates";
import {
  ADD_TASK_EVENT,
  ADD_UPDATE_REMOVE_TASK_EVENT,
  DESELECT_TASKS_EVENT,
  REFRESH_ACTIVITY_HISTORY,
  SYNC_COMPLETED,
  TASK_UPDATED_EVENT,
} from "@/variables/events";
import { createProjectLabel } from "./projects";
import { isEqual as isDatesEqual } from "date-fns";
import { addOrUpdateOrRemoveTasksInGoogleCal } from "@/mixins/googleCalHelpersMixins";

import Vue from "vue";
import {
  addDTSTARTRuleInRulesString,
  getRRuleWeekDay,
  getRuleSetFromRuleString,
} from "./rrulesHelpers";
import { DateTime } from "luxon";
import { daysOfWeek, parsedWeekOptsMap } from "@/data/weekDays";
import {
  checkIfStringContainsNewLine,
  countCharacters,
  escapeHtmlString,
  getAllIndexesOfCharInString,
  isHTML,
  replaceCharInString,
  truncateString,
} from "@/utils/stringHelpers";
import {
  convertDateTimeStrToDate,
  convertValueToArray,
  emitRefreshList,
  extractDataFromHTMLBlob,
  getInnerTextFromHTMLContent,
} from "./common";

import {
  areQlDeltasEqual,
  convertHTMLToQlDelta,
  createOrGetQlInstance,
  removeQlInstance,
  setDataAndGetHTMLFromQl,
  stichStringFromQlDelta,
} from "./quill";
import {
  createIgnoreResourceTypeFilter,
  getAllMentionedResourceIdsFromText,
  getAllResourcesIdsByTags,
  getUserResources,
} from "./resources";
import {
  getAllMentionsFromStr,
  replaceMentionTagsWithTagEls,
} from "./mentions";
import { isHTMBlob } from "./regex";
import { createRange } from "@/utils/range";
import {
  taskFiltersConfigMap,
  treeSideBarModes,
  clearedTaskFiltersToRemove,
} from "@/variables/viewConfigs";
import {
  checkIfObjectiveDataChanged,
  createUpdateAndEditedForObjective,
} from "./objectives";
import { decrement, increment } from "@/utils/number";
import { resourceTypesMap } from "@/data/resources";
import { getTextContentFromHTML } from "@/utils/elements";
import { taskTypesMap } from "@/data/taskTypes";
import { BASE_BLACK_COLOR } from "@/variables/colors";
const taskTypesMapList = Object.values(taskTypesMap);
const taskTypesList = Object.keys(taskTypesMap);
import isUndefinedVal from "@/utils/isUndefinedVal";
import DataFrame from "@/core/components/DataTable/lib/pandas/pandas";
import filterTaskList from "@/core/components/DataTable/lib/pandas/filter";
import GoogleCalHelpers from "./googleCalHelpers";
/**
 * It takes the custom compare func and returns a function which calls the custom
 * function with task item
 * @param {Function} customCompareFun - Custom compare function
 * @param {Object} taskItem - Task Item
 */
const compareFun = (customCompareFun, taskItem) => {
  return (filterItem) => {
    return customCompareFun(filterItem, taskItem);
  };
};

/**
 * It takes the list of tasks and filter list and a custom compare function to filter out tasks
 * @param {Object[]} taskList - List of tasks
 * @param {Object[]} filtersList - List of filters
 *  @param {string} filtersList[].type - Type of the filter.
 * @param {string} filtersList[].id - Id of the filter.
 * @param {Function} customCompareFun - Custom compare function to filter out tasks
 */
export const filterTasksByList = (taskList, filtersList, customCompareFun) => {
  if (!filtersList) {
    throw new Error("filtersList is undefined!");
  }
  taskList = taskList.filter(function (taskItem) {
    return filtersList.some(compareFun(customCompareFun, taskItem));
  });

  return taskList;
};

/**
 * Creates a unique task id
 * @returns {String} created task id
 */
export const createTaskId = () => {
  return createUniqueId();
};

export const convertTaskKeyForGoogleCal = (keyToConvert) =>
  keyToConvert.split("-").join("");

/**
 * It takes the task data and added the data required for to restore task in the updates object
 * @param {Object} taskData - Task data which needs to be restored
 * @param {Object} updatesObj - Object which will be modified to store data
 *  @param {Object} config - Config required
 *  @param {Object} config.projects - Dictionary of projects
 * @param {string[]} config.propsToRestore - List of keys in task data to restore
 */
export const createRestoreTaskUpdatesData = (taskData, updatesObj, config) => {
  const { propsToRestore, restoreRecurrence, doNotUpdateModified } = config;

  if (!propsToRestore || !propsToRestore.length) {
    throw new Error("Invalid param: propsToRestore");
  }

  if (!isEmpty(taskData) && taskData.key) {
    propsToRestore.forEach((key) => {
      if (
        booleanPropsOfTask.includes(key) &&
        typeof taskData[key] === "boolean"
      ) {
        updatesObj["/tasks/" + taskData.key + `/${key}`] = taskData[key];

        if (key === "completed" && taskData["completedOn"] !== undefined) {
          updatesObj["/tasks/" + taskData.key + "/completedOn"] =
            taskData["completedOn"] || "";
        }
      } else if (key === "repeatInfo") {
        if (taskData[key] !== undefined) {
          updatesObj["/tasks/" + taskData.key + `/${key}`] = !isEmpty(
            taskData[key]
          )
            ? taskData[key]
            : {};
        }
      } else if (key === "reminderDays") {
        if (taskData[key] !== undefined) {
          updatesObj["/tasks/" + taskData.key + `/${key}`] =
            taskData[key] || null;
        }
      } else if (key === "pausedReminderDays") {
        if (taskData[key] !== undefined) {
          updatesObj["/tasks/" + taskData.key + `/${key}`] =
            taskData[key] || [];
        }
      } else if (key === "snapshots") {
        if (taskData[key] !== undefined) {
          updatesObj["/tasks/" + taskData.key + `/${key}`] = !isEmpty(
            taskData[key]
          )
            ? taskData[key]
            : [];
        }
      } else if (key === "resources") {
        if (taskData[key] !== undefined) {
          updatesObj["/tasks/" + taskData.key + `/${key}`] =
            taskData[key] || [];
        }
      } else if (taskRestoreProps.includes(key)) {
        if (orderPropsOfTask.includes(key)) {
          updatesObj["/tasks/" + taskData.key + `/${key}`] =
            taskData[key] !== undefined ? taskData[key] : 0;
        } else {
          updatesObj["/tasks/" + taskData.key + `/${key}`] =
            taskData[key] || "";
        }
      }
    });

    if (restoreRecurrence && !isEmpty(taskData.recurrence)) {
      updatesObj["/tasks/" + taskData.key + "/recurrence"] =
        taskData.recurrence;
    }

    updatesObj["/tasks/" + taskData.key + "/key"] = taskData.key;

    if (!doNotUpdateModified) {
      updatesObj["/tasks/" + taskData.key + "/modified"] = getCurrDate();
    }
  }
};

/**
 * It takes two dates in string format and returns the order of date
 * @param {String} leftDate - date string to check (Format: 'YYYY-MM-DD')
 * @param {String} rightDate - date string to check by (Format: 'YYYY-MM-DD')
 * @returns {String|Number} dateOrder
 */

const getDateOrder = (leftDate, rightDate) => {
  if (leftDate === "none") {
    return 999;
  } else if (leftDate === rightDate) {
    return 1;
  } else if (leftDate < rightDate) {
    return 0;
  } else if (leftDate) {
    let day = parseInt(leftDate.split("-")[2]);
    let month = parseInt(leftDate.split("-")[1]);
    let refDay = parseInt(rightDate.split("-")[2]);
    let refMonth = parseInt(rightDate.split("-")[1]);
    if (month == refMonth && day > refDay) {
      return leftDate;
    } else if (month == increment(refMonth, 1) && day < refDay) {
      return leftDate;
    }
    return 1000;
  }
};

/**
 * It takes task's date and returns date formatted in 'MM/DD/YY' or 'None'
 * @param {String} taskDate - date string (Format: 'YYYY-MM-DD')
 * @returns {String} formatted date string
 */
const formatTaskDate = (taskDate) => {
  if (taskDate === "none" || !taskDate) {
    return "None";
  }
  const tmp = taskDate.split("-");
  return tmp[1] + "/" + tmp[2] + "/" + tmp[0].charAt(2) + tmp[0].charAt(3);
};

/**
 * It takes order of category and returns the category with its projects'
 * @param {Number} num - order of the category
 * @param {Object} projects - Collection of projects
 * @param {Object} categories - Collection of categories
 * @returns {Object} category with projects
 */
const getCategoryByOrder = (num, projects, categories) => {
  let rtn = {};
  for (let category in categories) {
    if (categories[category].order === num) {
      rtn = categories[category];
      break;
    }
  }
  if (rtn.children) {
    rtn.projects = [];
    for (let i = 0; i < rtn.children.length; i++) {
      rtn.projects[i] = projects[rtn.children[i]];
    }
  }
  return rtn;
};

/**
 * It takes collection of labels in string format and returns the proper group caption
 * @param {String} groupValue - collection of labels in string format
 * @param {Object} projects - Collection of projects
 * @param {Object} categories - Collection of categories
 * @returns {String} group caption
 */
export const createReviewGroupCaption = (groupValue, projects, categories) => {
  // Create review group label based on group category indexs;
  // let tmpThis = this;
  let rtn = "";

  if (groupValue) {
    let split = groupValue.split("-");
    if (parseInt(split[0]) === 999) {
      return "Inbox";
    }
    let tmp = getCategoryByOrder(parseInt(split[0]), projects, categories);
    rtn = tmp.title;

    let tmpParent = tmp;

    const theseProjects = tmpParent.projects;

    for (let i = 1; i < split.length; i++) {
      //console.debug(theseProjects)
      if (theseProjects) {
        //console.debug(tmpParent.projects[split[i]])
        //console.debug(tmpParent.projects[split[i]].title)
        if (theseProjects[split[i]]?.title) {
          // eslint-disable-next-line no-debugger
          // debugger;
          rtn += " / " + theseProjects[split[i]].title;
        } else {
          // eslint-disable-next-line no-debugger
          // debugger;
          rtn += " / " + projects[theseProjects[split[i]]]?.title;
        }
        // eslint-disable-next-line no-debugger
        // debugger;
        tmpParent = theseProjects[split[i]];
      }
    }
  }

  return rtn;
};

export const createReviewGroupLabel = (
  // groupValue,
  // groupKey,
  groupKey,
  projects,
  categories
) => {
  // Create review group label based on group category indexs;
  // let tmpThis = this;

  if (!groupKey) {
    return "Inbox";
  }

  return createProjectLabel(projects[groupKey], projects, categories);

  // if (groupValue) {
  //   let split = groupValue.split("-");
  //   if (parseInt(split[0]) === 999) {
  //     return "Inbox";
  //   }
  //   let tmp = getCategoryByOrder(parseInt(split[0]), projects, categories);
  //   rtn = tmp.title;

  //   let tmpParent = tmp;

  //   const theseProjects = tmpParent.projects;

  //   for (let i = 1; i < split.length; i++) {
  //     //console.debug(theseProjects)
  //     if (theseProjects) {
  //       //console.debug(tmpParent.projects[split[i]])
  //       //console.debug(tmpParent.projects[split[i]].title)
  //       if (theseProjects[split[i]]?.title) {
  //         // eslint-disable-next-line no-debugger
  //         // debugger;
  //         rtn += " / " + theseProjects[split[i]].title;
  //       } else {
  //         // eslint-disable-next-line no-debugger
  //         // debugger;
  //         rtn += " / " + projects[theseProjects[split[i]]]?.title;
  //       }
  //       // eslint-disable-next-line no-debugger
  //       // debugger;
  //       tmpParent = theseProjects[split[i]];
  //     }
  //   }
  // }

  // return rtn;
};

/**
 * It takes the task and returns the group caption and group order of the task
 * @param {Object} task - Task data
 * @param {Object} projects - Collection of projects
 * @param {Object} categories - Collection of categories
 * @returns {Object} group caption info
 */
const getReviewGroupOrder = (task, projects, categories) => {
  // Creates category indexs
  let rtn = categories[projects[task.project]?.category]?.order;
  let createOrderString = true;
  let groupLabel = "";
  if (categories[projects[task.project]?.category]?.title == "Inbox") {
    rtn = 999;
  }

  if (!task.project) {
    createOrderString = false;
    rtn = "ZZZZZZZ";
  }

  if (createOrderString) {
    let parent = projects[projects[task.project]?.subcategory];
    let mid = "";
    if (parent && parent.type) {
      let parentType = parent.type;
      while (parentType == "subcategory") {
        mid += parent.order;
        parent = projects[parent.subcategory];
        if (parent && parent.type) {
          parentType = parent.type;
        } else {
          parentType = "none";
        }
      }
    }
    mid = mid.split("");
    mid = mid.reverse();
    mid = mid.join("-");
    if (mid.length > 0) {
      rtn += "-" + mid + ("-" + projects[task.project].order);
    } else {
      rtn += "-" + projects[task.project]?.order;
    }
    // groupLabel = createReviewGroupCaption(rtn, projects, categories);
  }

  const categoryData = categories[projects[task.project]?.category];
  return {
    groupValue: rtn,
    groupLabel,
    groupKey: task.project,
    showLabel: createOrderString,
    categoryKey: categoryData?.key,
    categoryHash: !isEmpty(categoryData) ? `${categoryData.order}` : undefined,
  };
};

const processTaskByDate = (task) => {
  const currDate = new Date();
  const currDue = parseISO(formatDate(currDate, INTERNAL_DATE_FORMAT));

  if (!task.due) {
    task.due = "none";
  }
  if (task.due === "none" && task.flag) {
    task["CTIGroup"] = "1"; // Value for Flagged tasks
    task["CTIGroupCaption"] = "Flagged"; // Label for Flagged tasks
    // task["CTICustomGroup"] = "2";
    // Upcoming group
    task["CTICustomGroup"] = "2";
    // task["CTICustomGroupCaption"] = "Flagged"; // Label for Flagged tasks
    task["CTICustomGroupCaption"] = "Pending"; // Label for Upcoming tasks
    // Added property to sort by modified date;
    task["CTISortDate"] = task.modified;
  } else if (task.due === "none" && !task.flag) {
    task["CTIGroup"] = "2"; // Value for Un-Flagged tasks
    task["CTIGroupCaption"] = "Unflagged"; // Label for Un-Flagged Tasks;
    // task["CTICustomGroup"] = "3";
    // Upcoming group
    task["CTICustomGroup"] = "2";
    // task["CTICustomGroupCaption"] = "Unflagged"; // Label for Unflagged tasks
    task["CTICustomGroupCaption"] = "Pending";
    task["CTISortDate"] = task.created;
    // Added property to sort by created date;
  } else {
    task["CTIGroup"] = "0"; // Value for Scheduled Tasks
    task["CTIGroupCaption"] = "Scheduled"; // Label for Scheduled tasks
    task["CTISortDate"] = task.due;
    task["CTICustomGroup"] = "0"; // Value for Scheduled Tasks
    task["CTICustomGroupCaption"] = "Scheduled"; // Label for Scheduled tasks
    // Added property to sort by due date;
  }

  if (task.due !== "none" && isTaskOlder(parseISO(task.due), 90)) {
    task.isOlderTask = true;
  }

  // if (task.isContingent) {
  //   task["CTIGroup"] = "0"; // Value for Scheduled Tasks
  //   task["CTIGroupCaption"] = "Scheduled"; // Label for Scheduled tasks
  //   task["CTICustomGroup"] = "0"; // Value for Scheduled Tasks
  //   task["CTICustomGroupCaption"] = "Scheduled"; // Label for Scheduled tasks
  //   task["TodayGroup"] = "01Today"; // Value for Scheduled Tasks
  //   task["TodayGroupCaption"] = "Today"; // Label for Scheduled tasks

  //   if (task.due === "none") {
  //     task["CalendarGroup"] = "01Today"; // Value for tasks with current due date
  //     task["CalendarGroupCaption"] = "Today"; // Label for tasks with current due date
  //     task["CalendarGroupParseCaption"] = true;
  //   }

  //   task.contingentOrder = "1";
  // } else {
  //   task.contingentOrder = "0";
  // }
  if (task.due === "none" && task.isCustomPositioned) {
    // && !task.isContingent
    task.CTICustomGroup = "1";
    task.CTICustomGroupCaption = "Next Actions (drag to order)";
  }

  let taskDateToUse = task.due;
  let showAsReminder = false;
  if (isValidDate(task.reminderDate)) {
    showAsReminder = true;
    taskDateToUse = formatDate(task.reminderDate, INTERNAL_DATE_FORMAT);
  }
  //Calendar Sort Grouping
  const tmp = getDateOrder(taskDateToUse, getCurrDate("default"));

  if (tmp == 0) {
    task.CTICustomDateGroup = "00Past Due";
    task.CTICustomDateGroupCaption = "Past Due";
    task["CalendarGroup"] = "00Past Due"; // Value for due date passed tasks
    task["CalendarGroupCaption"] = "Past Due"; // Label for due date passed tasks
    task.showReminder = false;
  } else if (tmp == 1) {
    task.CTICustomDateGroup = "01Today";
    task.CTICustomDateGroupCaption = "Today";
    task["CTICustomDateGroupParseCaption"] = true;
    task["CalendarGroup"] = "01Today"; // Value for tasks with current due date
    task["CalendarGroupCaption"] = "Today"; // Label for tasks with current due date
    task["TodayGroup"] = "01Today"; // Value for tasks with current due date
    task["TodayGroupCaption"] = "Today"; // Label for tasks with current due date
    task["CalendarGroupParseCaption"] = true;
    task.showInToday = showAsReminder;
  } else if (tmp === 1000) {
    if (!showAsReminder) {
      task["CalendarGroup"] = "04"; // Value for tasks due in future
      task["CalendarGroupCaption"] = "Future";

      task.CTICustomDateGroup = "04";
      task.CTICustomDateGroupCaption = "Future";
      task.showReminder = false;
    }
  } else if (tmp === 999) {
    if (!showAsReminder) {
      task["CalendarGroup"] = "05"; // Value for tasks due in future
      task["CalendarGroupCaption"] = "Unscheduled";
      task.CTICustomDateGroup = "05";
      task.CTICustomDateGroupCaption = "Unscheduled";
      task.showReminder = false;
    }
  } else {
    task.showReminder = showAsReminder;
    task["CalendarGroup"] = "03" + formatTaskDate(tmp);
    task["CalendarGroupParseCaption"] = true;
    task["CalendarGroupCaption"] = formatTaskDate(tmp); // date value for tasks due in that particular date
    // task["CalendarGroup"] = this.formatDate(tmp);

    task.CTICustomDateGroup = "03" + formatTaskDate(tmp);
    task["CTICustomDateGroupParseCaption"] = true;
    task.CTICustomDateGroupCaption = formatTaskDate(tmp);
  }

  task["dueAsDate"] = new Date(task.due + "T" + (task.time || "00:00"));

  if (
    task.isContingent &&
    !task.completed &&
    task.due === "none"
    // ((isValidDate(task.dueAsDate) &&
    //   (isSameDay(parseDate(task.due, INTERNAL_DATE_FORMAT), currDue) ||
    //     isBefore(parseDate(task.due, INTERNAL_DATE_FORMAT), currDue))) ||
    //   task.due === "none")
  ) {
    task["TodayGroup"] = "01Today"; // Value for Scheduled Tasks
    task["TodayGroupCaption"] = "Today"; // Label for Scheduled tasks

    // if (task.due === "none") {
    task["CalendarGroup"] = "01Today"; // Value for tasks with current due date
    task["CalendarGroupCaption"] = "Today"; // Label for tasks with current due date
    task["CalendarGroupParseCaption"] = true;
    task.showContingentInToday = true;
    task.CTICustomDateGroup = "00000ContingentGroup";
    task.CTICustomDateGroupCaption = "Contingents";
    task["CTIGroup"] = "0"; // Value for Scheduled Tasks
    task["CTIGroupCaption"] = "Scheduled"; // Label for Scheduled tasks
    // task["CTISortDate"] = task.due;
    task["CTICustomGroup"] = "0"; // Value for Scheduled Tasks
    task["CTICustomGroupCaption"] = "Scheduled"; // Label for Scheduled tasks
    // }
  } else {
    task.showContingentInToday = false;
  }
  // if (task.isContingent) {
  //   task["TodayGroup"] = "01Today"; // Value for Scheduled Tasks
  //   task["TodayGroupCaption"] = "Today"; // Label for Scheduled tasks

  //   if (task.due === "none") {
  //     task["CalendarGroup"] = "01Today"; // Value for tasks with current due date
  //     task["CalendarGroupCaption"] = "Today"; // Label for tasks with current due date
  //     task["CalendarGroupParseCaption"] = true;
  //   }
  // }

  // if (task.CalendarGroup && task.CalendarGroup !== "05") {
  //   task.CalendarGroupShowLast = task.isContingent ? "1" : "0";
  // }

  task.contingentOrder = task.showContingentInToday ? "0" : "1";

  if (task.showReminder || task.showInToday) {
    task.reminderTaskOrder = "0";
  } else {
    task.reminderTaskOrder = task.showContingentInToday ? "1" : "2";
  }

  return task;
};

const processTaskByProject = (task, projects, categories) => {
  //review Sort Grouping
  // Creates value and label for tasks grouped by projects, categories, sub categories
  const {
    groupValue,
    groupLabel,
    showLabel,
    groupKey,
    categoryKey,
    categoryHash,
  } = getReviewGroupOrder(task, projects, categories);
  task["ReviewGroup"] = groupValue;
  task.ReviewGroupKey = groupKey;
  task["ReviewGroupCaption"] = groupLabel;
  task["ReviewGroupCaptionShow"] = showLabel;
  task.CategoryGroup = categoryHash;
  task.CategoryGroupKey = categoryKey;
  task.CategoryGroupCaptionShow = showLabel;
  task["projectName"] = projects[task.project]?.title;
  task["flagDisplay"] = "<v-icon>mdi-flag</v-icon>";
  task.subcategory =
    projects[task.project]?.subcategory || task.project || undefined;
  task.category = projects[task.project]?.category;
  task.projectTitle = projects[task.project]?.title;
  task.categoryTitle = categories[projects[task.project]?.category]?.title;
  task.projectCompleted = projects[task.project]?.hidden || false;
  task.projectHidden = projects[task.project]?.deleted || false;
  task.priority = projects[task.project]?.priority || "none";

  if (task.due === "none" && task.isCustomPositioned) {
    // && !task.isContingent
    task.areaWiseOrder = `${task.ReviewGroup}-${task.customPosition}-${task.customPositionIndex}`;
  }

  return task;
};

const setTaskType = (taskData) => {
  const isTaskContinued = checkIfTaskIsContinued(taskData);
  const isTaskRecurring = checkIfTaskIsRecurring(taskData);
  const isTaskRepeatIn = checkIfTaskIsRepeatIn(taskData);

  let taskTypeToSet = "normal";

  if (isTaskRepeatIn || isTaskRecurring) {
    taskTypeToSet = "repeat";
  } else if (isTaskContinued) {
    taskTypeToSet = "continued";
  }

  return taskTypeToSet;
};

const processTaskByAll = (task, projects, categories) => {
  task = processTaskByDate(task);
  task = processTaskByProject(task, projects, categories);
  task.createdAsDate = convertDateTimeStrToDate(task.created);
  task.modifiedAsDate = convertDateTimeStrToDate(task.modified);
  task.resourceTypes = getAllResourceTypeUsedInTask(task.resources);
  task.type = setTaskType(task);
  return task;
};

/**
 * It takes the task and returns the task with its grouping data and some othe properties
 * @param {Object} task - Task data
 * @param {Object} projects - Collection of projects
 * @param {Object} categories - Collection of categories
 * @returns {Object} task with additional properties
 */
export const processTask = (
  task,
  projects = store.getters["task/projects"],
  categories = store.getters["task/categories"],
  processBy = "default"
) => {
  let clonedTask = cloneDeep(task);
  switch (processBy) {
    case "date":
      clonedTask = processTaskByDate(clonedTask);
      break;
    case "project":
      clonedTask = processTaskByProject(clonedTask, projects, categories);
      break;
    default:
      clonedTask = processTaskByAll(clonedTask, projects, categories);
      break;
  }

  if (typeof clonedTask.completed === "boolean") {
    clonedTask.class = clonedTask.completed ? "complete" : "incomplete";
  }

  return clonedTask;

  // if (task.due === "none" && task.flag) {
  //   task["CTIGroup"] = "1"; // Value for Flagged tasks
  //   task["CTIGroupCaption"] = "Flagged"; // Label for Flagged tasks
  //   // Added property to sort by modified date;
  //   task["CTISortDate"] = task.modified;
  // } else if (task.due === "none" && !task.flag) {
  //   task["CTIGroup"] = "2"; // Value for Un-Flagged tasks
  //   task["CTIGroupCaption"] = "Un-Flagged"; // Label for Un-Flagged Tasks;
  //   task["CTISortDate"] = task.created;
  //   // Added property to sort by created date;
  // } else {
  //   task["CTIGroup"] = "0"; // Value for Scheduled Tasks
  //   task["CTIGroupCaption"] = "Scheduled"; // Label for Scheduled tasks
  //   task["CTISortDate"] = task.due;
  //   // Added property to sort by due date;
  // }
  // //Calendar Sort Grouping
  // const tmp = getDateOrder(task.due, getCurrDate());
  // if (tmp == 0) {
  //   task["CalendarGroup"] = "00Past Due"; // Value for due date passed tasks
  //   task["CalendarGroupCaption"] = "Past Due"; // Label for due date passed tasks
  // } else if (tmp == 1) {
  //   task["CalendarGroup"] = "01Today"; // Value for tasks with current due date
  //   task["CalendarGroupCaption"] = "Today"; // Label for tasks with current due date
  // } else if (tmp === 1000) {
  //   task["CalendarGroup"] = "04"; // Value for tasks due in future
  //   task["CalendarGroupCaption"] = "Future";
  // } else if (tmp === 999) {
  //   task["CalendarGroup"] = "05"; // Value for tasks due in future
  //   task["CalendarGroupCaption"] = "Unscheduled";
  // } else {
  //   task["CalendarGroup"] = "03" + formatTaskDate(tmp);
  //   task["CalendarGroupCaption"] = formatTaskDate(tmp); // date value for tasks due in that particular date
  //   // task["CalendarGroup"] = this.formatDate(tmp);
  // }
  // //review Sort Grouping
  // // Creates value and label for tasks grouped by projects, categories, sub categories
  // const { groupValue, groupLabel, showLabel } = getReviewGroupOrder(
  //   task,
  //   projects,
  //   categories
  // );
  // task["ReviewGroup"] = groupValue;
  // task["ReviewGroupCaption"] = groupLabel;
  // task["ReviewGroupCaptionShow"] = showLabel;
  // task["projectName"] = projects[task.project]?.title;
  // task["flagDisplay"] = "<v-icon>mdi-flag</v-icon>";
  // task.subcategory =
  //   projects[task.project]?.subcategory || task.project || undefined;
  // task.category = projects[task.project]?.category;
  // task.projectTitle = projects[task.project]?.title;
  // task.categoryTitle = categories[projects[task.project]?.category]?.title;
  // return task;
};

/**
 * It takes the list of tasks and processes each task item and returns the list
 * @param {Object[]} taskList - List of tasks
 * @param {Object} projects - Collection of projects
 * @param {Object} categories - Collection of categories
 * @returns {Object[]} list of tasks
 */
export const processTaskList = (
  taskList = [],
  projects = {},
  categories = {},
  processBy = ""
) => {
  return taskList.map((task) =>
    processTask(task, projects, categories, processBy)
  );
};

/**
 * It takes the list of tasks and processes each task item and returns the list
 * @param {String[]} dateList - List of due dates
 * @returns {Object} due date filter object
 */
export const createDueDateListFilter = (datesList) => ({
  field: "due",
  type: "in",
  value: datesList || [],
});

export const createContingentFilter = () => ({
  field: "isContingent",
  type: "=",
  value: true,
});

export const createProjectsOrCategoriesFilters = (list) => {
  if (list && list.length) {
    list = list
      .map((l) => {
        if (l.type === "project") {
          return {
            field: "project",
            type: "=",
            value: l.id,
          };
        }

        if (l.type === "category") {
          return {
            field: "category",
            type: "=",
            value: l.id,
          };
        }

        if (l.type === "sub-category") {
          return {
            field: "subcategory",
            type: "=",
            value: l.id,
          };
        }
      })
      .filter((v) => !!v);
  }

  return list;
};

export const createTaskSearchQueryFilter = (searchQuery) => {
  const basicQueryProps = {
    type: "like",
    value: searchQuery,
  };

  const parsedStr = escapeHtmlString(searchQuery);
  const filters = [
    {
      ...basicQueryProps,
      field: "title",
      value: parsedStr,
    },
    {
      ...basicQueryProps,
      field: "priority",
    },
    {
      ...basicQueryProps,
      field: "taskType",
    },
    {
      ...basicQueryProps,
      field: "projectTitle",
    },
    {
      ...basicQueryProps,
      field: "categoryTitle",
    },
    {
      ...basicQueryProps,
      field: "description",
      value: parsedStr,
    },
  ];

  return filters;
};

export const createTaskListFiltersFromFiltersMap = (listFilters) => {
  let filters = [];
  if (!isEmpty(listFilters)) {
    for (const filter in listFilters) {
      const list = listFilters[filter];
      if (list && list.length) {
        if (filter === "resourceTypes") {
          filters.push({
            field: filter,
            type: "includes",
            value: list,
          });
        } else {
          filters.push({
            field: filter,
            type: "in",
            value: list,
          });
        }
      }
    }
  }

  return filters;
};

export const createLabelForProjectOrCategoriesFilter = (
  selectedOpts,
  projects,
  categories
) => {
  let label = "";

  if (!isEmpty(selectedOpts)) {
    if (selectedOpts.length === 1) {
      const selectedItem = selectedOpts[0];
      if (
        selectedItem.type === "category" &&
        selectedItem.subCategoryId == "all" &&
        selectedItem.projectId == "all"
      ) {
        //display all tasks in specified category
        label = "in " + categories[selectedItem.id]?.title;
      } else if (
        selectedItem.type === "project" &&
        selectedItem.subCategoryId === "all"
        // selectedItem.selectedProject != "all"
      ) {
        //display all tasks in specified project
        label =
          "in " +
          categories[projects[selectedItem.id].category]?.title +
          "/" +
          projects[selectedItem.id].title;
      } else if (
        selectedItem.type === "sub-category" &&
        selectedItem.projectId === "all"
        // selectedItem.selectedProject == "all"
      ) {
        //display all tasks in specified subcategory
        label =
          "in " +
          categories[projects[selectedItem.id].category]?.title +
          "/" +
          projects[selectedItem.id].title;
      } else if (selectedItem.type === "project" && selectedItem.id !== "all") {
        //display all tasks in specified project that is in a subcategory
        const category1 =
          categories[projects[selectedItem.id]?.category]?.title;
        const category2 = projects[selectedItem.subCategoryId]?.title;
        const project = projects[selectedItem.id]?.title;

        // Added check to make sure all values exists before creating the string
        if (category1 && category2 && project) {
          label = `in ${category1}/${category2}/${project}`;
        }
      }
    } else {
      label = "in All Selected Categories";
    }
  }

  return label;
};

export const createLabelForSelectedDatesFilter = (selectedDates) => {
  let label = "";
  if (!isEmpty(selectedDates)) {
    if (selectedDates.length === 1) {
      if (selectedDates[0] === getCurrDate("default")) {
        label = "for Today";
      } else {
        label =
          "for " +
          parseAndFormatDate(selectedDates[0], "yyyy-MM-dd", "MM/dd/yy");
      }
    } else {
      label = " for Selected Dates";
    }
  }

  return label;
};

const checkTaskFields = (fields, fieldsToCheck) =>
  fields.some((field) => fieldsToCheck.includes(field));
export const fieldRequriesProcessing = (fieldsInTask, fieldsToCheck) =>
  checkTaskFields(fieldsInTask, fieldsToCheck);
export const taskContainsFieldsForDateProcessing = (
  fieldsInTask,
  fieldsToCheck
) => checkTaskFields(fieldsInTask, fieldsToCheck);
export const taskContainsFieldsForProjectProcessing = (
  fieldsInTask,
  fieldsToCheck
) => checkTaskFields(fieldsInTask, fieldsToCheck);

/**
 * since we removed inbox from project we need this
 * @param {String} project
 * @returns {Boolean} isProject
 */
export function isProjectInbox(project) {
  if (!project) project = "inbox";
  project = project.toLowerCase();
  return project === "inbox";
}

export function getNewID() {
  return createUniqueId();
}

/**
 *
 * @param {Object} task
 * @param {Number} tasksCount
 * @returns {Object} newTask
 */
export function duplicateTask(task, tasksCount = 0) {
  const today = getCurrDate();
  return {
    completed: false,
    created: today,
    description: task.description || "",
    dueAsDate: task.dueAsDate || "",
    dueAsDateTime: task.dueAsDateTime || "",
    due: task.due || "none",
    flag: (task.due && task.due !== "none") || task.flag,
    key: getNewID(),
    modified: today,
    order: tasksCount,
    priority: task.priority || "",
    project: task.project || "",
    status: task.status,
    taskType: task.taskType || "task",
    time: task.time || "00:00",
    title: task.title,
    frequency: task.frequency || "",
    recurrence: task.recurrence || [],
    isContingent: task.isContingent || false,
    userPosition: task.userPosition || 0,
    objective: task.objective || "",
    repeatInfo: !isEmpty(task.repeatInfo) ? task.repeatInfo : {},
    reminderDays: task.reminderDays || null,
    pausedReminderDays: getPausedReminderDaysVal(task.pausedReminderDays),
    positionChanged: task.positionChanged || false,
    resources: task.resources || [],
    isCustomPositioned: task.isCustomPositioned || false,
    customPosition: task.customPosition || 0,
    customPositionIndex: task.customPositionIndex || 0,
  };
}

export function updateDueDate(
  tasks,
  updates,
  totalTasks = 0,
  isGoogleCalSyncEnabled
) {
  return updateTaskFields(tasks, updates, totalTasks, isGoogleCalSyncEnabled);
}

export function updateTaskFields(
  tasks,
  updates,
  totalTasks = 0,
  isGoogleCalSyncEnabled
) {
  // updating order of new tasks being added
  let newTaskAdded = 0;

  // task updates in db
  let taskUpdates = {};

  // adding new tasks in db
  const newTasks = {};

  // adding action history
  const editRowList = [];

  // keys to deselect in grid
  const taskKeys = [];

  // processed task for grid updates
  const updatedTasks = [];

  // new values to be added
  const taskUpdate = updatesToApply(updates);

  const tasksToAddInGoogleCal = [];
  const tasksToUpdateInGoogleCal = [];

  tasks.forEach((task) => {
    taskKeys.push(task.key);

    if (task.completed) {
      newTaskAdded++;
      const newTask = duplicateTask(
        { ...task, ...taskUpdate },
        totalTasks + newTaskAdded
      );
      newTasks[newTask.key] = newTask;

      if (isGoogleCalSyncEnabled) {
        if (task.dueAsDate && isValidDate(new Date(task.dueAsDate))) {
          newTasks[newTask.key].googleEventId = convertTaskKeyForGoogleCal(
            newTask.key
          );

          newTasks[newTask.key].googleCalendarId =
            GoogleCalHelpers.getStoredCalId();
          const createdTaskData = {
            ...task,

            dueAsDate: createDateTimeFromDue(task.due, task.time),
          };

          if (
            taskUpdate.due &&
            isValidDate(parseDate(taskUpdate.due, INTERNAL_DATE_FORMAT))
          ) {
            createdTaskData.dueAsDate = createDateTimeFromDue(
              taskUpdate.due,
              taskUpdate.time
            );
          }

          tasksToAddInGoogleCal.push({
            key: newTask.key,
            data: {
              ...createTaskDataForGoogleCal({
                ...createdTaskData,
                taskKey: newTask.key,
              }),
              taskKey: newTask.key,
            },
          });
        }
      }
    } else {
      const { oldTaskData, newTaskData, newTask } = updateTask(
        task,
        taskUpdate
      );

      editRowList.push(oldTaskData);

      if (isGoogleCalSyncEnabled) {
        const createdNewTaskData = {
          ...newTask,
        };

        createdNewTaskData.dueAsDate = createDateTimeFromDue(
          newTask.due,
          newTask.time
        );
        const createdCalData =
          createDataForAddOrRemoveOrUpdateInGoogleCalByTaskData({
            key: task.key,
            updates: {
              ...createdNewTaskData,
            },
            currData: {
              ...task,
            },
          });

        if (createdCalData.tasksToAdd && createdCalData.tasksToAdd.length) {
          const googleKey = convertTaskKeyForGoogleCal(task.key);
          const googelCalId = GoogleCalHelpers.getStoredCalId();
          newTaskData[`/tasks/${task.key}/googleEventId`] = googleKey;
          newTaskData[`/tasks/${task.key}/googleCalendarId`] = googelCalId;
          newTask.googleEventId = googleKey;
          newTask.googleCalendarId = googelCalId;
        }
        tasksToAddInGoogleCal.push(...createdCalData.tasksToAdd);
        tasksToUpdateInGoogleCal.push(...createdCalData.tasksToUpdate);
      }

      taskUpdates = {
        ...taskUpdates,
        ...newTaskData,
      };
      updatedTasks.push(processTask(newTask));
    }
  });

  return {
    newTasks,
    taskUpdates,
    editRowList,
    taskKeys,
    updatedTasks,
    tasksToAddInGoogleCal,
    tasksToUpdateInGoogleCal,
  };
}

function exists(value) {
  return value !== undefined && value !== null;
}

const acceptedValues = ["time", "due", "project", "taskType", "priority"];

function updatesToApply(updates) {
  const taskUpdate = {
    modified: getCurrDate(),
  };

  for (let acceptedValue of acceptedValues) {
    if (exists(updates[acceptedValue])) {
      taskUpdate[acceptedValue] = updates[acceptedValue];
    }
  }

  if (exists(updates.date)) {
    taskUpdate.due = updates.date;
  }

  return taskUpdate;
}

function updateTask(task, updatesToApply) {
  let flagged = task.flag;

  /**
   * Mark task as flagged
   * whenn scheduling unscheduled task only
   */
  if (
    updatesToApply.due &&
    isValidDate(new Date(updatesToApply.due)) &&
    !isValidDate(new Date(task.due))
  ) {
    flagged = true;
  }

  const oldTaskData = { key: task.key };
  const newTaskData = {};
  const newTask = {
    ...task,
  };

  for (let key of acceptedValues) {
    if (exists(updatesToApply[key])) {
      oldTaskData[key] = task[key];
      newTaskData[`/tasks/${task.key}/${key}`] = updatesToApply[key];
      newTask[key] = updatesToApply[key];
    }
  }

  if (updatesToApply.due !== undefined) {
    newTaskData[`/tasks/${task.key}/status`] = "scheduled";
    newTask.status = "scheduled";
  }

  if (flagged !== task.flag) {
    oldTaskData.flag = task.flag;
    newTaskData[`/tasks/${task.key}/flag`] = flagged;
    newTask.flag = flagged;
  }

  newTaskData[`/tasks/${task.key}/modified`] = updatesToApply.modified;
  newTask.modified = updatesToApply.modified;

  oldTaskData.changedProps = updatesToApply;
  return { oldTaskData, newTaskData, newTask };
}

export const callRowsUpdate = (rowsToUpdate) => {
  if (!Array.isArray(rowsToUpdate)) rowsToUpdate = [rowsToUpdate];
  rowsToUpdate = rowsToUpdate.map((row) => ({ key: row.key, updates: row }));
  EventEmitter.emit(TASK_UPDATED_EVENT, rowsToUpdate);
};

export const callDeselectRows = (taskKeys) =>
  EventEmitter.emit(DESELECT_TASKS_EVENT, taskKeys);

export const callTasksRemove = (tasks, triggerRefresh = true) => {
  if (!Array.isArray(tasks)) tasks = [tasks];

  addOrRemoveOrUpdateTasksInLocalTasksList(
    {
      tasksToRemove: tasks,
    },
    triggerRefresh
  );

  if (triggerRefresh) {
    Vue.nextTick(() => {
      EventEmitter.emit(REFRESH_ACTIVITY_HISTORY);
    });
  }
};

export const callAddTasks = (tasks) => {
  EventEmitter.emit(ADD_TASK_EVENT, tasks);
};

// addOrUpdateOrRemoveTasksInGoogleCal(
export async function updateTasks(args) {
  const {
    newTasks,
    taskUpdates,
    editRowList,
    // eslint-disable-next-line no-unused-vars
    taskKeys,
    // eslint-disable-next-line no-unused-vars
    updatedTasks,
    userId,
    tasksToUpdateInGoogleCal,
    tasksToAddInGoogleCal,
  } = args;

  if (!isEmpty(taskUpdates)) {
    DatabaseInterface.update(taskUpdates, userId);

    getUserActions().addTaskAction({
      type: TASK_ACTIONS.BATCH_EDIT,
      data: editRowList,
    });

    // callRowsUpdate(updatedTasks);
    // EventEmitter.emit("update-task", updatedTasks);
  }

  if (!isEmpty(newTasks)) {
    DatabaseInterface.addTo(`/${userId}/tasks/`, newTasks);
    getUserActions().addTaskAction({
      type: TASK_ACTIONS.BATCH_ADD,
      data: Object.values(newTasks),
    });
  }

  addOrUpdateOrRemoveTasksInGoogleCal(
    {
      tasksToUpdate: tasksToUpdateInGoogleCal,
      tasksToAdd: tasksToAddInGoogleCal,
    },
    false
  );

  return true;
}

/**
 * It removes the predefined list of properties from the task object and returns the object
 * @param {Object} task- Task object
 * @returns {Object}
 */
export const cleanTask = (task, extraPropsToRemove = []) => {
  let propsToRemove = taskPropsToRemove;
  if (!isEmpty(extraPropsToRemove)) {
    propsToRemove = [...propsToRemove, ...extraPropsToRemove];
  }
  return removeProps(task, propsToRemove);
};

/**
 * It tasks the list of tasks and returns a list of tasks without any unnecessary property
 * @param {Object[]} tasksToClean- List of tasks to clean
 * @returns {Object[]}
 */
export const cleanTasks = (tasksToClean) => {
  if (!Array.isArray(tasksToClean)) tasksToClean = [tasksToClean];
  const clonedTaskList = cloneDeep(tasksToClean);
  return clonedTaskList.map((task) => cleanTask(task));
};

/**
 * It creates data required for add action
 * @param {Object[]} tasks- list of tasks to add
 * @returns {Object[]}
 */
export const createAddedTaskDataForAddAction = (tasks) => {
  if (!Array.isArray(tasks)) tasks = [tasks];

  return tasks.map((task) => ({
    key: task.key,
    project: task.project ? task.project : null,
  }));
};

/**
 * It added the provided list of tasks to db
 * @param {Object[]} tasksToAdd - list of tasks to add
 * @param {String} userId - Id of user
 * @returns {Promise}
 */
export const addTasks = async (tasksToAdd, userId) => {
  if (isEmpty(tasksToAdd)) {
    throw new Error("Invalid task collection!");
  }
  if (!Array.isArray(tasksToAdd)) tasksToAdd = [tasksToAdd];

  const taskCollection = tasksToAdd.reduce((a, v) => {
    a[v.key] = v;
    return a;
  }, {});
  const res = await DatabaseInterface.addTo(
    `/${userId}/tasks/`,
    taskCollection
  );
  return res;
};

/**
 * It removes the provided list of tasks from db
 * @param {Object[]} tasksToDelete - list of tasks to delete
 * @param {String} userId - Id of user
 * @returns {Promise}
 */
export const deleteTasks = async (tasksToDelete, userId) => {
  if (!Array.isArray(tasksToDelete)) tasksToDelete = [tasksToDelete];
  const updates = tasksToDelete.reduce((a, v) => {
    a[`/tasks/${v.key}`] = null;
    return a;
  }, {});
  const res = DatabaseInterface.update(updates, userId);
  return res;
};

/**
 * It takes the task data and returns the data with default values for missing properties
 * @param {Object} taskData - Task Data
 * @returns {Object} task data with default values for missing properties
 */
export const fillTaskData = (taskData, extraData = {}, checkForProject) => {
  let createdData = {
    title: taskData.title || "",
    key: taskData.key || createTaskId(),
    created: taskData.created || getCurrDate(),
    modified: taskData.modified || getCurrDate(),
    due: taskData.due || "none",
    completed:
      typeof taskData.completed === "boolean" ? taskData.completed : false,
    status: taskData.status || "incomplete",
    flag: typeof taskData.flag === "boolean" ? taskData.flag : false,
    description: taskData.description || "",
    resources: taskData.resources || [],
    order: taskData.order || 0,
    time: taskData.time || "00:00",
    priority: taskData.priority || "priority",
    taskType: taskData.taskType || "task",
    project: taskData.project || "",
    googleEventId: taskData.googleEventId || "",
    frequency: taskData.frequency || "",
    linkedTo: taskData.linkedTo || "",
    coRelationalId: taskData.coRelationalId || "",
    recurrence: !isEmpty(taskData.recurrence) ? taskData.recurrence : [],
    isFromGoogle: taskData.isFromGoogle || false,
    isContingent: taskData.isContingent || false,
    completedOn: taskData.completedOn || "",
    userPosition: taskData.userPosition || 0,
    repeatInfo: !isEmpty(taskData.repeatInfo) ? taskData.repeatInfo : {},
    objective: taskData.objective || "",
    reminderDays: taskData.reminderDays || null,
    pausedReminderDays: getPausedReminderDaysVal(taskData.pausedReminderDays),
    positionChanged: taskData.positionChanged || false,
    isCustomPositioned: taskData.isCustomPositioned || false,
    customPosition: taskData.customPosition || 0,
    customPositionIndex: taskData.customPositionIndex || 0,
  };

  // if (!isEmpty(taskData.recurrence)) {
  //   createdData.recurrence = taskData.recurrence;
  // }
  if (createdData.coRelationalId) {
    createdData.isFirstTask = taskData.isFirstTask || false;
  }

  if (checkForProject && !createdData.project) {
    createdData.priority = "purpose";
  }

  if (createdData.googleEventId) {
    createdData.googleCalendarId = GoogleCalHelpers.getStoredCalId();
  }
  createdData = {
    ...createdData,
    ...extraData,
  };

  return createdData;
};

export const getUpdatedTaskProps = (
  taskData,
  propsToUpdate,
  restoreRecurrence
) => {
  const updates = {};

  propsToUpdate.forEach((key) => {
    if (
      booleanPropsOfTask.includes(key) &&
      typeof taskData[key] === "boolean"
    ) {
      updates[key] = taskData[key];

      if (key === "completed") {
        updates.keepItVisible = !!taskData[key];
      }
    } else if (key === "repeatInfo") {
      if (taskData[key] !== undefined) {
        updates[key] = !isEmpty(taskData[key]) ? taskData[key] : {};
      }
    } else if (key === "reminderDays") {
      if (taskData[key] !== undefined) {
        updates[key] = taskData[key] || null;
      }
    } else if (key === "pausedReminderDays") {
      if (taskData[key] !== undefined) {
        updates[key] = taskData[key] || [];
      }
    } else if (taskRestoreProps.includes(key)) {
      if (taskData[key] !== undefined) {
        if (key === "resources") {
          updates.resources = taskData[key] || [];
        } else if (orderPropsOfTask.includes(key)) {
          updates[key] = taskData[key] !== undefined ? taskData[key] : 0;
        } else {
          updates[key] = taskData[key] || "";
        }
      }
    }
  });

  if (restoreRecurrence && !isEmpty(taskData.recurrence)) {
    updates.recurrence = taskData.recurrence;
  }

  return updates;
};

export const convertTaskDescrStringToHTMLString = (str) => {
  let strToRtn = str && str.trim();

  if (strToRtn) {
    strToRtn = escapeHtmlString(strToRtn);
    if (checkIfStringContainsNewLine(strToRtn)) {
      strToRtn = replaceCharInString(strToRtn, /\r|\n/g, "</p><p>");
    }
  }

  return `<p>${strToRtn}</p>`;
};
export const getProjectByName = (
  nameToSearch,
  allProjects = store.getters["task/projects"]
) => {
  nameToSearch = nameToSearch && nameToSearch.toLowerCase();
  const project = Object.values(allProjects).find(
    (proj) => proj.title.toLowerCase() === nameToSearch
  );
  return project;
};

export const createTasksDataFromParsedCSV = (
  results,
  isGoogleCalSyncEnabled
) => {
  const nullVals = [undefined, null, ""];
  const { data } = results;
  const [headers, ...rows] = data;
  let currTasksCount = store.getters["task/rawTasks"].length;
  const updates = {};
  rows.forEach((row) => {
    let data = {
      key: createTaskId(),
    };

    if (!nullVals.includes(row[0])) {
      let status = "incomplete";
      let titleCopyToDescr = false;
      let titleTextToStoreInDescr = "";
      headers.forEach((header, index) => {
        if (TASK_PROPS_TO_PARSE[header]) {
          const prop = TASK_PROPS_TO_PARSE[header];

          if (prop.field === "priority") {
            data.priority = (row[index] || "").toLowerCase() || "priority";
            // data.priority = "priority";
          } else if (prop.type === "date") {
            if (TASK_PARSE_DATE_PROPS_NEEDS_EXTRA.includes(prop.field)) {
              if (prop.field === "completed") {
                let completed = false;
                let completedOn = "";
                const parsedDate = parseDate(
                  row[index],
                  DATE_TIME_FORMAT_IN_FILE
                );
                if (isValidDate(parsedDate)) {
                  completed = true;
                  status = "completed";
                  completedOn = formatISO(parsedDate);
                }
                data.completed = completed;
                data.completedOn = completedOn;
              }

              if (prop.field === "due") {
                const date = parseDate(row[index], DATE_TIME_FORMAT_IN_FILE);
                let isFlagged = false;
                if (isValidDate(date)) {
                  status = "scheduled";
                  isFlagged = true;
                }
                data = {
                  ...data,
                  flag: isFlagged,
                  ...getDateAndTimeFromDueDate(date),
                };
              }
            } else {
              const date = parseDate(row[index], DATE_TIME_FORMAT_IN_FILE);

              data[prop.field] = isValidDate(date)
                ? formatDate(date, INTERNAL_DATE_FORMAT)
                : "";
              // if (isValidDate(date)) {
              //   data[prop.field] = ;
              // }
            }
          } else if (prop.type === "string") {
            if (prop.field === "title") {
              const rowTitle = (row[index] && row[index].trim()) || "";
              if (rowTitle) {
                const characterCount = countCharacters(rowTitle);
                // const cleanedTitle = cleanString(rowTitle);
                // const wordCount = countWords(cleanedTitle);
                if (characterCount > MAX_TASK_TITLE_CHARACTER_COUNT) {
                  data.title = convertTaskDescrStringToHTMLString(
                    truncateString(
                      rowTitle,
                      MAX_TASK_TITLE_CHARACTER_COUNT,
                      false
                    )
                  );
                  titleTextToStoreInDescr = rowTitle;
                  // data.description = convertStringToPTagString(rowTitle);
                  titleCopyToDescr = true;
                } else {
                  data[prop.field] =
                    convertTaskDescrStringToHTMLString(rowTitle);
                }
              } else {
                data[prop.field] = "";
              }
            } else if (prop.field === "description") {
              // if (!titleCopyToDescr) {
              // let descrToStore = "";
              // if (row[index] && !isHTML(row[index])) {
              //   descrToStore = convertStringToPTagString(row[index]);
              // }
              data[prop.field] = row[index] || "";
              // }
            } else if (prop.field === "project") {
              const storedProject = getProjectByName(row[index]);
              if (!isEmpty(storedProject)) {
                data[prop.field] = storedProject.key || "";
                data.priority = storedProject.priority || "";
              } else {
                data[prop.field] = "";
              }
            } else {
              data[prop.field] = row[index] || "";
            }
          } else if (prop.type === "boolean") {
            if (prop.field === "flag") {
              if (data.flag === undefined) {
                data[prop.field] = Boolean(parseInt(row[index]));
              }
            } else {
              data[prop.field] = Boolean(parseInt(row[index]));
            }
          }
        }
      });

      // Do not add modified if does exist
      // if (!data.modified) {
      //   data.modified = getCurrDate();
      // }

      if (titleCopyToDescr && titleTextToStoreInDescr) {
        const convertedText = convertTaskDescrStringToHTMLString(
          titleTextToStoreInDescr
        );

        if (!isHTML(data.description)) {
          const combinedText = `${convertedText}${convertTaskDescrStringToHTMLString(
            `${data.description}`
          )}`;
          // data.description = convertStringToPTagString(combinedText);
          data.description = combinedText;
        } else {
          if (!isHTML(titleTextToStoreInDescr)) {
            // data.description = `${titleTextToStoreInDescr}\n${data.description}`;
            data.description = convertedText + data.description;
            // data.description = `${convertStringToPTagString(
            //   titleTextToStoreInDescr
            // )}\n${data.description}`;
          } else {
            // data.description = `${titleTextToStoreInDescr}\n${data.description}`;
            data.description = titleTextToStoreInDescr + data.description;
            // data.description =
          }
        }
      } else if (data.description && !isHTML(data.description)) {
        data.description = convertTaskDescrStringToHTMLString(data.description);
      }

      if (!data.created) {
        data.created = getCurrDate();
      }

      if (!data.priority) {
        data.priority = "priority";
      }

      data.status = status;
      data.resources = [];
      data.order = currTasksCount;
      data.userPosition = 0;
      data.customPosition = currTasksCount;
      data.customPositionIndex = 0;
      // data.priority = "";
      data.taskType = "task";

      updates["/tasks/" + data.key] = { ...data, key: data.key };
      if (
        isGoogleCalSyncEnabled &&
        isValidDate(parseDate(data.due, INTERNAL_DATE_FORMAT))
      ) {
        const googleEventKey = convertTaskKeyForGoogleCal(data.key);
        const googleCalId = GoogleCalHelpers.getStoredCalId();
        data.googleEventId = googleEventKey;
        data.googleCalendarId = googleCalId;

        updates[`/tasks/${data.key}/googleCalendarId`] = googleCalId;
        updates["/tasks/" + data.key].googleEventId = googleEventKey;
      }
      currTasksCount++;
    }
  });

  return updates;
};

export const getDateAndTimeFromDueDate = (
  date,
  dateFormat
  // dueFormat = INTERNAL_DATE_FORMAT,
  // timeFormat = INTERNAL_TIME_FORMAT
) => {
  let timeToSet = "00:00"; // Default time
  let dateToSet = "none"; // Date defaults to 'none'

  if (dateFormat) {
    date = parseDate(date, dateFormat);
  }
  const isNewTaskDateValid = date && isValidDate(date);
  // Check if dateCopy exists then use date and time from it
  if (isNewTaskDateValid) {
    timeToSet = makeTimeFormat(date);

    // if (useCurrentTime && timeToSet === "00:00") {
    //   timeToSet = makeTimeFormat(new Date());
    // }
    dateToSet = makeDateFormat(date);
    // timeToSet = format(date, timeFormat);
    // dateToSet = format(date, dueFormat);
  }

  return {
    due: dateToSet,
    time: timeToSet,
  };
};

export const createUpdateAndEditedTaskDataByCell = ({
  fieldName,
  oldValue,
  currValue,
  rowId,
  currRowData,
}) => {
  const allProjects = store.getters["task/projects"] || {};
  let updates = {};
  let editActionData = {};
  let changedProps = {};
  // const booleanFields = {
  //   completed: "changedCompleted",
  //   flag: "changedFlag",
  // }; // Boolean fields
  // const booleanFields = ["completed", "flag"];
  // const booleanFieldsChangedProps = ["changedCompleted","changedFlag"]; //
  if (fieldName === "dueAsDate") {
    let due = "none";
    let time = "00:00";

    editActionData = {
      time: "00:00",
      due: "none",
      flag: typeof currRowData.flag === "boolean" ? currRowData.flag : false,
    };

    const oldDateValid = oldValue && isValidDate(oldValue);
    const newDateValid = currValue && isValidDate(currValue);
    if (oldDateValid) {
      editActionData.time = makeTimeFormat(oldValue);
      editActionData.due = makeDateFormat(oldValue);
    }

    if (newDateValid) {
      due = makeDateFormat(currValue);
      time = makeTimeFormat(currValue);
    }

    /**
     * mark as flagged when schudeling unscheduled task
     */
    if (newDateValid && !oldDateValid) {
      updates["/tasks/" + rowId + "/flag"] = true;
      changedProps.flag = true;
    }

    if (!newDateValid && !oldDateValid) {
      if (currRowData.isCustomPositioned) {
        if (!isEmpty(editActionData)) {
          editActionData.isCustomPositioned = currRowData.isCustomPositioned;
          editActionData.flag = currRowData.flag;
        }
        updates[`/tasks/${rowId}/isCustomPositioned`] = false;
      }

      if (currRowData.isContingent) {
        if (!isEmpty(editActionData)) {
          editActionData.isContingent = currRowData.isCustomPositioned;
          editActionData.flag = currRowData.flag;
        }
        updates[`/tasks/${rowId}/isContingent`] = false;
      }
      updates[`/tasks/${rowId}/flag`] = false;
      changedProps.flag = false;
      changedProps.isCustomPositioned = false;
      changedProps.isContingent = false;
    }

    updates["/tasks/" + rowId + "/due"] = due;
    updates["/tasks/" + rowId + "/time"] = time;
    changedProps.due = due;
    changedProps.time = time;
  } else if (fieldName === "project") {
    editActionData = {
      project: oldValue || "",
    };

    if (allProjects[currValue] && allProjects[currValue].priority) {
      // const projectPriority = allProjects[currValue].priority;
      // editActionData.priority = currRowData.priority || "";
      // changedProps.priority = projectPriority;
      // updates[`/tasks/${rowId}/priority`] = projectPriority;
    }
    changedProps[fieldName] = currValue || "";
    updates[`/tasks/${rowId}/${fieldName}`] = currValue || "";
    // if (currValue) {
    //   updates["/tasks/" + rowId + "/project"] = currValue;

    //   if (oldValue && oldValue !== currValue) {
    //     editActionData.changedProject = currValue;
    //     // let changedProjectTasksList =
    //     //   cloneDeep(this.projects[currValue].tasks) || [];
    //     // const oldProjectTasksList = cloneDeep(
    //     //   this.projects[oldValue].tasks
    //     // );

    //     // // Remove task from old project;
    //     // if (oldProjectTasksList && oldProjectTasksList.length) {
    //     //   oldProjectTasksList.splice(oldProjectTasksList.indexOf(rowId), 1);
    //     //   updates["/projects/" + oldValue + "/tasks"] = oldProjectTasksList;
    //     //   updates["/projects/" + oldValue + "/modified"] = getCurrDate();
    //     // }
    //     // // Add task to new project
    //     // if (changedProjectTasksList && changedProjectTasksList.length > 0) {
    //     //   changedProjectTasksList.push(rowId);
    //     // } else {
    //     //   changedProjectTasksList = [rowId];
    //     // }
    //     // updates["/projects/" + currValue + "/tasks"] =
    //     //   changedProjectTasksList;
    //     // updates["/projects/" + currValue + "/modified"] = getCurrDate();
    //   }
    // }
  } else if (booleanPropsOfTask.indexOf(fieldName) > -1) {
    if (typeof currValue === "boolean") {
      updates[`/tasks/${rowId}/${fieldName}`] = currValue;
      changedProps[fieldName] = currValue;
    }

    editActionData[fieldName] =
      typeof oldValue === "boolean" ? oldValue : false;

    if (fieldName === "completed" && currValue) {
      editActionData.completedOn = "";
      changedProps.completedOn = getCurrDate();
    }

    // if (fieldName === "completed") {
    //   updates[`/tasks/${rowId}/status`] = currValue
    //     ? "complete"
    //     : "incomplete";
    //   editActionData["status"] = oldValue ? "complete" : "incomplete";
    // }
  } else if (fieldName === "pausedReminderDays") {
    editActionData[fieldName] = [];
    if (!isEmpty(oldValue)) {
      editActionData[fieldName] = oldValue;
    }

    if (!isEmpty(currValue)) {
      updates["/tasks/" + rowId + `/${fieldName}`] = currValue;
      changedProps[fieldName] = currValue;
    } else {
      updates["/tasks/" + rowId + `/${fieldName}`] = [];
      changedProps[fieldName] = [];
    }
  } else {
    editActionData[fieldName] = "";
    // if (currValue) {
    updates["/tasks/" + rowId + `/${fieldName}`] = currValue || "";
    changedProps[fieldName] = currValue || "";
    // }
    if (oldValue) {
      editActionData[fieldName] = oldValue;
    }
  }

  editActionData.changedProps = changedProps;

  updates["/tasks/" + rowId + `/modified`] = getCurrDate();
  return {
    updates,
    editedData: editActionData,
  };
};

export const createTaskRowDataByCell = (
  currRowData,
  fieldName,
  value,
  oldValue
) => {
  // const allProjects = store.getters["task/projects"] || {};
  let createdRowData = cloneDeep(currRowData);

  if (fieldName === "dueAsDate") {
    let due = "none";
    let time = "00:00";

    if (value && isValidDate(value)) {
      due = makeDateFormat(value);
      time = makeTimeFormat(value);
      // if (time === "00:00") {
      //   time = makeTimeFormat(new Date());
      // }
    }

    /**
     * mark as flagged when schudeling unscheduled task
     */
    if (value && isValidDate(value) && !isValidDate(oldValue)) {
      createdRowData.flag = true;
    }

    if (!isValidDate(value) && !isValidDate(oldValue)) {
      if (currRowData.isCustomPositioned) {
        createdRowData.flag = false;
        createdRowData.isCustomPositioned = false;
      }

      if (currRowData.isContingent) {
        createdRowData.flag = false;
        createdRowData.isContingent = false;
      }
    }

    createdRowData.due = due;
    createdRowData.time = time;
  }

  createdRowData[fieldName] = value;

  if (fieldName === "completed" && value) {
    createdRowData.keepItVisible = true;
  }
  // if (
  //   fieldName === "project" &&
  //   value &&
  //   value !== oldValue &&
  //   allProjects[value] &&
  //   allProjects[value].priority
  // ) {
  //   // const newProjectPriority = allProjects[value].priority;
  //   // createdRowData.priority = newProjectPriority;
  // }

  if (fieldsToReprocessTask.indexOf(fieldName) > -1) {
    createdRowData = processTask(
      createdRowData,
      store.getters["task/projects"],
      store.getters["task/categories"]
    );
  }

  createdRowData.modified = getCurrDate();
  createdRowData.modifiedAsDate = convertDateTimeStrToDate(
    createdRowData.modified
  );
  return createdRowData;
};

export const createShowClearedTasksFilters = (
  providedFilters,
  providedClearedTaskFiltersToRemove,
  show
) => {
  const currFilters = cloneDeep(providedFilters);

  if (show) {
    providedClearedTaskFiltersToRemove.forEach((c) => {
      const cIndex = currFilters.findIndex((cF) => isEqual(cF, c));
      if (cIndex >= 0) {
        currFilters.splice(cIndex, 1);
      }
    });
  }
  // if (filterIndex > -1) {

  // }

  return currFilters;
};

export const addProjectOrCategoriesFilter = (
  list,
  currFilters,
  isGlobalView,
  categoryFiltersEnabled,
  showAllTasks
) => {
  const filters = cloneDeep(currFilters);

  let createdFilters = [];

  if (!isEmpty(list) && !categoryFiltersEnabled) {
    createdFilters = [...createProjectsOrCategoriesFilters(list)];
  }

  // const nextActionsGroupFilter = {
  //   field: "CTICustomGroup",
  //   type: "=",
  //   value: "1",
  // };
  // let isOnlyAreaIsSelected = false;

  if (createdFilters.length > 0) {
    const existingFilterIndex = filters.findIndex((filter) =>
      isEqual(filter, createdFilters)
    );

    if (existingFilterIndex === -1) {
      filters.push(createdFilters);
      // isOnlyAreaIsSelected = checkIfSelectedTaskCateHasOnlyArea(list);
    }
  }

  // if (!isOnlyAreaIsSelected && !isGlobalView) {
  //   filters.push({
  //     ...nextActionsGroupFilter,
  //   });
  // }

  if (
    isEmpty(list) &&
    !isGlobalView &&
    (!categoryFiltersEnabled || categoryFiltersEnabled) &&
    !showAllTasks
  ) {
    filters.push({
      ...nextActionsGroupFilter,
    });
  }

  return filters;
};

export const addDueDateFilter = (
  dates,
  contingentVal,
  currentFilters,
  currNav,
  showContingentTasks,
  isGlobalViewEnabled
) => {
  if (currentFilters && currentFilters.length) {
    // const currDate = formatDate(new Date(), INTERNAL_DATE_FORMAT);
    // const existingFilterIndex = currentFilters.findIndex(
    //   (curr) => curr.field === "due"
    // );

    if (currNav === 0) {
      if (!isEmpty(dates)) {
        currentFilters = addDatesAndContingentListFilter(
          currentFilters,
          dates,
          showContingentTasks
        );
      } else if (isEmpty(dates)) {
        const baseFilters = [
          [
            { field: "due", type: "!=", value: "none" },
            {
              field: "isReminderTask",
              type: "!=",
              value: true,
            },
          ],
          [
            { field: "due", type: "!=", value: "none" },
            [
              {
                field: "showReminder",
                type: "=",
                value: true,
              },
              {
                field: "showInToday",
                type: "=",
                value: true,
              },
            ],
            {
              field: "isFirstReminderTask",
              type: "=",
              value: true,
            },
          ],
          [
            { field: "due", type: "!=", value: "none" },
            [
              {
                field: "showReminder",
                type: "=",
                value: true,
              },
              {
                field: "showInToday",
                type: "=",
                value: true,
              },
            ],
            {
              field: "isFirstReminderTask",
              type: "=",
              value: false,
            },
          ],
        ];

        const storedIndex = currentFilters.findIndex((f) =>
          isEqual(f, baseFilters)
        );

        if (storedIndex >= 0) {
          const filtersToReplace = [
            [
              { field: "due", type: "!=", value: "none" },
              {
                field: "isReminderTask",
                type: "!=",
                value: true,
              },
            ],

            // Disabled to hide reminders in no selected date mode
            // [
            //   { field: "due", type: "!=", value: "none" },
            //   [
            //     {
            //       field: "showReminder",
            //       type: "=",
            //       value: true,
            //     },
            //     {
            //       field: "showInToday",
            //       type: "=",
            //       value: true,
            //     },
            //   ],
            //   {
            //     field: "isFirstReminderTask",
            //     type: "=",
            //     value: true,
            //   },
            // ],
          ];

          if (showContingentTasks) {
            filtersToReplace.push([
              {
                field: "showContingentInToday",
                type: "=",
                value: true,
              },
            ]);
          }
          currentFilters.splice(storedIndex, 1, [...filtersToReplace]);
        }
      }

      if (contingentVal && contingentVal !== "all") {
        currentFilters = addContingentFilter(contingentVal, currentFilters);
      }
    } else if (currNav === 3) {
      const filtersToAdd = [
        // [
        //   {
        //     field: "isContingent",
        //     type: "=",
        //     value: true,
        //   },
        //   [
        //     {
        //       field: "due",
        //       type: "=",
        //       value: "none",
        //     },
        //     {
        //       ...createDueDateListFilter(dates),
        //     },
        //     {
        //       field: "showInToday",
        //       type: "=",
        //       value: true,
        //       // dasdsadsad,
        //     },
        //   ],
        // ],
        [
          {
            ...createDueDateListFilter(dates),
          },
          // {
          //   field: "contingentOrder",
          //   type: "=",
          //   value: "0",
          // },
        ],
        [
          {
            field: "showInToday",
            type: "=",
            value: true,
            // dasdsadsad,
          },
        ],
      ];

      if (showContingentTasks) {
        filtersToAdd.push([...taskContingentFilter]);
      }

      if (!isGlobalViewEnabled) {
        currentFilters.push(filtersToAdd);
      }
    }

    if (!showContingentTasks) {
      currentFilters.push([
        {
          ...taskContingentFilter[0],
          value: false,
        },
        {
          ...taskContingentFilter[0],
          value: undefined,
        },
      ]);
    }

    // currentFilters.push([
    //   { ...createDueDateListFilter(dates) },
    //   {
    //     field: "due",
    //     type: "=",
    //     value: "none",
    //   },
    // ]);

    // currentFilters = currentFilters.map((currentFilter) => {
    //   if (currentFilter.field === "due") {
    //     currentFilter = { ...createDueDateListFilter(dates) };
    //   }
    //   return currentFilter;
    // });
  }

  return currentFilters;
};

export const createTaskRowDataByRowData = (currRowData, editedRowData) => {
  let createdRowData = cloneDeep(currRowData);
  const allProjects = store.getters["task/projects"] || {};
  const editDataKeys = Object.keys(editedRowData);
  const keysRequireProcessing = [];
  let updatePriorityValue = true;
  editDataKeys.forEach((editKey) => {
    const editedValue = editedRowData[editKey];
    if (editKey === "dueAsDate") {
      let due = "none";
      let time = "00:00";
      keysRequireProcessing.push(editKey);
      const oldValue = currRowData[editKey];
      if (editedValue && isValidDate(editedValue)) {
        due = makeDateFormat(editedValue);
        time = makeTimeFormat(editedValue);
        // if (time === "00:00") {
        //   time = makeTimeFormat(new Date());
        // }
      }

      /**
       * mark as flagged when schudeling unscheduled task
       */
      if (editedValue && isValidDate(editedValue) && !isValidDate(oldValue)) {
        createdRowData.flag = true;
      }

      createdRowData.due = due;
      createdRowData.time = time;
    } else {
      // if (fieldsToReprocessTask.indexOf(editKey) > -1) {
      //   keysRequireProcessing.push(editKey);
      // }

      if (
        editKey === "project" &&
        editedValue &&
        editedValue !== currRowData.project &&
        allProjects[editedValue] &&
        allProjects[editedValue].priority
      ) {
        updatePriorityValue = false;
        createdRowData.priority = allProjects[editedValue].priority;
      }
      if (editKey === "priority") {
        if (updatePriorityValue) {
          createdRowData[editKey] = editedValue;
        }
      } else {
        createdRowData[editKey] = editedValue;

        if (editKey === "title") {
          createdRowData.resources =
            getAllMentionedResourceIdsFromText(editedValue);
        }
      }
    }
  });
  createdRowData.modified = getCurrDate();
  // if (keysRequireProcessing && keysRequireProcessing.length) {
  createdRowData = processTask(
    createdRowData,
    store.getters["task/projects"],
    store.getters["task/categories"]
  );
  // }

  return createdRowData;
};

export const updateLocalTasks = (tasksToUpdate, localTasks) => {
  const currTasks = [...localTasks];

  if (!Array.isArray(tasksToUpdate)) tasksToUpdate = [tasksToUpdate];

  if (tasksToUpdate && tasksToUpdate.length) {
    tasksToUpdate.forEach((taskData) => {
      const storedItemIndex = currTasks.findIndex(
        (t) => t.key === taskData.key
      );
      if (storedItemIndex >= 0) {
        const finalData = {
          ...currTasks[storedItemIndex],
          ...taskData.updates,
        };
        currTasks.splice(storedItemIndex, 1, finalData);
      }
    });
  }

  return currTasks;
};

const createFinalTasksToAddList = (
  tasksToProcess,
  localList,
  verify = true
) => {
  let finalTasksToAdd = [];
  if (verify) {
    tasksToProcess.forEach((t) => {
      const currIndex = localList.findIndex((cT) => cT.key === t.key);
      if (currIndex === -1) {
        finalTasksToAdd.push(t);
      }
    });
  } else {
    finalTasksToAdd = [...tasksToProcess];
  }

  return finalTasksToAdd;
};

export const addTasksInLocalTasks = (
  tasksToAdd,
  localTasks,
  method = "unshift",
  verify = true
) => {
  const currTasks = [...localTasks];

  if (!Array.isArray(tasksToAdd)) tasksToAdd = [tasksToAdd];
  if (tasksToAdd && tasksToAdd.length) {
    if (method === "unshift") {
      const finalTasksList = createFinalTasksToAddList(
        tasksToAdd,
        currTasks,
        verify
      );
      currTasks.unshift(...finalTasksList);
    }

    if (method === "push") {
      const finalTasksList = createFinalTasksToAddList(
        tasksToAdd,
        currTasks,
        verify
      );
      currTasks.push(...finalTasksList);
    }
  }
  return currTasks;
};

export const removeTasksFromLocalList = (taskIdsToRemove, localTasks) => {
  const currTasks = [...localTasks];

  if (!isEmpty(taskIdsToRemove)) {
    if (!Array.isArray(taskIdsToRemove)) taskIdsToRemove = [taskIdsToRemove];

    taskIdsToRemove.forEach((taskId) => {
      const storedTaskIndex = currTasks.findIndex((t) => t.key === taskId);
      if (storedTaskIndex >= 0) {
        currTasks.splice(storedTaskIndex, 1);
      }
    });
  }

  return currTasks;
};

export const callRefreshList = (reprocessTasks) => {
  emitRefreshList(reprocessTasks, "tasks");
};

export const callAddUpdateRemoveTask = (tasksData) => {
  EventEmitter.emit(ADD_UPDATE_REMOVE_TASK_EVENT, tasksData);
};

export const createTaskDataForGoogleCal = (taskData, currData) => {
  let updates = {};

  const qlInstance = createOrGetQlInstance();
  let allMentionsMap = {};

  if (!isEmpty(currData) && currData.dueAsDate === undefined) {
    currData.dueAsDate = createDateTimeFromDue(currData.due, currData.time);
  }

  if (
    !isEmpty(taskData) &&
    taskData.dueAsDate === undefined &&
    taskData.due &&
    taskData.time
  ) {
    taskData.dueAsDate = createDateTimeFromDue(taskData.due, taskData.time);
  }
  TASK_PROPS_FOR_GOOGLE_CAL.forEach((fieldData) => {
    if (
      fieldData.field === "dueAsDate" &&
      taskData[fieldData.field] !== undefined
    ) {
      if (!isEmpty(currData)) {
        if (
          isValidDate(currData.dueAsDate) &&
          !isValidDate(taskData[fieldData.field])
        ) {
          updates.status = "cancelled";
        } else if (
          (isValidDate(taskData[fieldData.field]) &&
            !isValidDate(currData.dueAsDate)) ||
          (isValidDate(taskData[fieldData.field]) &&
            isValidDate(currData.dueAsDate) &&
            !isDatesEqual(taskData[fieldData.field], currData.dueAsDate))
        ) {
          const { startDateTime, endDateTime, isAllDay, startDate, endDate } =
            getStartAndEndFromDueDateTime(taskData[fieldData.field]);

          updates.start = {};
          updates.end = {};
          if (isAllDay) {
            updates.start.date = startDate;
            updates.start.timeZone = getCurrentTimeZoneName();

            updates.start.dateTime = null;
            updates.end.date = endDate;
            updates.end.dateTime = null;
            updates.end.timeZone = getCurrentTimeZoneName();
            updates.extendedProperties = {
              private: {
                isAllDayEvent: true,
              },
            };
          } else {
            updates.start = {
              dateTime: startDateTime,
              date: null,
              timeZone: getCurrentTimeZoneName(),
            };
            updates.end = {
              dateTime: endDateTime,
              date: null,
              timeZone: getCurrentTimeZoneName(),
            };
          }

          if (
            areTaskRecurrenceRulesValid(taskData.recurrence) &&
            !isEqual(taskData.recurrence, currData.recurrence)
          ) {
            updates.recurrence = taskData.recurrence;
          }

          updates.status = "confirmed";
        }
      } else if (isValidDate(taskData[fieldData.field])) {
        const { startDateTime, endDateTime, isAllDay, startDate, endDate } =
          getStartAndEndFromDueDateTime(taskData[fieldData.field]);
        updates.start = {};
        updates.end = {};
        if (isAllDay) {
          updates.start.date = startDate;
          updates.end.date = endDate;
          updates.start.timeZone = getCurrentTimeZoneName();
          updates.end.timeZone = getCurrentTimeZoneName();
          updates.extendedProperties = {
            private: {
              isAllDayEvent: true,
            },
          };
        } else {
          updates.start = {
            dateTime: startDateTime,
            timeZone: getCurrentTimeZoneName(),
          };
          updates.end = {
            dateTime: endDateTime,
            timeZone: getCurrentTimeZoneName(),
          };
        }

        if (areTaskRecurrenceRulesValid(taskData.recurrence)) {
          updates.recurrence = taskData.recurrence;
        }
        updates.status = "confirmed";
      }
    } else {
      if (!isEmpty(currData)) {
        if (taskData[fieldData.field] !== undefined) {
          if (
            fieldData.field === "title" ||
            fieldData.field === "description"
          ) {
            const oldConvertedData = convertHTMLToQlDelta(
              currData[fieldData.field],
              qlInstance
            );
            const newConveredData = convertHTMLToQlDelta(
              taskData[fieldData.field],
              qlInstance
            );

            if (!areQlDeltasEqual(oldConvertedData, newConveredData)) {
              if (fieldData.field === "title") {
                const { str: createdStr } =
                  createStrWithMentionsFromQlDelta(newConveredData);

                updates[fieldData.key] = createdStr;
              } else {
                updates[fieldData.key] = taskData[fieldData.field];
              }
            }
          } else {
            if (fieldData.field === "status") {
              if (
                TASK_GOOGLE_EVENT_VALID_STATUS.includes(
                  taskData[fieldData.field]
                )
              ) {
                updates[fieldData.key] = taskData[fieldData.field];
              }
            } else if (
              !isEqual(currData[fieldData.field], taskData[fieldData.field])
            ) {
              updates[fieldData.key] = taskData[fieldData.field];
            }
          }
        }
      } else if (!isEmpty(taskData[fieldData.field])) {
        if (fieldData.field === "title") {
          const convertedTitleDelta = convertHTMLToQlDelta(
            taskData[fieldData.field],
            qlInstance
          );

          const { str: strWithMentions, allMentions: mentionsMap } =
            createStrWithMentionsFromQlDelta(convertedTitleDelta);
          // taskData[fieldData.field];
          updates[fieldData.key] = strWithMentions;
          allMentionsMap = mentionsMap;
        } else if (fieldData.field === "status") {
          if (
            TASK_GOOGLE_EVENT_VALID_STATUS.includes(taskData[fieldData.field])
          ) {
            updates[fieldData.key] = taskData[fieldData.field];
          }
        } else {
          updates[fieldData.key] = taskData[fieldData.field];
        }
      }
    }
  });
  // taskKeys.forEach((taskKey) => {
  //   const fieldData = TASK_PROPS_FOR_GOOGLE_CAL.find(
  //     (p) => p.field === taskKey
  //   );
  //   if (!isEmpty(fieldData)) {

  //   }
  // });

  if (!isEmpty(updates) && taskData.taskKey) {
    if (
      !isEmpty(updates.extendedProperties) &&
      !isEmpty(updates.extendedProperties.private)
    ) {
      updates.extendedProperties.private.taskKey = taskData.taskKey;
    } else {
      updates.extendedProperties = {
        private: {
          taskKey: taskData.taskKey,
        },
      };
    }
  }

  if (!isEmpty(allMentionsMap)) {
    if (
      !isEmpty(updates.extendedProperties) &&
      !isEmpty(updates.extendedProperties.private)
    ) {
      updates.extendedProperties.private.resourcesMentionMap =
        JSON.stringify(allMentionsMap);
    } else {
      updates.extendedProperties = {
        private: {
          resourcesMentionMap: JSON.stringify(allMentionsMap),
        },
      };
    }
  }

  if (!isEmpty(currData) && !isEmpty(updates)) {
    updates.dataForAdd = createTaskDataForGoogleCal({
      ...currData,
      ...taskData,
    });
  }
  return updates;
};

export const getStartAndEndFromDueDateTime = (dueDateTime) => {
  const time = makeTimeFormat(dueDateTime);
  let startDateTime = dueDateTime;
  let endDateTime = "";
  let startDate, endDate;
  let isAllDay = false;

  if (time === "00:00") {
    isAllDay = true;
    endDate = startDate = makeDateFormat(dueDateTime);
  } else {
    endDateTime = addHours(dueDateTime, 1);
  }

  return {
    endDate,
    startDate,
    startDateTime,
    endDateTime,
    isAllDay,
  };
};

export const createDataForAddOrRemoveOrUpdateInGoogleCal = (tasksToProcess) => {
  if (!Array.isArray(tasksToProcess)) tasksToProcess = [tasksToProcess];

  const tasksToAdd = [];
  const tasksToUpdate = [];
  const tasksToRemove = [];
  tasksToProcess.forEach((taskData) => {
    const { key, currData, updates } = taskData;
    const taskPropKeys = Object.keys(updates);
    taskPropKeys.forEach((taskPropKey) => {
      const fieldData = TASK_PROPS_FOR_GOOGLE_CAL.find(
        (p) => p.field === taskPropKey
      );
      // const googleEventKey = convertTaskKeyForGoogleCal(key);
      if (!isEmpty(fieldData)) {
        if (fieldData.field === "dueAsDate") {
          if (currData.googleEventId) {
            if (
              updates[fieldData.field] &&
              isValidDate(updates[fieldData.field])
            ) {
              tasksToUpdate.push({
                key,
                updates: createTaskDataForGoogleCal(updates),
              });
            } else {
              tasksToRemove.push({ ...currData });
            }
          } else if (
            updates[fieldData.field] &&
            isValidDate(new Date(updates[fieldData.field]))
          ) {
            tasksToAdd.push({
              key,
              data: createTaskDataForGoogleCal({
                ...currData,
                ...updates,
              }),
            });
          }
        } else {
          if (currData.googleEventId) {
            tasksToUpdate.push({
              key,
              updates: createTaskDataForGoogleCal(updates),
            });
          } else if (
            currData.dueAsDate &&
            isValidDate(new Date(currData.dueAsDate))
          ) {
            tasksToAdd.push({
              key,
              taskKey: key,
              data: createTaskDataForGoogleCal({
                ...currData,
                ...updates,
              }),
            });
          }
        }
      }
    });
  });

  return {
    tasksToAdd,
    tasksToUpdate,
    tasksToRemove,
  };
};

export const createDataForAddOrRemoveOrUpdateInGoogleCalByTaskData = (
  taskDataList
) => {
  const tasksToRemove = [];
  const tasksToUpdate = [];
  const tasksToAdd = [];

  const currGoogleCalId = GoogleCalHelpers.getStoredCalId();
  if (!Array.isArray(taskDataList)) taskDataList = [taskDataList];

  taskDataList.forEach((taskData) => {
    const { key, currData, updates } = taskData;
    if (
      currData.googleEventId &&
      (!currData.googleCalendarId ||
        (currGoogleCalId && currData.googleCalendarId === currGoogleCalId))
    ) {
      if (isCalenderPropsChanged(updates, currData)) {
        tasksToUpdate.push({
          key: currData.googleEventId,
          updates: createTaskDataForGoogleCal(
            { ...updates, taskKey: key },
            currData
          ),
        });
      }
    } else if (updates.dueAsDate && isValidDate(updates.dueAsDate)) {
      tasksToAdd.push({
        key,
        data: createTaskDataForGoogleCal({
          ...currData,
          ...updates,
          taskKey: key,
        }),
      });
    }
  });

  return {
    tasksToAdd,
    tasksToUpdate,
    tasksToRemove,
  };
};

export const isCalenderPropsChanged = (updates, currData) => {
  const qlInstance = createOrGetQlInstance();
  const isDataChanged = CALENDER_TASK_PROPS.some((key) => {
    if (updates[key] !== undefined) {
      if (key === "dueAsDate") {
        if (
          (isValidDate(currData.dueAsDate) &&
            !isValidDate(updates.dueAsDate)) ||
          (isValidDate(updates.dueAsDate) && !isValidDate(currData.dueAsDate))
        ) {
          return true;
        } else if (
          isValidDate(currData.dueAsDate) &&
          isValidDate(updates.dueAsDate)
        ) {
          return !isDatesEqual(
            new Date(updates.dueAsDate),
            new Date(currData.dueAsDate)
          );
        }
        // if (
        //   !isValidDate(updates.dueAsDate) ||
        //   !isValidDate(currData.dueAsDate)
        // ) {
        //   return true;
        // } else {
        //   return !isDatesEqual(
        //     new Date(updates.dueAsDate),
        //     new Date(currData.dueAsDate)
        //   );
        // }
      } else {
        // updates[key] !== currData[key]

        if (key === "description" || key === "title") {
          const oldConvertedData = convertHTMLToQlDelta(
            currData[key],
            qlInstance
          );
          const newConveredData = convertHTMLToQlDelta(
            updates[key],
            qlInstance
          );

          return !areQlDeltasEqual(oldConvertedData, newConveredData);
          // if (!areQlDeltasEqual(oldConvertedData, newConveredData)) {
          //   updates[key] = taskData[fieldData.field];
          // }
        } else if (key === "status") {
          if (TASK_GOOGLE_EVENT_VALID_STATUS.includes(updates[key])) {
            return !isEqual(updates[key], currData[key]);
          } else {
            return false;
          }
        } else {
          return !isEqual(updates[key], currData[key]);
        }
      }
    }
  });
  removeQlInstance();
  return isDataChanged;
};

export const createDateTimeFromDue = (due, time) =>
  new Date(due + "T" + (time || "00:00"));

export const createDataForTasksDbFromEvents = async (
  events,
  // lastConnectedDate,
  _,
  syncCurrTasks,
  currLastSync
) => {
  let toastMsg = "Sync completed";
  let toastColor = "green";

  let resStatus = "Error";
  try {
    createOrGetQlInstance();
    const currentTasks = store.getters["task/rawTasks"] || [];
    const currTasksMap = store.getters["task/rawTasksMap"] || {};
    const userId = store.getters["user/userInfo"].uid;
    const tasksToAdd = [];
    const tasksToUpdate = [];
    const tasksToRemove = [];
    const localTasksAddList = [];
    const recurringEventsMap = {};
    const updateTasksInGoogle = [];
    let tasksToAddInGoogle = [];
    let localRawTasksUpdatesMap = {};
    let currentTasksCount = Object.keys(currentTasks).length;
    let currTasksSynced = false;
    let currTasksKeysSynced = [];
    let currSyncedGoogleIDs = [];

    const currStoredGoogleCalendarId = GoogleCalHelpers.getStoredCalId();
    // const parsedConnectedDate =
    //   lastConnectedDate && parseISO(lastConnectedDate);

    if (syncCurrTasks) {
      for (const t of currentTasks) {
        if (t.due !== "none") {
          const googleEventData = events.find((e) => {
            const taskId = getTaskKeyFromGoogleEvent(e);
            return e.id === t.googleEventId || (taskId && taskId === t.key);
          });

          // const resData = {
          //   eventId: t.googleEventId,
          //   eventData: googleEventData,
          //   eventExists: !t.googleEventId && isEmpty(googleEventData),
          //   calendarIdChanged:
          //     currStoredGoogleCalendarId &&
          //     t.googleCalendarId &&
          //     currStoredGoogleCalendarId !== t.googleCalendarId,
          //   isOnwards: isTaskDateSameOrOnwards(
          //     parseISO(t.due),
          //     parsedConnectedDate
          //   ),
          //   storedCalendarId: currStoredGoogleCalendarId,
          //   taskCalendarId: t.googleCalendarId,
          //   dueDate: parseISO(t.due),
          //   storedConnectedDate: parsedConnectedDate,
          //   res:
          //     ((!t.googleEventId && isEmpty(googleEventData)) ||
          //       (currStoredGoogleCalendarId &&
          //         t.googleCalendarId &&
          //         currStoredGoogleCalendarId !== t.googleCalendarId)) &&
          //     isTaskDateSameOrOnwards(parseISO(t.due), parsedConnectedDate),
          // };

          // if (resData.res) {
          //   console.log("RES DATTTA", resData);
          // } else {
          //   console.log("NOR RES", resData)
          // }

          // if (isTaskDateSameOrOnwards(parseISO(t.due), parsedConnectedDate)) {
          if (
            (!t.googleEventId && isEmpty(googleEventData)) ||
            (currStoredGoogleCalendarId &&
              t.googleCalendarId &&
              currStoredGoogleCalendarId !== t.googleCalendarId)

            //     &&
            // isTaskDateSameOrOnwards(parseISO(t.due), parsedConnectedDate)
          ) {
            // const isRecurringTask = checkIfTaskIsRecurring(t);

            let googleEventId = convertTaskKeyForGoogleCal(t.key);

            // if (isRecurringTask && t.linkedTo) {
            //   googleEventId = createGoogleEventIdByDateAndTaskId(
            //     t.linkedTo,
            //     createDateTimeFromDue(t.due, t.time)
            //   );
            // }

            if (!currSyncedGoogleIDs.includes(googleEventId)) {
              tasksToUpdate.push({
                key: t.key,
                updates: {
                  googleEventId,
                  googleCalendarId: currStoredGoogleCalendarId,
                },
              });

              localRawTasksUpdatesMap[t.key] = {
                googleEventId,
                googleCalendarId: currStoredGoogleCalendarId,
              };
              currSyncedGoogleIDs.push(googleEventId);
              currTasksKeysSynced.push(t.key);
              tasksToAddInGoogle.push({
                key: googleEventId,
                data: {
                  ...createTaskDataForGoogleCal({
                    ...t,
                    dueAsDate: createDateTimeFromDue(t.due, t.time),
                    taskKey: t.key,
                  }),
                },
              });
            }
          } else if (
            !isEmpty(googleEventData) &&
            googleEventData.status !== "cancelled" &&
            !currSyncedGoogleIDs.includes(googleEventData.id)
          ) {
            const isGoogleDataChanged = hasGoogleDataChanged(
              googleEventData,
              t
            );

            if (isGoogleDataChanged) {
              const taskModifiedTimeParsedDate = parseISODate(t.modified);
              const lastSyncParsedDate = parseISODate(currLastSync);
              let useGoogleData = false;
              if (!isValidDate(taskModifiedTimeParsedDate)) {
                useGoogleData = true;
              } else if (
                isValidDate(lastSyncParsedDate) &&
                isValidDate(taskModifiedTimeParsedDate)
              ) {
                if (isAfter(lastSyncParsedDate, taskModifiedTimeParsedDate)) {
                  useGoogleData = true;
                }
              }
              currTasksKeysSynced.push(t.key);
              currSyncedGoogleIDs.push(googleEventData.id);

              const googleEventId = googleEventData.id;

              let taskUpdates = {
                googleEventId,
                googleCalendarId: currStoredGoogleCalendarId,
              };

              localRawTasksUpdatesMap[t.key] = {
                googleEventId,
                googleCalendarId: currStoredGoogleCalendarId,
              };

              if (useGoogleData) {
                taskUpdates = {
                  ...t,
                  ...processGoogleEvent(googleEventData, "update", t),
                  ...taskUpdates,
                };
                // tasksToUpdate.push({
                //   key: t.key,
                //   updates: {
                //     ...t,
                //     ...processGoogleEvent(googleEventData, "update", t),
                //     googleCalendarId: currStoredGoogleCalendarId,
                //     googleEventId
                //   },
                // });
              } else {
                const googleTaskData = {
                  ...t,
                  ...processGoogleEvent(googleEventData, "update", t, true),
                };
                googleTaskData.dueAsDate = createDateTimeFromDue(
                  googleTaskData.due,
                  googleTaskData.time
                );

                googleTaskData.recurrence = !isEmpty(googleEventData.recurrence)
                  ? googleEventData.recurrence
                  : [];

                const currTaskData = {
                  ...t,
                  dueAsDate: createDateTimeFromDue(t.due, t.time),
                  recurrence: !isEmpty(t.recurrence) ? t.recurrence : [],
                };

                const createdGoogleData = createTaskDataForGoogleCal(
                  { ...currTaskData, taskKey: t.key },
                  {
                    ...googleTaskData,
                    key: t.key,
                  }
                );

                updateTasksInGoogle.push({
                  key: googleEventId,
                  updates: {
                    ...createdGoogleData,
                  },
                });

                console.debug("CURRENT TASK GOOGLE DATA", googleTaskData);
                console.debug("CURRENT TASK APP DATA", currTaskData);
                console.debug("UPDATED GOOGLE TASK DATA", createdGoogleData);
              }

              tasksToUpdate.push({
                key: t.key,
                updates: {
                  ...taskUpdates,
                },
              });
            }

            // if () {
            //   tasksToUpdate.push({
            //     key: currTask.key,
            //     updates: {
            //       ...currTask,
            //       ...processGoogleEvent(eventData, "update", currTask),
            //       googleCalendarId: currStoredGoogleCalendarId,
            //     },
            //   });
            // }

            // const googleEventId = googleEventData.id;

            // tasksToUpdate.push({
            //   key: t.key,
            //   updates: {
            //     googleEventId,
            //     googleCalendarId: currStoredGoogleCalendarId,
            //   },
            // });

            // localRawTasksUpdatesMap[t.key] = {
            //   googleEventId,
            //   googleCalendarId: currStoredGoogleCalendarId,
            // };
          }
          // }

          // if (
          //   !isEmpty(googleEventData) &&
          //   isTaskDateSameOrOnwards(parseISO(t.due), new Date()) &&
          //   googleEventData.status !== "cancelled"
          // ) {
          //   console.debug("ACTIVE TASK");

          //   const googleTaskData = {
          //     ...t,
          //     ...processGoogleEvent(googleEventData, "update", t, true),
          //   };
          //   googleTaskData.dueAsDate = createDateTimeFromDue(
          //     googleTaskData.due,
          //     googleTaskData.time
          //   );

          //   googleTaskData.recurrence = !isEmpty(googleEventData.recurrence)
          //     ? googleEventData.recurrence
          //     : [];
          //   const currTaskData = {
          //     ...t,
          //     dueAsDate: createDateTimeFromDue(t.due, t.time),
          //     recurrence: !isEmpty(t.recurrence) ? t.recurrence : [],
          //   };

          //   const createdGoogleData = createTaskDataForGoogleCal(
          //     { ...currTaskData, taskKey: t.key },
          //     {
          //       ...googleTaskData,
          //       key: t.key,
          //     }
          //   );

          //   console.debug("CURRENT TASK GOOGLE DATA", googleTaskData);
          //   console.debug("CURRENT TASK APP DATA", currTaskData);
          //   console.debug("UPDATED GOOGLE TASK DATA", createdGoogleData);
          // }

          //
        }
      }
    }

    // if (isValidDate(parsedConnectedDate)) {
    //   events = events.filter((e) => {
    //     const startDateData = e.start;
    //     let date;
    //     if (startDateData.date) {
    //       date = parseDate(startDateData.date, INTERNAL_DATE_FORMAT);
    //     } else if (startDateData.dateTime) {
    //       date = new Date(startDateData.dateTime);
    //     }
    //     return (
    //       isSameDates(date, parsedConnectedDate) ||
    //       isAfter(date, parsedConnectedDate)
    //     );
    //   });
    // }

    if (events && events.length) {
      events.forEach((eventData) => {
        const taskKey = getTaskKeyFromGoogleEvent(eventData);
        // const eventDate = getDateFromGoogleEvent(eventData);

        const currTask = currentTasks.find(
          (cT) =>
            cT.googleEventId === eventData.id || (taskKey && taskKey === cT.key)
        );

        if (eventData.status !== "cancelled") {
          if (!isEmpty(currTask)) {
            if (syncCurrTasks && !currSyncedGoogleIDs.includes(eventData.id)) {
              const googleTaskData = {
                ...currTask,
                ...processGoogleEvent(eventData, "update", currTask),
              };
              googleTaskData.dueAsDate = createDateTimeFromDue(
                googleTaskData.due,
                googleTaskData.time
              );
              const currTaskData = {
                ...currTask,
                dueAsDate: createDateTimeFromDue(currTask.due, currTask.time),
              };
              currSyncedGoogleIDs.push(eventData.id);
              updateTasksInGoogle.push({
                key: eventData.id,
                updates: {
                  ...createTaskDataForGoogleCal(
                    { ...currTaskData, taskKey: currTask.key },
                    {
                      ...googleTaskData,
                      key: currTask.key,
                    }
                  ),
                },
              });
            } else if (!syncCurrTasks) {
              if (hasGoogleDataChanged(eventData, currTask)) {
                tasksToUpdate.push({
                  key: currTask.key,
                  updates: {
                    ...currTask,
                    ...processGoogleEvent(eventData, "update", currTask),
                    googleCalendarId: currStoredGoogleCalendarId,
                  },
                });
              }
            }
          } else {
            const isExistingTask = !isEmpty(currTasksMap[taskKey]);

            if (
              (syncCurrTasks &&
                // isTaskDateSameOrOnwards(eventDate, parsedConnectedDate) &&
                ((taskKey && !currTasksKeysSynced.includes(taskKey)) ||
                  !isExistingTask)) ||
              (!isExistingTask && !syncCurrTasks)
            ) {
              let dataToAdd = {};
              let addToLocalList = true;
              const googleEventIdToUse = eventData.id;
              if (!isEmpty(eventData.recurrence)) {
                dataToAdd = {
                  ...processGoogleEvent(eventData, "add"),

                  googleCalendarId: currStoredGoogleCalendarId,
                };
                if (!recurringEventsMap[eventData.id]) {
                  const coRelationalId = createUniqueId();
                  const taskKey = createTaskId();
                  recurringEventsMap[eventData.id] = {
                    coRelationalId,
                    children: [],
                    key: taskKey,
                    data: { ...dataToAdd },
                    originalData: { ...eventData },
                    excludedDates: [],
                    foundFirstChild: false,
                  };
                } else {
                  if (!isEmpty(recurringEventsMap[eventData.id].data)) {
                    recurringEventsMap[eventData.id].data = { ...dataToAdd };
                  }

                  if (!isEmpty(recurringEventsMap[eventData.id].originalData)) {
                    recurringEventsMap[eventData.id].originalData = {
                      ...eventData,
                    };
                  }
                }
                addToLocalList = false;
                dataToAdd = {
                  ...dataToAdd,
                  coRelationalId:
                    recurringEventsMap[eventData.id].coRelationalId,
                  key: recurringEventsMap[eventData.id].key,
                  isFromGoogle: true,
                };
              } else if (eventData.recurringEventId) {
                let parsedDate;
                if (recurringEventsMap[eventData.recurringEventId]) {
                  const parentEventData =
                    recurringEventsMap[eventData.recurringEventId];
                  if (!parentEventData.children.includes(eventData.id)) {
                    parentEventData.children.push(eventData.id);
                    parsedDate = getDueDateFromGoogleEvent(
                      eventData,
                      "originalStartTime"
                    );

                    const isTaskFirstRecurrence =
                      checkIfTaskDateIsFirstRecurring(
                        parsedDate,
                        parentEventData.originalData
                      );
                    dataToAdd = {
                      ...processGoogleEvent(eventData, "add"),
                      googleCalendarId: currStoredGoogleCalendarId,
                      linkedTo: parentEventData.key,
                      coRelationalId: parentEventData.coRelationalId,
                      isFirstTask: isTaskFirstRecurrence,
                      isFromGoogle: true,
                    };
                  }
                } else {
                  recurringEventsMap[eventData.recurringEventId] = {
                    coRelationalId: createUniqueId(),
                    children: [eventData.id],
                    data: {},
                    originalData: {},
                    key: createUniqueId(),
                    excludedDates: [parsedDate],
                  };

                  dataToAdd = {
                    ...processGoogleEvent(eventData, "add"),
                    linkedTo:
                      recurringEventsMap[eventData.recurringEventId].key,
                    coRelationalId:
                      recurringEventsMap[eventData.recurringEventId]
                        .coRelationalId,

                    googleCalendarId: currStoredGoogleCalendarId,
                    isFromGoogle: true,
                    isFirstTask: true,
                  };
                  // }
                }
              } else {
                dataToAdd = {
                  ...processGoogleEvent(eventData, "add"),
                  googleCalendarId: currStoredGoogleCalendarId,
                };
              }

              if (!isEmpty(dataToAdd)) {
                tasksToAdd.push({
                  ...dataToAdd,
                  order: currentTasksCount,
                });

                if (addToLocalList) {
                  localTasksAddList.push({
                    ...dataToAdd,
                    order: currentTasksCount,
                  });
                }

                if (googleEventIdToUse) {
                  updateTasksInGoogle.push({
                    key: googleEventIdToUse,
                    updates: {
                      extendedProperties: {
                        ...createUpdatedExtendedPropsOfGoogleEventData(
                          eventData,
                          {
                            taskKey: dataToAdd.key,
                          }
                        ),
                      },
                    },
                  });
                }
              }
              currentTasksCount++;
            }
          }
        } else {
          if (!isEmpty(currTask)) {
            if (
              currTask.due !== "none" &&
              isValidDate(parseDate(currTask.due, INTERNAL_DATE_FORMAT))
            ) {
              tasksToRemove.push(currTask);
            }
          }
        }
      });
    }

    // if (syncCurrTasks) {
    //   currTasksSynced = true;
    //   currentTasks.forEach((t) => {
    //     if (
    //       t.due !== "none" &&
    //       !t.googleEventId &&
    //       isTaskDateSameOrOnwards(parseISO(t.due), parsedConnectedDate)
    //     ) {
    //       const isTaskRecurring = checkIfTaskIsRecurring(t);

    //       let googleEventId = convertTaskKeyForGoogleCal(t.key);

    //       if (isTaskRecurring && t.linkedTo) {
    //         googleEventId = createGoogleEventIdByDateAndTaskId(
    //           t.linkedTo,
    //           createDateTimeFromDue(t.due, t.time)
    //         );
    //       }

    //       tasksToUpdate.push({
    //         key: t.key,
    //         updates: {
    //           googleEventId,
    //         },
    //       });

    //       localRawTasksUpdatesMap[t.key] = {
    //         googleEventId,
    //       };

    //       tasksToAddInGoogle.push({
    //         key: googleEventId,
    //         data: {
    //           ...createTaskDataForGoogleCal({
    //             ...t,
    //             dueAsDate: createDateTimeFromDue(t.due, t.time),
    //             taskKey: t.key,
    //           }),
    //         },
    //       });
    //       //
    //     }
    //   });
    // }

    removeQlInstance();

    console.debug("FIREBASE", {
      tasksToAdd,
      tasksToUpdate,
      tasksToRemove,
    });

    console.debug("GOOGLE", {
      tasksToUpdate: updateTasksInGoogle,
      tasksToAdd: tasksToAddInGoogle,
    });

    await addOrRemoveOrUpdateTasksInDb(
      {
        tasksToAdd,
        tasksToUpdate,
        tasksToRemove,
      },
      currTasksMap,
      userId
    );

    await addOrUpdateOrRemoveTasksInGoogleCal(
      {
        tasksToUpdate: updateTasksInGoogle,
        tasksToAdd: tasksToAddInGoogle,
      },
      false
    );

    await Vue.nextTick();

    let rawTasks = store.getters["task/rawTasks"];

    if (currTasksSynced && !isEmpty(localRawTasksUpdatesMap)) {
      rawTasks = rawTasks.map((t) => {
        if (localRawTasksUpdatesMap[t.key]) {
          return {
            ...t,
            ...localRawTasksUpdatesMap[t.key],
          };
        }

        return t;
      });
    }

    resStatus = "OK";
    await processRawTasksAndRefreshList(rawTasks);

    EventEmitter.emit(SYNC_COMPLETED);
    // addOrRemoveOrUpdateTasksInLocalTasksList({
    //   tasksToAdd: [...localTasksAddList, ...recurrenceVirtualTasks],
    //   tasksToUpdate,
    //   tasksToRemove,
    // });
  } catch (error) {
    console.debug("Error in createDataForTasksDbFromEvents function", error);
    toastMsg = "Error occurred while syncing";
    toastColor = "red";
  } finally {
    Vue.nextTick(() => {
      store.dispatch("toast/showToast", {
        message: toastMsg,
        color: toastColor,
      });
      store.dispatch("setCalSync", false);
    });
  }

  return {
    status: resStatus,
  };
};

export const addOrRemoveOrUpdateTasksInDb = async (
  { tasksToAdd, tasksToUpdate, tasksToRemove },
  currTasksMap = store.getters["task/rawTasksMap"] || {},
  userId = store.getters["user/userInfo"].uid
) => {
  let dbUpdates = {};
  const prefix = "/tasks/";
  if (tasksToAdd && tasksToAdd.length) {
    dbUpdates = tasksToAdd.reduce((a, task) => {
      a[`/tasks/${task.key}`] = { ...task, key: task.key };
      return a;
    }, dbUpdates);
  }

  if (tasksToUpdate && tasksToUpdate.length) {
    dbUpdates = tasksToUpdate.reduce((a, task) => {
      if (!isEmpty(currTasksMap[task.key])) {
        const currTaskData = currTasksMap[task.key];
        a[`${prefix}${task.key}`] = {
          ...currTaskData,
          ...task.updates,
          key: currTaskData.key,
        };
      }
      return a;
    }, dbUpdates);
  }

  if (tasksToRemove && tasksToRemove.length) {
    dbUpdates = tasksToRemove.reduce((a, task) => {
      if (currTasksMap[task.key]) {
        a[`${prefix}${task.key}`] = null;
      }
      return a;
    }, dbUpdates);
  }

  await DatabaseInterface.update(
    {
      ...dbUpdates,
    },
    userId
  );
};

export const addOrRemoveOrUpdateTasksInLocalTasksList = async (
  {
    tasksToAdd,
    tasksToUpdate,
    tasksToRemove,
    addMethod = "unshift",
    verifyAdd = true,
    doNotProcessTaskForUpdate,
  },
  refreshList = true,
  refreshCallBack
) => {
  const data = {
    addMethod,
    verifyAdd,
  };
  const currProjects = store.getters["task/projects"];
  const currCategories = store.getters["task/categories"];
  const currProcessedTasks = store.getters["task/tasks"];
  if (tasksToUpdate && tasksToUpdate.length) {
    const taskUpdatesList = [];
    tasksToUpdate.forEach((t) => {
      const currIndex = currProcessedTasks.findIndex(
        (task) => task.key === t.key
      );
      if (currIndex >= 0) {
        let finalUpdatedData = {
          ...t.updates,
        };

        if (!doNotProcessTaskForUpdate) {
          finalUpdatedData = processTask({
            ...currProcessedTasks[currIndex],
            ...t.updates,
          });
        }
        taskUpdatesList.push({
          key: t.key,
          updates: { ...finalUpdatedData },
        });
      }
    });
    data.tasksToUpdate = taskUpdatesList;
  }

  if (tasksToRemove && tasksToRemove.length) {
    data.taskIdsToRemove = tasksToRemove.map((t) => t.key);
  }

  if (tasksToAdd && tasksToAdd.length) {
    data.tasksToAdd = processTaskList(tasksToAdd, currProjects, currCategories);
  }
  await store.dispatch("task/addOrUpdateOrRemoveTasks", data);
  // Vue.nextTick(() => {
  if (refreshList) {
    callRefreshList();
  }

  if (refreshCallBack) {
    refreshCallBack();
  }
  return true;
  // });
};

const processGoogleEvent = (eventData, type = "update", currTaskData) => {
  let createdData = {};
  let qlInstance = createOrGetQlInstance();
  TASK_PROPS_FROM_GOOGLE_EVENT.forEach((fieldData) => {
    if (fieldData.field === "start") {
      const date = getDueDateFromGoogleEvent(eventData);
      if (date && isValidDate(date)) {
        createdData = {
          ...createdData,
          ...getDateAndTimeFromDueDate(date, undefined, false),
          flag: true,
        };
      }
      // if (eventData.extendedProperties?.private) {
      //   if (eventData.extendedProperties?.private?.taskKey) {

      //   }
      // }
    } else if (fieldData.field === "recurrence") {
      let addRecurrence = false;
      let isInUpdateMode = false;
      if (type === "update" && !isEmpty(currTaskData)) {
        isInUpdateMode = true;
        if (
          !isEmpty(eventData.recurrence) &&
          !isEqual(currTaskData.recurrence, eventData.recurrence)
        ) {
          addRecurrence = true;
        }
      } else if (areTaskRecurrenceRulesValid(eventData.recurrence)) {
        addRecurrence = true;
      }

      if (addRecurrence) {
        let parsedDate;
        if (createdData?.due && createdData?.time) {
          parsedDate = createDateTimeFromDue(createdData.due, createdData.time);
        } else {
          const dateAndTimeFromDate = getDateFromGoogleStartTime(
            eventData.start
          );
          parsedDate = createDateTimeFromDue(...dateAndTimeFromDate);
        }
        const { rule: parsedRuleData } = parseRuleDataFromTaskRecurrenceRules({
          recurrence: eventData.recurrence,
          dueAsDate: parsedDate,
        });

        const parsedRuleOptions = parsedRuleData.options;

        const ruleFreq = parsedRuleOptions.freq;
        const taskFreq = TASK_FREQ_FROM_RRULE_MAP[ruleFreq];
        createdData.frequency = taskFreq;
        createdData.recurrence = eventData.recurrence;

        if (isInUpdateMode && !currTaskData.coRelationalId) {
          createdData.coRelationalId = createUniqueId();
        }
      }
    } else {
      if (!isEmpty(eventData[fieldData.field])) {
        if (fieldData.field === "description") {
          let fieldDataToUse = !isHTML(eventData[fieldData.field])
            ? convertTaskDescrStringToHTMLString(eventData[fieldData.field])
            : eventData[fieldData.field];

          if (eventData.parsedDescr) {
            fieldDataToUse = eventData.parsedDescr;
          } else {
            fieldDataToUse = setDataAndGetHTMLFromQl(
              fieldDataToUse,
              false,
              qlInstance
            );
          }

          createdData[fieldData.key] = fieldDataToUse;
        } else if (fieldData.field === "summary") {
          let resourcesToSet = [];
          let valueToSet = eventData[fieldData.field];

          if (eventData.parsedSummary && !isEmpty(currTaskData)) {
            const allUpdatedMentions = getAllMentionsFromStr(
              eventData.parsedSummary
            );

            if (!isEmpty(allUpdatedMentions)) {
              let processedText = convertTaskDescrStringToHTMLString(
                eventData.parsedSummary
              );

              resourcesToSet = getAllResourcesIdsByTags(allUpdatedMentions);

              processedText = replaceMentionTagsWithTagEls(
                currTaskData.title,
                allUpdatedMentions,
                processedText
              );
              valueToSet = processedText;
            }
          }

          createdData[fieldData.key] = valueToSet;
          createdData.resources = resourcesToSet || [];
        } else {
          createdData[fieldData.key] = eventData[fieldData.field];
        }
      }
    }
  });
  // eventPropsKeys.forEach((eventPropKey) => {
  //   const fieldData = TASK_PROPS_FROM_GOOGLE_EVENT.find(
  //     (p) => p.field === eventPropKey
  //   );
  //   if (!isEmpty(fieldData)) {
  //   }
  // });

  if (type === "add") {
    createdData = fillTaskData({
      ...createdData,
    });
    createdData.googleEventId = eventData.id;
    createdData.googleCalendarId = GoogleCalHelpers.getStoredCalId();
    createdData.isFromGoogle = true;
  }

  return createdData;
};

const hasGoogleDataChanged = (eventData, currData) => {
  const qlInstance = createOrGetQlInstance();
  return TASK_PROPS_FROM_GOOGLE_EVENT.some((p) => {
    let resVal = false;
    if (p.field === "start") {
      let date;
      const startDateData = eventData[p.field];
      if (startDateData.date) {
        date = parseDate(startDateData.date, INTERNAL_DATE_FORMAT);
      } else if (startDateData.dateTime) {
        date = new Date(startDateData.dateTime);
      }
      const currentDate = createDateTimeFromDue(currData.due, currData.time);
      resVal = !isDatesEqual(new Date(date), new Date(currentDate));
    } else {
      if (p.field === "description") {
        const eventDataProp = eventData[p.field];
        const currDataProp = currData[p.key];
        if (eventDataProp === undefined) {
          eventData[p.field] = "";
        }
        if (currDataProp === undefined) {
          currData[p.key] = "";
        }

        let currDescr = currData[p.key]?.trim();
        let eventDescr = eventData[p.key]?.trim();
        const convertedCurrPropData = setDataAndGetHTMLFromQl(
          currDescr,
          false,
          qlInstance
        );

        let isModifiedDescr = false;

        if (isHTMBlob(eventDescr)) {
          const htmlContext = extractDataFromHTMLBlob(eventDescr);
          const descrText = getInnerTextFromHTMLContent(currDescr);
          let eventDescrText = getInnerTextFromHTMLContent(htmlContext);
          const allUpdatedMentions = getAllMentionsFromStr(descrText);
          const allMentionsInGoogle = getAllMentionsFromStr(eventDescrText);

          if (!isEmpty(allUpdatedMentions)) {
            if (!isEmpty(allMentionsInGoogle)) {
              allMentionsInGoogle.forEach((mentInGoogle, mentIndex) => {
                if (allUpdatedMentions[mentIndex]) {
                  eventDescrText = eventDescrText.replace(
                    mentInGoogle,
                    allUpdatedMentions[mentIndex]
                  );
                }
              });
            }
          }

          const allUpdatedMentionsInGoogleDescr =
            getAllMentionsFromStr(eventDescrText);

          eventDescr = convertTaskDescrStringToHTMLString(eventDescrText);
          eventDescr = replaceMentionTagsWithTagEls(
            currDescr,
            allUpdatedMentionsInGoogleDescr,
            eventDescr
          );

          isModifiedDescr = true;
          //
        }

        const convertedUpdatedPropData = setDataAndGetHTMLFromQl(
          eventDescr,
          false,
          qlInstance
        );

        resVal = !isEqual(convertedUpdatedPropData, convertedCurrPropData);

        if (resVal && isModifiedDescr) {
          eventData.parsedDescr = convertedUpdatedPropData;
        }

        resVal = !isEqual(convertedUpdatedPropData, convertedCurrPropData);
      } else if (p.field === "summary") {
        const currPropDelta = convertHTMLToQlDelta(currData[p.key], qlInstance);
        const { str: convertedCurrTitleStr } =
          createStrWithMentionsFromQlDelta(currPropDelta);
        // const convertedEventSummary =
        //   updateMentionTagsInEventSummary(eventData);

        const allMentions = getAllMentionsFromStr(convertedCurrTitleStr);

        const allMentionsInGoogleTitle = getAllMentionsFromStr(
          eventData.summary
        );

        let updatedSummary = eventData.summary.trim();
        if (!isEmpty(allMentions)) {
          if (!isEmpty(allMentionsInGoogleTitle)) {
            // allMentions.forEach((ment, mainMentionIndex) => {
            allMentionsInGoogleTitle.forEach((mentInGoogle, mentIndex) => {
              if (allMentions[mentIndex]) {
                updatedSummary = updatedSummary.replace(
                  mentInGoogle,
                  allMentions[mentIndex]
                );
              }
            });
          }

          eventData.parsedSummary = updatedSummary;
        }

        // const convertedCurrPropData = setDataAndGetHTMLFromQl(
        //   currData[p.key],
        //   false,
        //   qlInstance
        // );
        // const convertedUpdatedPropData = setDataAndGetHTMLFromQl(
        //   eventData[p.field],
        //   false,
        //   qlInstance
        // );
        resVal = !isEqual(updatedSummary, convertedCurrTitleStr);
        // resVal = !isEqual(convertedEventSummary, convertedCurrTitleStr);
        // resVal = !isEqual(convertedUpdatedPropData, convertedCurrPropData);
      } else {
        if (p.field === "recurrence") {
          const currValue = currData[p.key];

          const googleValue = eventData[p.field];

          if (
            (isEmpty(googleValue) && !isEmpty(currValue)) ||
            (isEmpty(currValue) && !isEmpty(googleValue))
          ) {
            resVal = true;
          } else if (!isEmpty(currValue) && !isEmpty(googleValue)) {
            resVal = !isEqual(eventData[p.field], currData[p.key]);
          }
        } else {
          resVal = !isEqual(eventData[p.field], currData[p.key]);
        }
      }
    }
    return resVal;
  });
};

export const createAdditionalDates = (params) => {
  const { frequency, startDateTime, repeatCount = 1, repeatDay } = params;
  let rules;

  if (!isEmpty(frequency)) {
    let ruleFreg, ruleDay;
    if (frequency.value === "daily") {
      ruleFreg = RRule.DAILY;
    }
    if (frequency.value === "monthly") {
      ruleFreg = RRule.MONTHLY;
    }

    if (frequency.value === "weekly") {
      ruleDay = repeatDay;
      ruleFreg = RRule.WEEKLY;
    }

    if (startDateTime && ruleFreg) {
      const rule = new RRule({
        freq: ruleFreg,
        interval: repeatCount,
        dtstart: startDateTime,
        byweekday: getRRuleWeekDay(ruleDay),
      });

      const rulesList = rule.toString().split(/\r\n|\r|\n/);

      rules = rulesList.slice(1);
    }
  }

  return { rules };
};

export const createRecurringTasksDbData = (
  mainTaskData,
  recurringDates,
  objToUpdate,
  isGoogleCalSyncEnabled
) => {
  let currTasksCount = store.getters["task/rawTasks"]?.length || 0;
  if (recurringDates && recurringDates.length) {
    objToUpdate = recurringDates.reduce((obj, d) => {
      const createdTaskData = duplicateTask(
        {
          ...mainTaskData,
          ...d,
          userPosition: 0,
        },
        currTasksCount
      );

      if (isGoogleCalSyncEnabled) {
        createdTaskData.googleEventId = convertTaskKeyForGoogleCal(
          createdTaskData.key
        );

        createdTaskData.googleCalendarId = GoogleCalHelpers.getStoredCalId();
      }
      createdTaskData.linkedTo = mainTaskData.key;
      obj[`/tasks/${createdTaskData.key}`] = createdTaskData;
      currTasksCount++;
      return obj;
    }, objToUpdate);
  }

  return objToUpdate;
};

export const createRecurringTasksDataForAdd = (
  mainTaskData,
  recurringDates,
  isGoogleCalSyncEnabled
) => {
  let currTasksCount = store.getters["task/rawTasks"].length;
  let dbUpdates = {};
  let newTasks = [];
  if (recurringDates && recurringDates.length) {
    dbUpdates = recurringDates.reduce((obj, d) => {
      if (!d.key) {
        currTasksCount++;
        const createdTaskData = duplicateTask(
          {
            ...mainTaskData,
            ...d,
          },
          currTasksCount
        );

        if (isGoogleCalSyncEnabled) {
          createdTaskData.googleEventId = convertTaskKeyForGoogleCal(
            createdTaskData.key
          );
          createdTaskData.googleCalendarId = GoogleCalHelpers.getStoredCalId();
        }
        createdTaskData.linkedTo = mainTaskData.key;
        newTasks.push({ ...createdTaskData });
        obj[`/tasks/${createdTaskData.key}`] = createdTaskData;
      } else {
        const updatedTaskData = createTaskDataForDb(mainTaskData);
        obj = {
          ...obj,
          ...updatedTaskData,
        };
      }
      return obj;
    }, dbUpdates);
  }

  return {
    dbUpdates,
    newTasks,
  };
};

// export const getRecurringTasks = (tasksToLoop, linkedId, type = "map") => {
//   let currTasks = tasksToLoop;
//   let tasks = [];
//   if (type === "map") {
//     for (const taskKey in currTasks) {
//       const taskData = currTasks[taskKey];
//       if (taskData.linkedTo === linkedId) {
//         tasks.push({ key: taskKey, due: taskData.due, time: taskData.time });
//       }
//     }
//   } else {
//     currTasks.forEach((taskData) => {
//       if (taskData.linkedTo === linkedId) {
//         tasks.push({
//           key: taskData.key,
//           due: taskData.due,
//           time: taskData.time,
//         });
//       }
//     });
//   }

//   return tasks;
// };

export const getRecurringTasks = (tasksToLoop, type = "map", config) => {
  let linkId, propToCheck;
  const linkedId = config.linkedId || config.linkedTo;
  if (linkedId) {
    linkId = linkedId;
    propToCheck = "linkedTo";
  }

  if (config.coRelationalId) {
    linkId = config.coRelationalId;
    propToCheck = "coRelationalId";
  }

  let currTasks = tasksToLoop;
  let tasks = [];
  if (type === "map") {
    for (const taskKey in currTasks) {
      const taskData = currTasks[taskKey];
      if (taskData[propToCheck] === linkId) {
        tasks.push(taskData);
      }
    }
  } else {
    currTasks.forEach((taskData) => {
      if (taskData[propToCheck] === linkId) {
        tasks.push(taskData);
      }
    });
  }

  tasks.sort((a, b) => {
    const parsedADueDate = parseDate(a.due, INTERNAL_DATE_FORMAT);
    const parsedBDueDate = parseDate(b.due, INTERNAL_DATE_FORMAT);
    let resValue = 0;

    if (parsedADueDate > parsedBDueDate) {
      resValue = 1;
    } else if (parsedADueDate < parsedBDueDate) {
      resValue = -1;
    } else {
      if (a.linkedTo) {
        return 1;
      } else if (!a.linkedTo) {
        return -1;
      }
    }

    return resValue;
  });

  if (config.filter && !isEmpty(config.filterOpts)) {
    const filterOpts = config.filterOpts;
    tasks = tasks.filter((task) =>
      filterOpts.every((filterFunc) => filterFunc(task))
    );
  }
  return tasks;
};

export const createTaskDataForDb = (taskDataToUpdate) => {
  const taskDataProps = Object.keys(taskDataToUpdate);
  const objToUpdate = {};
  taskDataProps.forEach((taskPropKey) => {
    if (TASK_PROPS_TO_CHECK.includes(taskPropKey)) {
      objToUpdate[`/tasks/${taskDataToUpdate.key}/${taskPropKey}`] =
        taskDataToUpdate[taskPropKey];
    }
  });

  return objToUpdate;
};

export const checkIfTaskDataChanged = (
  updatedData,
  currTaskData,
  propsToCheck = TASK_PROPS_TO_CHECK
) => {
  return propsToCheck.some((key) => {
    return updatedData[key] !== currTaskData[key];
  });
};

export const areTaskRecurrenceRulesValid = (rules) =>
  Array.isArray(rules) && !isEmpty(rules) && rules.every((r) => !!r);

export const checkIfTaskIsRecurring = (taskData) => {
  return (
    !!taskData.linkedTo || areTaskRecurrenceRulesValid(taskData.recurrence)
  );
};

export const checkTaskIsAllDay = (dueDate) => {
  const dueTime = formatDate(dueDate, INTERNAL_TIME_FORMAT);

  return dueTime === "00:00";
};

export const isTaskNotCompleted = (task) => !task.completed;

export const isTaskVirtual = (task) => !!task.isVirtual;

export const isTaskAfterDateFilterCreator = (
  dateToCheck,
  propToCheck,
  comparer = "after"
) => {
  let compareFunction = (task) =>
    new Date(task[propToCheck]) > new Date(dateToCheck);

  if (comparer === "afterOrEqual") {
    compareFunction = (task) =>
      new Date(task[propToCheck]) >= new Date(dateToCheck);
  }
  return compareFunction;
};

export const filterRecurringTasks = (tasksToFilter, filters) => {
  return tasksToFilter.filter((task) =>
    filters.every((filterFun) => filterFun(task))
  );
};

export const convertTaskDateToAllDayOrTimeForGoogle = (
  dueDate,
  type = "allDay"
) => {
  let googleDataToSend = {};
  const { startDateTime, endDateTime, startDate, endDate } =
    getStartAndEndFromDueDateTime(dueDate);
  googleDataToSend.start = {};
  googleDataToSend.end = {};
  if (type === "allDay") {
    googleDataToSend.start.date = startDate;
    googleDataToSend.start.dateTime = null;
    googleDataToSend.end.date = endDate;
    googleDataToSend.end.dateTime = null;
    googleDataToSend.extendedProperties = {
      private: {
        isAllDayEvent: true,
      },
    };
  } else {
    googleDataToSend.start = {
      dateTime: startDateTime,
      date: null,
      timeZone: getCurrentTimeZoneName(),
    };
    googleDataToSend.end = {
      dateTime: endDateTime,
      date: null,
      timeZone: getCurrentTimeZoneName(),
    };
  }

  return googleDataToSend;
};

export const isTaskRecurring = (taskData) => checkIfTaskIsRecurring(taskData);

export const getDateFromGoogleStartTime = (googleStartTimeData) => {
  let date;
  if (googleStartTimeData.date) {
    date = parseDate(googleStartTimeData.date, INTERNAL_DATE_FORMAT);
  } else if (googleStartTimeData.dateTime) {
    date = new Date(googleStartTimeData.dateTime);
  }
  return {
    ...getDateAndTimeFromDueDate(date, undefined, false),
  };
};

export const parseRuleDataFromTaskRecurrenceRules = (taskData) => {
  const taskRecurrenceRulesString = taskData.recurrence.join("\r\n");
  const taskDate = taskData.dueAsDate;

  let ruleData = {
    ruleSet: {},
    rule: {},
  };

  if (isValidDate(taskDate)) {
    const extractedTaskDateInString = formatDate(
      taskDate,
      INTERNAL_DATE_FORMAT
    );

    // const currTaskDueDateUTC = converDateToUTC(taskDate, true, "utc");
    const currTaskDueDate = new Date(extractedTaskDateInString);
    // const currTaskDueDateUTC = DateTime.fromJSDate(taskDate)
    //   .setZone(getCurrentTimeZoneName())
    //   .toJSDate();

    const withDTStartRules = addDTSTARTRuleInRulesString(
      currTaskDueDate,
      taskRecurrenceRulesString
    );

    // console.debug("WI", withDTStartRules);
    // console.debug("DDD", withDTStartRules);
    const parsedRuleSet = getRuleSetFromRuleString(withDTStartRules, {
      forceset: true,
    });

    ruleData.ruleSet = parsedRuleSet;
    // console.debug("RRR", parsedRuleSet.rrules()[0]);
    ruleData.rule = parsedRuleSet.rrules()[0];
    // ruleData.rule = parsedRuleSet.rrules()[0];
  }

  return ruleData;
};

export const createTaskDataFromProvidedData = (dataToProcess) => {
  const allProjects = store.getters["task/projects"] || {};
  let createdData = {};
  let updatePriorityValue = true;
  INLINE_EDIT_TASK_PROPS.forEach((propData) => {
    if (
      propData.field === "dueAsDate" &&
      dataToProcess[propData.field] !== undefined
    ) {
      createdData = {
        ...createdData,
        ...getDateAndTimeFromDueDate(dataToProcess.dueAsDate, undefined, false),
      };
    } else if (dataToProcess[propData.field] !== undefined) {
      if (propData.field === "project" && dataToProcess[propData.field]) {
        const projectValue = dataToProcess[propData.field];
        updatePriorityValue = false;
        if (allProjects[projectValue] && allProjects[projectValue].priority) {
          createdData.priority = allProjects[projectValue].priority;
        }
      }

      if (propData.field === "priority") {
        if (updatePriorityValue) {
          createdData[propData.key] = dataToProcess[propData.field];
        }
      } else {
        createdData[propData.key] = dataToProcess[propData.field];

        if (
          propData.field === "title" &&
          dataToProcess[propData.field] !== undefined
        ) {
          createdData.resources = getAllMentionedResourceIdsFromText(
            dataToProcess[propData.field]
          );
        }
      }
    }
  });

  return createdData;
};

export const createDbUpdateDataFromProvidedData = (dataToProcess, currData) => {
  let updates = {};
  let editActionData = {};
  let changedProps = {};
  let updatedTaskData = {};
  const allProjects = store.getters["task/projects"] || {};
  const rowKey = currData.key;
  let updatePriorityValue = true;

  INLINE_EDIT_TASK_PROPS.forEach((fieldData) => {
    if (
      fieldData.field === "dueAsDate" &&
      dataToProcess[fieldData.field] !== undefined
    ) {
      let due = "none";
      let time = "00:00";
      const oldValue = currData[fieldData.field];
      const currValue = dataToProcess[fieldData.field];

      editActionData = {
        ...editActionData,
        time: "00:00",
        due: "none",
        flag: typeof currData.flag === "boolean" ? currData.flag : false,
      };

      const oldDateValid = oldValue && isValidDate(oldValue);
      const newDateValid = currValue && isValidDate(currValue);
      if (oldDateValid) {
        editActionData.time = makeTimeFormat(oldValue);
        editActionData.due = makeDateFormat(oldValue);
      }

      if (newDateValid) {
        ({ due, time } = getDateAndTimeFromDueDate(currValue));
      }

      /**
       * mark as flagged when schudeling unscheduled task
       */
      if (newDateValid && !oldDateValid) {
        updates["/tasks/" + currData.key + "/flag"] = true;
        changedProps.flag = true;
      }

      updates["/tasks/" + rowKey + "/due"] = due;
      updates["/tasks/" + rowKey + "/time"] = time;
      changedProps.due = due;
      changedProps.time = time;
      updatedTaskData.due = due;
      updatedTaskData.time = time;
    } else if (
      booleanPropsOfTask.indexOf(fieldData.field) > -1 &&
      dataToProcess[fieldData.field] !== undefined
    ) {
      const currValue = dataToProcess[fieldData.field];
      const oldValue = currData[fieldData.field];
      if (typeof currValue === "boolean") {
        updates[`/tasks/${rowKey}/${fieldData.key}`] = currValue;
        updatedTaskData[fieldData.key] = currValue;
        changedProps[fieldData.key] = currValue;
      }

      editActionData[fieldData.key] =
        typeof oldValue === "boolean" ? oldValue : false;
      // if (typeof oldValue === "boolean") {
      //   editActionData[fieldData.key] = oldValue;
      // }
    } else if (
      fieldData.field === "pausedReminderDays" &&
      dataToProcess[fieldData.field] !== undefined
    ) {
      const currValue = dataToProcess[fieldData.field] || [];
      const oldValue = currData[fieldData.field] || [];

      updates[`/tasks/${rowKey}/${fieldData.key}`] = currValue;
      updatedTaskData[fieldData.key] = currValue;
      changedProps[fieldData.key] = currValue;
      editActionData[fieldData.key] = oldValue || [];
    } else if (
      fieldData.field === "resources" &&
      dataToProcess[fieldData.field] !== undefined
    ) {
      const currValue = dataToProcess[fieldData.field];
      editActionData[fieldData.key] = currData[fieldData.key] || [];
      updates["/tasks/" + rowKey + `/${fieldData.key}`] = currValue || [];
      updatedTaskData[fieldData.key] = currValue || [];
      changedProps[fieldData.key] = currValue || [];
    } else if (
      fieldData.field === "reminderDays" &&
      dataToProcess[fieldData.field] !== undefined
    ) {
      const currValue = dataToProcess[fieldData.field];
      editActionData[fieldData.key] = currData[fieldData.key] || 0;
      updates["/tasks/" + rowKey + `/${fieldData.key}`] = currValue || 0;
      updatedTaskData[fieldData.key] = currValue || 0;
      changedProps[fieldData.key] = currValue || 0;
    } else if (!isEmpty(dataToProcess[fieldData.field])) {
      const currValue = dataToProcess[fieldData.field];
      let oldValue;
      if (fieldData.field === "project") {
        editActionData[fieldData.key] = "";
        const currProject = currData.project || "";
        oldValue = currProject;
        const newProject = currValue;
        if (
          newProject !== currProject &&
          allProjects[currValue] &&
          allProjects[currValue].priority
        ) {
          updatePriorityValue = false;
          editActionData.priority = currData.priority || "";
          changedProps.priority = allProjects[currValue].priority;
          updatedTaskData["priority"] = allProjects[currValue].priority;
          updates["/tasks/" + rowKey + `/priority`] =
            allProjects[currValue].priority;
        }
      }

      if (fieldData.key === "priority") {
        if (updatePriorityValue) {
          editActionData[fieldData.key] = "";
          oldValue = currData[fieldData.key];
          updates["/tasks/" + rowKey + `/${fieldData.key}`] = currValue || "";
          updatedTaskData[fieldData.key] = currValue || "";
          changedProps[fieldData.key] = currValue || "";
        }
      } else {
        editActionData[fieldData.key] = "";
        oldValue = currData[fieldData.key];
        updates["/tasks/" + rowKey + `/${fieldData.key}`] = currValue || "";
        updatedTaskData[fieldData.key] = currValue || "";
        changedProps[fieldData.key] = currValue || "";
      }

      if (oldValue) {
        editActionData[fieldData.key] = oldValue;
      }
    }
  });

  editActionData.changedProps = changedProps;

  updates[`/tasks/${rowKey}/key`] = rowKey;
  updates["/tasks/" + rowKey + `/modified`] = getCurrDate();
  return {
    updates,
    editedData: editActionData,
    updatedTaskData,
  };
};

export const createGoogleEventIdByDateAndTaskId = (taskKey, taskDate) => {
  let dateFormatToUse = GOOGLE_ID_ALL_DAY_FORMAT;
  let dateToUse = taskDate;
  if (!checkTaskIsAllDay(dateToUse)) {
    dateFormatToUse = GOOGLE_ID_DAY_FORMAT;
    return `${convertTaskKeyForGoogleCal(taskKey)}_${formatDate(
      convertDateToUTCWithLocalTime(dateToUse),
      dateFormatToUse
    )}`;
  }
  return `${convertTaskKeyForGoogleCal(taskKey)}_${formatDate(
    dateToUse,
    dateFormatToUse
  )}`;
};

export const createVirtualTasksFromRecurringTasks = (tasksToProcess) => {
  let tasksToAdd = [];
  let recurrenceList = [];
  let reminderTasks = [];
  if (!Array.isArray(tasksToProcess)) tasksToProcess = [tasksToProcess];

  if (tasksToProcess && tasksToProcess.length) {
    tasksToProcess.forEach((taskData) => {
      const isRecurring = isTaskRecurring(taskData);
      let tasksCount = 0;
      const tasksRulesAreValid = areTaskRecurrenceRulesValid(
        taskData.recurrence
      );
      if (isRecurring && tasksRulesAreValid) {
        // const mainTaskId = taskData.key;

        // const mainTaskDueDate = new Date(taskData.due);
        const parsedDueDate = createDateTimeFromDue(
          taskData.due,
          taskData.time
        );

        const { ruleSet: parsedRuleSet } = parseRuleDataFromTaskRecurrenceRules(
          {
            recurrence: taskData.recurrence,
            dueAsDate: parsedDueDate,
          }
        );

        // console.debug("RRRR", parsedRuleSet.valueOf());
        const excludedDates = parsedRuleSet.exdates();
        // const excludedDates = [];
        let endDate = add(new Date(), {
          years: 1,
        });

        endDate = set(endDate, {
          hours: 0,
          minutes: 0,
          seconds: 0,
        });

        // let startDate = zonedTimeToUtc(parsedDueDate, getCurrentTimeZoneName());
        // let startDate = DateTime.fromFormat(taskData.due, "yyyy-MM-dd", {
        //   zone: "UTC",
        // }).toJSDate();
        // let startDate = DateTime.(parsedDueDate)
        //   .setZone("UTC", { keepLocalTime: true })
        //   .toJSDate();

        const startDate = new Date(taskData.due);
        const recurrences = parsedRuleSet.between(startDate, endDate, true);

        recurrences.forEach((recurrenceDate, index) => {
          const convertedRecurrence =
            convertDateToUTCWithLocalTime(recurrenceDate);

          // DateTime.fromJSDate(recurrenceDate)
          //   .toUTC()
          //   .setZone("local", { keepLocalTime: true })
          //   .toJSDate();

          const excludedIndex = excludedDates.findIndex((d) => {
            const convertedExludedDate = convertDateToUTCWithLocalTime(d);
            return isSameDay(convertedExludedDate, convertedRecurrence);
          });
          if (excludedIndex === -1) {
            const taskId = createTaskId();
            const extractedDate =
              DateTime.fromJSDate(convertedRecurrence).toFormat(
                INTERNAL_DATE_FORMAT
              );

            const taskDataToStore = {
              ...fillTaskData(taskData),
              key: taskId,
              isFirstTask: index === 0,
              due: extractedDate,
              time: taskData.time,
              order: taskData.order,
              userPosition: taskData.userPosition,
              modified: "",
              // ...getDateAndTimeFromDueDate(recurrenceDate, undefined, false),
              linkedTo: taskData.key,
              recurrence: [],
              isVirtual: true,
              isCalendarOnly: tasksCount !== 0,
            };

            reminderTasks.push(
              ...createReminderTasks({
                ...taskDataToStore,
                due: extractedDate,
                time: taskData.time,
              })
            );
            tasksToAdd.push(processTask({ ...taskDataToStore }));
            recurrenceList.push({
              key: taskId,
              date: convertedRecurrence,
            });
            tasksCount++;
          }
        });
      }
    });
  }

  tasksToAdd.push(...reminderTasks);

  return {
    tasks: tasksToAdd,
    datesList: recurrenceList,
  };
};

export const createVirtualTasksFromRecurringTasksAndRefreshList = (
  tasksToProcess
) => {
  const tasksToAdd = createVirtualTasksFromRecurringTasks(tasksToProcess);

  store.dispatch("task/addOrUpdateOrRemoveTasks", {
    tasksToAdd,
  });
  Vue.nextTick(() => {
    callRefreshList();
  });
};

export const getDueDateFromGoogleEvent = (
  eventData,
  propToProcess = "start"
) => {
  const startDateData = eventData[propToProcess];
  let date;
  if (startDateData.date) {
    date = parseDate(startDateData.date, INTERNAL_DATE_FORMAT);
  } else if (startDateData.dateTime) {
    date = new Date(startDateData.dateTime);
  }

  return date;
};

export const createVirtualTasksFromGoogleRecurringEventsMap = (eventsMap) => {
  let tasksToAdd = [];

  for (const eventKey in eventsMap) {
    const recurringEventData = eventsMap[eventKey];
    const taskData = {
      ...recurringEventData.data,
      coRelationalId: recurringEventData.coRelationalId,
      key: recurringEventData.key,
    };

    const parsedDueDate = createDateTimeFromDue(taskData.due, taskData.time);
    const { ruleSet: parsedRuleSet } = parseRuleDataFromTaskRecurrenceRules({
      recurrence: taskData.recurrence,
      dueAsDate: parsedDueDate,
    });
    const rulesExcludedDates = parsedRuleSet.exdates();
    const finalExludedDatesList = [
      ...rulesExcludedDates,
      ...recurringEventData.excludedDates,
    ];
    const endDate = add(new Date(), {
      years: 1,
    });

    const recurrences = parsedRuleSet.between(
      createTaskDueDate(taskData.due),
      endDate
    );

    recurrences.forEach((recurrenceDate, index) => {
      const convertedRecurrence = convertDateToUTCWithLocalTime(recurrenceDate);
      const excludedDateIndex = finalExludedDatesList.findIndex((d) => {
        const convertedExludedDate = convertDateToUTCWithLocalTime(d);
        return isSameDay(convertedExludedDate, convertedRecurrence);
      });

      if (excludedDateIndex === -1) {
        tasksToAdd.push(
          processTask({
            ...fillTaskData(taskData),
            key: createTaskId(),
            isFirstTask: index === 0 ? true : false,
            isVirtual: true,
            ...getDateAndTimeFromDueDate(convertedRecurrence, undefined, false),
            linkedTo: taskData.key,
            recurrence: [],
          })
        );
      }
    });
  }

  return tasksToAdd;
};

export const processRawTasks = (rawTasks) => {
  let tasks = [];
  let recurrenceTasksMap = {};
  let reminderTasks = [];
  const allRecurringTasks = [];
  rawTasks.forEach((task) => {
    const taskIsRecurring = checkIfTaskIsRecurring(task);
    if (taskIsRecurring) {
      if (areTaskRecurrenceRulesValid(task.recurrence)) {
        const { tasks: virtualTasks, datesList } =
          createVirtualTasksFromRecurringTasks(task);
        allRecurringTasks.push(...virtualTasks);
        recurrenceTasksMap[task.key] = datesList;
      } else if (task.linkedTo) {
        reminderTasks.push(...createReminderTasks(task));
        tasks.push(processTask(task));
      }
      // Soemthing for recurring
    } else {
      reminderTasks.push(...createReminderTasks(task));
      tasks.push(processTask(task));
    }
  });

  tasks = tasks.concat(allRecurringTasks, reminderTasks);

  return { tasks, recurrenceTasksMap };
};

export const checkIfTaskDateIsFirstRecurring = (taskDate, mainTaskData) => {
  const mainTaskRecurrenceRules = mainTaskData.recurrence;
  const mainTaskDueDate = getDueDateFromGoogleEvent(mainTaskData);
  const { ruleSet: parsedRuleSet } = parseRuleDataFromTaskRecurrenceRules({
    recurrence: mainTaskRecurrenceRules,
    dueAsDate: mainTaskDueDate,
  });
  const endDate = add(new Date(), {
    years: 1,
  });

  const recurrences = parsedRuleSet.between(
    createTaskDueDate(formatDate(mainTaskDueDate, INTERNAL_DATE_FORMAT)),
    // converDateToUTC(mainTaskDueDate),
    // converDateToUTC(endDate)
    endDate
  );

  return isBefore(taskDate, recurrences[1]);
};

export const processRawTasksAndRefreshList = async (
  rawTasks,
  refreshList = true
) => {
  const { tasks: processedTasks, recurrenceTasksMap } =
    processRawTasks(rawTasks);
  store.commit("task/updateState", {
    tasks: processedTasks,
    recurringTasksMap: recurrenceTasksMap,
  });

  await Vue.nextTick();
  // Vue.nextTick(() => {

  if (refreshList) {
    callRefreshList();
  }

  // });
};

export const createRecurringDatesFromTaskRules = (
  taskData
  // timezone = CURR_TIMEZONE_NAME
) => {
  let finalDatesList = [];
  const parsedDueDate = createDateTimeFromDue(taskData.due, taskData.time);

  const { ruleSet: parsedRuleSet } = parseRuleDataFromTaskRecurrenceRules({
    recurrence: taskData.recurrence,
    dueAsDate: parsedDueDate,
  });

  const excludedDates = parsedRuleSet.exdates();
  const endDate = add(new Date(), {
    years: 1,
  });

  const startDate = new Date(taskData.due);

  const recurrences = parsedRuleSet.between(startDate, endDate, true);

  recurrences.forEach((recurrenceDate) => {
    const convertedRecurrence = convertDateToUTCWithLocalTime(recurrenceDate);
    // const convertedRecurrence = DateTime.fromJSDate(recurrenceDate)
    //   .toUTC()
    //   .setZone("local", { keepLocalTime: true })
    //   .toJSDate();

    const excludedDateIndex = excludedDates.findIndex((d) => {
      return isSameDay(convertDateToUTCWithLocalTime(d), convertedRecurrence);
    });
    if (excludedDateIndex === -1) {
      finalDatesList.push(convertedRecurrence);
    }
  });

  return finalDatesList;
};

export const getRecurrenceIdByDateFromRecurrences = (
  dateToFind,
  recurrencingTaskId
) => {
  let firstDate = {};
  const tasksRecurrenceMap = store.getters["task/recurringTasksMap"] || {};
  const recurrences = tasksRecurrenceMap[recurrencingTaskId];
  if (recurrences && recurrences.length) {
    firstDate = recurrences.find((recurrenceData) =>
      isSameDay(recurrenceData.date, dateToFind)
    );
  }

  return firstDate;
};

export const removeTasksFromRecurringTasksMap = (list) => {
  if (!Array.isArray(list)) list = [list];

  store.dispatch("task/removeRecurringTasksFromMap", {
    // list: [
    //   {
    //     mainId: mainTaskId,
    //     tasksIds: tasksIdsToRemove,
    //   },
    // ],
    list,
  });
};

export const addOrUpdateRecurringTasksInRecurringTasksInfoMap = (list) => {
  if (!Array.isArray(list)) list = [list];

  store.dispatch("task/addOrUpdateRecurringTasksInRecurringInfoMap", {
    // list: [
    //   {
    //     mainId: mainTaskId,
    //     tasksIds: tasksIdsToRemove,
    //   },
    // ],
    list,
  });
};

export const removeTasksFromRecurringTasksMapByDates = (
  mainId,
  datesToCheck
) => {
  store.dispatch("task/removeRecurringTasksFromMapByDates", {
    datesToCheck,
    mainTaskId: mainId,
  });
};

export const replaceRecurringTasksInfoInMap = (mainTaskId, tasks) => {
  store.dispatch("task/replaceRecurringTasksInfoInMap", {
    list: [
      {
        mainTaskId,
        tasks,
      },
    ],
  });
};

export const addRecurringTasksInfoInMap = (mainTaskId, tasksToAdd) => {
  if (!Array.isArray(tasksToAdd)) tasksToAdd = [tasksToAdd];

  store.dispatch("task/addRecurringTasksInMap", {
    mainId: mainTaskId,
    tasksToAdd,
  });
};

export const createTaskDueDate = (taskDueDateString) =>
  new Date(taskDueDateString);

export const excludeDatesInTaskRules = (
  datesToExclude,
  taskRuleSet,
  firstConvert = true
) => {
  if (!Array.isArray(datesToExclude)) datesToExclude = [datesToExclude];
  datesToExclude.forEach((d) => {
    if (isValidDate(d)) {
      let dateToUse = d;
      if (firstConvert) {
        const extractedDate = formatDate(d, INTERNAL_DATE_FORMAT);
        dateToUse = new Date(extractedDate);
      }

      taskRuleSet.exdate(dateToUse);
    }
  });
};

export const createRecurrenceOptsValuesFromTaskRecurrenceRules = (taskData) => {
  let values = {};

  if (!isEmpty(taskData) && areTaskRecurrenceRulesValid(taskData.recurrence)) {
    let dataToUse = { ...taskData };

    if (
      !isValidDate(dataToUse.dueAsDate) &&
      dataToUse.due &&
      dataToUse.due !== "none"
    ) {
      dataToUse.dueAsDate = createDateTimeFromDue(
        dataToUse.due,
        dataToUse.time
      );
    }
    const { rule: parsedRuleData } =
      parseRuleDataFromTaskRecurrenceRules(dataToUse);
    values = {
      recurrenceRepeatDay: !isEmpty(parsedRuleData.options.byweekday)
        ? parsedWeekOptsMap[parsedRuleData.options.byweekday[0]]
        : undefined,
      recurrenceCount:
        parsedRuleData.options.interval >= 1
          ? parsedRuleData.options.interval
          : undefined,
    };

    if (!taskData.frequency) {
      values.frequency = TASK_FREQ_FROM_RRULE_MAP[parsedRuleData.options.freq];
    }
  }

  return values;
};

export const checkIfTaskRecurrenceIntervalOrDayChanged = (
  updatedTaskData,
  currTaskData,
  updatedDataRetrieveMode = "existing",
  currDataRetrieveMode = "extract"
) => {
  let currRecurrenceRepeatCount,
    // updatedRecurrenceRepeatDay,
    updatedRecurrenceRepeatCount;
  // currRecurrenceRepeatDay,
  if (currDataRetrieveMode === "extract") {
    ({
      // recurrenceRepeatDay: currRecurrenceRepeatDay,
      recurrenceCount: currRecurrenceRepeatCount,
    } = createRecurrenceOptsValuesFromTaskRecurrenceRules(currTaskData));
  } else if (currDataRetrieveMode === "existing") {
    ({
      // recurrenceRepeatDay: currRecurrenceRepeatDay,
      recurrenceCount: currRecurrenceRepeatCount,
    } = currTaskData);
  }

  if (updatedDataRetrieveMode === "existing") {
    ({
      // recurrenceRepeatDay: updatedRecurrenceRepeatDay,
      recurrenceCount: updatedRecurrenceRepeatCount,
    } = updatedTaskData);
  } else if (updatedDataRetrieveMode === "extract") {
    ({
      // recurrenceRepeatDay: updatedRecurrenceRepeatDay,
      recurrenceCount: updatedRecurrenceRepeatCount,
    } = createRecurrenceOptsValuesFromTaskRecurrenceRules(updatedTaskData));
  }

  return (
    // !isEqual(currRecurrenceRepeatDay, updatedRecurrenceRepeatDay) ||
    !isEqual(currRecurrenceRepeatCount, updatedRecurrenceRepeatCount)
  );
};

export const createDataForRecurringTask = (
  providedNewData,
  mode = "update",
  replaceRecurrenceRules = false,
  providedCurrData,
  storeRecurrenceRulesChange,
  keepExistingRulesInUpdate,
  autoIncrementOrderCount = true,
  providedProjectData,
  providedObjectiveData
) => {
  const rawTasksMap = store.getters["task/rawTasksMap"] || {};
  const projects = store.getters["task/projects"];
  const objectivesMap = store.getters["task/objectivesMap"] || {};
  const isGoogleCalSyncEnabled = store.getters["isGoogleCalSyncEnabled"];
  let updatedProjectData = {};
  let updatedObjectiveData = {};
  let projectDbUpdates = {};
  if (!isEmpty(providedProjectData)) {
    updatedProjectData = { ...providedProjectData };
  } else if (!isEmpty(store.getters["editForm/updatedProjectData"])) {
    updatedProjectData = store.getters["editForm/updatedProjectData"];
  }

  if (!isEmpty(providedObjectiveData)) {
    updatedObjectiveData = { ...providedObjectiveData };
  } else if (!isEmpty(store.getters["editForm/updatedObjectiveData"])) {
    updatedObjectiveData = store.getters["editForm/updatedObjectiveData"];
  }
  let newTaskData = {};
  let updatedTaskData = {};
  let toProject = providedNewData.project;
  const previousDueDate = providedCurrData.dueAsDate;
  const currRecurrenceRules = providedCurrData.recurrence;
  // const newTaskSnapshots = providedNewData.snapshots;
  // const currTaskSnapshots = providedCurrData.snapshots;
  const newRecurrenceRules = providedNewData.recurrence;
  let newTaskFlagged = providedNewData.flag;
  let taskProjectChanged = false;
  let onlyProjectPriorityChanged = false;
  let taskProjectData = {};
  let editedTaskData = {};
  let recurrenceData = {};
  // const currentTaskPriority = providedCurrData.priority;

  const newTaskDueDate = providedNewData.dueAsDate;
  let newTaskPriority = providedNewData.priority || "";
  let updates = {};
  let editProjectData = {};
  let googleEventIdData = {};
  let googleCalUpdatesList = [];
  let timeToSet = "00:00"; // Default time
  let dateToSet = "none"; // Date defaults to 'none'
  let currTasksCount = Object.keys(rawTasksMap).length || 0;
  let objectiveDbUpdates = {},
    objectiveEditData = {};
  let isTaskObjectiveValid = checkIfTaskObjectiveIsValid(providedNewData);
  const isNewTaskDateValid = newTaskDueDate && isValidDate(newTaskDueDate);

  if (mode === "update") {
    const rulesAreValid = areTaskRecurrenceRulesValid(currRecurrenceRules);
    if (rulesAreValid && !replaceRecurrenceRules) {
      recurrenceData = {
        recurrence:
          areTaskRecurrenceRulesValid(newRecurrenceRules) &&
          !isEqual(newRecurrenceRules, currRecurrenceRules)
            ? newRecurrenceRules
            : currRecurrenceRules,
      };
    }

    // if (
    //   rulesAreValid &&
    //   storeRecurrenceRulesChange &&
    //   !isEmpty(currRecurrenceRules)
    // ) {
    //   recurrenceData = {
    //     recurrence: currRecurrenceRules,
    //   };
    //   console.debug("STR");
    // }

    if (
      replaceRecurrenceRules &&
      areTaskRecurrenceRulesValid(newRecurrenceRules)
    ) {
      recurrenceData = {
        recurrence: newRecurrenceRules,
      };
    }

    if (keepExistingRulesInUpdate && rulesAreValid) {
      recurrenceData = {
        recurrence: currRecurrenceRules,
      };
    }
  }

  if (mode === "add" && areTaskRecurrenceRulesValid(newRecurrenceRules)) {
    recurrenceData = {
      recurrence: newRecurrenceRules,
    };
  }

  if (isNewTaskDateValid) {
    timeToSet = makeTimeFormat(newTaskDueDate);
    dateToSet = makeDateFormat(newTaskDueDate);
    newTaskFlagged = true;
  }

  const dataToFill = {
    completed: providedNewData.completed,
    created: providedNewData.created,
    description: providedNewData.description || "",
    due: dateToSet,
    flag: newTaskFlagged,
    key: providedCurrData.key,
    modified: getCurrDate(),
    order: providedNewData.order,
    priority: newTaskPriority,
    project: providedNewData.project || "",
    status: providedNewData.status,
    taskType: providedNewData.taskType || "",
    time: timeToSet,
    title: providedNewData.title || "",
    googleEventId: providedNewData.googleEventId,
    coRelationalId:
      providedNewData.coRelationalId || providedCurrData.coRelationalId,
    frequency: providedNewData.frequency,
    linkedTo: providedNewData.linkedTo,
    isFirstTask: providedNewData.isFirstTask || providedCurrData.isFirstTask,
    isContingent: providedNewData.isContingent || false,
    completedOn: providedNewData.completedOn || providedCurrData.completedOn,
    userPosition: providedNewData.userPosition || 0,
    objective: providedNewData.objective || "",
    repeatInfo: !isEmpty(providedNewData.repeatInfo)
      ? providedNewData.repeatInfo
      : {},
    reminderDays: providedNewData.reminderDays || null,
    pausedReminderDays: getPausedReminderDaysVal(
      providedNewData.pausedReminderDays
    ),
    positionChanged: providedNewData.positionChanged || false,
    resources: getAllMentionedResourceIdsFromText(providedNewData.title) || [],
    isCustomPositioned: providedNewData.isCustomPositioned || false,
    customPosition: providedNewData.customPosition || 0,
    customPositionIndex: providedNewData.customPositionIndex || 0,
  };

  const changedProps = fillTaskData(dataToFill, recurrenceData);

  if (mode === "add") {
    // const convertedDate = isValidDate(this.taskData.dueAsDate)
    //   ? converDateToUTC(this.taskData.dueAsDate)
    //   : "";
    changedProps.originalDate = isValidDate(providedCurrData.dueAsDate)
      ? providedCurrData.dueAsDate
      : "";

    if (autoIncrementOrderCount) {
      changedProps.order = currTasksCount;
      changedProps.userPosition = 0;
      changedProps.customPosition = currTasksCount;
      changedProps.customPositionIndex = 0;
    }
  } else {
    // const convertedTaskDate = isValidDate(
    //   new Date(this.rawTasksMap[this.taskData.key]?.originalDate)
    // )
    //   ? converDateToUTC(
    //       new Date(this.rawTasksMap[this.taskData.key]?.originalDate)
    //     )
    //   : "";
    changedProps.originalDate = isValidDate(
      rawTasksMap[providedCurrData.key]?.originalDate
    )
      ? rawTasksMap[providedCurrData.key]?.originalDate
      : "";
  }

  // if (newTaskSnapshots && newTaskSnapshots.length) {
  //   changedProps.snapshots = newTaskSnapshots;
  // } else if (currTaskSnapshots && currTaskSnapshots.length) {
  //   changedProps.snapshots = currTaskSnapshots;
  // }

  editedTaskData = {
    project: providedCurrData.project || "",
    time: "00:00",
    due: "none",
    completed: providedCurrData.completed,
    created: providedCurrData.created,
    description: providedCurrData.description || "",
    flag: providedCurrData.flag,
    key: providedCurrData.key,
    modified: providedCurrData.modified || "",
    order: providedCurrData.order || 0,
    userPosition: providedCurrData.userPosition || 0,
    priority: providedCurrData.priority || "",
    status: providedCurrData.status || "",
    taskType: providedCurrData.taskType || "",
    title: providedCurrData.title,
    isContingent: providedCurrData.isContingent || false,
    completedOn: providedCurrData.completedOn || "",
    frequency: providedCurrData.frequency || "",
    objective: providedCurrData.objective || "",
    repeatInfo: !isEmpty(providedCurrData.repeatInfo)
      ? providedCurrData.repeatInfo
      : {},
    reminderDays: providedCurrData.reminderDays || null,
    pausedReminderDays: getPausedReminderDaysVal(
      providedCurrData.pausedReminderDays
    ),
    positionChanged: providedCurrData.positionChanged || false,
    resources: providedCurrData.resources || [],
    isCustomPositioned: providedCurrData.isCustomPositioned || false,
    customPosition: providedCurrData.customPosition || 0,
    customPositionIndex: providedCurrData.customPositionIndex || 0,
  };

  if (previousDueDate && isValidDate(previousDueDate)) {
    (editedTaskData.due = makeDateFormat(previousDueDate)),
      (editedTaskData.time = makeTimeFormat(previousDueDate));
  }

  if (providedCurrData.project && projects[providedCurrData.project]) {
    if (toProject !== providedCurrData.project) {
      taskProjectChanged = true;
      editedTaskData.changedProject = toProject;
    } else {
      taskProjectData = projects[providedCurrData.project];
    }
  } else if (!providedCurrData.project && providedNewData.project) {
    taskProjectChanged = true;
  }

  if (taskProjectChanged && toProject && projects[toProject]) {
    taskProjectData = projects[toProject];
  }

  if (!isEmpty(taskProjectData)) {
    let newProjectPriority;
    // if (!taskProjectData.priority) {
    //   if (newTaskPriority) {
    //     newProjectPriority = newTaskPriority;
    //   }
    // } else {
    // if (!currentTaskPriority) {
    //   newTaskPriority = taskProjectData.priority;
    // }

    // }

    if (
      !taskProjectChanged &&
      !isEmpty(updatedProjectData) &&
      taskProjectData.priority !== updatedProjectData.priority
    ) {
      newProjectPriority = updatedProjectData.priority;

      onlyProjectPriorityChanged = true;
    }

    if (
      taskProjectChanged &&
      taskProjectData.priority !== updatedProjectData.priority
    ) {
      newProjectPriority = updatedProjectData.priority;
    }

    if (newProjectPriority) {
      updates["/projects/" + taskProjectData.key + "/priority"] =
        newProjectPriority;
      updates[`/projects/${taskProjectData.key}/modified`] = getCurrDate();
      projectDbUpdates[`/projects/${taskProjectData.key}/priority`] =
        newProjectPriority;
      projectDbUpdates[`/projects/${taskProjectData.key}/modified`] =
        getCurrDate();
      editProjectData = {
        key: taskProjectData.key,
        priority: taskProjectData.priority || "",
        changedProps: {
          priority: newProjectPriority,
        },
      };
    }
  }

  editedTaskData.changedProps = changedProps;
  editedTaskData.key = providedCurrData.key;
  if (storeRecurrenceRulesChange) {
    if (!isEmpty(currRecurrenceRules)) {
      editedTaskData.recurrence = currRecurrenceRules;
    }

    if (!isEmpty(newRecurrenceRules)) {
      editedTaskData.changedProps = {
        ...editedTaskData.changedProps,
        recurrence: newRecurrenceRules,
      };
    }
  }

  if (
    isTaskObjectiveValid &&
    !isEmpty(updatedObjectiveData) &&
    checkIfObjectiveDataChanged(
      updatedObjectiveData,
      objectivesMap[providedNewData.objective]
    )
  ) {
    ({ dbUpdates: objectiveDbUpdates, editedData: objectiveEditData } =
      createUpdateAndEditedForObjective(
        updatedObjectiveData,
        objectivesMap[providedNewData.objective]
      ));
    //
  }

  if (!isEmpty(objectiveDbUpdates)) {
    updates = {
      ...updates,
      ...objectiveDbUpdates,
    };
  }

  if (mode === "update") {
    const areRulesValid = areTaskRecurrenceRulesValid(
      providedNewData.recurrence
    );

    if (isGoogleCalSyncEnabled) {
      if (!providedCurrData.googleEventId && isNewTaskDateValid) {
        googleEventIdData = {
          googleEventId: convertTaskKeyForGoogleCal(changedProps.key),
          googleCalendarId: GoogleCalHelpers.getStoredCalId(),
        };
      }

      const recurrenceUpdates = {};
      if (areRulesValid) {
        recurrenceUpdates.recurrence = providedNewData.recurrence;
      }
      googleCalUpdatesList.push({
        key: providedNewData.key,
        updates: {
          ...providedNewData,
          dueAsDate: newTaskDueDate,
          ...recurrenceUpdates,
        },
        currData: {
          ...providedCurrData,
        },
      });
    }

    if (changedProps.completed) {
      changedProps.completedOn = getCurrDate("extended");
    }

    // if (newTaskSnapshots && newTaskSnapshots.length) {
    //   changedProps.snapshots = newTaskSnapshots;
    // }

    updatedTaskData = {
      ...changedProps,
      key: providedCurrData.key,
      modified: getCurrDate(),
      ...googleEventIdData,
    };

    updates["/tasks/" + providedCurrData.key] = updatedTaskData;
  } else {
    newTaskData = {
      ...changedProps,
      key: providedCurrData.key,
      // key: createTaskId(),
    };

    if (isGoogleCalSyncEnabled && isNewTaskDateValid) {
      newTaskData.googleEventId = convertTaskKeyForGoogleCal(newTaskData.key);
      newTaskData.googleCalendarId = GoogleCalHelpers.getStoredCalId();
    }

    updates[`/tasks/${newTaskData.key}`] = newTaskData;
    // updates[]
    // For Add
  }

  return {
    newTaskData,
    updatedTaskData,
    editProjectData,
    editedTaskData,
    objectiveDbUpdates,
    objectiveEditData,
    projectDbUpdates,
    dbUpdates: updates,
    onlyProjectPriorityChanged,
  };
};

export const checkIfTaskIsEdited = (
  updatedTaskData,
  currTaskData,
  taskPropCheckList = []
) => {
  let dataChanged = false;
  if (!isEmpty(updatedTaskData) && !isEmpty(currTaskData)) {
    const propsToCheck = !isEmpty(taskPropCheckList)
      ? taskPropCheckList
      : TASK_PROPS_FOR_FORM;
    dataChanged = propsToCheck.some((prop) => {
      // console.log("PROP", prop);
      // console.log("CURR", currTaskData[prop]);
      // console.log("UPDATED", updatedTaskData[prop]);
      // console.log("RES", !isEqual(updatedTaskData[prop], currTaskData[prop]));
      return !isEqual(updatedTaskData[prop], currTaskData[prop]);
    });
  }

  return dataChanged;
};

export const createRecurringDatesFromRurrenceRulesSet = (
  recurrenceRules,
  taskDueDateString
) => {
  let finalDatesList = [];
  const parsedRuleSet = getRuleSetFromRuleString(recurrenceRules, {
    forceset: true,
  });

  const excludedDates = parsedRuleSet.exdates();
  const endDate = add(new Date(), {
    years: 1,
  });

  const startDate = new Date(taskDueDateString);

  const recurrences = parsedRuleSet.between(startDate, endDate, true);

  recurrences.forEach((recurrenceDate) => {
    const convertedRecurrence = convertDateToUTCWithLocalTime(recurrenceDate);

    const excludedDateIndex = excludedDates.findIndex((d) => {
      return isSameDay(convertDateToUTCWithLocalTime(d), convertedRecurrence);
    });
    if (excludedDateIndex === -1) {
      finalDatesList.push(convertedRecurrence);
    }
  });
};

export const whiteListDatesFromList = (existingDatesList, datesToWhiteList) => {
  const clonedExistingDatesList = [...existingDatesList];
  datesToWhiteList.forEach((date) => {
    const existingIndex = clonedExistingDatesList.findIndex((d) => {
      return isSameDay(
        convertDateToUTCWithLocalTime(d),
        convertDateToUTCWithLocalTime(date)
      );
    });

    if (existingIndex !== -1) {
      clonedExistingDatesList.splice(existingIndex, 1);
    }
  });

  return clonedExistingDatesList;
};

export const createRecurringTasksInfoData = (taskData) => {
  let recurringTasks = [],
    recurringDatesList = [];
  const res = createVirtualTasksFromRecurringTasks(taskData);
  if (!isEmpty(res.datesList)) {
    recurringDatesList = res.datesList;
  }

  if (!isEmpty(res.tasks)) {
    recurringTasks = res.tasks;
  }

  return {
    recurringTasks,
    recurringDatesList,
  };
};

export const fillRecurrenceOptsInTaskData = (taskData, parentData) => {
  const filledTaskData = {
    ...taskData,
    ...createRecurrenceOptsValuesFromTaskRecurrenceRules(parentData),
  };
  return filledTaskData;
};

export const isTaskGroup = (itemData) => itemData.group !== undefined;

export const isTaskDataValid = (taskDataToCheck) => {
  const isRecurringTask = checkIfTaskIsRecurring(taskDataToCheck);
  let dataIsValid = false;

  const rawTasksMap = store.getters["task/rawTasksMap"] || {};

  if (isRecurringTask) {
    let taskDataForRulesParsing = {};
    const isVirtual = taskDataToCheck.isVirtual;
    const linkedTo = taskDataToCheck.linkedTo;
    const tasksRulesAreValid = areTaskRecurrenceRulesValid(
      taskDataToCheck.recurrence
    );
    if (isVirtual) {
      taskDataForRulesParsing = rawTasksMap[taskDataToCheck.linkedTo];
    } else if (linkedTo || tasksRulesAreValid) {
      const keyToUse = linkedTo || taskDataToCheck.key;
      if (rawTasksMap[keyToUse]) {
        taskDataForRulesParsing = rawTasksMap[keyToUse];
        // const { rule:parsedRuleData } = parseRuleDataFromTaskRecurrenceRules(this.rawTasksMap[linkedTo])
      }
    }
    if (!isEmpty(taskDataForRulesParsing) && taskDataForRulesParsing.key) {
      dataIsValid = true;
    }
  } else {
    if (
      rawTasksMap[taskDataToCheck.key] &&
      rawTasksMap[taskDataToCheck.key].key
    ) {
      dataIsValid = true;
    }
  }

  return dataIsValid;
};

export const getMainTaskRulesAndFillRecurringOpts = (taskData) => {
  const rawTasksMap = store.getters["task/rawTasksMap"] || {};
  const isRecurringTask = isTaskRecurring(taskData);
  let taskDataForRulesParsing = {};
  const isVirtual = taskData.isVirtual;
  const linkedTo = taskData.linkedTo;
  const tasksRulesAreValid = areTaskRecurrenceRulesValid(taskData.recurrence);

  if (isRecurringTask) {
    if (isVirtual) {
      taskDataForRulesParsing = rawTasksMap[taskData.linkedTo];
    } else if (linkedTo || tasksRulesAreValid) {
      const keyToUse = linkedTo || taskData.key;
      if (rawTasksMap[keyToUse]) {
        taskDataForRulesParsing = rawTasksMap[keyToUse];
        // const { rule:parsedRuleData } = parseRuleDataFromTaskRecurrenceRules(this.rawTasksMap[linkedTo])
      }
    }

    if (!isEmpty(taskDataForRulesParsing) && taskDataForRulesParsing.key) {
      taskData = fillRecurrenceOptsInTaskData(
        taskData,
        taskDataForRulesParsing
      );
    }
  }

  return {
    ...taskData,
  };
};

export const getValidTaskDataFromListByIndex = (
  indexToGet,
  listToCheck,
  mode = "next"
) => {
  let currIndex =
    mode === "next" ? increment(indexToGet, 1) : decrement(indexToGet, 1);

  let taskData = listToCheck[currIndex];
  const taskIsValid = isTaskDataValid(taskData);
  if (!taskIsValid) {
    return {};
  }
  if (taskIsValid) {
    return {
      ...getMainTaskRulesAndFillRecurringOpts(taskData),
    };
  }

  return getValidTaskDataFromListByIndex(currIndex++, listToCheck, mode);
};

export const areTasksOfSameProject = (tasksToCheck, projectToCheck) => {
  let resValue = false;

  if (!isEmpty(tasksToCheck)) {
    resValue = tasksToCheck.every(
      (t) => t.project && t.project === projectToCheck
    );
  }

  return resValue;
};

export const processDroppedTasks = (
  droppedTasksToProcess,
  projectToCheck,
  orderToCheck
) => {
  let taskPositionsToFilter = [];
  let otherTasks = [];
  // let areSameProjectTasks = false;

  if (!isEmpty(droppedTasksToProcess)) {
    droppedTasksToProcess.forEach((droppedTask) => {
      if (droppedTask.project === projectToCheck) {
        if (droppedTask.order === orderToCheck) {
          taskPositionsToFilter.push(droppedTask.key);
        }
      } else {
        otherTasks.push(droppedTask);
      }
    });
  }

  return {
    otherTasks,
    taskIdsToFilter: taskPositionsToFilter,
  };
};

export const getTasksByOrderAndPosition = (
  listToCheck,
  fromOrder,
  fromPosition,
  excludedTasksIds
) => {
  return listToCheck.filter((taskData) => {
    return (
      taskData.order === fromOrder &&
      taskData.userPosition >= fromPosition &&
      !excludedTasksIds.includes(taskData.key)
    );
  });
};

export const createDbDataForConvertingRecurringTaskToRealTask = (
  currTaskData,
  providedUpdatedData,
  useExistingDataForGoogle = true,
  autoIncrementOrderNumber = true,
  makeSingleTask
) => {
  //   try {
  let addToDb = true;
  // let dbTasksAdd = [];
  // let dbTasksUpdate = [];
  // let tasksToAdd = [];
  // let localMainTaskDataUpdate = {};
  // let tasksToUpdate = [];
  // let googleTasksUpdateList = [];
  let removeTaskFromList = false;
  let excludedDates = [];
  let rawDataForLocalSave = {};
  let dataToAddInDb = {};
  let dataToUpdateInDb = {};
  let dataToUpdateInLocalList = {};
  let dataToAddInLocalList = {};
  // let googleDataToAdd = {};

  let dataForGoogle = {};
  // let mainTaskDataUpdateForGoogle = {};

  if (!currTaskData.isVirtual) {
    addToDb = false;
  } else {
    removeTaskFromList = true;
  }
  const mainId = currTaskData.linkedTo || currTaskData.key;
  const rawTasksMap = store.getters["task/rawTasksMap"] || {};
  const isGoogleCalSyncEnabled = store.getters["isGoogleCalSyncEnabled"];

  const mainTaskData = rawTasksMap[mainId];
  // const mainTaskRules = mainTaskData.recurrence;

  // const clonedMainTaskData = cloneDeep(mainTaskData);
  const newDueDate = providedUpdatedData.dueAsDate;
  const currDueDate = currTaskData.dueAsDate;

  let newData = cloneDeep(providedUpdatedData);
  let dateToExclude;

  newData.linkedTo = mainId;
  newData = removeProps(newData, ["recurrence"]);
  const { newTaskData, editedTaskData, updatedTaskData } =
    createDataForRecurringTask(
      newData,
      addToDb ? "add" : "update",
      undefined,
      currTaskData,
      false,
      false,
      autoIncrementOrderNumber
    );

  // const { ruleSet: parsedRuleSet } = parseRuleDataFromTaskRecurrenceRules({
  //   recurrence: mainTaskRules,
  //   dueAsDate: createDateTimeFromDue(mainTaskData.due, mainTaskData.time),
  // });

  excludedDates.push(currDueDate);
  if (newDueDate !== undefined) {
    if (!isEqual(currDueDate, newDueDate)) {
      if (
        isValidDate(currDueDate) &&
        isValidDate(newDueDate) &&
        !isSameDay(newDueDate, currDueDate)
      ) {
        dateToExclude = newDueDate;
      } else if (isValidDate(currDueDate) && !isValidDate(newDueDate)) {
        dateToExclude = currDueDate;
      } else if (isValidDate(newDueDate) && !isValidDate(currDueDate)) {
        dateToExclude = newDueDate;
      }
    }
  }

  if (isValidDate(dateToExclude)) {
    excludedDates.push(dateToExclude);
  }

  if (addToDb) {
    editedTaskData.key = newTaskData.key;

    if (makeSingleTask) {
      editedTaskData.linkedTo = currTaskData.linkedTo;
      editedTaskData.coRelationalId = currTaskData.coRelationalId;
      editedTaskData.frequency = currTaskData.frequency;

      editedTaskData.changedProps.linkedTo = "";
      editedTaskData.changedProps.coRelationalId = "";
      editedTaskData.changedProps.frequency = "";

      newTaskData.linkedTo = "";
      newTaskData.coRelationalId = "";
      newTaskData.frequency = "";
    }

    if (isGoogleCalSyncEnabled && newTaskData.due !== "none") {
      newTaskData.googleCalendarId = GoogleCalHelpers.getStoredCalId();
      if (makeSingleTask) {
        newTaskData.googleEventId = convertTaskKeyForGoogleCal(newTaskData.key);
      } else if (currTaskData.isFromGoogle) {
        newTaskData.googleEventId = createGoogleEventIdByDateAndTaskId(
          currTaskData.googleEventId.split("_")[0],
          currDueDate
        );
      } else {
        newTaskData.googleEventId = createGoogleEventIdByDateAndTaskId(
          mainId,
          currDueDate
        );
      }
    }

    dataToAddInDb = { ...newTaskData };
    dataToAddInLocalList = { ...newTaskData };
    rawDataForLocalSave = { data: { ...newTaskData }, key: newTaskData.key };
  } else {
    const taskToProcess = {
      ...rawTasksMap[currTaskData.key],
      ...updatedTaskData,
    };

    dataToUpdateInDb = {
      key: currTaskData.key,
      updates: { ...updatedTaskData },
    };

    dataToUpdateInLocalList = {
      key: currTaskData.key,
      updates: { ...updatedTaskData },
    };

    rawDataForLocalSave = { data: { ...taskToProcess }, key: currTaskData.key };
  }

  const finalEditedDataObj = {
    ...editedTaskData,
    key: editedTaskData.key,
  };

  // Google code
  if (isGoogleCalSyncEnabled) {
    const dataToSend = addToDb
      ? {
          ...newTaskData,
          dueAsDate: createDateTimeFromDue(newTaskData.due, newTaskData.time),
        }
      : {
          ...updatedTaskData,
          dueAsDate: createDateTimeFromDue(
            updatedTaskData.due,
            updatedTaskData.time
          ),
        };

    const dataForGoogleCal = {
      ...createTaskDataForGoogleCal(
        {
          ...dataToSend,
          taskKey: useExistingDataForGoogle
            ? currTaskData.key
            : addToDb
            ? newTaskData.key
            : updatedTaskData.key,
        },
        useExistingDataForGoogle ? currTaskData : {}
      ),
    };

    dataForGoogle = {
      currData: {
        dueAsDate: currDueDate,
        taskKey: newTaskData.key,
        googleEventId: newTaskData.googleEventId || currTaskData.googleEventId,
      },
      newData: dataForGoogleCal,
    };
  }

  rawDataForLocalSave.data = fillRecurrenceOptsInTaskData(
    rawDataForLocalSave.data,
    mainTaskData
  );

  return {
    editedTaskData: finalEditedDataObj,
    mainTaskId: mainTaskData.key,
    rawDataForLocalSave,
    removeTaskFromList,
    dataToAddInDb,
    dataToAddInLocalList,
    dataToUpdateInDb,
    dataToUpdateInLocalList,
    dataForGoogle,
    excludedDates,
  };
};

export const checkIfTaskGroupCaptionHasDate = (groupCaption) => {
  return /(\d{1,2}\/\d{1,2}\/\d{2})/g.test(groupCaption);
};

export const createAndAddTodayFilter = (
  todayFilterVal,
  selectedDates,
  currFilters
) => {
  const localFilters = [...currFilters];
  const baseFilters = [
    // [
    //   {
    //     field: "isContingent",
    //     type: "=",
    //     value: true,
    //   },
    //   [
    //     {
    //       field: "due",
    //       type: "=",
    //       value: "none",
    //     },
    //     {
    //       ...createDueDateListFilter(selectedDates),
    //     },
    //     {
    //       field: "showInToday",
    //       type: "=",
    //       value: true,
    //       // dasdsadsad,
    //     },
    //   ],
    //   // {
    //   //   field: "due",
    //   //   type: "=",
    //   //   value: "none",
    //   // },
    // ],
    [
      {
        ...createDueDateListFilter(selectedDates),
      },
      // {
      //   field: "contingentOrder",
      //   type: "=",
      //   value: "0",
      // },
    ],
    [
      {
        field: "showInToday",
        type: "=",
        value: true,
      },
    ],

    // {
    //   ...createDueDateListFilter(dates),
    // },
    // { ...createDueDateListFilter(selectedDates) },
    // { ...createContingentFilter() },
  ];

  if (!isEmpty(todayFilterVal)) {
    // if (todayFilterVal === "today") {
    //   filterToSet = [baseFilters[1]];
    // } else if (todayFilterVal === "contingents") {
    //   filterToSet = [baseFilters[0]];
    // } else if (todayFilterVal === "reminders") {
    //   filterToSet = [baseFilters[2]];
    // }

    const storedIndex = localFilters.findIndex((filter) => {
      return isEqual(filter, baseFilters);
    });

    if (storedIndex >= 0) {
      // const filterData = cloneDeep(localFilters[storedIndex]);
      let finalFilterData = [];
      localFilters.splice(storedIndex, 1);
      todayFilterVal.forEach((f) => {
        if (f === "today" || f === "contingents") {
          finalFilterData.push(baseFilters[0]);
        } else if (f === "reminders") {
          finalFilterData.push(baseFilters[1]);
        }

        // else if (f === "contingents") {
        //   finalFilterData.push(baseFilters[0]);
        // }
      });
      if (!isEmpty(finalFilterData)) {
        localFilters.push(finalFilterData);
      }
    }
  }

  return localFilters;
};

export const checkIfRowTaskDataEditedChanged = (
  updatedData,
  currData,
  propsToCheck
) => {
  const isDataChanged = propsToCheck.some((prop) => {
    if (updatedData[prop] !== undefined) {
      return !isEqual(updatedData[prop], currData[prop]);
    }
  });

  return isDataChanged;
};

export const createViewDataForDb = (viewToGo, keepCompletedVal) => {
  const isInboxViewEnabled = store.getters["task/isInboxViewEnabled"];
  const isInspectModeEnabled = store.getters["task/isInspectModeEnabled"];
  const currNav = store.getters["task/currNav"];
  const prevTreeGroupView = store.getters["task/prevTreeGroupView"];
  let updates = {};
  let viewIndex = parseInt(viewToGo, 10);
  updates["nav"] = viewIndex;
  updates["globalView"] = false;
  updates["inboxView"] = false;
  updates["inspectModeEnabled"] = false;

  if (
    !keepCompletedVal &&
    (isInboxViewEnabled || isInspectModeEnabled || currNav === 3)
  ) {
    // if (isInspectModeEnabled) {
    //   localUpdates.inspectModeEnabled = false;
    // }
    updates["showCompleted"] = false;
  }
  // updates["view/0/groupView"] = 0;
  updates["view/0/sortMode"] = 0;
  updates["view/0/statusFilter"] = "scheduled";
  updates["view/1/statusFilter"] = "incomplete";
  updates["view/1/sortMode"] = 0;

  if (viewIndex === 0) {
    updates["view/0/groupView"] = 0;
  }

  if (viewIndex === 1) {
    updates["view/1/groupView"] = 1;
  }

  if (viewIndex === 4) {
    updates["view/4/groupView"] = 1;
  }
  if (isInboxViewEnabled) {
    if (typeof prevTreeGroupView === "number" && prevTreeGroupView >= 0) {
      updates["view/1/groupView"] = prevTreeGroupView;
    }
  }

  updates["view/2/statusFilter"] = "incomplete";
  updates["view/3/statusFilter"] = "incomplete";
  updates["view/3/sortMode"] = 1;

  return updates;
};

export const createNextRepeatTask = (taskData) => {
  // existingDateData
  let resData = {};

  const currTaskDueDate = taskData.dueAsDate;
  // const currDate = new Date();
  if (isValidDate(currTaskDueDate) && !isEmpty(taskData.repeatInfo)) {
    // const currDateOnlyString = formatDate(new Date(), INTERNAL_DATE_FORMAT);
    // const currTaskDueDateString = formatDate(
    //   currTaskDueDate,
    //   INTERNAL_DATE_FORMAT
    // );
    // const currDateObj = createDateTimeFromDue(currDateOnlyString);
    // const currTaskDueDateObj = createDateTimeFromDue(currTaskDueDateString);

    const baseDateToUse = currTaskDueDate;
    // const baseDateToUse = isBefore(currTaskDueDateObj, currDateObj)
    //   ? createDateTimeFromDue(currDateOnlyString, existingDateData.time)
    //   : currTaskDueDate;
    const { frequency, count } = taskData.repeatInfo;
    let newDueDate;

    switch (frequency) {
      case "weekly":
        newDueDate = addWeeks(baseDateToUse, count);
        break;
      case "daily":
        newDueDate = addDays(baseDateToUse, count);
        break;
      case "monthly":
        newDueDate = addMonths(baseDateToUse, count);
        break;
      case "yearly":
        newDueDate = addYears(baseDateToUse, count);
    }

    if (isValidDate(newDueDate)) {
      const dateAndTimeRes = getDateAndTimeFromDueDate(newDueDate);
      let clonedTaskData = cloneDeep(taskData);
      const createdKey = createUniqueId();
      clonedTaskData.recurrence = [];
      clonedTaskData.linkedTo = "";
      clonedTaskData.coRelationalId = "";
      clonedTaskData.userPosition = increment(clonedTaskData.userPosition, 1);
      clonedTaskData.customPositionIndex = increment(
        clonedTaskData.customPositionIndex,
        1
      );
      clonedTaskData.key = createdKey;
      clonedTaskData.googleEventId = convertTaskKeyForGoogleCal(createdKey);
      clonedTaskData.googleCalendarId = GoogleCalHelpers.getStoredCalId();
      clonedTaskData.due = dateAndTimeRes.due;
      clonedTaskData.time = dateAndTimeRes.time;
      clonedTaskData.completed = false;
      clonedTaskData.completedOn = "";
      clonedTaskData.created = getCurrDate();
      clonedTaskData.modified = "";
      clonedTaskData.snapshots = [];
      clonedTaskData.objective = clonedTaskData.objective || "";

      resData = { ...clonedTaskData };
    }
  }

  return resData;
};

export const checkIfTaskIsRepeatIn = (taskData) => {
  return !isEmpty(taskData) && !isEmpty(taskData.repeatInfo);
};

export const checkIfTaskIsContinued = (taskData) => {
  return (
    (!isEmpty(taskData) &&
      !isEmpty(taskData.resources) &&
      !checkIfTaskIsRecurring(taskData) &&
      isEmpty(taskData.repeatInfo)) ||
    (!isEmpty(taskData) &&
      !isEmpty(taskData.resources) &&
      !isEmpty(taskData.repeatInfo) &&
      !checkIfTaskIsRecurring(taskData))
  );
};

export const createStrWithMentionsFromQlDelta = (delta) => {
  const allResourcesMap = getUserResources().getResources();
  let allMentions = {};
  const fullStr = stichStringFromQlDelta(delta, (deltaBlock) => {
    let str = "";
    if (typeof deltaBlock.insert === "string") {
      str = deltaBlock.insert;
    } else if (typeof deltaBlock.insert === "object") {
      let updatedResourceTag =
        allResourcesMap[deltaBlock.insert?.resourceMention?.id]?.tag;
      // if (
      //   updatedResourceTag &&
      //   !allMentions[deltaBlock.insert.resourceMention.id]
      // ) {
      //   allMentions[deltaBlock.insert.resourceMention.id] = updatedResourceTag;
      // }
      str = `@${updatedResourceTag || "deleted-resource"}`;
    }
    return str;
  });

  return {
    str: fullStr?.trim(),
    allMentions,
  };
};

export const updateMentionTagsInEventSummary = (eventData) => {
  let summaryToModified = eventData.summary;
  const allResources = getUserResources().getResources();
  if (
    !isEmpty(eventData.extendedProperties) &&
    !isEmpty(eventData.extendedProperties.private) &&
    !isEmpty(eventData.extendedProperties.private.resourcesMentionMap)
  ) {
    const resourceMentionsMap = JSON.parse(
      eventData.extendedProperties.private.resourcesMentionMap || {}
    );
    Object.entries(resourceMentionsMap).forEach(([key, value]) => {
      const newTag = allResources[key]?.tag || "deleted-resource";
      const matchRegex = new RegExp(`@${value}`, "gi");
      summaryToModified = summaryToModified.replace(matchRegex, `@${newTag}`);
    });
  }

  return summaryToModified;
};

export const getTitleAfterHyphen = (txt) => {
  let nextPosition = 0;
  let positionSet = new Set([]);
  while (nextPosition !== -1) {
    const startIndex = txt.indexOf("<s", nextPosition);
    const lastIndex = txt.indexOf("</span>", startIndex);
    if (startIndex === -1 || lastIndex === -1) {
      break;
    }
    const range = createRange(startIndex, lastIndex);
    positionSet = new Set([...positionSet, ...range]);
    nextPosition = lastIndex;
  }

  const allIndexesOfHyphen = getAllIndexesOfCharInString(txt, "-");
  const mentionsPositons = Array.from(positionSet);
  const diffRange = difference(allIndexesOfHyphen, mentionsPositons);

  let strToReturn = txt;
  const firstOccurenceOfHyphen = diffRange[0];

  if (firstOccurenceOfHyphen !== null && firstOccurenceOfHyphen !== undefined) {
    strToReturn = txt.substr(increment(firstOccurenceOfHyphen, 1));
    strToReturn = `<p>${strToReturn}`;
  }
  return strToReturn;
};

export const isModeInterChangableForInspectMode = (currNav, navToGo) => {
  let disable = false;

  if (
    treeSideBarModes.includes(currNav) &&
    treeSideBarModes.includes(navToGo) &&
    currNav !== navToGo
  ) {
    disable = true;
  }
  return disable;
};

export const checkIfTaskHasObjective = (taskData) =>
  !isEmpty(taskData) && !isEmpty(taskData.objective);

export const getTasksByObjective = (objective, rawTasksMap, excludeTaskId) => {
  let tasks = [];

  if (!isEmpty(rawTasksMap)) {
    tasks = Object.values(rawTasksMap).filter(
      (t) => t.objective && t.objective === objective && t.key !== excludeTaskId
    );
  }

  return tasks;
};

export const createContinuedTaskData = (taskData) => {
  let newTaskData = cloneDeep(taskData);
  const taskDueDate = newTaskData.dueAsDate;
  newTaskData = removeProps(newTaskData, [
    "recurrence",
    "linkedTo",
    "coRelationalId",
    "frequency",
    "recurrenceCount",
    "repeatMonthDate",
    "recurrenceRepeatDay",
    // "repeatInfo",
    "snapshots",
  ]);

  const createdDateAndTime = getDateAndTimeFromDueDate(taskDueDate);
  let createdTaskData = duplicateTask(
    {
      ...newTaskData,
      created: getCurrDate(),
      ...createdDateAndTime,
      userPosition: increment(newTaskData.userPosition, 1),
      customPositionIndex: increment(newTaskData.customPositionIndex, 1),
    },
    newTaskData.order
  );

  createdTaskData.key = createUniqueId();
  createdTaskData.modified = "";
  createdTaskData.flag = createdDateAndTime.due !== "none";

  return createdTaskData;
};

export const createListFilterForCalendar = (selectedDates) => {
  return [
    // [
    //   {
    //     field: "isContingent",
    //     type: "=",
    //     value: true,
    //   },
    //   [
    //     {
    //       field: "due",
    //       type: "=",
    //       value: "none",
    //     },
    //     !isEmpty(selectedDates)
    //       ? { ...createDueDateListFilter(selectedDates) }
    //       : { field: "due", type: "!=", value: "none" },
    //   ],
    // ],
    [
      !isEmpty(selectedDates)
        ? { ...createDueDateListFilter(selectedDates) }
        : { field: "due", type: "!=", value: "none" },
      // {
      //   field: "contingentOrder",
      //   type: "=",
      //   value: "0",
      // },
      {
        field: "isReminderTask",
        type: "!=",
        value: true,
      },
    ],

    // [
    //   {
    //     field: "showContingentInToday",
    //     type: "=",
    //     value: true,
    //   },
    // ],
  ];
};

export const addDatesAndContingentListFilter = (
  currFilters,
  selectedDates,
  showContingentTasks
) => {
  let localFilters = [...currFilters];

  const currDateFormatted = formatDate(new Date(), INTERNAL_DATE_FORMAT);
  const baseFilter = [
    ...createListFilterForCalendar(),
    [
      { field: "due", type: "!=", value: "none" },
      [
        {
          field: "showReminder",
          type: "=",
          value: true,
        },
        {
          field: "showInToday",
          type: "=",
          value: true,
        },
      ],
      {
        field: "isFirstReminderTask",
        type: "=",
        value: true,
      },
    ],
    [
      { field: "due", type: "!=", value: "none" },
      [
        {
          field: "showReminder",
          type: "=",
          value: true,
        },
        {
          field: "showInToday",
          type: "=",
          value: true,
        },
      ],
      {
        field: "isFirstReminderTask",
        type: "=",
        value: false,
      },
    ],
  ];
  const storedIndex = localFilters.findIndex((f) => isEqual(f, baseFilter));

  if (storedIndex >= 0) {
    localFilters[storedIndex] = [
      ...createListFilterForCalendar(selectedDates),

      // Disabled logic to show reminders of selected dates except for single date
      // [
      //   { field: "due", type: "!=", value: "none" },
      //   {
      //     field: "reminderDateFormatted",
      //     type: "in",
      //     value: [...selectedDates],
      //   },
      //   [
      //     {
      //       field: "showReminder",
      //       type: "=",
      //       value: true,
      //     },
      //     {
      //       field: "showInToday",
      //       type: "=",
      //       value: true,
      //     },
      //   ],
      //   {
      //     field: "isFirstReminderTask",
      //     type: "=",
      //     value: true,
      //   },
      // ],
      // [
      //   { field: "due", type: "!=", value: "none" },
      //   {
      //     field: "reminderDateFormatted",
      //     type: "=",
      //     value: selectedDates[0],
      //   },
      //   [
      //     {
      //       field: "showReminder",
      //       type: "=",
      //       value: true,
      //     },
      //     {
      //       field: "showInToday",
      //       type: "=",
      //       value: true,
      //     },
      //   ],
      //   {
      //     field: "isFirstReminderTask",
      //     type: "=",
      //     value: false,
      //   },
      // ],
      // [
      //   { field: "due", type: "!=", value: "none" },
      //   {
      //     field: "snoozedDates",
      //     type: "includes",
      //     value: selectedDates[0],
      //   },
      //   [
      //     {
      //       field: "showReminder",
      //       type: "=",
      //       value: true,
      //     },
      //     {
      //       field: "showInToday",
      //       type: "=",
      //       value: true,
      //     },
      //   ],
      //   {
      //     field: "isFirstReminderTask",
      //     type: "=",
      //     value: false,
      //   },
      // ],

      // Old logic not related to disabling reminder logic for selected dates

      // [
      //   { field: "due", type: "!=", value: "none" },
      //   {
      //     field: "reminderDateFormatted",
      //     type: "in",
      //     value: [...selectedDates],
      //   },
      //   [
      //     {
      //       field: "showReminder",
      //       type: "=",
      //       value: true,
      //     },
      //     {
      //       field: "showInToday",
      //       type: "=",
      //       value: true,
      //     },
      //   ],
      // ],
    ];

    if (!isEmpty(selectedDates)) {
      let filtersToReplace = [];
      if (selectedDates.length === 1) {
        filtersToReplace = [
          [
            { field: "due", type: "!=", value: "none" },
            {
              field: "reminderDateFormatted",
              type: "in",
              value: [...selectedDates],
            },
            [
              {
                field: "showReminder",
                type: "=",
                value: true,
              },
              {
                field: "showInToday",
                type: "=",
                value: true,
              },
            ],
            {
              field: "isFirstReminderTask",
              type: "=",
              value: true,
            },
          ],
          [
            { field: "due", type: "!=", value: "none" },
            {
              field: "reminderDateFormatted",
              type: "=",
              value: selectedDates[0],
            },
            [
              {
                field: "showReminder",
                type: "=",
                value: true,
              },
              {
                field: "showInToday",
                type: "=",
                value: true,
              },
            ],
            {
              field: "isFirstReminderTask",
              type: "=",
              value: false,
            },
          ],
        ];
      }

      if (showContingentTasks) {
        filtersToReplace.push(taskContingentFilter);
      }
      localFilters[storedIndex].push(
        ...filtersToReplace
        // taskContingentFilter
      );
    }
  }

  if (!showContingentTasks) {
    localFilters.push([
      {
        ...taskContingentFilter[0],
        value: false,
      },
      {
        ...taskContingentFilter[0],
        value: undefined,
      },
    ]);
  }
  return localFilters;
};

export const addContingentFilter = (contingentVal, currFilters) => {
  let localFilters = [...currFilters];

  if (contingentVal && !isEmpty(contingentVal)) {
    let finalFilterData = [];
    // const filterData = {
    //   field: "contingentOrder",
    //   type: "=",
    // };

    contingentVal.forEach((f) => {
      if (f === "non-contingents" || f === "contingents") {
        finalFilterData.push([
          // {
          //   ...filterData,
          //   value: "0",
          // },
          {
            field: "isReminderTask",
            type: "!=",
            value: true,
          },
        ]);
      }

      // if (f === "contingents") {
      //   finalFilterData.push({
      //     ...filterData,
      //     value: "1",
      //   });
      // }

      if (f === "reminders") {
        finalFilterData.push({
          field: "isReminderTask",
          type: "=",
          value: true,
        });
      }
    });

    localFilters.push(finalFilterData);
  }

  return localFilters;

  // localFilters.push({
  //   field:""
  // })
};

export const getPausedReminderDaysVal = (days) => {
  return !isEmpty(days) ? days : [];
};

export const isDateInPausedReminderDates = (dateList, date) => {
  return dateList.includes(date);
};

export const getReminderDaysInfoFromTask = (taskData) => {
  const parsedDueDate = parseISO(taskData.due);
  const startDayOfReminder = subDays(parsedDueDate, taskData.reminderDays);
  return {
    start: startDayOfReminder,
    end: parsedDueDate,
  };
};

export const createProjectFilters = (projectId) => {
  return [
    {
      field: "project",
      type: Array.isArray(projectId) ? "in" : "=",
      value: projectId,
    },
  ];
};

export const createResourceFilters = (resourceId) => {
  return [
    {
      field: "resources",
      type: "includes",
      value: resourceId,
    },
  ];
};

export const createBaseActivityListFilters = (
  removeDueFilter,
  addNextActionsTaskFilter,
  ignoreTaskTempVisibleFilter
) => {
  const filteresToUse = [
    {
      field: "isReminderTask",
      type: "=",
      value: false,
    },
    {
      field: "due",
      type: "!=",
      value: "none",
    },

    ignoreTaskTempVisibleFilter
      ? {
          field: "completed",
          type: "=",
          value: false,
        }
      : [
          {
            field: "keepItVisible",
            type: "=",
            value: true,
          },
          {
            field: "completed",
            type: "=",
            value: false,
          },
        ],
    {
      field: "projectCompleted",
      type: "=",
      value: false,
    },
    [
      {
        field: "isFirstTask",
        type: "=",
        value: true,
      },
      {
        field: "isCalendarOnly",
        type: "=",
        value: false,
      },
      {
        field: "coRelationalId",
        type: "=",
        value: "",
      },
      {
        field: "isVirtual",
        type: "=",
        value: false,
      },
      {
        field: "isVirtual",
        type: "=",
        value: undefined,
      },
      {
        field: "showInForm",
        type: "=",
        value: true,
      },
    ],
  ];

  if (removeDueFilter) {
    const dueFilterIndex = filteresToUse.findIndex((f) => f.field === "due");

    if (dueFilterIndex >= 0) {
      filteresToUse.splice(dueFilterIndex, 1);
    }
  }

  if (addNextActionsTaskFilter) {
    filteresToUse.push({
      ...nextActionsGroupFilter,
    });
  }

  return filteresToUse;
};
export const addActivityListFilters = ({
  currFilters,
  projectId,
  resourceId,
  isInResourceMode,
  showAllTasks,
  additionalTaskFilters,
  removeDueFilter,
  showOnlyNextActionsTasks,
  resourceTypeToIgnore,
  useAreaAndResourceIds,
  isNoProjectNodeSelected,
  noProjectNodeId,
}) => {
  // currTaskId
  let localFilters = [...currFilters];

  localFilters.push(
    ...createBaseActivityListFilters(
      isInResourceMode || removeDueFilter,
      showOnlyNextActionsTasks
    )
  );

  if (isNoProjectNodeSelected && noProjectNodeId) {
    localFilters.unshift([
      [
        createIgnoreResourceTypeFilter("project"),
        ...createProjectFilters(noProjectNodeId.split("_")[0]),
      ],
    ]);
  } else if (useAreaAndResourceIds) {
    // Do nothing

    const createdFilters = createResourceIdsAndAreaNoProjectFilters({
      areaId: projectId,
      resourceTypeToIgnore: resourceTypeToIgnore,
      resourceIds: convertValueToArray(resourceId),
    });
    // console.log("SJKKKK", createdFilters);

    localFilters.unshift([...createdFilters]);
  } else if (resourceTypeToIgnore) {
    localFilters.unshift(createIgnoreResourceTypeFilter(resourceTypeToIgnore));
  } else if (!isEmpty(resourceId)) {
    localFilters.unshift(...createResourceFilters(resourceId));
  } else if (!Array.isArray(projectId) && projectId) {
    localFilters.unshift(...createProjectFilters(projectId));
  }

  if (!isEmpty(additionalTaskFilters)) {
    const createdFilters = createTaskListFiltersFromFiltersMap(
      additionalTaskFilters
    );

    if (!isEmpty(createdFilters)) {
      localFilters = [...localFilters, ...createdFilters];
    }
  }

  localFilters = createShowClearedTasksFilters(
    localFilters,
    clearedTaskFiltersToRemove,
    showAllTasks
  );

  return localFilters;
};

export const checkIfTasksWithProjectExists = (
  projectId,
  rawTasksMap
  // checkForIncomplete = true
  // excludeTaskId
) => {
  let tasksExists = [];

  if (!isEmpty(rawTasksMap)) {
    const tasksListToUse = Array.isArray(rawTasksMap)
      ? rawTasksMap
      : Object.values(rawTasksMap);

    tasksExists = tasksListToUse.some((t) => {
      return t.project && t.project === projectId;
      // if (checkForIncomplete) {
      //   return t.project && t.project === projectId && !t.completed;
      // } else {
      //   return t.project && t.project === projectId;
      // }
    });
  }

  return tasksExists;
};

export const checkIfTaskObjectiveIsValid = (taskData) => {
  const objectivesMap = store.getters["task/objectivesMap"];
  return taskData.objective && !isEmpty(objectivesMap[taskData.objective]);
};

export const createRepeatInfoLabelFromTaskData = (taskData, parseDate) => {
  let showTxt = false;
  let txt = "";

  taskData = getMainTaskRulesAndFillRecurringOpts(taskData);
  const isRepeatEvery = checkIfTaskIsRecurring(taskData);
  const isRepeatIn = checkIfTaskIsRepeatIn(taskData);
  const parsedDate = parseDate
    ? parseISO(taskData.dueAsDate)
    : taskData.dueAsDate;
  const taskDateIsValid = isValidDate(parsedDate);
  if (isRepeatEvery) {
    showTxt = true;
    const repeatCountMoreThanOne = taskData.recurrenceCount > 1;
    if (taskData.frequency === "weekly" && taskDateIsValid) {
      const repeatDayVal = getComponentsFromDate(parsedDate).weekDay;
      const repeatDay = daysOfWeek[repeatDayVal];
      if (!repeatDay) {
        showTxt = false;
      } else {
        txt = `Repeats every ${
          repeatCountMoreThanOne ? `${taskData.recurrenceCount} weeks` : "week"
        } on ${repeatDay}`;
      }
      //
    } else if (taskData.frequency === "monthly" && taskDateIsValid) {
      txt = `Repeats every ${
        repeatCountMoreThanOne ? `${taskData.recurrenceCount} months` : "month"
      } on day ${formatDate(parsedDate, DAY_FORMAT)}`;
    } else if (taskData.frequency === "yearly" && taskDateIsValid) {
      txt = `Repeats every ${
        repeatCountMoreThanOne ? `${taskData.recurrenceCount} years` : "year"
      } on ${formatDate(parsedDate, MONTH_DAY_FORMAT)}`;
    } else {
      showTxt = false;
    }
  } else if (isRepeatIn) {
    showTxt = true;
    const { frequency, count } = taskData.repeatInfo;
    const isCountMoreThanOne = count > 1;

    let period = "";
    if (frequency === "weekly") {
      period = "week";
    } else if (frequency === "monthly") {
      period = "month";
    } else if (frequency === "yearly") {
      period = "year";
    } else {
      period = "day";
    }

    txt = `Repeat in ${count} ${period}${isCountMoreThanOne ? "s" : ""}`;
  }

  return {
    showTxt,
    txt,
  };
};

export const checkIfTasksWithResourceExists = (
  resourceId,
  rawTasksMap
  // checkForIncomplete = true
  // excludeTaskId
) => {
  let tasksExists = [];

  if (!isEmpty(rawTasksMap)) {
    const tasksListToUse = Array.isArray(rawTasksMap)
      ? rawTasksMap
      : Object.values(rawTasksMap);

    tasksExists = tasksListToUse.some((t) => {
      return t.resources?.includes(resourceId);
      // if (checkForIncomplete) {
      //   return t.resources.includes(resourceId) && !t.completed;
      // } else {
      //   return t.resources.includes(resourceId);
      // }
    });
  }

  return tasksExists;
};

export const checkIfInspectModeNeedsToBeDisabled = (currNav, navToGo) => {
  let disable = true;

  if (isModeInterChangableForInspectMode(currNav, navToGo)) {
    disable = false;
  }
  return disable;
};

export const createReminderTasks = (taskData) => {
  const createdTasks = [];

  const currDate = new Date();
  // const currDateFormatted = formatDate(new Date(), INTERNAL_DATE_FORMAT);
  if (taskData.reminderDays && taskData.due !== "none" && !taskData.completed) {
    const { start: startDayOfReminder, end: parsedDueDate } =
      getReminderDaysInfoFromTask(taskData);
    const pausedReminderDays = taskData.pausedReminderDays || [];

    let reminderDates = createDates({
      start: startDayOfReminder,
      end: parsedDueDate,
    });

    let startIndex = 0;
    reminderDates.forEach((rD) => {
      const formattedDate = formatDate(rD, INTERNAL_DATE_FORMAT);
      if (
        (isSameDates(rD, currDate) || isAfter(rD, currDate)) &&
        !isSameDates(rD, parsedDueDate) &&
        !isDateInPausedReminderDates(pausedReminderDays, formattedDate)
      ) {
        const snoozedDates = checkDateForSnoozedDate(
          rD,
          startDayOfReminder,
          pausedReminderDays
        );

        const createdKey = blueimpMD5(`${taskData.key}_${formattedDate}`);
        createdTasks.push({
          ...processTask({
            ...fillTaskData(taskData),
            // createdKey: ,
            // key: createUniqueId(),
            key: createdKey,
            isReminderTask: true,
            reminderLinkedTo: taskData.key,
            reminderDate: rD,
            reminderDateFormatted: formatDate(rD, INTERNAL_DATE_FORMAT),
            isFirstReminderTask: startIndex === 0,
            snoozedDates,
          }),
        });
        startIndex++;
        //
      }
    });
  }

  return createdTasks;
};

export const checkIfTaskHasReminder = (taskData) => {
  // const currDateFormatted = formatDate(new Date(), INTERNAL_DATE_FORMAT);
  return (
    taskData.reminderDays &&
    taskData.reminderDays > 0 &&
    taskData.due !== "none"
  );
};

export const getReminderTasksLinkedToTaskId = (allTasksList, taskId) => {
  return allTasksList.filter(
    (t) => t.isReminderTask && t.reminderLinkedTo === taskId
  );
};

export const extractDateFromTaskDateGroupStr = (dateGroupStr) => {
  let date;

  if (dateGroupStr) {
    date = extractDateFromString(dateGroupStr, /(\d{1,2}\/\d{1,2}\/\d{2})/g);
  }

  return date;
};

export const filterOutFlaggedAndUnFlaggedDraggedTasks = (tasks, groupKey) => {
  let filteredTasks = [];

  if (!isEmpty(tasks)) {
    filteredTasks = tasks.filter((t) =>
      nextActionsGroupsDragAllowed.includes(t[groupKey])
    );
  }

  return filteredTasks;
};

export const processDroppedFlaggedAndUnflaggedTasksForCustomOrder = (
  droppedTasksToProcess,
  groupToCheck,
  orderToCheck
) => {
  let taskPositionsToFilter = [];
  let otherTasks = [];
  // let areSameProjectTasks = false;

  if (!isEmpty(droppedTasksToProcess)) {
    droppedTasksToProcess.forEach((droppedTask) => {
      if (droppedTask[groupToCheck] === "1") {
        if (droppedTask.customPosition === orderToCheck) {
          taskPositionsToFilter.push(droppedTask.key);
        }
      } else {
        otherTasks.push(droppedTask);
      }
    });
  }

  return {
    otherTasks,
    taskIdsToFilter: taskPositionsToFilter,
  };
};

export const getTasksByCustomOrderAndOrderIndex = (
  listToCheck,
  fromOrder,
  fromPosition,
  excludedTasksIds
) => {
  return listToCheck.filter((taskData) => {
    return (
      taskData.isCustomPositioned &&
      taskData.CTICustomGroup === "1" &&
      taskData.customPosition === fromOrder &&
      taskData.customPositionIndex >= fromPosition &&
      !excludedTasksIds.includes(taskData.key)
    );
  });
};

export const filterOutCustomOrderTasks = (tasks, groupKey) => {
  let filteredTasks = [];

  if (!isEmpty(tasks)) {
    filteredTasks = tasks.filter((t) => t[groupKey] === "1");
  }

  return filteredTasks;
};

export const checkIfSelectedTaskCateHasOnlyArea = (selectedCateList) => {
  return (
    !isEmpty(selectedCateList) &&
    selectedCateList.length === 1 &&
    selectedCateList.some((s) => s.type === "project")
  );
};

export const getAllResourceTypeUsedInTask = (resourceList) => {
  const allResourcesMap = getUserResources().getResources();
  // const allResourcesMap = store.getters["resourcesData/rawResourcesMap"] || {};
  let resourceTypesUsed = [];

  if (!isEmpty(resourceList)) {
    resourceTypesUsed = resourceList.reduce((accu, rId) => {
      if (
        !isEmpty(allResourcesMap[rId]) &&
        resourceTypesMap[allResourcesMap[rId].type] &&
        !accu.includes(allResourcesMap[rId].type)
      ) {
        accu.push(allResourcesMap[rId].type);
      }

      return accu;
    }, []);
  }
  return resourceTypesUsed;
};

export const getTaskTreeView = (type = "active") => {
  const isShowClearedTasksEnabled = store.getters["task/showClearedTasks"];

  let treeElement;

  if (type === "active") {
    const treeTarget = isShowClearedTasksEnabled
      ? "#treeview-all-data"
      : "#treeview";
    treeElement = document.querySelector(treeTarget);
  } else if (type === "inactive") {
    const treeTarget = isShowClearedTasksEnabled
      ? "#treeview"
      : "#treeview-all-data";
    treeElement = document.querySelector(treeTarget);
  }

  return {
    element: treeElement,
    treeInstance: treeElement?.ej2_instances[0],
  };
};

export const getInactiveTaskTreeView = () => {};

export const checkDateForSnoozedDate = (
  dateToCheck,
  startingDate,
  snoozeDates
) => {
  let finalList = [];

  const createdDates = createDates({
    start: startingDate,
    end: dateToCheck,
  });
  if (!isEmpty(snoozeDates)) {
    for (let index = createdDates.length - 1; index >= 0; index--) {
      const date = createdDates[index];

      if (isSameDates(date, dateToCheck)) {
        continue;
      }
      const stringFormattedDate = formatDate(date, INTERNAL_DATE_FORMAT);

      if (!snoozeDates.includes(stringFormattedDate)) {
        break;
      }
      if (snoozeDates.includes(stringFormattedDate)) {
        finalList.push(stringFormattedDate);
      }
    }
  }

  return finalList;
};

// eslint-disable-next-line no-unused-vars
export const checkIfNextActionModeGroupKeyIsVisible = (groupKey) => {
  // const currNav = store.getters["task/currNav"];
  // const isInboxViewEnabled = store.getters["task/isInboxViewEnabled"];
  // const isGlobalViewEnabled = store.getters["task/isGlobalViewEnabled"];

  // disabled to show area wise groups
  // const selectedOptsList = store.getters["task/selectedOptsList"];

  // if (groupKey !== "1") {
  //   return !isEmpty(selectedOptsList);
  // } else {
  //   return true;
  // }

  // if (groupKey === "2") {
  //   return !(
  //     !isEmpty(selectedOptsList) &&
  //     !checkIfSelectedTaskCateHasOnlyArea(selectedOptsList)
  //   );
  // } else {
  //   return true;
  // }
  return true;
};

export function isTitleUpdated(n, o) {
  return getTextContentFromHTML(n.title) !== getTextContentFromHTML(o.title);
}

export function isTypeUpdated(n, o) {
  return n.taskType !== o.taskType;
}

export function isProjectUpdated(n, o) {
  return n.project !== o.project;
}
/**
 * Checks if due date has changed in a task
 * @param {{[k:string]:any}} n New Task Data
 * @param {{[k:string]:any}} o Old Task Data
 * @returns {boolean}
 */
export function isDueDateChanged(n, o) {
  return !isEqual(n.dueAsDate, o.dueAsDate);
}
export const createSearchQueryFilters = (
  currFilters,
  searchQuery,
  isInNextActionsMode
) => {
  searchQuery = searchQuery && searchQuery.trim();
  const localFilters = [...currFilters];

  if (searchQuery && searchQuery !== "*") {
    if (isInNextActionsMode) {
      const storedIndex = localFilters.findIndex((f) =>
        isEqual(f, nextActionsGroupFilter)
      );

      if (storedIndex >= 0) {
        localFilters.splice(storedIndex, 1);
      }
    }
    localFilters.push([...createTaskSearchQueryFilter(searchQuery)]);
  }

  return localFilters;
};

const isTaskOlder = (date, diff) => {
  return isValidDate(date) && diffGetters.day(new Date(), date) > diff;
};

const isTaskDateSameOrOnwards = (taskDate, dateToCompare) => {
  return (
    isSameDates(taskDate, dateToCompare) || isAfter(taskDate, dateToCompare)
  );
};

export const getTaskKeyFromGoogleEvent = (googleEvent) => {
  return googleEvent?.extendedProperties?.private?.taskKey;
};

export const getDateFromGoogleEvent = (googleEventData) => {
  const startDateData = googleEventData.start;
  let date;
  if (startDateData.date) {
    date = parseDate(startDateData.date, INTERNAL_DATE_FORMAT);
  } else if (startDateData.dateTime) {
    date = new Date(startDateData.dateTime);
  }

  return date;
};

export const createUpdatedExtendedPropsOfGoogleEventData = (
  googleData,
  dataToUpdate
) => {
  let updatedData = !isEmpty(googleData.extendedProperties)
    ? googleData.extendedProperties
    : {};

  if (!isEmpty(updatedData)) {
    if (isEmpty(updatedData.private)) {
      updatedData.private = {
        ...dataToUpdate,
      };
    } else {
      updatedData.private = {
        ...updatedData.private,
        ...dataToUpdate,
      };
    }
  } else {
    updatedData = {
      private: {
        ...dataToUpdate,
      },
    };
  }

  return updatedData;
};

export const createNextModeFilters = (modifiedVal, dateFilter) => {
  const currDate = new Date();
  let startDate;
  if (modifiedVal) {
    startDate = subFromDate(
      currDate,
      taskFiltersConfigMap[modifiedVal].subtractType
    );
  }

  const dateRange = createDateRangeBasedOnForecastVal(dateFilter);

  return (t) => {
    const parsedModified = parseJSON(t.modified);

    if (isTaskRowGroupOrEmptyRow(t)) {
      return true;
    }

    if (
      modifiedVal &&
      isEmpty(dateRange) &&
      isValidDate(parsedModified) &&
      isBetweenInterval(parsedModified, startDate, currDate)
    ) {
      return true;
    }

    if (
      !isEmpty(dateRange) &&
      t.due !== "none" &&
      !modifiedVal &&
      dateRange.includes(t.due)
    ) {
      return true;
    }

    if (modifiedVal && !isEmpty(dateRange)) {
      return (
        (isValidDate(parsedModified) &&
          isBetweenInterval(parsedModified, startDate, currDate)) ||
        dateRange.includes(t.due)
      );
    }
    // return (
    //   isTaskRowGroupOrEmptyRow(t) ||
    //   (isValidDate(parsedDate) &&
    //     isBetweenInterval(parsedDate, startDate, currDate)) ||
    //   (isValidDate(parsedModified) &&
    //     isBetweenInterval(parsedModified, startDate, currDate))
    // );
  };

  // if (filterVal === "week" || filterVal === "year") {
  //   const dateToUse = subDays(currDate, taskFiltersConfigMap[filterVal].days);
  //   return (t) => {
  //     return (
  //       isTaskRowGroupOrEmptyRow(t) ||
  //       isTaskDueOlderThan(parseISO(t.due), dateToUse) ||
  //       isTaskDueOlderThan(parseJSON(t.modified), dateToUse)
  //     );
  //   };
  // }

  // if (filterVal === "month" || filterVal === "quarter") {
  // const startDate = subDays(
  //   currDate,
  //   taskFiltersConfigMap[filterVal].startDate
  // );
  // const endDate = subDays(startDate, taskFiltersConfigMap[filterVal].endDate);

  // }
};

export const isTaskDueOlderThan = (date, olderThan) => {
  return (
    isValidDate(date) &&
    isValidDate(olderThan) &&
    (isSameDates(date, olderThan) || isBefore(date, olderThan))
  );
};

export const isTaskRowGroupOrEmptyRow = (t) => {
  return isTaskGroup(t) || t.isEmptyRow;
};

export const createDateRangeBasedOnForecastVal = (forecast) => {
  let createdDates = [];
  const startDate = new Date();
  const addDatesConfig = {};
  switch (forecast) {
    case "week":
      addDatesConfig.weeks = 1;
      break;
    case "month":
      addDatesConfig.months = 1;
      break;
    case "quarter":
      addDatesConfig.days = 91;
      break;
    case "year":
      addDatesConfig.years = 1;
      break;
  }

  if (!isEmpty(addDatesConfig)) {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const endDate = add(yesterday, addDatesConfig);

    createdDates = createDateRange(startDate, endDate).map((d) =>
      formatDate(d, INTERNAL_DATE_FORMAT)
    );
  }
  return createdDates;
};

export const processRawTaskList = (rawTasks = [], projects, categories) => {
  let tasks = [];
  let recurringVirtualTasks = [];
  let recurringTasksMap = {};
  let reminderTasksList = [];
  rawTasks.forEach((task) => {
    if (!task.key) return;
    const taskIsRecurring = checkIfTaskIsRecurring(task);
    if (taskIsRecurring) {
      if (areTaskRecurrenceRulesValid(task.recurrence)) {
        const { tasks: virtualTasks, datesList } =
          createVirtualTasksFromRecurringTasks(task);

        recurringVirtualTasks.push(...virtualTasks);
        recurringTasksMap[task.key] = datesList;
      } else if (task.linkedTo) {
        tasks.push(processTask(task, projects, categories));
        reminderTasksList.push(...createReminderTasks(task));
      }
      // Soemthing for recurring
    } else {
      tasks.push(processTask(task, projects, categories));
      reminderTasksList.push(...createReminderTasks(task));
    }
  });

  return {
    recurringTasksMap,
    tasks: tasks.concat([...recurringVirtualTasks], [...reminderTasksList]),
  };
};
const collator = new Intl.Collator([], { numeric: true });
export const getOrderedTasksByProject = (taskList, project) => {
  const orderedTasks = taskList.filter(
    (t) => t.project === project && t.isCustomPositioned
  );
  orderedTasks.sort((a, b) => {
    const orderedA = a.areaWiseOrder;
    const orderedB = b.areaWiseOrder;
    return collator.compare(orderedA, orderedB);
  });

  return orderedTasks;
};

export const checkIfProjectIsChangedOrUnChanged = (n, o, rowEditData) => {
  return (
    (!isEmpty(rowEditData) && rowEditData.project === undefined) ||
    (n.project === o.project && !isEmpty(n.project)) ||
    (n.project !== o.project && !isEmpty(n.project))
  );
};

export const checkIfTaskIsUnscheduled = (n, o, rowEditData) => {
  return (
    checkIfProjectIsChangedOrUnChanged(n, o, rowEditData) &&
    (isEmpty(rowEditData) || rowEditData.dueAsDate !== undefined) &&
    !isValidDate(n.dueAsDate) &&
    isValidDate(o.dueAsDate)
  );
};

export const checkIfTaskIsParentRecurringTask = (taskData) =>
  checkIfTaskIsRecurring(taskData) && !taskData.linkedTo;

export const checkIfSelectedTaskCateHasCategory = (selectedCateList) => {
  return (
    !isEmpty(selectedCateList) &&
    selectedCateList.some((s) => s.type === "category")
  );
};

export const getNextFirstTaskByDate = (taskList, date) => {
  const filteredList = taskList.filter((t) => {
    let parsedDate = parseISO(t.due);
    return (
      !t.isReminderTask &&
      !t.showContingentInToday &&
      isValidDate(parsedDate) &&
      (isSameDates(parsedDate, date) || isAfter(parsedDate, date))
    );
  });

  return filteredList?.[0];
};

export const getFirstVirtualReccuringTask = (taskList, linkedTo, taskDate) => {
  const filtersToUse = [(row) => !!row.isVirtual];

  if (isValidDate(taskDate)) {
    filtersToUse.push((row) => isEqual(row.dueAsDate, taskDate));
  }

  return getRecurringTasks(taskList, "list", {
    linkedTo,
    filter: true,
    filterOpts: [...filtersToUse],
  })[0];
};

export const makeVirtualTaskVisibleInFormGrid = (taskDataList) => {
  let tasksToUpdate = [];

  if (!Array.isArray(taskDataList)) taskDataList = [taskDataList];

  taskDataList.forEach((taskData) => {
    const isTaskRecurring = checkIfTaskIsRecurring(taskData);

    const showTaskInForm =
      isTaskRecurring &&
      taskData.isVirtual &&
      !taskData.isFirstTask &&
      !!taskData.isCalendarOnly;

    if (showTaskInForm) {
      tasksToUpdate.push({
        key: taskData.key,
        updates: { showInForm: true },
      });
    }
  });

  if (!isEmpty(tasksToUpdate)) {
    addOrRemoveOrUpdateTasksInLocalTasksList(
      {
        tasksToUpdate: tasksToUpdate,
        doNotProcessTaskForUpdate: true,
      },
      false
    );
  }
};

export const checkIfTaskIsOrderedTask = (taskData) => {
  return !!taskData.isCustomPositioned;
};

export const checkIfSelectedTaskFiltersHasCategory = (taskFiltersMap) => {
  return !isEmpty(taskFiltersMap.category);
};

export const groupTasksByTaskType = (tasksList) => {
  let totalTasksCount = 0;

  let tasksTypeMap = {};
  tasksList.forEach((t) => {
    if (t.taskType && taskTypesList.includes(t.taskType)) {
      if (!tasksTypeMap[t.taskType]) {
        tasksTypeMap[t.taskType] = {
          title: taskTypesMap[t.taskType]?.title,
          tasksCount: 0,
        };
      }
      // localCategoryTasksMap.tasksTypeMap[t.taskType].tasks.push(t);
      tasksTypeMap[t.taskType].tasksCount += 1;
      totalTasksCount += 1;
    }
  });

  return {
    totalTasks: totalTasksCount,
    tasksTypeMap,
  };
};

export const createTaskTypeSeriesDataFromCategoryList = (
  cateList,
  cateTasksMap
) => {
  let barsData = [];

  taskTypesMapList.forEach((taskTypeData) => {
    const barData = {
      name: taskTypeData.title,
      type: "bar",
      stack: "total",
      label: {
        show: true,
        fontSize: 13,

        // color: BASE_BLACK_COLOR,
      },
      emphasis: {
        focus: "series",
      },
      data: [],
    };

    let isTaskAvailable = false;
    cateList.forEach((cateD, cateIndex) => {
      const cateTasksTypeMap =
        cateTasksMap[cateD.key].tasksTypeMap[taskTypeData.key];

      if (!isEmpty(cateTasksTypeMap)) {
        isTaskAvailable = true;
        barData.data[cateIndex] = cateTasksTypeMap.tasksCount;
      }
      // }
    });

    if (isTaskAvailable) {
      barsData.push(barData);
    }
  });

  return barsData;
};

export const checkTaskAdditionalFiltersEmpty = (filtersMap) => {
  let res = true;

  for (const filter in filtersMap) {
    if (filtersMap[filter].length > 0) {
      res = false;
      break;
    }
  }

  return res;
};

/**
 * Check if provided task data's area is not empty
 * @param {{[string]:any}} taskData Task data
 * @returns {boolean}
 */
export const checkIfTaskHasArea = (taskData) => {
  return !isUndefinedVal(taskData.project);
};
/**
 * Check if provided task settings area is not empty
 * @param {{[string]:any}} taskSettings Task Settings
 * @returns {boolean}
 */
export const checkTaskSettingsIsNotEmpty = (taskSettings) => {
  return !isEmpty(taskSettings);
};

/**
 * Check if status btn position is in flag cell
 * @param {{[string]:any}} taskSettings Task Settings
 * @returns {boolean}
 */
export const checkStatusBtnPosIsFlagCell = (taskSettings) => {
  return (
    checkTaskSettingsIsNotEmpty(taskSettings) &&
    taskSettings.statusBtnPosition === "with-task"
  );
};

/**
 * Check if status btn position is in area cell
 * @param {{[string]:any}} taskSettings Task Settings
 * @returns {boolean}
 */
export const checkStatusBtnPosIsAreaCell = (taskSettings) => {
  return (
    checkTaskSettingsIsNotEmpty(taskSettings) &&
    taskSettings.statusBtnPosition === "in-area"
  );
};

export const checkIfContingentTaskIsUnscheduled = (n, o, rowEditData) => {
  return (
    checkIfProjectIsChangedOrUnChanged(n, o, rowEditData) &&
    (isEmpty(rowEditData) || rowEditData.dueAsDate !== undefined) &&
    !isValidDate(n.dueAsDate) &&
    !isValidDate(o.dueAsDate) &&
    !!o.isContingent
  );
};

export const refreshSubTasksTable = (reprocessTasks, useExistingTasks) => {
  EventEmitter.emit(REFRESH_ACTIVITY_HISTORY, reprocessTasks, useExistingTasks);
};

export const createTaskCategorySelectionOptsList = (selectedIDs) => {
  const selectedList = [];

  const projectsData = store.getters["task/projects"];
  const categoriesData = store.getters["task/categories"];
  const categoriesKeys = Object.keys(categoriesData);
  const projectsKeys = Object.keys(projectsData);
  selectedIDs.forEach((selectedId) => {
    if (categoriesKeys.includes(selectedId)) {
      selectedList.push({
        id: selectedId,
        projectId: "all",
        subCategoryId: "all",
        type: "category",
      });
    } else if (projectsKeys.includes(selectedId)) {
      if (projectsData[selectedId].type === "project") {
        const itemData = {
          id: selectedId,
          subCategoryId: "all",
          categoryId: projectsData[selectedId].category,
          type: "project",
        };

        if (projectsData[selectedId].subcategory) {
          itemData.subCategoryId = projectsData[selectedId].subcategory;
        }
        selectedList.push(itemData);
      } else {
        selectedList.push({
          id: selectedId,
          projectId: "all",
          categoryId: projectsData[selectedId].category,
          type: "sub-category",
        });
      }
    }
  });

  return selectedList;
};

export const convertRawTasksToDataFrameObj = (tasks) => {
  let currTasks = null;
  if (!isEmpty(tasks)) {
    const columns = new Set([...Object.keys(tasks[0]), "due", "project"]);

    currTasks = new DataFrame(tasks, [...columns]);
  }
  return currTasks;
};

export const createFiltersForNoProjectTasks = ({
  currFilters,
  showAllTasks,
  areaId,
}) => {
  let localFilters = [...currFilters];

  localFilters.push(...createBaseActivityListFilters(true, false, true));

  localFilters.unshift(createIgnoreResourceTypeFilter("project"));

  if (areaId) {
    localFilters.unshift(...createProjectFilters(areaId));
  }

  if (showAllTasks !== undefined) {
    localFilters = createShowClearedTasksFilters(
      localFilters,
      getClearedTaskFilters(true),
      showAllTasks
    );
  }

  return localFilters;
};

export const filterTasksWithoutProjectResource = ({
  tasks,
  doNotCountAllTasks,
  areaId,
}) => {
  let taskList = convertRawTasksToDataFrameObj(tasks);

  const filters = createFiltersForNoProjectTasks({
    currFilters: [],
    showAllTasks: !doNotCountAllTasks,
    areaId,
  });
  if (taskList) {
    taskList = filterTaskList(taskList, filters).toCollection();
  }

  return taskList || [];
};

export const getClearedTaskFilters = (excludeTempTaskVisibleFilter) => {
  if (excludeTempTaskVisibleFilter) {
    const filters = cloneDeep(clearedTaskFiltersToRemove);

    filters[0] = {
      field: "completed",
      type: "=",
      value: false,
    };

    return filters;
  }

  return clearedTaskFiltersToRemove;
};

export const createResourceIdsAndAreaNoProjectFilters = ({
  areaId,
  resourceTypeToIgnore,
  resourceIds,
}) => {
  let filters = [];

  filters.push([
    createIgnoreResourceTypeFilter(resourceTypeToIgnore),
    createProjectFilters(areaId),
  ]);

  filters.push(createResourceFilters(resourceIds));

  return filters;
};

/**
 * Check if provided reminder value is valid
 * @param {Number} val
 * @returns {boolean}
 */
export const isRemindarValValid = (val) => {
  return val > 0;
};

/**
 * Creates the group filters by the provided selection of group filter
 *
 * Currently it accepts one group at a time for creating filters
 * @param {string} selection
 * @returns {{[k:string]:any}[]}
 */
export const createTaskGroupFiltersFromList = (selection) => {
  let filters = [
    { field: "groupProp", type: "in", value: [undefined, null, ""] },
  ];

  if (!isEmpty(selection)) {
    if (Array.isArray(selection)) {
      filters.push({
        field: "CTICustomGroup",
        type: "in",
        value: [
          selection.map((s) => TASK_NEXT_ACTIONS_GROUP_FILTERS_VALUES[s]),
        ],
      });
    } else {
      if (selection !== "default") {
        filters.push({
          field: "CTICustomGroup",
          type: "=",
          value: TASK_NEXT_ACTIONS_GROUP_FILTERS_VALUES[selection],
        });
      }
    }
  }

  return filters;
};

/**
 * Checks if task data contains the provided group name key
 * @param {{[k:string]:any}} taskData Task data
 * @param {string} groupKeyName Group key name
 * @returns {boolean}
 */
export const checkIfTaskContainsGroupKey = (taskData, groupKeyName) => {
  return !isEmpty(taskData) && !isEmpty(taskData[groupKeyName]);
};
