<template>
  <v-btn-toggle
    id="tasks-group-filters"
    :value="value"
    @change="onInputChange"
    mandatory
  >
    <v-btn v-for="option in options" :key="option.value" :value="option.value">
      {{ option.label }}
    </v-btn>
    <v-btn value="default" v-show="false"></v-btn>
  </v-btn-toggle>
</template>
<script>
export default {
  props: {
    value: {
      type: [String, Array],
      default: "",
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    onInputChange(changedValue) {
      this.$emit("input", changedValue);
    },
  },
};
</script>
<style>
#tasks-group-filters {
  border-radius: 0px !important;
  /* margin-top: 6px; */
  margin-bottom: 10px;
}
#tasks-group-filters button.v-btn.v-btn {
  height: auto !important;
  padding: 8px 12px !important;
  border-color: var(--group-filter-btn-group-border-color) !important;
}

#tasks-group-filters button:not(.v-btn--active) {
  background: #fff !important;
}
#tasks-group-filters button .v-btn__content {
  text-transform: capitalize !important;
  color: #000 !important;
  letter-spacing: normal !important;
  font-size: 13px !important;
  font-weight: normal !important;
}

#tasks-group-filters .v-btn.v-btn--active {
  background: #eaeaea !important;
}
</style>
