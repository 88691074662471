<template>
  <div class="d-flex align-center resource-filter-bar">
    <TasksFilterBar
      :showClrFilterBtn="false"
      :showCategoryFilters="showCategoryFilter"
      mode="resource"
      :filterOpts="filterOpts"
      from="list"
      :showResourceCategories="!resourceTypeToIgnore"
      :resourceTypeToIgnore="resourceTypeToIgnore"
    >
      <template #filter-btn>
        <div class="d-flex align-center btn-row">
          <ClearFilterBtn
            :isEmpty="isFilterEmpty"
            :count="countToDisplay"
            @clear-btn:click="handleClearBtnClick"
          />
          <ProjectsViewToggleBtn v-if="showProjectsToggleBtn" />

          <!-- <ToggleOrderedTasksBtnVue /> -->
        </div>
      </template>
    </TasksFilterBar>
  </div>
</template>
<script>
import {
  resourceTypeFilterOptList,
  resourceTypeFilterIndexs,
} from "@/data/resources";
import { mapGetters, mapActions, mapMutations } from "vuex";
// getResourceTreeView,
import {
  getResourceViewTaskFilters,
  getUserResources,
} from "@/helpers/resources";
import isEmpty from "lodash/isEmpty";
import ClearFilterBtn from "@/components/ClearFilterBtn/ClearFilterBtn.vue";
// import DatabaseInterface from "@/services/DatabaseInterface";
import userDetailsMixin from "@/mixins/userDetailsMixin";
import resourcesHelpersMixin from "@/mixins/resourcesHelpersMixin";
import TasksFilterBar from "@/components/NewTaskFilterBar/NewTaskFilterBar.vue";
import EventEmitter from "@/helpers/eventEmitter";
import {
  REFRESH_RESOURCE_ID_FILTER,
  TOGGLE_ORDERED_TASKS,
} from "@/variables/events";
import ToggleOrderedTasksBtnVue from "@/components/ToggleOrderedTasksBtn/ToggleOrderedTasksBtn.vue";
import { checkTaskAdditionalFiltersEmpty } from "@/helpers/tasks";
import ProjectsViewToggleBtn from "@/components/ProjectsViewToggleBtn.vue";
import { DEFAULT_TASK_GROUP_FILTER_VALUE } from "@/variables/tasks";

export default {
  mixins: [userDetailsMixin, resourcesHelpersMixin],
  components: {
    ClearFilterBtn,
    TasksFilterBar,
    ToggleOrderedTasksBtnVue,
    ProjectsViewToggleBtn,
  },
  props: {
    isInCategoryMode: {
      type: Boolean,
    },
    tasksCount: {
      type: Number,
    },
    filterOpts: {
      type: Object,
    },
    showCategoryFilter: {
      type: Boolean,
    },
  },
  methods: {
    ...mapActions("resourcesData", ["setResourceTypeOpt"]),
    ...mapMutations("resourcesData", {
      setResourceData: "setData",
    }),
    ...mapActions(["updateRootState"]),
    handleClick(_, itemData) {
      this.handleResourceTypeSelect(itemData.key);
    },
    handleResourceTypeSelect(val) {
      getUserResources().set(val, "selectedResourceType");
    },
    createBtnStyles(btnData, index) {
      let basicStyles = {
        background: btnData.bgColor ? btnData.bgColor : undefined,
      };
      if (this.selectedFilterOptIndex === index) {
        const activeStyles = btnData.activeStyles;
        if (activeStyles?.borderColor) {
          basicStyles.border = `1.2px solid ${activeStyles.borderColor} !important`;
        }
      }
      return basicStyles;
    },

    handleClearBtnClick() {
      this.clearListFiltersList(true, true);
      this.setResourceData({
        filterResources: [],
      });
      this.updateRootState({
        currViewData: {},
        itemInspectEnabled: false,
        subTasksListGroupFilter: DEFAULT_TASK_GROUP_FILTER_VALUE,
      });
      EventEmitter.emit(TOGGLE_ORDERED_TASKS, true);
      EventEmitter.emit(REFRESH_RESOURCE_ID_FILTER);
    },
  },
  computed: {
    ...mapGetters("resourcesData", [
      "selectedResourceTypeOpt",
      "selectedCategories",
      "resourcesCount",
      "filterResources",
    ]),
    ...mapGetters("task", ["isProjectsResourceModeEnabled"]),
    resourceTypeOpts() {
      return resourceTypeFilterOptList;
    },
    selectedFilterOptIndex() {
      return resourceTypeFilterIndexs[this.selectedResourceTypeOpt];
    },
    filterBtnIcon() {
      const res = this.isFilterEmpty
        ? "$ctiFilterCleared"
        : "$ctiFilterEnabled";
      return res;
    },
    isFilterEmpty() {
      return (
        isEmpty(this.selectedCategories) &&
        checkTaskAdditionalFiltersEmpty(getResourceViewTaskFilters()) &&
        isEmpty(this.filterResources)
      );
    },
    countToDisplay() {
      return this.isInCategoryMode ? this.tasksCount : this.resourcesCount;
    },
    showProjectsToggleBtn() {
      return this.isProjectsResourceModeEnabled;
    },
    resourceTypeToIgnore() {
      return this.getResourceTypeToIgnoreVal();
    },
  },
};
</script>
<style scoped>
.btn-row {
  gap: 8px;
}
.resource-filter-bar {
  padding-bottom: 10px;
  margin-top: -2px;
  border-bottom: 1px solid #edebeb;
}

.resource-filter-bar >>> .tasks-filters-bar-wrapper {
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
