<template>
  <v-app-bar app color="var(--primary-color)" flat name="TaskBar" height="40px">
    <v-row class="app-bar">
      <v-col cols="auto" class="py-0 px-0">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="action-btn keep-task-selected task-bar-action-btn sidebar-toggle-btn"
              text
              v-bind="attrs"
              v-on="on"
              @click="toggleSidebar()"
              :class="{ 'btn-is-focused': false }"
              data-ignore="1"
            >
              <v-icon size="19" v-if="!sidebarOpen" color="white">
                {{ activeMenu }}
              </v-icon>
              <v-icon size="19" v-else color="white">$ctiSidebarClosed</v-icon>
            </v-btn>
          </template>
          <span>{{ !sidebarOpen ? "Show Sidebar" : "Close Sidebar" }}</span>
        </v-tooltip>
      </v-col>
      <v-col cols="auto" class="py-0 px-0 mr-1 ml-1">
        <div class="home-btn-container">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="action-btn keep-task-selected task-bar-action-btn home-btn"
                text
                v-bind="attrs"
                v-on="on"
                @click="handleHomeToggle"
                data-action-type="nav-route-change-by-app-bar"
                data-action-value="home"
              >
                <v-icon size="19" color="white">$ctiHome</v-icon>
                <span class="label-text d-none d-md-inline-block">CTI</span>
              </v-btn>
            </template>
            <span> Home </span>
          </v-tooltip>
        </div>
      </v-col>

      <!-- Search Input -->
      <v-col
        cols="auto"
        md="3"
        class="pr-0 pl-0 mr-0 py-0"
        :class="{ 'search-expanded': searchExpanded }"
      >
        <v-text-field
          hide-details
          :class="searchInputStyles"
          clearable
          clear-icon="mdi-close-circle"
          :placeholder="searchPlaceHolder"
          @click:append="search"
          type="text"
          height="28"
          :value="searchVal"
          @click:clear="clearValues"
          @input="handleSearch"
          @keydown="handleKeydown"
          :data-ignore="ignoreSearchBar"
          data-action-type="data-change"
          data-action-value="search"
          data-search-bar="1"
          ref="searchInput"
          @blur="handleSearchBlur"
        >
          <template v-slot:prepend-inner>
            <v-icon
              v-if="isGlobalViewEnabled"
              size="20"
              color="#fff"
              class="search-icon"
            >
              mdi-search-web
            </v-icon>
            <v-icon v-else size="20" color="#fff" class="search-icon">
              mdi-magnify
            </v-icon>
            <div>
              <v-menu bottom right :offset-y="true" direction="bottom">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    dark
                    icon
                    v-bind="attrs"
                    v-on="on"
                    class="search-area-menu-btn"
                  >
                    <v-icon>mdi-menu-down</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    key="here"
                    @click="toggleSearchContext($event, 'here')"
                    :class="{ 'primary--text': !isGlobalViewEnabled }"
                  >
                    <v-list-item-icon class="search-list-icon">
                      <v-icon>mdi-magnify</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Search Here</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    key="global"
                    @click="toggleSearchContext($event, 'global')"
                    :class="{ 'primary--text': isGlobalViewEnabled }"
                  >
                    <v-list-item-icon class="search-list-icon">
                      <v-icon>mdi-search-web</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Search Everywhere</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="auto" md="auto" class="px-0 pl-2 taskbar-actions-wrapper">
        <v-row class="flex-nowrap" :no-gutters="true">
          <!-- Toggle Global/Local Search -->
          <!-- <v-col class="d-none d-sm-inline-block text-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="action-btn task-bar-action-btn"
                  text
                  v-bind="attrs"
                  v-on="on"
                  @click="handleTasksToggle"
                  data-action-type="nav-route-change-by-app-bar"
                  data-action-value="global"
                  :class="{ 'btn-is-active': isGlobalViewEnabled }"
                >
                  <v-icon size="20" color="white">$ctiNetwork</v-icon>
                </v-btn>
              </template>
              <span> View All Tasks </span>
            </v-tooltip>
          </v-col> -->

          <!-- Clean Up/Display Completed Tasks Buttons -->
          <v-col class="text-center" v-show="showTaskVisibility">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  text
                  v-on:click="handleBroomBtnClick"
                  class="action-btn task-bar-action-btn"
                  data-ignore="1"
                  :class="activeCleanableBtnClass"
                >
                  <v-icon color="white"> $ctiEye </v-icon>
                </v-btn>
              </template>
              <span> {{ broomBtnLabel }} </span>
            </v-tooltip>
          </v-col>
          <!-- Toggle Inspect Mode Buttons -->
          <v-col class="text-center" v-show="showInspectBtn">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  text
                  v-on:mousedown="inspectModeSwitch"
                  class="action-btn search-btn inspect-mode-btn task-bar-action-btn"
                  :class="activeInspectBtnClass"
                  v-show="showInspectBtn"
                  data-action-type="data-change"
                  data-action-value="inspect"
                >
                  <!-- <v-icon color="grey" v-if="inspectMode"> $inspect </v-icon> -->
                  <v-icon color="white"> $ctiDocSearch </v-icon>
                </v-btn>
              </template>
              <span> {{ inspectBtnTooltip }} </span>
            </v-tooltip>
          </v-col>

          <!-- Undo Button -->
          <v-col class="text-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  text
                  v-on:click="undo"
                  data-action-type="data-change"
                  data-action-value="undo"
                  class="action-btn small-icon task-bar-action-btn undo-btn"
                >
                  <v-icon color="white"> $ctiUndo </v-icon>
                </v-btn>
              </template>
              <span> Undo </span>
            </v-tooltip>
          </v-col>

          <!-- Redo Button -->
          <v-col class="text-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  text
                  v-on:click="redo"
                  data-action-type="data-change"
                  data-action-value="redo"
                  class="action-btn small-icon task-bar-action-btn redo-btn"
                >
                  <v-icon color="white"> $ctiRedo </v-icon>
                </v-btn>
              </template>
              <span> Redo </span>
            </v-tooltip>
          </v-col>

          <!-- Add/Delete Task Buttons -->
          <v-col class="text-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  text
                  v-on:click="addItem"
                  class="action-btn task-bar-action-btn"
                  :data-prevent-close="preventFormCloseOnDeleteAttr"
                  v-show="showItemHandlerBtns"
                >
                  <v-icon color="white"> $ctiAdd </v-icon>
                </v-btn>
              </template>
              <span> Add New {{ viewItemLabel }} </span>
            </v-tooltip>
          </v-col>

          <v-col class="delete-btn text-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  text
                  v-on:click="deleteItem"
                  class="action-btn task-bar-action-btn"
                  :data-prevent-close="preventFormCloseOnDeleteAttr"
                  v-show="showItemHandlerBtns"
                >
                  <v-icon color="white">mdi-minus</v-icon>
                </v-btn>
              </template>
              <span> Delete Selected {{ removeItemBtnLabel }} </span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>

      <v-spacer class="d-none d-sm-block"> </v-spacer>

      <v-col cols="auto" class="d-none d-sm-block">
        <v-row no-gutters>
          <!-- <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              text
              v-on:click="handlePurge"
              data-action-type="data-change"
              data-action-value="purge"
              class="action-btn task-bar-action-btn"
            >
              <v-icon color="white" size="22"> $ctiDelete </v-icon>
            </v-btn>
          </template>
          <span> Purge </span>
        </v-tooltip> -->

          <!-- Disable import btn -->
          <!-- <v-col cols="auto">
            <div class="ml-1 mr-1">
              <upload-btn ref="uploadBtn" />
            </div>
          </v-col> -->
          <v-col cols="auto">
            <!-- Disabled google sync  -->
            <!-- <Badge :isVisible="googleCalNeedsReconnect" bottom>
              <template #badge-content>
                <v-icon color="red" size="16"> mdi-alert </v-icon>
              </template>
              <v-tooltip bottom v-if="isInTasksAndSyncEnabled">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    text
                    v-on:click="pullGoogleCalenderData"
                    data-action-type="data-change"
                    data-action-value="enable-sync"
                    class="action-btn small-icon google-connect-btn task-bar-action-btn"
                    :class="syncBtnClasses"
                  >
                    <v-icon size="22" class="google-connect-icon" color="white">
                      $ctiSyncNew
                    </v-icon>
                  </v-btn>
                </template>
                <span> {{ googleCalSyncBtnTxt }} </span>
              </v-tooltip>
            </Badge> -->
          </v-col>
          <v-col cols="auto" class="py-0 pr-0">
            <div v-if="userInfo" class="d-none d-sm-block">
              <SettingsMenu color="white" />
            </div>
            <div v-else class="py-0 pr-0">
              <router-link to="/">
                <v-btn tile text color="white" class="float-right">
                  SIGN IN
                </v-btn>
              </router-link>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <!-- <AccountDialog :user="userInfo"> </AccountDialog> -->
    </v-row>
  </v-app-bar>
</template>
<script>
import Vue from "vue";
import EventEmitter from "@/helpers/eventEmitter";
import DatabaseInterface from "@/services/DatabaseInterface";
// import AccountDialog from "./components/AccountDialog";
import UploadBtn from "./components/UploadBtn";
import { mapActions, mapGetters, mapMutations } from "vuex";
import isEmpty from "lodash/isEmpty";
import {
  isSameDates,
  isValidDate,
  makeDateFormat,
  parseDate,
} from "@/helpers/dates";
import userDetailsMixin from "@/mixins/userDetailsMixin";
import notesHelperMixin from "@/mixins/notesHelpersMixin";
import tasksHelpersMixin from "@/mixins/tasksHelpersMixin";
import resourcesHelpersMixin from "@/mixins/resourcesHelpersMixin";
import googleCalHelpersMixins, {
  removeTaskFromGoogleCal,
} from "@/mixins/googleCalHelpersMixins";

import {
  UNDO_EVENT,
  REDO_EVENT,
  // ADD_DATA_ITEM_EVENT,
  OPEN_INSPECT_MODE,
  CLOSE_INPECT_MODE,
  SET_ID_FOR_SCROLL,
  ADD_NEW_TASK_IN_ACTIVITY_GRID,
  OPEN_STATUS_EDIT_MODE,
  SET_SELECTED_NODE_EVENT,
} from "@/variables/events";

import debounce from "lodash/debounce";
import GoogleCalHelpers from "@/helpers/googleCalHelpers";
import { PRIMARY_COLOR } from "@/variables/colors";
import { isExternalNavRoute } from "@/helpers/routesHelpers";
import {
  calModes,
  taskVisibiltyToggleVisibleModes,
} from "@/variables/viewConfigs";
import { focusOnInputEle } from "@/helpers/elements";
// import { createLabelForResource } from "@/helpers/resources";
import { INTERNAL_DATE_FORMAT } from "@/variables/dates";
import { getHours, getMinutes, set as setInDate } from "date-fns";
import {
  getMainTaskRulesAndFillRecurringOpts,
  getReminderTasksLinkedToTaskId,
  getTaskTreeView,
  makeVirtualTaskVisibleInFormGrid,
} from "@/helpers/tasks";
import isUndefinedVal from "@/utils/isUndefinedVal";
import Badge from "@/core/components/Badge.vue";
import SettingsMenu from "./SettingsMenu.vue";
import navMenuMixin, { NAV_ITEMS } from "@/components/NavMenuBar/navMenuMixin";
import { addOrRemoveOrUpdateNotesInLocalList } from "@/helpers/notes";
export default {
  mixins: [
    userDetailsMixin,
    notesHelperMixin,
    tasksHelpersMixin,
    googleCalHelpersMixins,
    resourcesHelpersMixin,
    navMenuMixin,
  ],
  props: {
    navSelect: Number,
    toggleSidebar: Function,
    sidebarOpen: Boolean,
  },
  components: {
    // AccountDialog,
    UploadBtn,
    Badge,
    SettingsMenu,
  },
  data() {
    return {
      queryToUse: "",
      localQuery: "",
      // inspectMode: false,
      viewOnRelease: 0,
      accountDialog: false,
      searchExpanded: false,
      searchContext: "here",
    };
  },
  methods: {
    ...mapActions({
      loaderToggler: "toggleLoader",
    }),
    handleKeydown(e) {
      const keyCode = e.which ? e.which : e.keyCode;
      if (keyCode === 27) {
        this.updateRootState({
          searchVal: "",
        });
      }
    },
    handleSearch(val) {
      this.updateRootState({
        searchVal: val,
      });
      // console.debug("VAL", val);
      // this.debouncedQuerySearch(val);
    },
    goTo(route) {
      let funcToCall;
      switch (route) {
        case "home":
          funcToCall = this.handleHomeToggle;
          break;
        case "global":
          funcToCall = this.handleTasksToggle;
          break;
      }

      if (funcToCall) {
        funcToCall();
      }
    },
    openUploadBox() {
      this.$refs.uploadBtn.openUploadWindow();
    },
    callDataAction(actionName) {
      let funcToCall;
      switch (actionName) {
        case "undo":
          funcToCall = this.undo;
          break;
        case "redo":
          funcToCall = this.redo;
          break;
        case "purge":
          funcToCall = this.handlePurge;
          break;
        case "upload":
          funcToCall = this.openUploadBox;
          break;
        case "sync":
          funcToCall = this.syncGoogleCalendar;
          break;
        case "enable-sync":
          funcToCall = this.pullGoogleCalenderData;
          break;
        case "search":
          funcToCall = this.focusOnSearchInput;
          break;
        case "inspect":
          funcToCall = this.inspectModeSwitch;
          break;
      }

      if (funcToCall) {
        funcToCall();
      }
    },
    openSettings() {
      // this.$refs.settingsBtn.
      // open settings logic
    },
    focusOnSearchInput() {
      const searchEl = this.$refs.searchInput.$el;
      if (searchEl) {
        searchEl.querySelector("input")?.focus();
      }
    },
    async handlePurge() {
      const res = await this.$swal({
        title: "Are you sure?",
        text: "This will remove all your tasks",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        iconColor: PRIMARY_COLOR,
        confirmButtonColor: PRIMARY_COLOR,
      });

      if (res.isConfirmed) {
        DatabaseInterface.update(
          {
            "/tasks": {},
          },
          this.userInfo.uid
        );
        this.removeAllTasksFromLocalList();

        removeTaskFromGoogleCal(
          this.rawTasks,
          undefined,
          this.rawTasks.length > 1
        );
      }
    },
    async pullGoogleCalenderData() {
      this.setCalSync(true);
      let useLastSync = true;
      let calendarIdToUpdate;
      if (isEmpty(this.rawTasks)) {
        useLastSync = false;
      }
      let recreateCalAndSync = this.googleCalNeedsReconnect;

      const currTimeStamp = new Date().toISOString();

      if (this.googleCalNeedsReconnect) {
        this.toggleGoogleCalReconnectStatus(false);
      }

      if (!recreateCalAndSync) {
        const currCalId = GoogleCalHelpers.getStoredCalId();
        const storedCal = await GoogleCalHelpers.getCalendarById(currCalId);
        recreateCalAndSync = isEmpty(storedCal);
      }

      if (recreateCalAndSync) {
        const calendarStatusRes =
          await GoogleCalHelpers.checkAndSetupCalendar();
        if (calendarStatusRes.calendarCreated) {
          useLastSync = false;
          calendarIdToUpdate = calendarStatusRes.calendarId;
        }
      }

      const dbUpdates = {};

      if (calendarIdToUpdate) {
        dbUpdates["/googleCalSyncInfo/calendarId"] = calendarIdToUpdate;
      }

      const syncStatus = await this.syncCalendar(
        currTimeStamp,
        useLastSync,
        recreateCalAndSync,
        true,
        false
      );

      if (syncStatus.status === "OK") {
        dbUpdates["/googleCalSyncInfo/lastSynced"] = currTimeStamp;
      }

      DatabaseInterface.update(
        {
          ...dbUpdates,
        },
        this.userInfo.uid
      );
    },
    async syncGoogleCalendar() {
      this.setLoader(true);

      try {
        const url = await GoogleCalHelpers.createCalAuthLink();
        this.setLoader(false);

        if (url) {
          window.location.href = url;
        }
      } catch (error) {
        console.error(error);
        this.setLoader(false);
      }
    },
    async handleHomeToggle() {
      const updates = {};
      let goToDashboard = false;
      let localUpdates = {
        isFilterBarOptsEnabled: false,
      };
      updates["nav"] = 3;
      updates["/view/3/selectedDates"] = [makeDateFormat(new Date())];
      updates["/view/3/groupView"] = 0;
      updates["globalView"] = false;
      updates["inboxView"] = false;
      updates["notesView"] = false;
      updates["activeNavView"] = "dashboard";
      updates["showCompleted"] = false;
      updates["inspectModeEnabled"] = false;
      updates["isProjectsResourceModeEnabled"] = false;
      if (this.isInspectModeEnabled) {
        localUpdates.inspectModeEnabled = false;
      }

      if (isExternalNavRoute(this.$route.name)) {
        goToDashboard = true;
      }
      await this.hideTempVisibleTasks();

      this.clearListFilters();
      this.toggleContingentTasks(false);
      this.closeResourceInfoViewer();
      this.updateState({
        ...localUpdates,
      });
      DatabaseInterface.update(updates, this.userInfo.uid);

      if (goToDashboard) {
        this.$router.replace({
          name: "Dashboard",
        });
      }

      this.updateRootState({
        currNav: 3,
        isNotesView: false,
        currActiveNav: "dashboard",
        searchVal: "",
        currViewData: {},
        itemInspectEnabled: false,
      });
    },
    toggleSearchContext(event, type) {
      // event.preventDefault();
      // event.stopPropagation();
      if (
        (type === "here" && this.isGlobalViewEnabled) ||
        (type === "global" && !this.isGlobalViewEnabled)
      ) {
        this.handleTasksToggle();
      }
      setTimeout(() => {
        this.$refs.searchInput.$el.querySelector("input")?.focus();
      }, 0);
      this.searchExpanded = true;
      this.searchContext = type;
    },
    handleSearchBlur() {
      if (!this.searchVal) {
        this.searchExpanded = false;
      }
    },
    async handleTasksToggle() {
      let enableGlobalView = false;
      let enableInboxView = false;
      let localUpdates = {
        isFilterBarOptsEnabled: false,
      };
      let dbUpdates = {};
      if (!this.isGlobalViewEnabled) {
        enableGlobalView = true;
        this.releaseInboxView = false;
        if (this.isInboxViewEnabled) {
          this.releaseInboxView = true;
        }

        if (this.currNav === 3) {
          if (this.prevTodayViewGroupMode !== null) {
            this.prevTodayGroupMode = this.prevTodayViewGroupMode;
          } else {
            this.prevTodayGroupMode = 0;
          }

          this.restoreTodaysViewGroupMode = true;
        }
      }
      if (this.releaseInboxView) {
        enableInboxView = true;
      }

      if (this.isInspectModeEnabled) {
        localUpdates.inspectModeEnabled = false;
      }

      if (
        this.restoreTodaysViewGroupMode &&
        this.prevTodayGroupMode !== null &&
        this.prevTodayGroupMode >= 0
      ) {
        dbUpdates["/view/3/groupView"] = this.prevTodayGroupMode;
      }

      dbUpdates["/view/6/groupView"] = 3;

      dbUpdates = {
        ...dbUpdates,
        isProjectsResourceModeEnabled: false,
        globalView: enableGlobalView,
        inboxView: enableInboxView,
        notesView: false,
        activeNavView: "dashboard",
        showCompleted: false,
        inspectModeEnabled: false,
      };

      if (isExternalNavRoute(this.$route.name)) {
        this.$router.replace({
          name: "Dashboard",
        });
      }

      await this.hideTempVisibleTasks();
      this.updateState({ ...localUpdates });
      this.clearListFilters();
      this.closeResourceInfoViewer();
      DatabaseInterface.update(dbUpdates, this.userInfo.uid);

      this.updateRootState({
        searchVal: "",
        currViewData: {},
        itemInspectEnabled: false,
      });
    },
    async revokeGoogleCalAccess() {
      this.setLoader(true);
      try {
        GoogleCalHelpers.revokeAccess();
        this.updateRootState({
          isGoogleCalSyncEnabled: false,
          googleCalLastSync: "",
        });

        const dbUpdates = {
          ["/googleCalSyncInfo/isEnabled"]: false,
          ["/googleCalSyncInfo/refreshToken"]: null,
          ["/googleCalSyncInfo/lastSynced"]: null,
        };
        DatabaseInterface.update(
          {
            ...dbUpdates,
          },
          this.userInfo.uid
        );
        this.showToast({
          message: "Google calendar is disconnected",
          color: "green",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.setLoader(false);
      }
    },
    async goToPreviousView() {
      if (!isEmpty(this.storedViewData)) {
        this.hideTempVisibleTasks();
        if (this.$route.name === "Dashboard") {
          this.handleDashboardReturnToView(this.storedViewData);
        }

        if (this.$route.name === "Notes") {
          this.handleNotesViewConfig(this.storedViewData);
        }

        if (this.$route.name === "Resources") {
          this.handleResourcesViewReturnInfo(this.storedViewData);
        }

        await this.$nextTick();

        this.updateRootState({
          itemInspectEnabled: false,
          currViewData: {},
        });
      }
    },
    async inspectModeSwitch() {
      if (this.itemInspectEnabled) {
        this.goToPreviousView();
        return;
      }
      let taskConfig = {};
      let localUpdates = {
        searchVal: "",
      };
      let dbUpdates = {};
      let viewToGo = 4;
      let projectsModeTrigged = false;
      let checkForReminderTask = false;
      let selectedProjects = [];
      let taskIdToSelect;
      let navigateToRoute = false;
      let routeName = "";
      let routeParams = {};

      let currViewData = {};
      let selectTaskInGrid = false;
      if (isExternalNavRoute(this.$route.name)) {
        this.hideTempVisibleTasks();
        DatabaseInterface.update(
          {
            notesView: false,
            activeNavView: "dashboard",
            // showCompleted: false,
          },
          this.userInfo.uid
        );

        this.$router.replace({
          name: "Dashboard",
        });
        return;
      }
      if (!this.isInspectModeEnabled) {
        this.reminderTaskInfo = undefined;
        let selectedDates = [];
        let selectedTasks = this.selectedTasks;
        let inspectTriggeredFrom = "";
        let currInspectedTaskId = "";
        let isUserFromDifferentTreeMode = false;

        if (
          this.inspectModeTriggeredFrom === "func" ||
          this.inspectModeTriggeredFrom === "resources"
        ) {
          selectedTasks = [this.taskDataToInspect];
        } else if (this.inspectModeTriggeredFrom === "next-actions") {
          selectedTasks = [this.taskDataToInspect];
          this.setEmptyCateList = isEmpty(this.selectedOptsList);
        } else if (
          this.inspectModeTriggeredFrom === "cal" ||
          this.inspectModeTriggeredFrom === "today"
        ) {
          selectedTasks = [this.taskDataToInspect];
        }

        if (this.inspectModeTriggeredFrom) {
          inspectTriggeredFrom = this.inspectModeTriggeredFrom;
        }
        if (isEmpty(selectedTasks)) {
          this.showToast("Select a Task");
          return;
        }

        const selectedTask = selectedTasks[0];

        if (isValidDate(selectedTask.reminderDate)) {
          this.reminderTaskInfo = {
            linkedId: selectedTask.key,
            reminderDate: selectedTask.reminderDate,
          };
        }
        dbUpdates = {
          // showCompleted: false,
          inspectModeEnabled: true,
        };

        // selectedTasks.forEach((t) => {
        //   if (t.due !== "none") {
        //     selectedDates.push(t.due);
        //   }

        //   if (t.project) {
        //     selectedProjects.push(t.project);
        //   }
        // });

        if (
          this.currNav === 1 ||
          (this.currNav === 4 && !this.isGlobalViewEnabled)
        ) {
          // let hasDate = false;
          // let errToastMainMsg = "";

          // let checkForDate = true;
          // errToastMainMsg = "Selected tasks";

          // if (this.inspectModeTriggeredFrom === "next-actions") {
          //   // checkForDate = false;
          // }

          // if (checkForDate) {
          // hasDate = selectedTasks.every((i) => {
          //   if (i.due !== "none") {
          //     selectedDates.push(i.due);
          //     return true;
          //   }
          //   return false;
          // });

          // if (!hasDate) {
          //   this.showToast(`${errToastMainMsg} should contain due date`);
          //   return;
          // }
          // }

          selectedTasks.forEach((t) => {
            if (t.due !== "none") {
              selectedDates.push(t.due);
            } else {
              selectedProjects.push(t.project);
            }
          });
          if (this.inspectModeTriggeredFrom !== "next-actions") {
            // set calender view has view to navigate to
            viewToGo = 0;

            if (!isEmpty(selectedProjects)) {
              viewToGo = 4;

              projectsModeTrigged = true;
            } else if (this.inspectModeTriggeredFrom === "resources") {
              viewToGo = 4;
            }
          } else {
            viewToGo = 4;

            // if (!isEmpty(this.inspectModeOpts)) {
            //   if (this.inspectModeOpts.openProject) {
            //     selectedProjects = selectedTasks.map((t) => t.project);
            //     projectsModeTrigged = true;
            //   }
            // }
          }
          taskIdToSelect = selectedTasks[0].key;
          if (!isEmpty(this.inspectModeOpts)) {
            if (this.inspectModeOpts.openProject) {
              selectedProjects = selectedTasks.map((t) => t.project);
              projectsModeTrigged = true;
            }

            if (this.inspectModeOpts.selectTask) {
              selectTaskInGrid = true;
            }

            if (!isEmpty(this.inspectModeOpts.returnViewInfo)) {
              this.returnToViewInfo = {
                ...this.inspectModeOpts.returnViewInfo,
              };
            }
          }
        } else if (
          calModes.includes(this.currNav) &&
          !this.isGlobalViewEnabled &&
          !this.isInboxViewEnabled
        ) {
          const hasAreas = selectedTasks.every(
            (t) => !isUndefinedVal(t.project)
          );

          if (!hasAreas) {
            // if (!hasDate) {
            this.showToast(`Selected Tasks should contain area`);
            return;
            // }
          }
          taskIdToSelect = selectedTasks[0].key;
          if (
            this.inspectModeTriggeredFrom === "func" ||
            this.inspectModeTriggeredFrom === "resources"
          ) {
            selectedProjects = selectedTasks.map((t) => t.project);

            projectsModeTrigged = true;

            if (!isEmpty(this.inspectModeOpts)) {
              if (this.inspectModeOpts.selectTask) {
                selectTaskInGrid = true;
              }

              if (!isEmpty(this.inspectModeOpts.returnViewInfo)) {
                this.returnToViewInfo = {
                  ...this.inspectModeOpts.returnViewInfo,
                };
              }
            }
          }
        }

        // if (this.currNav === 4 && !this.isGlobalViewEnabled) {
        //   isUserFromDifferentTreeMode = true;
        // }

        // if (
        //   treeSideBarModes.includes(this.currNav) &&
        //   this.isGlobalViewEnabled
        // ) {
        //   isUserFromDifferentTreeMode = true;
        // }

        currInspectedTaskId = selectedTasks[0].key;

        // check if navigate view is calender then set selected dates to filter;
        if (viewToGo === 0) {
          taskConfig = {
            selectedDates,
            groupMode: 0,
            contingentFilter: [],
          };
        }

        if (viewToGo === 4) {
          taskConfig = {
            selectedOptsList: selectedTasks.map((i) => ({
              id: i.project,
              subCategoryId: "all",
              categoryId: this.projects[i.project]?.category,
              type: "project",
            })),
            groupMode: 1,
          };

          makeVirtualTaskVisibleInFormGrid(selectedTasks);
          // this.groupMode = 1;
        }

        taskConfig.nav = viewToGo;

        taskConfig.inspectModeEnabled = true;
        taskConfig.isUserFromDifferentTreeMode = isUserFromDifferentTreeMode;
        taskConfig.viewOnInspectRelease = this.currNav;
        taskConfig.collapsedGroups = [];
        taskConfig.currInspectedTaskId = currInspectedTaskId;
        taskConfig.inspectTriggeredFrom = inspectTriggeredFrom;
        taskConfig.inspectModeOpts = !isEmpty(this.inspectModeOpts)
          ? this.inspectModeOpts
          : {};

        currViewData = {
          ...currViewData,

          inspectModeReturnData: {
            viewOnRelease: this.currNav,
            goBackToGlobalView: !!this.isGlobalViewEnabled,
            groupModeOnRelease: this.groupMode,
            selectedDatesOnRelease: this.selectedDates,
            selectedOptsListOnRelease: this.selectedOptsList,

            checkForReminderTask: true,

            reminderTaskInfo: !isEmpty(this.reminderTaskInfo)
              ? this.reminderTaskInfo
              : {},
            returnViewInfo: !isEmpty(this.returnToViewInfo)
              ? this.returnToViewInfo
              : {},
            setEmptyCateList: this.setEmptyCateList,
            taskIdToSelect,
            showContingentTasks: this.showContingentTasks,
            taskFilters: !isEmpty(this.taskFiltersMap)
              ? this.taskFiltersMap
              : {},
            additionalTaskFilters: !isEmpty(this.taskAdditionalFiltersMap)
              ? this.taskAdditionalFiltersMap
              : {},
          },
        };
        this.viewOnRelease = this.currNav;
        this.goBackToGlobalView = !!this.isGlobalViewEnabled;
        this.groupModeOnRelease = this.groupMode;
        this.selectedDatesOnRelease = this.selectedDates;
        this.selectedOptsListOnRelease = this.selectedOptsList;
        this.isShowHideTasksEnabled = this.showClearedTasks;
        this.storedSelectedId = !isEmpty(this.selectedTasks[0])
          ? this.selectedTasks[0].key
          : null;

        this.inspectModeTriggeredFrom = undefined;
      } else {
        this.focusOnSelectedItemKey = this.storedSelectedId;
        checkForReminderTask = true;
        taskConfig = {
          inspectModeEnabled: false,
          nav: this.viewOnRelease,
          selectedDates: this.selectedDatesOnRelease,
          selectedOptsList: this.setEmptyCateList
            ? []
            : this.selectedOptsListOnRelease,
          // selectedTasks: [],/
          groupMode: this.groupModeOnRelease,
          globalViewEnabled: this.goBackToGlobalView,
          isUserFromDifferentTreeMode: false,
          currInspectedTaskId: "",
          inspectTriggeredFrom: "",
        };
        dbUpdates = {
          showCompleted: false,
          inspectModeEnabled: false,
        };

        if (this.showClearedTasks) {
          dbUpdates.showCompleted = true;
        }
        viewToGo = this.viewOnRelease;

        if (!isEmpty(this.returnToViewInfo)) {
          dbUpdates["activeNavView"] = this.returnToViewInfo.to;
          navigateToRoute = true;
          routeName = this.returnToViewInfo.to;
          routeParams = {
            viewConfig: {
              modeData: this.returnToViewInfo.modeData,
              taskData: this.returnToViewInfo.taskData,
            },
          };

          //
        }
      }

      if (this.isInspectModeEnabled) {
        taskConfig.showClearedTasks = this.showClearedTasks;
      }

      taskConfig.isFilterBarOptsEnabled = false;

      if (taskIdToSelect && selectTaskInGrid) {
        EventEmitter.emit(SET_ID_FOR_SCROLL, taskIdToSelect);
      }
      if (checkForReminderTask && !isEmpty(this.reminderTaskInfo)) {
        let taskIdToUse = this.reminderTaskInfo.linkedId;
        const allReminderTasks = getReminderTasksLinkedToTaskId(
          this.tasks,
          this.reminderTaskInfo.linkedId
        );

        if (!isEmpty(allReminderTasks)) {
          const selectedDateReminderTask = allReminderTasks.find((t) =>
            isSameDates(t.reminderDate, this.reminderTaskInfo.reminderDate)
          );

          if (!isEmpty(selectedDateReminderTask)) {
            taskIdToUse = selectedDateReminderTask.key;
          }
        }

        EventEmitter.emit(SET_ID_FOR_SCROLL, taskIdToUse);

        this.reminderTaskInfo = undefined;
      }

      await this.hideTempVisibleTasks();

      if (!isEmpty(currViewData)) {
        this.storeCurrViewData(currViewData);
      }
      this.updateState({
        ...taskConfig,
      });
      this.clearListFilters();
      if (!isEmpty(dbUpdates)) {
        DatabaseInterface.update(dbUpdates, this.userInfo.uid);
      }
      localUpdates.currNav = viewToGo;

      this.updateRootState({ ...localUpdates });

      await Vue.nextTick();
      if (projectsModeTrigged) {
        this.changeExpandedNodesInTree(selectedProjects);
      }

      if (navigateToRoute && routeName) {
        this.$router.replace({
          name: routeName,
          params: {
            ...routeParams,
          },
        });
      }
    },
    closeInpectMode() {
      // this.focusOnSelectedItemKey = this.storedSelectedId;
      let localUpdates = {};
      let taskConfig = {
        inspectModeEnabled: false,
        nav: this.viewOnRelease,
        selectedDates: this.selectedDatesOnRelease,
        selectedOptsList: this.setEmptyCateList
          ? []
          : this.selectedOptsListOnRelease,
        // selectedTasks: [],/
        groupMode: this.groupModeOnRelease,
        globalViewEnabled: this.goBackToGlobalView,
        isUserFromDifferentTreeMode: false,
        currInspectedTaskId: "",
        inspectTriggeredFrom: "",
      };
      const dbUpdates = {
        showCompleted: false,
        inspectModeEnabled: false,
      };

      if (this.isInspectModeEnabled) {
        taskConfig.showClearedTasks = this.showClearedTasks;
      }

      if (this.showClearedTasks) {
        dbUpdates.showCompleted = true;
      }
      const viewToGo = this.viewOnRelease;

      if (this.isInspectModeEnabled) {
        taskConfig.showClearedTasks = this.showClearedTasks;
      }

      taskConfig.isFilterBarOptsEnabled = false;
      this.updateState({
        ...taskConfig,
      });
      this.clearListFilters();
      if (!isEmpty(dbUpdates)) {
        DatabaseInterface.update(dbUpdates, this.userInfo.uid);
      }
      localUpdates.currNav = viewToGo;
      this.updateRootState({ ...localUpdates });
    },
    changeExpandedNodesInTree(selectedNodes) {
      const activeTaskTreeViewData = getTaskTreeView();
      const inactiveTaskTreeViewData = getTaskTreeView("inactive");
      // const catTreeEl = activeTaskTreeViewData.element;
      // eslint-disable-next-line no-unused-vars
      const catTreeView = activeTaskTreeViewData.treeInstance;
      // eslint-disable-next-line no-unused-vars
      const inactiveTree = inactiveTaskTreeViewData.treeInstance;

      catTreeView?.expandAll(selectedNodes);
      inactiveTree?.expandAll(selectedNodes);

      setTimeout(() => {
        catTreeView?.ensureVisible(selectedNodes[0]);
      }, 0);
    },
    addItem() {
      let eventToEmit;
      let dataToSend = {};
      let sendEventToActivityGrid = false;
      let dueAsDate = new Date();
      if (this.isNotesView) {
        if (this.selectedFolders.length === 1) {
          dataToSend = { path: this.selectedFolders[0] };
        }
        eventToEmit = "add-note";
      } else if (this.isResourcesView || this.isProjectsResourceModeEnabled) {
        // let catId = "";
        let resourceIdToUse = "";
        const treeEl = document.querySelector("#resources-categories-tree")
          ?.ej2_instances[0];

        if (treeEl) {
          const selectedNode = treeEl.selectedNodes[0];
          if (selectedNode && this.rawResourcesMap[selectedNode]) {
            resourceIdToUse = selectedNode;
          }
          // if (selectedNode && this.resourcesCategories[selectedNode]) {
          //   catId = this.resourcesCategories[selectedNode].key;
          // }
        }

        eventToEmit = ADD_NEW_TASK_IN_ACTIVITY_GRID;

        dataToSend = { resourceToUse: resourceIdToUse, dueAsDate };
        // Disabled logic
        // const selectedResourceType = this.selectedResourceType;
        // eventToEmit = ADD_DATA_ITEM_EVENT;
        // dataToSend = { catId, type: selectedResourceType || "thing" };
      } else {
        let project = "";
        const treeElId = this.showClearedTasks
          ? "#treeview-all-data"
          : "#treeview";
        const treeview = document.querySelector(treeElId)?.ej2_instances[0];

        if (treeview && !this.isInboxViewEnabled) {
          const selectedNode = treeview.selectedNodes[0];
          if (
            selectedNode &&
            this.projects[selectedNode] &&
            this.projects[selectedNode].type === "project"
          ) {
            project = this.projects[selectedNode].key;
          }
        }

        if (calModes.includes(this.currNav)) {
          if (!isEmpty(this.selectedDates) && this.selectedDates.length === 1) {
            const parsedDate = parseDate(
              this.selectedDates[0],
              INTERNAL_DATE_FORMAT
            );

            if (isValidDate(parsedDate)) {
              const hoursToSet = getHours(dueAsDate);
              const minsToSet = getMinutes(dueAsDate);
              dueAsDate = setInDate(parsedDate, {
                hours: hoursToSet,
                minutes: minsToSet,
              });
            }
          }
        }
        eventToEmit = "add-task";
        dataToSend = { project, dueAsDate };

        sendEventToActivityGrid = true;
      }

      EventEmitter.emit(eventToEmit, dataToSend);

      if (sendEventToActivityGrid) {
        EventEmitter.emit(ADD_NEW_TASK_IN_ACTIVITY_GRID, dataToSend);
      }
    },
    deleteItem() {
      if (this.isNotesView) {
        this.removeNotesFromNotesList(this.selectedNotes);
        addOrRemoveOrUpdateNotesInLocalList({
          notesToRemove: [...this.selectedNotes],
        });
      } else {
        if (isEmpty(this.selectedTasks)) {
          return;
        }

        this.handleDeletionOfTasks(this.selectedTasks);
      }

      // Disabled logic which removed selected resources;
      //     else if (this.isResourcesView) {
      // this.removeResourcesFromLocalAndDb(this.selectedResources);
      //     }
    },
    undo() {
      EventEmitter.emit(UNDO_EVENT);
    },
    redo() {
      EventEmitter.emit(REDO_EVENT);
    },

    updateStatusFilter: function () {
      let updates = {};
      if (!this.isGlobalViewEnabled) {
        updates["/view/" + this.currNav + "/statusFilter"] = "all";
      }

      updates["globalView"] = !this.isGlobalViewEnabled;
      updates["inboxView"] = false;
      DatabaseInterface.update(updates, this.userInfo.uid);
    },
    cleanupTasks() {
      // if (this.showClearedTasks) {
      DatabaseInterface.update(
        {
          showCompleted: !this.showClearedTasks,
        },
        this.userInfo.uid
      );
    },
    showCompletedTasks() {
      DatabaseInterface.update(
        {
          showCompleted: !this.showClearedTasks,
        },
        this.userInfo.uid
      );
    },
    handleBroomBtnClick() {
      if (this.$route.name === "Dashboard" && this.isInspectModeEnabled) {
        this.updateState({
          showClearedTasks: !this.showClearedTasks,
        });
        return;
      }

      let navigateToTasks = false;

      let dbUpdates = {
        showCompleted: !this.showClearedTasks,
      };

      if (
        !taskVisibiltyToggleVisibleModes.includes(
          this.$route.name.toLowerCase()
        )
      ) {
        navigateToTasks = true;
        dbUpdates = {
          ...dbUpdates,
          notesView: false,
          activeNavView: "dashboard",
        };
      }

      if (this.$route.name === "Resources") {
        this.loaderToggler(true);
      }

      DatabaseInterface.update(
        {
          ...dbUpdates,
        },
        this.userInfo.uid
      );

      if (navigateToTasks) {
        this.$router.replace({
          name: "Dashboard",
        });
      }
    },
    search() {
      this.getSearchResults(this.searchVal);
    },
    isCleanableTasks() {
      return this.showClearedTasks;
    },
    setValue() {
      this.localQuery = this.queryToUse;
    },
    clearValues() {
      // this.localQuery = "";
      // this.queryToUse = "";

      this.updateRootState({
        searchVal: "",
      });

      if (this.$route.name === "Notes") {
        this.setNotesSearchQuery("");
      } else if (this.$route.name === "Resources") {
        this.setResourcesSearchQuery("");
      } else {
        this.updateState({
          query: "",
        });
      }
    },

    async getSearchResults(query) {
      const inputEl = this.$refs.searchInput.$el.querySelector("input");
      // this.queryToUse = query;
      if (this.$route.name === "Notes") {
        this.setNotesSearchQuery(query);
      } else {
        this.updateState({
          query,
        });
      }
      // Disabled to enable searching of tasks in form grid (NewActivityHistoryTable)
      // else if (this.$route.name === "Resources" ) {
      //   this.setResourcesSearchQuery(query);
      // }

      await Vue.nextTick();
      setTimeout(() => {
        focusOnInputEle(inputEl);
      }, 0);
    },
    triggerInspectMode({ triggeredFrom, taskData, opts }) {
      this.inspectModeTriggeredFrom = triggeredFrom;
      this.taskDataToInspect = taskData;
      this.inspectModeOpts = opts;
      this.inspectModeSwitch();
    },
    async triggerOpenStatusEdit({ triggeredFrom, taskData, opts }) {
      const menuItem = {
        title: "Areas",
        icon: "$ctiTreeViewIcon",
        index: 4,
        groupView: 1,
        // groupView: 2,
        // index: 1,
        actionVal: "projects",
        fontSize: 20,
      };
      let localUpdates = {
        isFilterBarOptsEnabled: false,
        ignoreAreaCheck: true,
      };
      let dbUpdates = {};
      let navigateToRoute = false;
      let routeToGo = "";
      let disableInspectMode = true;
      let isLocalUpdate = false;
      let rootUpdates = {};
      let updates = {};
      const isProjectsResourceModeEnabled = this.isProjectsResourceModeEnabled;

      updates["nav"] = menuItem.index;
      updates["globalView"] = false;
      updates["inboxView"] = false;
      updates["notesView"] = false;
      updates["activeNavView"] = "dashboard";
      updates["showCompleted"] = false;
      // updates["view/0/groupView"] = 0;
      updates["view/0/sortMode"] = 0;
      updates["view/0/statusFilter"] = "scheduled";
      updates["view/1/statusFilter"] = "incomplete";
      updates["view/1/sortMode"] = 0;
      if (menuItem.groupView !== undefined) {
        updates[`view/${menuItem.index}/groupView`] = menuItem.groupView;
      }

      if (this.isInboxViewEnabled) {
        if (
          typeof this.prevTreeGroupView === "number" &&
          this.prevTreeGroupView >= 0
        ) {
          updates["view/1/groupView"] = this.prevTreeGroupView;
        }
      }

      updates["view/2/statusFilter"] = "incomplete";
      updates["view/3/statusFilter"] = "incomplete";
      updates["view/3/sortMode"] = 1;

      if (isProjectsResourceModeEnabled) {
        updates["isProjectsResourceModeEnabled"] = false;
        localUpdates.isProjectsResourceModeEnabled = false;
      }

      updates["inspectModeEnabled"] = disableInspectMode ? false : true;

      dbUpdates = { ...updates };

      if (this.$route.name !== "Dashboard") {
        navigateToRoute = true;
        routeToGo = "Dashboard";
      }

      if (disableInspectMode) {
        localUpdates.inspectModeEnabled = false;
        localUpdates.additionalTaskFilters = {};
      }

      this.updateState({ ...localUpdates });
      this.clearListFilters();
      this.updateRootState({
        searchVal: "",
        ...rootUpdates,
      });

      if (!isLocalUpdate && !isEmpty(dbUpdates)) {
        DatabaseInterface.update(dbUpdates, this.userInfo.uid);
      }

      if (navigateToRoute) {
        this.$router.replace({
          name: routeToGo,
        });
      }

      await this.$nextTick();

      this.changeExpandedNodesInTree([taskData.project]);
      await this.$nextTick();
      EventEmitter.emit(
        SET_SELECTED_NODE_EVENT,
        [taskData.project],
        true,
        true,
        undefined,
        true,
        undefined,
        true
      );

      await Vue.nextTick();

      if (opts?.openTask) {
        const processedTaskData =
          getMainTaskRulesAndFillRecurringOpts(taskData);
        makeVirtualTaskVisibleInFormGrid(processedTaskData);

        this.showNewEditTaskDialog({
          taskData: { ...processedTaskData },
          selectedTasks: [],
          returnViewInfo: !isEmpty(opts.returnViewInfo)
            ? opts.returnViewInfo
            : {},
        });
      }

      this.updateState({
        ignoreAreaCheck: false,
      });
    },
    getItemLabel(type) {
      if (type === "add") {
        return this.viewItemLabel;
      }

      if (type === "remove") {
        return this.itemRemoveBtnLabel;
      }
    },
    ...mapActions("user", ["clearUserDetails"]),
    ...mapActions("editForm", ["showNewEditTaskDialog"]),
    ...mapActions("task", [
      "toggleClearedTasks",
      "clearListFilters",
      "toggleContingentTasks",
    ]),
    ...mapMutations("task", ["updateState"]),
    ...mapMutations(["setLoader"]),
    ...mapActions("toast", ["showToast"]),
    ...mapActions("note", ["setNotesSearchQuery"]),
    ...mapActions("resourcesData", ["setResourcesSearchQuery"]),
    ...mapActions([
      "updateRootState",
      "storeCurrViewData",
      "setCalSync",
      "toggleGoogleCalReconnectStatus",
    ]),
    ...mapActions("resourceInfo", ["closeResourceInfoViewer"]),
  },
  computed: {
    activeMenu() {
      let menuIcon = "";

      switch (this.navIndex) {
        case NAV_ITEMS.AREA:
          menuIcon = "$ctiHamburgerArea";
          break;
        case NAV_ITEMS.CONTEXTS:
          menuIcon = "$ctiHamburgerContexts";
          break;
        case NAV_ITEMS.INBOX:
          menuIcon = "$ctiHamburgerInbox";
          break;
        case NAV_ITEMS.NOTES:
          menuIcon = "$ctiHamburgerNotes";
          break;
        default:
          menuIcon = "$ctiHamburgerCalendar";
      }
      return menuIcon;
    },
    isInspectBtnActive() {
      return this.itemInspectEnabled || this.isInspectModeEnabled;
    },
    inspectBtnTooltip() {
      let text = "Inspect";

      if (this.isInspectBtnActive) {
        text = "Return from Inspect";
      }
      return text;
    },
    ignoreSearchBar() {
      return this.$route.name === "Resources" ||
        this.isProjectsResourceModeEnabled ||
        this.openNewForm
        ? "1"
        : "0";
    },
    searchInputStyles() {
      return {
        "pl-2 search-input search-input-active": true,
        "search-field": true,
        // "search-input-active": this.searchVal && this.searchVal !== "",
      };
    },
    searchPlaceHolder() {
      return this.isGlobalViewEnabled ? "Search Everywhere" : "Search Here";
    },
    activeCleanableBtnClass() {
      return {
        "action-btn task-bar-action-btn": true,
        ["btn-is-active"]: this.isCleanableTasks(),
        "keep-task-selected": this.isInspectBtnActive,
      };
    },
    activeInspectBtnClass() {
      return {
        ["btn-is-active"]: this.isInspectBtnActive,
      };
    },
    broomBtnLabel() {
      return this.isCleanableTasks()
        ? "Hide Completed Tasks"
        : "Show Completed Tasks";
    },

    ...mapGetters("task", [
      "selectedTasks",
      "currNav",
      "isGlobalViewEnabled",
      "isInspectModeEnabled",
      "isInboxViewEnabled",
      "projects",
      "categories",
      "groupMode",
      "selectedDates",
      "selectedOptsList",
      "showClearedTasks",
      "prevTodayViewGroupMode",
      "rawTasks",
      "tasks",
      "isProjectsResourceModeEnabled",
    ]),
    ...mapGetters("task", {
      taskAdditionalFiltersMap: "additionalTaskFilters",
      taskFiltersMap: "filters",
      showContingentTasks: "showContingentTasks",
    }),
    ...mapGetters("resourcesData", {
      resourcesCategories: "categories",
      rawResourcesMap: "rawResourcesMap",
      selectedResourceType: "selectedResourceTypeOpt",
      selectedResourceCategories: "selectedCategories",
    }),
    isNotesView() {
      return this.$route.name === "Notes";
    },
    isResourcesView() {
      return this.$route.name === "Resources";
    },
    isInTasksAndSyncEnabled() {
      return (
        this.$route.name === "Dashboard" &&
        (this.isGoogleCalSyncEnabled || this.googleCalNeedsReconnect)
      );
    },
    showInspectBtn() {
      return (
        (isExternalNavRoute(this.$route.name) && this.itemInspectEnabled) ||
        (this.$route.name === "Dashboard" &&
          (this.itemInspectEnabled || !this.isProjectsResourceModeEnabled))
      );
    },
    showTaskVisibility() {
      return taskVisibiltyToggleVisibleModes.includes(
        this.$route.name.toLowerCase()
      );
    },
    viewItemLabel() {
      let label = "Task";
      // let useNewKeyword = true;
      if (this.isNotesView) {
        label = "Note";
      }

      // else if (this.isResourcesView) {
      //   label = createLabelForResource(this.selectedResourceType);
      //   // useNewKeyword = false;
      // }
      return label;
    },
    removeItemBtnLabel() {
      let label = "Task(s)";
      // let useNewKeyword = true;
      if (this.isNotesView) {
        label = "Note(s)";
      }

      // else if (this.isResourcesView) {
      //   label = createLabelForResource(this.selectedResourceType);

      //   if (label !== "People") {
      //     label = `${label}(s)`;
      //   }
      //   // useNewKeyword = false;
      // }
      return label;
    },
    googleCalSyncBtnTxt() {
      let txt = "Connect with Google Calendar";
      if (this.isGoogleCalSyncEnabled && !this.googleCalNeedsReconnect) {
        txt = "Sync Data";
      } else if (this.googleCalNeedsReconnect) {
        txt = "Click to re-create google calendar and sync data";
      }
      return txt;
    },
    syncBtnClasses() {
      return {
        "sync-started": this.isCalSyncRunning,
      };
    },
    ...mapGetters("note", ["selectedNotes", "selectedFolders"]),
    ...mapGetters("resourcesData", ["selectedResources"]),
    ...mapGetters([
      "isGoogleCalSyncEnabled",
      "isCalSyncRunning",
      "searchVal",
      "itemInspectEnabled",
      "googleCalNeedsReconnect",
    ]),
    ...mapGetters({
      storedViewData: "currViewData",
    }),
    ...mapGetters("editForm", {
      isEditTaskFormOpen: "isOpen",
      openNewForm: "openNewForm",
    }),
    ...mapGetters("resourceInfo", {
      isResourceFormOpen: "isOpen",
    }),
    showControls() {
      const res = !this.isInResourceMode && !this.isEditTaskFormOpen;

      return res;
    },
    // showInspectBtnControl(){
    //   return this.$route.name === "Dashboard" &&
    // },
    showItemHandlerBtns() {
      const res =
        this.isInResourceMode ||
        this.isProjectsResourceModeEnabled ||
        this.showControls;

      return res;
    },
    isInResourceMode() {
      return this.$route.name === "Resources";
    },
    preventFormCloseOnDeleteAttr() {
      return this.isInResourceMode ||
        this.isProjectsResourceModeEnabled ||
        this.openNewForm
        ? "1"
        : "0";
    },
    // broomColorClass()
  },
  mounted() {
    this.debouncedQuerySearch = debounce(this.getSearchResults, 500);
    EventEmitter.on(OPEN_STATUS_EDIT_MODE, this.triggerOpenStatusEdit);
    EventEmitter.on(OPEN_INSPECT_MODE, this.triggerInspectMode);
    EventEmitter.on(CLOSE_INPECT_MODE, this.closeInpectMode);
  },
  beforeDestroy() {
    EventEmitter.off(OPEN_STATUS_EDIT_MODE, this.triggerOpenStatusEdit);
    EventEmitter.off(OPEN_INSPECT_MODE, this.triggerInspectMode);
    EventEmitter.off(CLOSE_INPECT_MODE, this.closeInpectMode);
  },
  watch: {
    searchVal(n) {
      this.debouncedQuerySearch(n);
    },
  },
};
</script>
<style>
.search-area-menu-btn {
  color: var(--grey-icon-color) !important;
  height: 25px !important;
  width: 1px !important;
  padding-left: 28px;
  padding-right: 4px;
  margin-left: -28px;
  padding-bottom: 7px;
}
.search-list-icon {
  margin: 0px !important;
  margin-top: 6px !important;
}
.v-menu__content .search-list-icon .v-icon {
  color: inherit !important;
  font-size: 20px !important;
}
</style>
<style scoped>
.theme--light.v-btn--active:hover::before,
.theme--light.v-btn--active::before {
  opacity: 0;
}
.selected::before {
  opacity: 0.18 !important;
}
.selected {
  border: 1px solid;
  border-color: #39f;
}

.action-btn {
  height: 28px !important;
  width: 28px !important;
  min-width: auto !important;
}

.sidebar-toggle-btn {
  padding: 0 22px 0 19px !important;
  position: relative;
}

.sidebar-toggle-btn::after {
  content: "";
  display: block;
  position: absolute;
  background: rgb(255 255 255 / 50%);
  width: 1px;
  height: 17px;
  right: 0;
}

.home-btn {
  width: auto !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.search-input >>> .v-input__slot::before {
  /* border-color: transparent !important; */
  border-width: 0px !important;
}

.search-input >>> input::placeholder {
  color: #fff;
}

.search-input {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  border: none !important;
  transition: all 0.3s;
}
.search-input:hover {
  background-color: #fff !important;
}

.search-input.v-input--is-focused,
.search-input:hover {
  background-color: #fff !important;
}

.search-input-active {
  background-color: #fff !important;
}
.search-input-active .search-icon {
  color: var(--grey-icon-color) !important;
}
.search-input-active >>> input::placeholder {
  color: #9f9f9f !important;
}

.search-input-active >>> button[aria-label="clear icon"] {
  color: var(--grey-icon-color) !important;
  caret-color: var(--grey-icon-color) !important;
  font-size: 20px;
  padding-right: 8px;
}
.search-input:hover .search-icon {
  color: var(--grey-icon-color) !important;
}

.search-input.search-input.v-input--is-focused >>> .search-icon {
  color: var(--grey-icon-color) !important;
}

.search-input.search-input.v-input--is-focused
  >>> button[aria-label="clear icon"] {
  color: var(--grey-icon-color) !important;
  caret-color: var(--grey-icon-color) !important;
}
.search-input.search-input.v-input--is-focused,
.search-input:hover >>> button[aria-label="clear icon"] {
  color: var(--grey-icon-color) !important;
  caret-color: var(--grey-icon-color) !important;
}

.search-input.v-input--is-focused >>> input::placeholder {
  color: #9f9f9f !important;
}

.search-input:hover >>> input::placeholder {
  color: #9f9f9f !important;
}
.search-input >>> .v-input__slot::after {
  /* border-color: transparent !important; */
  border-width: 0px !important;
}

.action-btn:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.action-btn.btn-is-focused {
  background-color: rgba(255, 255, 255, 0.2);
}

.action-btn.btn-is-active {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.action-btn >>> svg {
  fill: #fff !important;
}

.small-icon >>> svg {
  height: 20px;
  width: 20px;
}

.google-connect-icon {
  transition-duration: 2s;
  transition-property: transform;
}

.google-connect-btn.sync-started .google-connect-icon {
  -webkit-animation: spin 2s linear infinite;
  -moz-animation: spin 2s linear infinite;
  -ms-animation: spin 2s linear infinite;
  -o-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.home-btn-container {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

@media screen and (max-width: 600px) {
  .home-btn-container {
    margin-right: 0px;
  }

  /* .home-btn {
    padding: 0 14px !important;
  } */
}

.home-btn-container .label-text {
  color: #fff;
  /* margin-top: 10px; */
  margin-left: 8px;
  font-size: 21px !important;
  padding-top: 6px;
  padding-bottom: 4px;
  /* line-height: 1 !important; */
  /* margin-top: 3px;
  margin-bottom: 3px; */
  font-weight: 300 !important;
}

@-webkit-keyframes spin /* Safari and Chrome */ {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

.taskbar-actions-wrapper .action-btn {
  min-width: 40px !important;
}

/* .google-connect-icon::active {

} */
/* .v-input__slot:after {
  border: none !important;
} */

/* .search-input > .v-input__icon--append {
  color: #000 !important;
} */
</style>
<style>
.app-bar {
  flex-wrap: nowrap;
  align-items: center;
}
.app-bar .v-text-field .v-input__append-inner {
  margin-top: 2px;
}

.app-bar .delete-btn {
  margin-left: -4px;
}

@media screen and (max-width: 600px) {
  .action-btn {
    padding: 0 14px !important;
  }

  .action-btn.task-bar-action-btn.home-btn {
    padding: 0 6px !important;
  }

  .search-field input {
    width: 0;
  }

  .search-expanded {
    width: 100%;
    max-width: calc(100vw - 90px);
  }

  .search-expanded .search-field input {
    /* width: calc(100% - ); */
    width: auto;
  }
}
</style>
