import Vue from "vue";
import Vuex from "vuex";
import listOptsModules from "./modules/listOpts";
import toastModule from "./modules/toast";
import userModule from "./modules/user";
import editFormModule from "./modules/editForm";
import convertDataModeModule from "./modules/convertDataMode";
import resourcesModule from "./modules/resources";
import resourceInfoModule from "./modules/resourceInfo";
import areaPrioritySetterData from "./modules/areaPrioritySetterData";
import cateDataModule from "./modules/cateData";
import taskModule from "./modules/task";
import noteModule from "./modules/note";
import resourceParentFormModule from "./modules/resourceParentForm";
import deleteRecurringConfirmDialogModule from "./modules/deleteReccuringConfirmDialog";
import rootGetters from "./getters";
import rootMutations from "./mutations";
import rootActions from "./actions";
Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    listOpts: listOptsModules,
    toast: toastModule,
    user: userModule,
    editForm: editFormModule,
    task: taskModule,
    note: noteModule,
    convertModeData: convertDataModeModule,
    resourcesData: resourcesModule,
    cateData: cateDataModule,
    resourceInfo: resourceInfoModule,
    areaPrioritySetterData: areaPrioritySetterData,
    resourceParentForm: resourceParentFormModule,
    deleteRecurringConfirmDialog: deleteRecurringConfirmDialogModule,
  },
  state() {
    return {
      isGlobalSearchBoxOpen: false,
      isSettingsOpen: false,
      isNotesView: false,
      currActiveView: "",
      currNav: 0,
      showLoader: false,
      isLoaderEnabled: false,
      isGoogleCalSyncEnabled: false,
      isCalSyncRunning: false,
      googleCalLastSync: "",
      googleCalLastConnected: "",
      googleCalNeedsReconnect: false,
      searchVal: "",
      currentViewData: {},
      viewedItemKey: "",
      resourceSettings: {},
      currRowInEditing: "",
      currViewData: {},
      taskSettings: {},
      editFormSettings: {},
      itemInspectEnabled: false,
      isResourceTipHidden: false,
      isAreasTipHidden: false,
      openedSettings: "",
      selectedColorTheme: "",
      keepResourceNodesClosed: false,
      sidebarOpen: true,
      subTasksListGroupFilter: "scheduled",
      // subTasksListGroupFilter: "default",
    };
  },
  actions: rootActions,
  mutations: rootMutations,
  getters: rootGetters,
});

export default store;
