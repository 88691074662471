var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column flex-nowrap fill-height history-table",attrs:{"id":"activity-tasks-list"}},[_c('NextActionsGroupFiltersRow',{attrs:{"value":_vm.subTasksListGroupFilter,"isControlled":""},on:{"change":_vm.handleTaskGroupFilterChange}}),_c('div',{staticClass:"flex-grow-1 min-h-0 overflow-hidden",attrs:{"id":("activity-tasks-list-wrapper-" + _vm.randomId)},on:{"contextmenu":_vm.handleContextMenuTrigger}},[_c('DataTable',{ref:"dataGrid",attrs:{"list":_vm.tasks,"columnsList":_vm.tableColumns,"selectedRowIds":_vm.selectedRowKeys,"options":_vm.options,"allowSelectionByArrowKeys":_vm.allowArrowKeySelection,"renderGroupCaption":_vm.createGroupItemsCaptions,"rowClassNamesCreator":_vm.createRowClassNames,"whiteListBtns":[
        'project-form',
        'add-resource-pop-up',
        'resource-type-selector',
        'resource-type-cate-selector',
        'resource-save-btn',
        'resource-title-editor-wrapper',
        'resource-error-pop-up',
        'convert-dialog',
        'note-path-selector-popup',
        'v-overlay' ],"allowMultiSelect":_vm.allowMultiSelect,"allowDragAndDrop":_vm.allowDrag,"rowDragVerifier":_vm.verifyRowDrag,"collapsedGroupsList":_vm.collapsedGroupsList,"verifyColumnSortablity":_vm.checkColumnIsSortable,"postSortProcessor":_vm.processTasksAfterSort,"postGroupProcessor":_vm.processTasksAfterGrouping,"processFinalList":_vm.processFinalTaskList,"doNotHandleRowScroll":_vm.doNotHandleRowScroll,"checkDeslectOnClick":_vm.deselectOnClick,"checkRowDragEnabled":_vm.checkIfRowDragEnabled,"itemSize":36,"placeholderText":_vm.placeholderTxt},on:{"edit:enabled":_vm.handleEditEnabled,"row:updated":_vm.handleRowUpdated,"rows:selected":_vm.handleRowsSelected,"row:select":_vm.handleRowSelect,"row:deselect":_vm.handleRowDeselect,"row:clicked":_vm.handleRowClick,"row:focused":_vm.handleRowFocused,"row:added":_vm.handleRowAdd,"list:processed":_vm.handleListProcessed,"group:toggled":_vm.handleGroupToggle,"keydown":_vm.handleKeydown,"key-pressed:delete":_vm.handleDeleteCall,"list:post-grouping":_vm.handlePostGrouping,"row:reordered":_vm.handleRowDragStop,"row:touch:long":_vm.handleLongTouch}})],1),_c('ContextMenu',{staticClass:"activity-tasks-popup-menu",attrs:{"target":("#activity-tasks-list-wrapper-" + _vm.randomId),"items":_vm.menuItems,"menuId":("activity-tasks-menu-opts-" + _vm.randomId)},on:{"menu-item:selected":_vm.handleMenuSelection,"beforeMenuOpen":_vm.handleBeforeMenuOpen}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }