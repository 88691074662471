export default {
  updateRootState(context, payload) {
    context.commit("updateState", payload);
  },
  toggleNotesView(context) {
    context.commit("toggleNotesView");
  },
  // toggleLoader(context) {
  //   context.commit("toggleLoaderState");
  // },
  setCalSync(context, payload) {
    context.commit("setCalSync", payload);
  },
  setGoogleCalLastSync(context, payload) {
    context.commit("setGoogleCalLastSync", payload);
  },
  toggleSettingsDialog(context, payload = {}) {
    const { settingsToOpen } = payload;
    context.commit("updateState", {
      isSettingsOpen: !context.state.isSettingsOpen,
      openedSettings: settingsToOpen || "",
    });
  },
  toggleGlobalSearchBox(context) {
    context.commit("updateState", {
      isGlobalSearchBoxOpen: !context.state.isGlobalSearchBoxOpen,
    });
  },
  toggleLoader(context, payload) {
    context.commit("updateState", {
      isLoaderEnabled: payload,
    });
  },

  storeCurrViewData(context, payload) {
    context.commit("updateState", {
      currViewData: payload,
    });
  },
  toggleGoogleCalReconnectStatus(context, payload) {
    context.commit("updateState", {
      googleCalNeedsReconnect:
        payload !== undefined
          ? payload
          : !context.state.googleCalNeedsReconnect,
    });
  },
  setSubTasksListGroupFilter(context, payload) {
    context.commit("updateState", {
      subTasksListGroupFilter: payload,
    });
  },
};
