import { cloneDeep } from "lodash";

export default {
  storeProjects(context, payload) {
    context.commit("setList", {
      type: "projects",
      list: payload,
    });
  },
  storeTasks(context, payload) {
    context.commit("setList", {
      type: "tasks",
      list: payload,
    });
  },
  storeCategories(context, payload) {
    context.commit("setList", {
      type: "categories",
      list: payload,
    });
  },
  changeNav(context, payload) {
    context.commit("setNav", payload);
  },
  setGroupMode(context, payload) {
    context.commit("setGroup", payload);
  },
  toggleInboxView(context) {
    context.commit("toggleInbox");
  },
  changeListFilter(context, payload) {
    context.commit("setListFilter", {
      key: payload.key,
      filter: payload.filter,
      list: payload.list,
      overwrite: payload.overwrite,
    });
  },
  clearListFilters(context, payload) {
    context.commit("resetListFilters", payload);
  },

  changeTasksCount(context, payload) {
    context.commit("updateTaskCount", payload);
  },
  updateSelectedTasks(context, payload) {
    context.commit("setSelectedTasks", payload);
  },
  changeSelectedDates(context, payload) {
    context.commit("setSelectedDates", payload);
  },
  toggleClearedTasks(context) {
    context.commit("toggleClearedTasks");
  },
  setProjectsTree(context, payload) {
    context.commit("setProjectsTree", payload);
  },
  setSelectedCatIds(context, payload) {
    context.commit("setSelectedCatIds", payload);
  },
  updateTasks(context, payload) {
    context.commit("updateTasks", payload);
  },
  removeTasks(context, payload) {
    context.commit("removeTasks", payload);
  },
  addTask(context, payload) {
    context.commit("addTask", payload);
  },
  updateOrAddTasks(context, payload) {
    context.commit("updateOrAddTasks", payload);
  },
  addOrUpdateOrRemoveTasks(context, payload) {
    context.commit("addOrUpdateOrRemoveTasks", payload);
  },
  removeRecurringTasksFromMap(context, payload) {
    context.commit("removeRecurringTasksFromMap", payload);
  },
  removeRecurringTasksFromMapByDates(context, payload) {
    context.commit("removeRecurringTasksFromMapByDates", payload);
  },
  addRecurringTasksInMap(context, payload) {
    context.commit("addRecurringTasksInMap", payload);
  },
  replaceRecurringTasksInfoInMap(context, payload) {
    context.commit("replaceRecurringTasksInfoInMap", payload);
  },
  addOrUpdateRecurringTasksInRecurringInfoMap(context, payload) {
    context.commit("addOrUpdateRecurringTasksInRecurringMap", payload);
  },
  removeAllTasks(context) {
    context.commit("removeAllTasks");
  },
  setTodayModeFilter(context, payload) {
    context.commit("updateState", { todayModeFilter: payload });
  },
  setAdditionalTaskFilters(context, payload) {
    const { key, list } = payload;
    const currState = context.state;
    const currAdditionalFilters = cloneDeep(currState.additionalTaskFilters);

    currAdditionalFilters[key] = [...list];

    context.commit("updateState", {
      additionalTaskFilters: currAdditionalFilters,
    });
  },
  clearAdditionalFilters(context, payload = {}) {
    const { key, ignore } = payload;

    const currState = context.state;
    let currAdditionalFilters = cloneDeep(currState.additionalTaskFilters);

    if (key) {
      currAdditionalFilters[key] = [];
    } else if (ignore) {
      const keep = {};
      ignore.forEach((key) => (keep[key] = currAdditionalFilters[key]));
      currAdditionalFilters = keep;
    } else {
      currAdditionalFilters = {};
    }

    context.commit("updateState", {
      additionalTaskFilters: currAdditionalFilters,
    });
  },
  toggleContingentTasks(context, payload) {
    context.commit("updateState", {
      showContingentTasks: payload,
    });
  },
  toggleDeleteRecurringConfirmDialog(context, payload) {
    context.commit("updateState", {
      isDeleteRecurringConfirmDialogOpen: payload,
    });
  },

  setTaskGroupFilter(context, payload) {
    context.commit("updateState", {
      selectedTaskGroupFilter: payload,
    });
  },
};
