import { checkIfSelectedResourceCateHasCategoryOnly } from "@/helpers/resources";
import {
  checkIfTaskIsParentRecurringTask,
  checkIfTaskIsRecurring,
} from "@/helpers/tasks";
import { calModes, treeSideBarModes } from "@/variables/viewConfigs";
import isEmpty from "lodash/isEmpty";
import { mapGetters, mapActions } from "vuex";
import EventEmitter from "@/helpers/eventEmitter";
import {
  OPEN_INSPECT_MODE,
  OPEN_SELECTED_ITEM,
  SET_ID_FOR_SCROLL,
} from "@/variables/events";
import DatabaseInterface from "@/services/DatabaseInterface";
import { makeNodeVisible } from "@/helpers/categories";
import { mapMutatins } from "vuex";
const mixin = {
  methods: {
    ...mapActions("editForm", ["setDataForNoteEdit"]),
    ...mapActions({
      loaderToggler: "toggleLoader",
      updateRootState: "updateRootState",
    }),
    createCurrViewData({
      inspectModeEnabledFrom,
      inspectModeOpts,
      taskId,
      taskData,
      enableContingentFilter,
    } = {}) {
      let createdData = {};

      if (this.$route.name === "Dashboard") {
        const returnViewInfo = {};

        let viewItemId, viewItemDate;
        const currViewedId = taskId;

        if (!isEmpty(this.selectedTaskList)) {
          let itemSelectDate;
          let itemIdToSelect;

          const selectedTask = this.selectedTaskList[0];

          itemIdToSelect = selectedTask.key;

          if (
            selectedTask.isVirtual &&
            this.rawTasksMap[selectedTask.linkedTo]
          ) {
            itemIdToSelect = selectedTask.linkedTo;
            itemSelectDate = selectedTask.dueAsDate;
          }

          if (itemIdToSelect) {
            viewItemId = itemIdToSelect;
            viewItemDate = itemSelectDate;
            returnViewInfo.itemIdToSelect = itemIdToSelect;
            returnViewInfo.itemIdDateSelect = itemSelectDate;
          }
        }
        if (this.isInspectModeEnabled) {
          returnViewInfo.enableInspectMode = true;

          let taskData = this.tasks.find(
            (t) => t.key === this.currInspectedTaskId
          );

          if (!isEmpty(taskData)) {
            let taskIdToInspect = taskData.key;
            let taskDate;
            if (taskData.isVirtual && this.rawTasksMap[taskData.linkedTo]) {
              taskIdToInspect = taskData.linkedTo;
              taskDate = taskData.dueAsDate;
            }

            if (taskIdToInspect) {
              returnViewInfo.inspectModeConfig = {
                inspectedTaskId: taskIdToInspect,
                taskDate,
                triggeredFrom: inspectModeEnabledFrom,
                opts: inspectModeOpts,
              };
            }
          }
        }

        if (this.isNewEditFormOpened) {
          let itemIdToOpen = this.editTaskData.key;
          if (
            checkIfTaskIsRecurring(this.editTaskData) &&
            this.editTaskData.isVirtual &&
            this.rawTasksMap[this.editTaskData.linkedTo]
          ) {
            itemIdToOpen = this.editTaskData.linkedTo;

            returnViewInfo.itemDate = this.editTaskData.dueAsDate;
          }

          returnViewInfo.itemIdToOpen = itemIdToOpen;
          returnViewInfo.openForm = true;
        }

        if (currViewedId) {
          const taskDataViewed = this.tasks.find((t) => t.key === currViewedId);
          if (!isEmpty(taskDataViewed)) {
            if (
              taskDataViewed.isVirtual &&
              this.rawTasksMap[taskDataViewed.linkedTo]
            ) {
              viewItemId = taskDataViewed.linkedTo;
              viewItemDate = taskDataViewed.dueAsDate;
            } else if (!taskDataViewed.isVirtual) {
              viewItemId = taskDataViewed.key;
            }
          }
        }

        createdData = {
          ...returnViewInfo,
          viewData: {
            ...this.getCurrViewData({
              enableContingentFilter: enableContingentFilter,
            }),
          },

          linkedItemData: {
            key: viewItemId,
            date: viewItemDate,
          },
        };
      }

      if (this.$route.name === "Notes") {
        let selectedNoteId,
          viewConfig = {};
        if (!isEmpty(this.selectedNotes)) {
          selectedNoteId = this.selectedNotes[0].key;
        }
        // currViewType = "note";
        // currEditingId = this.$refs.grid.getCurrEditingRowId();

        if (this.isEditFormOpen) {
          const itemIdToOpen = this.editNoteData.key;

          viewConfig.itemIdToOpen = itemIdToOpen;
          viewConfig.openForm = true;
        }
        if (selectedNoteId) {
          viewConfig.itemIdToSelect = selectedNoteId;
        }

        createdData = {
          ...viewConfig,
        };
      }
      if (this.$route.name === "Resources") {
        let modeData = {};

        modeData.type = checkIfSelectedResourceCateHasCategoryOnly(
          this.selectedResrcCategories
        )
          ? "grid"
          : "form";

        if (modeData.type === "form") {
          modeData.resourceIdToOpen = this.selectedResrcCategories[0];
        } else if (modeData.type === "grid") {
          modeData.selectedCategories = this.selectedResrcCategories || [];
        }

        modeData.resourceType = this.selectedResourceTypeOpt;

        modeData = {
          ...modeData,
          ...this.getCurrViewData({
            enableContingentFilter: enableContingentFilter,
          }),
        };

        let viewItemId, viewItemDate;

        let taskDataToUse = taskData;

        if (isEmpty(taskDataToUse) && !isEmpty(this.selectedTaskList[0])) {
          taskDataToUse = this.selectedTaskList[0];
        }

        if (!isEmpty(taskDataToUse)) {
          const taskDataViewed = { ...taskDataToUse };
          if (
            taskDataViewed.isVirtual &&
            this.rawTasksMap[taskDataViewed.linkedTo]
          ) {
            viewItemId = taskDataViewed.linkedTo;
            viewItemDate = taskDataViewed.dueAsDate;
          } else if (!taskDataViewed.isVirtual) {
            viewItemId = taskDataViewed.key;
          }
        }
        createdData = {
          modeData,
          taskData: {
            key: viewItemId,
            date: viewItemDate,
          },
          goBackToResourceMode: true,
        };
      }

      const res = Object.freeze({
        ...createdData,
      });

      return res;
    },
    getCurrViewData() {
      let currViewData = {};

      if (this.$route.name === "Dashboard") {
        let currNav = this.currNav;

        const enableGlobalView = this.isGlobalViewEnabled;
        const enableInboxView = this.isInboxViewEnabled;
        const showClearedTasks = this.showClearedTasks;
        const enableProjectsMode = this.isProjectsResourceModeEnabled;
        currViewData = {
          currNav: currNav,
          enableGlobalView,
          enableInboxView,
          showClearedTasks,
          enableProjectsMode,
        };

        if (calModes.includes(this.currNav)) {
          currViewData.selectedDates = this.selectedDatesList;
          currViewData.selectedCalForecastType = this.selectedCalForecastType;
        }

        if (treeSideBarModes.includes(this.currNav)) {
          currViewData.selectedOptsList = this.selectedOptsList;
        }

        currViewData.showContingentTasks = this.showContingentTasks;
        currViewData.currTaskGroupFilter = this.selectedTaskGroupFilter;
      }

      currViewData.taskFilters = !isEmpty(this.taskFiltersMap)
        ? this.taskFiltersMap
        : {};
      currViewData.additionalTaskFilters = !isEmpty(
        this.taskAdditionalFiltersMap
      )
        ? this.taskAdditionalFiltersMap
        : {};

      if (
        this.$route.name === "Resources" ||
        this.isProjectsResourceModeEnabled
      ) {
        currViewData.resourceFiltersList = !isEmpty(
          this.selectedFilterResources
        )
          ? this.selectedFilterResources
          : [];

        if (this.isProjectsResourceModeEnabled) {
          const modeData = {};
          modeData.type = checkIfSelectedResourceCateHasCategoryOnly(
            this.selectedResrcCategories
          )
            ? "grid"
            : "form";

          if (modeData.type === "form") {
            modeData.resourceIdToOpen = this.selectedResrcCategories[0];
          } else if (modeData.type === "grid") {
            modeData.selectedCategories = this.selectedResrcCategories || [];
          }

          modeData.resourceType = "project";

          currViewData.resourceModeData = modeData;
        }

        currViewData.currSubTaskGroupFilter = this.subTasksListGroupFilter;
      }

      return currViewData;
    },
    async handleNotesViewConfig(viewData) {
      let closeLoader = true;
      let dbUpdates = {};
      let viewConfig = {};
      let routeName = "";
      if (!isEmpty(viewData)) {
        if (viewData.to !== undefined) {
          const { to, ...restConfig } = viewData;
          routeName = to;
          viewConfig = { ...restConfig };
          if (to === "Dashboard") {
            dbUpdates = {
              notesView: false,
              activeNavView: "dashboard",
            };
          } else if (to === "Resources") {
            dbUpdates = {
              notesView: false,
              activeNavView: "resources",
            };
          }
          // }

          if (!isEmpty(dbUpdates)) {
            DatabaseInterface.update(dbUpdates, this.userInfo.uid);
          }

          this.$router.replace({
            name: routeName,
            params: {
              viewConfig: {
                ...viewConfig,
              },
            },
          });
        } else {
          const { openForm, itemIdToOpen, itemIdToSelect } = viewData;

          if (!openForm && itemIdToSelect) {
            const noteIdToSelect = this.notesList.find(
              (n) => n.key === itemIdToSelect
            )?.key;

            if (noteIdToSelect) {
              EventEmitter.emit(SET_ID_FOR_SCROLL, noteIdToSelect, true);
            }
          }
          if (openForm && itemIdToOpen) {
            closeLoader = false;
            const noteData = this.notesList.find((n) => n.key === itemIdToOpen);

            await this.$nextTick();
            setTimeout(() => {
              if (!isEmpty(noteData)) {
                if (!this.isEditFormOpen) {
                  this.setDataForNoteEdit({
                    dataToSet: { ...noteData },
                  });
                } else {
                  EventEmitter.emit(OPEN_SELECTED_ITEM, noteData);
                }
              }
            }, 0);
          }

          this.updateRootState({
            currViewData: {},
            itemInspectEnabled: false,
          });
          //
        }

        if (closeLoader) {
          this.loaderToggler(false);
        }
      }
    },

    // async handleDashboardViewConfig(viewConfig) {
    //   let closeLoader = true;
    //   if (!isEmpty(viewConfig)) {
    //     const {
    //       openForm,
    //       itemIdToOpen,
    //       itemDate,
    //       enableInspectMode,
    //       inspectModeConfig,
    //       itemIdToSelect,
    //       itemIdDateSelect,
    //       viewData,
    //       doNotClearViewData,
    //     } = viewConfig;
    //     let finalTaskIdToUse;

    //     let dbUpdates = {};
    //     let localUpdates = {};
    //     let resourceStateUpdates = {};
    //     let makeCategoryNodeVisible = false;
    //     if (!isEmpty(viewData)) {
    //       let navToUse = viewData.currNav;

    //       if (treeSideBarModes.includes(navToUse) && viewData.enableInboxView) {
    //         navToUse = 1;
    //       }
    //       dbUpdates.nav = navToUse;

    //       if (viewData.selectedDates !== undefined) {
    //         dbUpdates["/view/0/selectedDates"] = viewData.selectedDates || [];
    //       }
    //       if (viewData.selectedCalForecastType !== undefined) {
    //         dbUpdates["/view/0/selectedForecastType"] =
    //           viewData.selectedCalForecastType || "";
    //       }

    //       if (viewData.selectedOptsList !== undefined) {
    //         makeCategoryNodeVisible = true;
    //         dbUpdates[`/view/1/selectedOptsList`] =
    //           viewData.selectedOptsList || [];
    //       }
    //       if (viewData.enableGlobalView) {
    //         dbUpdates.globalView = true;
    //       }

    //       if (viewData.enableProjectsMode !== undefined) {
    //         dbUpdates.isProjectsResourceModeEnabled =
    //           viewData.enableProjectsMode;

    //         localUpdates.isProjectsResourceModeEnabled =
    //           viewData.enableProjectsMode;
    //       }
    //       if (viewData.enableInboxView) {
    //         dbUpdates.inboxView = true;
    //       }

    //       if (viewData.showContingentTasks) {
    //         localUpdates.showContingentTasks = true;
    //       }

    //       if (!isEmpty(viewData.taskFilters)) {
    //         localUpdates.filters = viewData.taskFilters;
    //       }

    //       if (!isEmpty(viewData.additionalTaskFilters)) {
    //         localUpdates.additionalTaskFilters = viewData.additionalTaskFilters;
    //       }

    //       if (!isEmpty(viewData?.resourceFiltersList)) {
    //         resourceStateUpdates.filterResources =
    //           viewData?.resourceFiltersList;
    //       }
    //     }

    //     if (openForm) {
    //       localUpdates.ignoreAreaCheck = true;
    //     }

    //     this.updateState({ ...localUpdates });
    //     this.setResourceStateData({ ...resourceStateUpdates });
    //     if (!isEmpty(dbUpdates)) {
    //       DatabaseInterface.update(dbUpdates, this.userInfo.uid);
    //     }

    //     if (!openForm && itemIdToSelect) {
    //       if (itemIdToSelect && this.rawTasksMap[itemIdToSelect]) {
    //         let finalTaskId = itemIdToSelect;
    //         if (
    //           checkIfTaskIsParentRecurringTask(this.rawTasksMap[itemIdToSelect])
    //         ) {
    //           finalTaskId = this.getFirstVirtualReccuringTask(
    //             itemIdToSelect,
    //             itemIdDateSelect
    //           )?.key;
    //         }

    //         finalTaskIdToUse = finalTaskId;
    //         if (finalTaskIdToUse) {
    //           EventEmitter.emit(SET_ID_FOR_SCROLL, finalTaskIdToUse);
    //         }
    //       }
    //     }

    //     if (enableInspectMode && !isEmpty(inspectModeConfig)) {
    //       //
    //       makeCategoryNodeVisible = false;
    //       const {
    //         inspectedTaskId,
    //         triggeredFrom,
    //         taskDate,
    //         opts: inspectModeOpts,
    //       } = inspectModeConfig;

    //       let taskDataToUse = {};

    //       if (inspectedTaskId && this.rawTasksMap[inspectedTaskId]) {
    //         if (
    //           checkIfTaskIsParentRecurringTask(
    //             this.rawTasksMap[inspectedTaskId]
    //           )
    //         ) {
    //           taskDataToUse = this.getFirstVirtualReccuringTask(
    //             inspectedTaskId,
    //             taskDate
    //           );
    //         } else {
    //           taskDataToUse = this.tasks.find((t) => t.key === inspectedTaskId);
    //         }
    //       }

    //       if (!isEmpty(taskDataToUse)) {
    //         EventEmitter.emit(OPEN_INSPECT_MODE, {
    //           triggeredFrom: triggeredFrom || "func",
    //           taskData: { ...taskDataToUse },
    //           opts: inspectModeOpts,
    //         });
    //       }
    //     }
    //     if (openForm && itemIdToOpen && this.rawTasksMap[itemIdToOpen]) {
    //       let taskDataToUse = {};
    //       closeLoader = false;
    //       if (
    //         checkIfTaskIsParentRecurringTask(this.rawTasksMap[itemIdToOpen])
    //       ) {
    //         taskDataToUse = this.getFirstVirtualReccuringTask(
    //           itemIdToOpen,
    //           itemDate
    //         );
    //       } else {
    //         taskDataToUse = this.tasks.find((t) => t.key === itemIdToOpen);
    //       }

    //       await this.$nextTick();

    //       setTimeout(() => {
    //         if (!isEmpty(taskDataToUse)) {
    //           this.openTaskInEditForm(taskDataToUse, undefined, true, true);
    //           // this.openTaskDataInEditForm({
    //           //   taskData: taskDataToUse,
    //           //   doNotEnableInspect: true,
    //           // });
    //         }
    //       }, 0);
    //     }

    //     if (makeCategoryNodeVisible && !isEmpty(viewData.selectedOptsList)) {
    //       makeNodeVisible(viewData.selectedOptsList[0].id);
    //     }

    //     if (!doNotClearViewData) {
    //       this.storeCurrViewData({});
    //       this.updateRootState({
    //         itemInspectEnabled: false,
    //       });
    //     }
    //   }

    //   if (closeLoader) {
    //     this.loaderToggler(false);
    //   }
    // },

    // ...mapMutatins("task", ["updateState"]),
    // ...mapActions(["updateRootState", "storeCurrViewData"]),
  },
  computed: {
    ...mapGetters("task", {
      selectedTaskList: "selectedTasks",
      selectedDatesList: "selectedDates",
      rawTasksMap: "rawTasksMap",
      selectedOptsList: "selectedOptsList",
      currInspectedTaskId: "currInspectedTaskId",
      tasks: "tasks",
      isInspectModeEnabled: "isInspectModeEnabled",
      currNav: "currNav",
      isInboxViewEnabled: "isInboxViewEnabled",
      isGlobalViewEnabled: "isGlobalViewEnabled",
      showClearedTasks: "showClearedTasks",
      taskAdditionalFiltersMap: "additionalTaskFilters",
      taskFiltersMap: "filters",
      showContingentTasks: "showContingentTasks",
      isProjectsResourceModeEnabled: "isProjectsResourceModeEnabled",
      selectedTaskGroupFilter: "selectedTaskGroupFilter",
    }),
    ...mapGetters("editForm", {
      editTaskData: "taskData",
      editNoteData: "noteData",
      isEditFormOpen: "isOpen",
      isNewEditFormOpened: "openNewForm",
    }),
    ...mapGetters("note", {
      selectedNotes: "selectedNotes",
      notesList: "notes",
    }),
    ...mapGetters("resourcesData", {
      selectedResrcCategories: "selectedCategories",
      selectedResourceTypeOpt: "selectedResourceTypeOpt",
      selectedFilterResources: "filterResources",
    }),
    ...mapGetters(["subTasksListGroupFilter"]),
  },
  // watch: {
  //   selectedTaskList: {
  //     handler(n, o) {
  //       console.log("SELECT CHANGED", n, o);
  //     },
  //   },
  // },
};

export default mixin;
