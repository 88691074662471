<template>
  <div
    class="fill-height task-list-view-container"
    :class="{ 'hide-row-drag': !sidebarOpen }"
  >
    <div class="fill-height" v-show="!isProjectsResourceModeEnabled">
      <div v-show="isTasksGridActive" class="fill-height">
        <TasksList ref="tasksListWrapper" :isActive="isTasksGridActive" />
      </div>
      <div v-show="isTaskFormActive" class="fill-height">
        <NewTaskEditForm :isActive="isTaskFormActive" />
      </div>
    </div>

    <div class="fill-height" v-show="isProjectsResourceModeEnabled">
      <div v-show="!isResourceInfoViewOpen" class="fill-height">
        <ResourcesTableWrapper
          v-if="resourcesLoaded && appDataLoaded"
          ref="grid"
          :isActive="
            resourcesLoaded &&
            appDataLoaded &&
            isProjectsResourceModeEnabled &&
            !isResourceInfoViewOpen
          "
        />
      </div>
      <div v-show="isResourceInfoViewOpen" class="fill-height">
        <ResourceInfoEditor :isActive="isResourceInfoViewOpen" />
      </div>
    </div>

    <!-- <div v-show="isResourceInfoViewOpen">
      <ResourceInfoEditor sendRefreshEvent />
    </div> -->
    <OptSelectorDialog />
    <AreaPrioritySetterDialog />
    <ResourceInfoPopUp
      ref="resourcePopUp"
      :isVisible="showMentionInfo"
      :resourceData="mentionInfo"
      :showNotFoundError="showNoMentionFoundError"
    />
    <DeleteRecurringTaskConfirmDialog />
  </div>
</template>

<script>
import Vue from "vue";
import firebase from "@/services/firebase";
import DatabaseInterface from "@/services/DatabaseInterface";
import TasksList from "./components/TasksList/TasksList.vue";
import TaskEditForm from "@/components/TaskEditForm/TaskEditForm.vue";
import NewTaskEditForm from "@/components/TaskEditForm/NewTaskEditForm.vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import {
  ACTION_ON_TYPES,
  CATEGORY_ACTIONS,
  createUserActionsHistory,
  getUserActions,
  NOTES_ACTIONS,
  OBJECTIVE_ACTIONS,
  PROJECT_ACTIONS,
  TASK_ACTIONS,
} from "@/helpers/actionHistory";
import {
  addOrRemoveOrUpdateTasksInDb,
  addOrRemoveOrUpdateTasksInLocalTasksList,
  addRecurringTasksInfoInMap,
  areTaskRecurrenceRulesValid,
  checkIfTaskHasReminder,
  checkIfTaskIsRecurring,
  createDateTimeFromDue,
  createReminderTasks,
  createRestoreTaskUpdatesData,
  createTaskDataForGoogleCal,
  createVirtualTasksFromRecurringTasks,
  getDateAndTimeFromDueDate,
  getMainTaskRulesAndFillRecurringOpts,
  getRecurringTasks,
  getReminderTasksLinkedToTaskId,
  getUpdatedTaskProps,
  isCalenderPropsChanged,
  isModeInterChangableForInspectMode,
  isTaskVirtual,
  checkIfTaskIsParentRecurringTask,
} from "@/helpers/tasks";
import { createRestoreProjectUpdateData } from "@/helpers/projects";
import userDetailsMixin from "@/mixins/userDetailsMixin";
import categoriesUnoRedoHelpersMixin from "@/mixins/categoriesUndoRedoHelpersMixins";
import { processTask, fieldRequriesProcessing } from "@/helpers/tasks";
import tasksHelpersMixin from "@/mixins/tasksHelpersMixin";
import {
  isUndoHotKeys,
  isRedoHotKeys,
  isDeleteKey,
  isEscKey,
} from "@/utils/hotKeys";
import EventEmitter from "@/helpers/eventEmitter";
import {
  OPEN_INSPECT_MODE,
  REDO_EVENT,
  REFRESH_FORM_DATA,
  SET_ID_FOR_SCROLL,
  SET_SELECTED_NODE_EVENT,
  SYNC_COMPLETED,
  UNDO_EVENT,
  OPEN_STATUS_EDIT_MODE,
  TOGGLE_ORDERED_TASKS,
  REFRESH_ACTIVITY_HISTORY,
} from "@/variables/events";
import cloneDeep from "lodash/cloneDeep";
const fieldWhichRequiresProcessing = [
  "project",
  "flag",
  "due",
  "isContingent",
  "time",
  "reminderDays",
  "pausedReminderDays",
  "completed",
  "isCustomPositioned",
  "customPosition",
  "customPositionIndex",
  "taskType",
  "title",
];
import ResourceInfoPopUp from "@/components/ResourceInfoPopUp";
import ResourceInfoEditor from "@/components/ResourceInfoEditor";
import isEqual from "fast-deep-equal";
import isEmpty from "lodash/isEmpty";
import {
  removeTaskFromGoogleCal,
  restoreTasksInGoogleCal,
  updateTasksInGoogleCal,
} from "@/mixins/googleCalHelpersMixins";

import removeProps from "@/utils/removeProps";
import {
  booleanPropsOfTask,
  orderPropsOfTask,
  RECURRENCE_TASK_PROPS,
  taskRestoreProps,
} from "@/variables/tasks";
import OptSelectorDialog from "@/components/OptSelectorDialog";
import AreaPrioritySetterDialog from "@/components/AreaPrioritySetterDialog";
import { calModes, treeSideBarModes } from "@/variables/viewConfigs";
import {
  changeCatOfProjectResources,
  getProjectResourcesFromText,
  initUserResources,
  resourcesCollection,
} from "@/helpers/resources";
import metionsHelpersMixin from "@/mixins/mentionsHelpers";
import notesUndoRedoHelpersMixin from "@/mixins/notesUndoAndRedoHelpersMixin";
import resourcesUndoRedoHelpersMixin from "@/mixins/resourcesUndoRedoHelpersMixin";
import resourceCateUndoRedoHelpersMixin from "@/mixins/resourcesCateUndoRedoHelpersMixin";
import {
  addMultiNotesInNotesList,
  removeNotesFromNotesList,
} from "@/mixins/notesHelpersMixin";
import objectiveUndoRedoHelpersMixin from "@/mixins/objectivesUndoRedoHelpersMixins";
import { getCurrDate } from "@/helpers/dates";
import { getAreasTipStatus } from "@/helpers/tips";
import { getAppColorThemeFromUserData } from "@/helpers/app";
import DeleteRecurringTaskConfirmDialog from "@/components/DeleteRecurringTaskConfirmDialog.vue";
import resourceHelpersMixin from "@/mixins/resourcesHelpersMixin";
import ResourcesTableWrapper from "@/components/ResourcesListView/components/ResourcesTableWrapper/ResourcesTableWrapper.vue";
export default {
  mixins: [
    userDetailsMixin,
    tasksHelpersMixin,
    resourceHelpersMixin,
    categoriesUnoRedoHelpersMixin,
    metionsHelpersMixin,
    objectiveUndoRedoHelpersMixin,
    notesUndoRedoHelpersMixin,
    resourcesUndoRedoHelpersMixin,
    resourceCateUndoRedoHelpersMixin,
  ],
  components: {
    TaskEditForm,
    TasksList,
    OptSelectorDialog,
    ResourceInfoPopUp,
    // eslint-disable-next-line vue/no-unused-components
    ResourceInfoEditor,
    AreaPrioritySetterDialog,
    NewTaskEditForm,
    DeleteRecurringTaskConfirmDialog,
    ResourcesTableWrapper,
  },
  data() {
    return {
      dataLoaded: false,
      resourcesLoaded: false,
      makeAllTasksVisible: true,
    };
  },
  provide() {
    const toggleData = {};

    Object.defineProperty(toggleData, "showAllTasks", {
      enumerable: true,
      get: () => this.makeAllTasksVisible,
    });
    return {
      toggleData,
    };
  },

  computed: {
    ...mapState(["sidebarOpen"]),
    ...mapGetters("task", [
      "projects",
      "categories",
      "objectivesMap",
      "rawTasks",
      "tasks",
      "rawTasksMap",
      "recurringTasksMap",
      "selectedTasks",
      "isInspectModeEnabled",
      "isFilterBarOptsEnabled",
      "isInboxViewEnabled",
      "currNav",
      "isProjectsResourceModeEnabled",
    ]),
    ...mapGetters("editForm", {
      isEditTaskFormOpen: "isOpen",
      editTaskData: "taskData",
      openNewForm: "openNewForm",
    }),
    ...mapGetters("resourceInfo", {
      isResourceInfoViewOpen: "isOpen",
      editedResourceData: "data",
    }),
    ...mapGetters("resourcesData", {
      rawResourcesMap: "rawResourcesMap",
      resourcesCategories: "categories",
    }),
    ...mapGetters(["isGoogleCalSyncEnabled", "currViewData"]),
    isInNextActionsMode() {
      return (
        treeSideBarModes.includes(this.currNav) && !this.isInboxViewEnabled
      );
    },
    isTasksGridActive() {
      return (
        !this.isEditTaskFormOpen && !this.openNewForm && this.appDataLoaded
      );
    },
    isTaskFormActive() {
      return !this.isEditTaskFormOpen && this.openNewForm && this.appDataLoaded;
    },
    appDataLoaded() {
      return this.dataLoaded && this.resourcesLoaded;
    },
  },
  methods: {
    ...mapMutations(["setLoader"]),
    ...mapActions("editForm", ["setTaskData"]),
    handleToggle(value) {
      this.makeAllTasksVisible =
        value !== undefined ? value : !this.makeAllTasksVisible;
    },
    async onDataChange(tmp) {
      let updates = {
        inboxViewEnabled: tmp?.inboxView,
        globalViewEnabled: tmp?.globalView,
        nav: tmp?.nav,
        showClearedTasks: tmp?.showCompleted || false,
        groupMode: tmp?.view[tmp.nav]?.groupView || 0,
        collapsedGroups: tmp?.view[tmp.nav]?.collapsedGroups || [],
        isProjectsResourceModeEnabled: tmp?.isProjectsResourceModeEnabled,
        // openedCategories: tmp.view[tmp.nav]?.openedCategories || [],
      };

      const rootStateUpdates = {
        resourceSettings: tmp.resourceSettings || {},
        taskSettings: tmp.taskSettings || {},
        editFormSettings: tmp.editFormSettings || {},
        selectedColorTheme: getAppColorThemeFromUserData(tmp),
      };

      const newObectivesMap = tmp.objectives || {};
      const newProjects = tmp.projects || {};
      const newCategories = tmp.categories || {};
      const currObjectivesMap = this.objectivesMap;

      if (tmp.inboxView || tmp.globalView) {
        const navToUse = tmp.globalView ? 6 : 5;
        updates.groupMode = tmp.view[navToUse]?.groupView || 3;
        updates.collapsedGroups = tmp.view[navToUse]?.collapsedGroups || [];
      }

      if (calModes.includes(tmp.nav)) {
        let valToUse = tmp.view[tmp.nav].selectedTypes || [];
        if (tmp.nav === 3 && isEmpty(valToUse)) {
          valToUse = [];
          // valToUse = ["today"];
        } else if (tmp.nav === 0 && isEmpty(valToUse)) {
          valToUse = [];
        }

        // if (tmp.nav === 0) {
        //   updates.selectedCalForecastType =
        //     tmp.view[tmp.nav].selectedForecastType || "";
        // }

        updates.selectedCalForecastType =
          tmp.nav === 0 && tmp.view[tmp.nav]?.selectedForecastType
            ? tmp.view[tmp.nav].selectedForecastType
            : "";
        updates.contingentFilter = valToUse;
      }

      if (treeSideBarModes.includes(tmp.nav) && !tmp.inboxView) {
        const taskFilterVal = tmp.view[tmp.nav].selectedFilter || "";
        const taskDateFilterVal = tmp.view[tmp.nav].selectedDateFilter || "";

        updates.selectedTaskFilter = taskFilterVal;
        updates.selectedTaskDateFilter = taskDateFilterVal;
      }

      const selectedDates = tmp.view[tmp.nav].selectedDates || [];

      if (!isEqual(currObjectivesMap, newObectivesMap)) {
        updates.objectivesMap = newObectivesMap;
      }

      if (!isEqual(this.projects, newProjects)) {
        updates.projects = newProjects;
      }

      if (!isEqual(this.categories, newCategories)) {
        updates.categories = newCategories;
      }

      if (!isEqual(this.selectedDates, selectedDates)) {
        updates.selectedDates = selectedDates;
      }

      if (treeSideBarModes.includes(tmp.nav)) {
        const selectedOptsList = tmp.view[1].selectedOptsList || [];
        if (!isEqual(this.selectedOptsList, selectedOptsList)) {
          updates.selectedOptsList = selectedOptsList;
        }

        updates.selectedOptsByFilterBar =
          tmp.view[1].selectedByFilterBar || false;
      }

      if (this.isInspectModeEnabled) {
        let propsToRemove = [
          "groupMode",
          "selectedDates",
          "selectedOptsList",
          "inboxViewEnabled",
          "globalViewEnabled",
          "showClearedTasks",
          "nav",
          "collapsedGroups",
          "openedCategories",
          "contingentFilter",
          "selectedTaskFilter",
          "selectedTaskDateFilter",
        ];

        if (isModeInterChangableForInspectMode(this.currNav, tmp.nav)) {
          // propsToRemove.splice(propsToRemove.indexOf("groupMode"), 1);
          // propsToRemove.splice(propsToRemove.indexOf("nav"), 1);
          propsToRemove.splice(propsToRemove.indexOf("collapsedGroups"), 1);
        }
        updates = removeProps(updates, propsToRemove);
      }

      if (this.isFilterBarOptsEnabled) {
        updates = removeProps(updates, [
          "selectedOptsList",
          "openedCategories",
        ]);
      }

      this.updateState(updates);

      if (!this.isInspectModeEnabled) {
        rootStateUpdates.currNav = tmp.nav;
        // this.updateRootState({
        //   currNav: tmp.nav,
        // });
      }

      this.updateRootState({
        ...rootStateUpdates,
      });

      await this.$nextTick();
      let rawTasks = Object.values(tmp.tasks || {});

      for (const taskId in tmp.tasks) {
        const taskData = tmp.tasks[taskId];

        if (!taskData.key) {
          console.debug("TA", taskId);
        }
      }
      if (!isEqual(this.rawTasks, rawTasks)) {
        const updates = {
          rawTasks,
          rawTasksMap: tmp.tasks,
        };

        if (isEmpty(this.tasks)) {
          let tasks = [];
          let recurringVirtualTasks = [];
          let recurringTasksMap = {};
          let reminderTasksList = [];
          rawTasks.forEach((task) => {
            if (!task.key) return;
            const taskIsRecurring = checkIfTaskIsRecurring(task);
            if (taskIsRecurring) {
              if (areTaskRecurrenceRulesValid(task.recurrence)) {
                const { tasks: virtualTasks, datesList } =
                  createVirtualTasksFromRecurringTasks(task);

                recurringVirtualTasks.push(...virtualTasks);
                recurringTasksMap[task.key] = datesList;
              } else if (task.linkedTo) {
                tasks.push(processTask(task));
                reminderTasksList.push(...createReminderTasks(task));
              }
              // Soemthing for recurring
            } else {
              tasks.push(processTask(task));
              reminderTasksList.push(...createReminderTasks(task));
            }
          });

          updates.recurringTasksMap = recurringTasksMap;
          updates.tasks = tasks.concat(
            [...recurringVirtualTasks],
            [...reminderTasksList]
          );
        }

        this.updateState(updates);
      } else {
        rawTasks = null;
      }

      if (!this.dataLoaded) {
        this.dataLoaded = true;

        this.updateState({
          isTasksDataLoaded: true,
        });
      }
    },
    async handleAddTaskAction(actionData, type = "undo") {
      let taskDataForEditForm = {};
      let tasksToAdd = [];
      let recurringTasksMap = {};
      let localTasksToRemove = [];
      let reminderTasksToRemove = [];
      if (type === "undo") {
        const recurrence = actionData.recurrence;
        const restoreOldTaskIdData = actionData.restoreOldIdData;
        await this.removeTasksFromTaskList(actionData, false);
        if (areTaskRecurrenceRulesValid(recurrence)) {
          let tasksToRemove = [];
          let localTasksToRemove = [];
          let googleTasksToRemove = [];

          const currTaskData = actionData;
          const recurringTasks = getRecurringTasks(this.tasks, "list", {
            linkedTo: currTaskData.key,
          });

          if (!isEmpty(recurringTasks)) {
            recurringTasks.forEach((t) => {
              const isTaskVirtual = t.isVirtual;
              // const isFromGoogle = t.isFromGoogle;

              const linkedReminders = getReminderTasksLinkedToTaskId(
                this.tasks,
                t.key
              );

              if (!isEmpty(linkedReminders)) {
                reminderTasksToRemove.push(...linkedReminders);
              }
              tasksToRemove.push(t);
              localTasksToRemove.push(t);
              if (!isTaskVirtual) {
                googleTasksToRemove.push(t);
                // if (isFromGoogle) {
                //   googleIdToUse = createGoogleEventIdByDateAndTaskId(
                //     currTaskData.googleEventId.split("_")[0],
                //     currTaskData.dueAsDate
                //   );
                // } else {
                //   googleIdToUse = createGoogleEventIdByDateAndTaskId(
                //     currTaskData.linkedTo,
                //     currTaskData.dueAsDate
                //   );
                // }
              }
            });
          }

          addOrRemoveOrUpdateTasksInDb({
            tasksToRemove: tasksToRemove,
          });
          addOrRemoveOrUpdateTasksInLocalTasksList({
            tasksToRemove: [...localTasksToRemove, ...reminderTasksToRemove],
          });

          removeTaskFromGoogleCal(googleTasksToRemove);

          this.replaceRecurringTasksInfoInMap({
            list: [
              {
                mainTaskId: currTaskData.key,
                tasks: [],
              },
            ],
          });
        }

        if (!isEmpty(restoreOldTaskIdData)) {
          const taskKey = restoreOldTaskIdData.key;
          const restoreType = restoreOldTaskIdData.restoreFrom;
          let listToGetFrom =
            restoreType === "raw" ? this.rawTasks : this.tasks;
          taskDataForEditForm = listToGetFrom.find((t) => t.key === taskKey);
        }
      }

      if (type === "redo") {
        const recurrence = actionData.recurrence;
        const recurrenceRulesAreValid = areTaskRecurrenceRulesValid(recurrence);
        let dataForGoogle = { ...actionData };
        if (recurrenceRulesAreValid) {
          dataForGoogle.extraData = {
            recurrence,
          };
        }

        this.restoreGoogleCalTasks({ ...dataForGoogle });
        await this.fillTaskDataThenAddInList(actionData, false);

        const currRawData = { ...actionData };
        taskDataForEditForm = { ...currRawData };
        if (recurrenceRulesAreValid) {
          const { tasks: recurringTasks, datesList: recurringTasksList } =
            createVirtualTasksFromRecurringTasks([{ ...currRawData }]);

          taskDataForEditForm = recurringTasks[0];

          tasksToAdd.push(...recurringTasks);
          recurringTasksMap.mainTaskId = currRawData.key;
          recurringTasksMap.tasksToAdd = recurringTasksList;
          localTasksToRemove.push({ ...currRawData });

          addOrRemoveOrUpdateTasksInLocalTasksList({
            tasksToAdd,
            tasksToRemove: localTasksToRemove,
          });

          addRecurringTasksInfoInMap(
            recurringTasksMap.mainTaskId,
            recurringTasksMap.tasksToAdd
          );
        }
      }

      if (!isEmpty(taskDataForEditForm)) {
        if (!isEmpty(this.taskStoredInResourceForm)) {
          this.refreshTaskDataInResourceForm(taskDataForEditForm.key);
        } else if (!isEmpty(this.editTaskData)) {
          this.setTaskData(
            processTask(
              getMainTaskRulesAndFillRecurringOpts(taskDataForEditForm)
            )
          );

          await this.$nextTick();
          EventEmitter.emit(REFRESH_FORM_DATA, true);
        }
      }
    },
    async handleTaskBatchActions(actionDataList, isRedoAction) {
      for (const actionData of actionDataList) {
        if (isRedoAction) {
          await this.handleTaskRedoAction(actionData);
        } else {
          await this.handleTaskUndoAction(actionData);
        }
      }
    },
    async handleTasksConvertedToNotes(actionData, isRedoAction) {
      const { tasksToRemoveData, notesToAddData } = actionData;

      if (!isEmpty(tasksToRemoveData)) {
        if (isRedoAction) {
          this.removeRecurringOrNormalTasksFromMap(tasksToRemoveData);
        } else {
          this.restoreRemovedNormalOrRecurringTasks(tasksToRemoveData);
        }
      }

      if (!isEmpty(notesToAddData)) {
        if (isRedoAction) {
          addMultiNotesInNotesList(notesToAddData, false);
        } else {
          removeNotesFromNotesList(notesToAddData, false);
        }
      }
    },
    async handleTaskUndoAction(currAction) {
      switch (currAction.type) {
        case TASK_ACTIONS.ADD: {
          await this.handleAddTaskAction(currAction.data, "undo");
          break;
        }
        case TASK_ACTIONS.BATCH_ADD:
          await this.removeTasksFromTaskList(currAction.data, false);
          break;
        case TASK_ACTIONS.EDIT:
          await this.restoreTaskData(currAction.data);
          break;
        case TASK_ACTIONS.BATCH_EDIT:
          await this.restoreMultiTaskData(currAction.data);
          break;
        case TASK_ACTIONS.BATCH_DELETE:
          this.restoreGoogleCalTasks(currAction.data);
          await this.fillTaskDataThenAddInList(currAction.data, false);
          break;
        case TASK_ACTIONS.NORMAL_RECURRING_EDIT:
          this.restoreNormalAndRecurringTaskData(currAction.data);
          break;
        case TASK_ACTIONS.NORMAL_RECURRING_DELETE:
          this.restoreRemovedNormalOrRecurringTasks(currAction.data);
          break;
        case TASK_ACTIONS.NORMAL_TO_RECURRING:
        case TASK_ACTIONS.REPEAT_EVERY_TO_NORMAL:
        case TASK_ACTIONS.REPEAT_EVERY_TO_REPEAT_IN:
          this.restoreNormalTask(currAction.data);
          break;
        case TASK_ACTIONS.ACTIONS_BATCH:
          this.handleTaskBatchActions(currAction.data);
          break;
        case TASK_ACTIONS.GROUP_OR_REORDER_BATCH:
          await this.restoreMultiTaskData(currAction.data);
          break;
        // case TASK_ACTIONS.REPEAT_EVERY_TO_REPEAT_IN:
        //   await this.handleRepeatEveryToRepeatInConvert(currAction.data);
        //   break;
        case TASK_ACTIONS.CONVERTED_TO_NOTES:
          await this.handleTasksConvertedToNotes(currAction.data);
          break;
      }
    },
    async handleProjectUndoAction(currAction) {
      switch (currAction.type) {
        case PROJECT_ACTIONS.EDIT:
        case PROJECT_ACTIONS.BATCH_EDIT:
          await this.restoreCategoriesData(currAction.data);
          break;
        case PROJECT_ACTIONS.DELETE:
        case PROJECT_ACTIONS.BATCH_DELETE:
          await this.handleProjectAddAction(
            currAction.data,
            this.userInfo.uid,
            false
          );
          break;
        case PROJECT_ACTIONS.ADD:
          await this.handleProjectRemoveAction(currAction.data);
          // await removeProject(currAction.data, this.userInfo.uid, false);
          break;
        // await handleProjectAddAc
      }
    },
    handleCategoryUndoAction(currAction) {
      switch (currAction.type) {
        case CATEGORY_ACTIONS.EDIT:
        case CATEGORY_ACTIONS.BATCH_EDIT:
          this.restoreCategoriesData(currAction.data);
          break;
        case CATEGORY_ACTIONS.ADD:
        case CATEGORY_ACTIONS.BATCH_ADD:
          this.handleRemoveCategoryAction(currAction.data);
          // await removeCategories(
          //   cloneDeep(currAction.data),
          //   this.userInfo.uid,
          //   false
          // );
          break;
        case CATEGORY_ACTIONS.DELETE:
        case CATEGORY_ACTIONS.BATCH_DELETE:
          this.handleAddCategoryAction(
            currAction.data,
            this.userInfo.uid,
            false
          );
          break;
        case CATEGORY_ACTIONS.REORDER_OR_MOVE:
          this.restoreCategoriesData(currAction.data);
          break;
      }
    },
    async handleCategoryRedoAction(currAction) {
      switch (currAction.type) {
        // case CATEGORY_ACTIONS.EDIT:
        //   await this.restoreProjectData(currAction.data);
        //   break;
        case CATEGORY_ACTIONS.ADD:
        case CATEGORY_ACTIONS.BATCH_ADD:
          await this.handleAddCategoryAction(
            currAction.data,
            this.userInfo.uid,
            false
          );
          break;
        case CATEGORY_ACTIONS.DELETE:
        case CATEGORY_ACTIONS.BATCH_DELETE:
          await this.handleRemoveCategoryAction(cloneDeep(currAction.data));
          break;
        case CATEGORY_ACTIONS.EDIT:
        case CATEGORY_ACTIONS.BATCH_EDIT:
        case CATEGORY_ACTIONS.REORDER_OR_MOVE:
          await this.restoreCategoriesData(currAction.data, true);
          break;
      }
    },
    async handleObjectiveUndoAction(currAction) {
      switch (currAction.type) {
        case OBJECTIVE_ACTIONS.EDIT:
        case OBJECTIVE_ACTIONS.BATCH_EDIT:
          await this.restoreObjectiveData(currAction.data);
          break;
      }
    },
    async handleObjectiveRedoAction(currAction) {
      switch (currAction.type) {
        case OBJECTIVE_ACTIONS.EDIT:
        case OBJECTIVE_ACTIONS.BATCH_EDIT:
          await this.restoreObjectiveData(currAction.data, true);
          break;
      }
    },
    async handleAllBatchAction(allActionData, isRedoAction) {
      for (const actionData of allActionData) {
        if (actionData.on === ACTION_ON_TYPES.TASK) {
          if (isRedoAction) {
            await this.handleTaskRedoAction(actionData);
          } else {
            await this.handleTaskUndoAction(actionData);
          }
        }
        if (actionData.on === ACTION_ON_TYPES.PROJECT) {
          if (isRedoAction) {
            await this.handleProjectRedoAction(actionData);
          } else {
            await this.handleProjectUndoAction(actionData);
          }
        }

        if (actionData.on === ACTION_ON_TYPES.CATEGORY) {
          if (isRedoAction) {
            await this.handleCategoryRedoAction(actionData);
          } else {
            await this.handleCategoryUndoAction(actionData.data);
          }
        }

        if (actionData.on === ACTION_ON_TYPES.OBJECTIVE) {
          if (isRedoAction) {
            await this.handleObjectiveRedoAction(actionData);
          } else {
            await this.handleObjectiveUndoAction(actionData);
          }
        }

        if (actionData.on === ACTION_ON_TYPES.RESOURCE) {
          if (isRedoAction) {
            await this.handleResourceRedoAction(actionData);
          } else {
            await this.handleResourceUndoAction(actionData);
          }
        }
      }
    },
    handleUndoAction() {
      let actions = getUserActions().getActions();
      const currAction = actions[0];
      let actionInUse = true;
      if (!currAction) {
        this.showToast("No action to undo!");
        return;
      }

      getUserActions().storeActionInHistory({ ...currAction });
      switch (currAction.on) {
        case ACTION_ON_TYPES.TASK:
          this.handleTaskUndoAction(currAction);
          break;
        case ACTION_ON_TYPES.PROJECT:
          this.handleProjectUndoAction(currAction);
          break;
        case ACTION_ON_TYPES.CATEGORY:
          this.handleCategoryUndoAction(currAction);
          break;
        case ACTION_ON_TYPES.NOTES:
          if (currAction.type === NOTES_ACTIONS.CONVERTED_TO_TASKS) {
            this.handleNotesConvertedToTasks(currAction.data);
          } else {
            actionInUse = false;
          }
          break;

        case ACTION_ON_TYPES.RESOURCE:
          this.handleResourceUndoAction(currAction);
          break;

        case ACTION_ON_TYPES.RESOURCES_CATEGORY:
          this.handleUndoOnResrCate(currAction);
          break;
        case ACTION_ON_TYPES.ALL:
          this.handleAllBatchAction(currAction.data);
          break;
        default:
          actionInUse = false;
      }

      if (actionInUse) {
        getUserActions().removeActionByIndex(0);
      }
    },
    async handleTaskRedoAction(currAction) {
      switch (currAction.type) {
        case TASK_ACTIONS.ADD:
          await this.handleAddTaskAction(currAction.data, "redo");
          break;
        case TASK_ACTIONS.BATCH_ADD:
          this.restoreGoogleCalTasks(currAction.data);
          await this.fillTaskDataThenAddInList(currAction.data, false);
          break;
        case TASK_ACTIONS.EDIT:
          await this.restoreTaskData(currAction.data, true);
          break;
        case TASK_ACTIONS.BATCH_EDIT:
          await this.restoreMultiTaskData(currAction.data, true);
          break;
        case TASK_ACTIONS.BATCH_DELETE:
          await this.removeTasksFromTaskList(currAction.data, false);
          break;
        case TASK_ACTIONS.NORMAL_RECURRING_EDIT:
          await this.restoreNormalAndRecurringTaskData(currAction.data, true);
          break;
        case TASK_ACTIONS.NORMAL_RECURRING_DELETE:
          this.removeRecurringOrNormalTasksFromMap(currAction.data);
          break;
        case TASK_ACTIONS.NORMAL_TO_RECURRING:
        case TASK_ACTIONS.REPEAT_EVERY_TO_NORMAL:
        case TASK_ACTIONS.REPEAT_EVERY_TO_REPEAT_IN:
          this.restoreNormalTask(currAction.data, true);
          break;
        case TASK_ACTIONS.ACTIONS_BATCH:
          this.handleTaskBatchActions(currAction.data, true);
          break;
        case TASK_ACTIONS.GROUP_OR_REORDER_BATCH:
          await this.restoreMultiTaskData(currAction.data, true);
          break;
        // case TASK_ACTIONS.REPEAT_EVERY_TO_REPEAT_IN:
        //   await this.handleRepeatEveryToRepeatInConvert(currAction.data, true);
        //   break;
        case TASK_ACTIONS.CONVERTED_TO_NOTES:
          await this.handleTasksConvertedToNotes(currAction.data, true);
          break;
      }
    },
    async handleProjectRedoAction(currAction) {
      switch (currAction.type) {
        case PROJECT_ACTIONS.EDIT:
        case PROJECT_ACTIONS.BATCH_EDIT:
          await this.restoreCategoriesData(currAction.data, true);
          break;
        case PROJECT_ACTIONS.ADD:
        case PROJECT_ACTIONS.BATCH_ADD:
          await this.handleProjectAddAction(currAction.data);
          break;
        case PROJECT_ACTIONS.BATCH_DELETE:
        case PROJECT_ACTIONS.DELETE:
          await this.handleProjectRemoveAction(currAction.data);
          // removeProject(currAction.data, this.userInfo.uid, false);
          break;
      }
    },
    async handleRedoAction() {
      const actions = [...getUserActions().getActionsHistory()];
      const currAction = actions[0];
      let actionInUse = true;
      if (!currAction) {
        this.showToast("No action to redo!");
        return;
      }

      switch (currAction.on) {
        case ACTION_ON_TYPES.TASK:
          await this.handleTaskRedoAction(currAction);
          break;
        case ACTION_ON_TYPES.PROJECT:
          await this.handleProjectRedoAction(currAction);
          break;
        case ACTION_ON_TYPES.CATEGORY:
          await this.handleCategoryRedoAction(currAction);
          break;
        case ACTION_ON_TYPES.NOTES:
          if (currAction.type === NOTES_ACTIONS.CONVERTED_TO_TASKS) {
            this.handleNotesConvertedToTasks(currAction.data, true);
          } else {
            actionInUse = false;
          }
          break;

        case ACTION_ON_TYPES.RESOURCE:
          await this.handleResourceRedoAction(currAction);
          break;

        case ACTION_ON_TYPES.RESOURCES_CATEGORY:
          await this.handleRedoOnResrCate(currAction);
          break;
        case ACTION_ON_TYPES.ALL:
          await this.handleAllBatchAction(currAction.data, true);
          break;
        default:
          actionInUse = false;
      }

      if (actionInUse) {
        getUserActions().removeActionFromHistoryByIndex(0);
      }
    },
    async handleRedoActionHotKeys(e) {
      if (!isRedoHotKeys(e)) return;

      e.stopImmediatePropagation();
      await this.handleRedoAction();
    },
    handleUndoHotKeys(e) {
      if (!isUndoHotKeys(e)) return;
      e.stopImmediatePropagation();
      this.handleUndoAction();
      // }
    },
    async restoreProjectData(projectData, isRedoAction) {
      const updates = {};

      let dataToUse = projectData;
      let keysFrom = projectData;
      if (isRedoAction) {
        dataToUse = { ...projectData.changedProps, key: projectData.key };
        keysFrom = projectData.changedProps;
      }
      const props = Object.keys(keysFrom);
      createRestoreProjectUpdateData(dataToUse, updates, props);
      await DatabaseInterface.update(updates, this.userInfo.uid);
    },
    //

    // Starting Data copy
    async restoreTaskData(taskData, isRedoAction) {
      const updates = {};

      let dataToUse = taskData;
      let keysFrom = taskData;
      let refreshDescrText = false;
      let refreshHistoryTable = false;
      if (isRedoAction) {
        dataToUse = { ...taskData.changedProps, key: taskData.key };
        keysFrom = taskData.changedProps;
      }

      if (taskData.updateDescrText) refreshDescrText = taskData.descrType;
      if (taskData.refreshFormData) refreshHistoryTable = true;
      const props = Object.keys(keysFrom);

      let refreshEditForm = true;

      const currTaskData = this.rawTasksMap[taskData.key];
      createRestoreTaskUpdatesData(dataToUse, updates, {
        // projects: this.projects,
        propsToRestore: props,
      });
      const updatedTaskData = isRedoAction
        ? {
            ...getUpdatedTaskProps(taskData.changedProps, props),
            key: taskData.key,
          }
        : taskData;

      this.restoreGoogleCalTasksData([
        { key: updatedTaskData.key, ...updatedTaskData },
      ]);

      const isAreaChanged = props.includes("project");
      if (
        !isEmpty(currTaskData) &&
        isAreaChanged &&
        this.projects[updatedTaskData.project]
      ) {
        const mentionedProjectResources = getProjectResourcesFromText(
          updatedTaskData.title ? updatedTaskData.title : currTaskData.title
        );

        if (!isEmpty(mentionedProjectResources)) {
          changeCatOfProjectResources([
            {
              resources: mentionedProjectResources,
              catId: updatedTaskData.project,
              isTaskRef: true,
            },
          ]);
        }
      }

      this.updateTasksInTable([{ ...updatedTaskData }]);

      if (
        (!isEmpty(this.editTaskData) &&
          this.editTaskData.key === taskData.key) ||
        this.checkIfTaskIsActiveInResourceForm(taskData.key)
      ) {
        refreshEditForm = false;
      }
      await DatabaseInterface.update(updates, this.userInfo.uid);
      await this.$nextTick();
      this.updateEditTaskData(
        { ...updatedTaskData },
        {
          refreshFormOnly: refreshEditForm,
          refreshDescrText,
          refreshHistoryTable,
        }
      );
    },
    async updateEditTaskData(
      taskDataToUpdate,
      { refreshFormOnly, refreshDescrText, refreshHistoryTable }
    ) {
      if (!isEmpty(taskDataToUpdate)) {
        delete taskDataToUpdate.changedProps;
        const taskKey = taskDataToUpdate.key;
        if (!refreshFormOnly) {
          const currTaskData = this.tasks.find((t) => t.key === taskKey);

          if (!isEmpty(currTaskData)) {
            if (this.checkIfTaskEditIsActiveInResourceForm()) {
              this.refreshTaskDataInResourceForm(taskKey);
            } else if (!isEmpty(this.editTaskData)) {
              let extraData = {};

              if (taskDataToUpdate.completed !== undefined) {
                extraData = {
                  keepItVisible: !!taskDataToUpdate.completed,
                };
              }
              const createdData = {
                ...currTaskData,
                ...taskDataToUpdate,
                ...extraData,
              };
              const processedTask = {
                ...processTask(
                  getMainTaskRulesAndFillRecurringOpts(createdData)
                ),
              };
              this.setTaskData({
                ...processedTask,
              });
              await this.$nextTick();
              EventEmitter.emit(
                REFRESH_FORM_DATA,
                true,
                refreshDescrText,
                refreshHistoryTable
              );
            }
            // if (isEqual(processedTask, currTaskData)) {
            //   EventEmitter.emit(REFRESH_FORM_DATA);
            // }
          }

          //
        } else if (refreshFormOnly) {
          if (this.checkIfTaskIsActiveInResourceForm(taskKey)) {
            this.refreshTaskDataInResourceForm(taskKey);
          } else {
            EventEmitter.emit(
              REFRESH_FORM_DATA,
              true,
              refreshDescrText,
              refreshHistoryTable
            );
          }
        }
      }
    },
    async restoreMultiTaskData(taskList, isRedoAction) {
      const updates = {};
      const tasksUpdates = [];
      let taskDataForLocalEditTask = {};
      if (isRedoAction) {
        taskList.forEach((taskData) => {
          const props = Object.keys(taskData.changedProps);
          createRestoreTaskUpdatesData(
            { ...taskData.changedProps, key: taskData.key },
            updates,
            {
              projects: this.projects,
              propsToRestore: props,
            }
          );

          const createdData = {
            ...getUpdatedTaskProps(taskData.changedProps, props),
            key: taskData.key,
          };

          if (
            (!isEmpty(this.editTaskData) &&
              this.editTaskData.key === taskData.key) ||
            this.checkIfTaskIsActiveInResourceForm(taskData.key)
          ) {
            taskDataForLocalEditTask = { ...createdData };
          }
          tasksUpdates.push({
            ...createdData,
          });
        });
      } else {
        taskList.forEach((taskData) => {
          const props = Object.keys(taskData);
          createRestoreTaskUpdatesData(taskData, updates, {
            projects: this.projects,
            propsToRestore: props,
          });
          const createdData = {
            ...taskData,
            key: taskData.key,
          };

          if (
            (!isEmpty(this.editTaskData) &&
              this.editTaskData.key === taskData.key) ||
            this.checkIfTaskIsActiveInResourceForm(taskData.key)
          ) {
            taskDataForLocalEditTask = { ...createdData };
          }

          tasksUpdates.push({ ...taskData });
        });
      }

      this.restoreGoogleCalTasksData(tasksUpdates);
      this.updateTasksInTable(tasksUpdates);
      this.updateEditTaskData(taskDataForLocalEditTask);
      await DatabaseInterface.update(updates, this.userInfo.uid);
    },

    async restoreNormalAndRecurringTaskData(groupDataMap, isRedoAction) {
      let dbUpdates = {};
      let taskUpdates = [];
      let tasksToAdd = [];
      let tasksToAddLocally = [];
      let updateMainRecurringGoogleTasks = [];
      let googleTasksUpdate = [];
      let tasksToRemoveFromList = [];
      let updateRecurringTasksInLocalList = [];
      // let refreshEditForm = false;
      let taskDataForLocalEditTask = {};
      let refreshDescrText = false;
      let refreshHistoryTable = false;
      let projectResourcesCatChangeMap = {};
      const recurrenceTasksMapList = [];
      for (const groupId in groupDataMap) {
        const groupData = groupDataMap[groupId];

        if (groupId !== "normal") {
          // For recurring
          const {
            editedTasks,
            updateMain,
            editedData,
            recreateTasks,
            updateVirtualTasks,
          } = groupData;
          if (!isEmpty(editedTasks)) {
            editedTasks.forEach((editedTaskData) => {
              const dataToUse = isRedoAction
                ? { ...editedTaskData.changedProps, key: editedTaskData.key }
                : editedTaskData;

              const doNotUpdateModified = editedTaskData.doNotUpdateModified;

              if (editedTaskData.updateDescrText)
                refreshDescrText = editedTaskData.descrType;
              if (editedTaskData.refreshFormData) refreshHistoryTable = true;

              const props = Object.keys(dataToUse);

              const isAreaChangeIncluded = props.includes("project");
              const createdUpdateObj = {
                ...getUpdatedTaskProps(dataToUse, props),
                key: editedTaskData.key,
              };

              if (
                isAreaChangeIncluded &&
                dataToUse.project &&
                !editedTaskData.isVirtual &&
                this.rawTasksMap[editedTaskData.key]
              ) {
                const mentionedProjectResources = getProjectResourcesFromText(
                  dataToUse.title
                    ? dataToUse.title
                    : this.rawTasksMap[editedTaskData.key].title
                );

                if (!projectResourcesCatChangeMap[dataToUse.project]) {
                  projectResourcesCatChangeMap[dataToUse.project] = {
                    catId: dataToUse.project,
                    resources: [],
                    isTaskRef: true,
                  };
                }

                if (projectResourcesCatChangeMap[dataToUse.project]) {
                  projectResourcesCatChangeMap[
                    dataToUse.project
                  ].resources.push(...mentionedProjectResources);
                }

                // if (!isEmpty(mentionedProjectResources)) {
                //   changeCatOfProjectResources({
                //     resources: mentionedProjectResources,
                //     catId: updatedData.project,
                //     isTaskRef: true,
                //   });
                // }
              }

              if (!doNotUpdateModified && !createdUpdateObj.modified) {
                createdUpdateObj.modified = getCurrDate();
              }
              if (!editedTaskData.isVirtual) {
                createRestoreTaskUpdatesData(dataToUse, dbUpdates, {
                  projects: this.projects,
                  propsToRestore: props,
                  doNotUpdateModified,
                });
                googleTasksUpdate.push({
                  ...createdUpdateObj,
                  isVirtual: editedTaskData.isVirtual,
                });
              }

              if (
                (!isEmpty(this.editTaskData) &&
                  this.editTaskData.key === editedTaskData.key) ||
                this.checkIfTaskIsActiveInResourceForm(editedTaskData.key)
              ) {
                const currTaskData = this.tasks.find(
                  (t) => t.key === editedTaskData.key
                );

                if (!isEmpty(currTaskData)) {
                  taskDataForLocalEditTask = {
                    ...this.rawTasksMap[editedTaskData.key],
                    ...createdUpdateObj,
                    key: editedTaskData.key,
                  };
                }
              }

              taskUpdates.push({ ...createdUpdateObj });
            });
          }

          if (updateMain) {
            const dataToUse = isRedoAction
              ? { ...editedData.changedProps, key: groupId }
              : { ...editedData, key: groupId };
            const doNotUpdateModified = editedData.doNotUpdateModified;
            const props = Object.keys(dataToUse);
            const currMainRawData = { ...this.rawTasksMap[groupId] };
            let localExtraData = {};
            createRestoreTaskUpdatesData(dataToUse, dbUpdates, {
              projects: this.projects,
              propsToRestore: props,
              restoreRecurrence: true,
              doNotUpdateModified,
            });

            const isAreaChangeIncluded = props.includes("project");

            if (
              isAreaChangeIncluded &&
              dataToUse.project &&
              this.rawTasksMap[groupId]
            ) {
              const mentionedProjectResources = getProjectResourcesFromText(
                dataToUse.title
                  ? dataToUse.title
                  : this.rawTasksMap[groupId].title
              );

              if (!projectResourcesCatChangeMap[dataToUse.project]) {
                projectResourcesCatChangeMap[dataToUse.project] = {
                  catId: dataToUse.project,
                  resources: [],
                  isTaskRef: true,
                };
              }

              if (projectResourcesCatChangeMap[dataToUse.project]) {
                projectResourcesCatChangeMap[dataToUse.project].resources.push(
                  ...mentionedProjectResources
                );
              }
            }
            const createdData = {
              ...getUpdatedTaskProps(dataToUse, props, true),
              key: groupId,
            };

            if (createdData.completed !== undefined) {
              localExtraData = {
                keepItVisible:
                  !currMainRawData.completed && createdData.completed,
              };
            }

            if (!doNotUpdateModified) {
              const currModifiedDate = getCurrDate();
              if (!createdData.modified) {
                createdData.modified = currModifiedDate;
              }

              if (!dataToUse.modified) {
                dataToUse.modified = currModifiedDate;
              }
            }

            updateMainRecurringGoogleTasks.push({
              ...createdData,
            });

            if (
              isEmpty(taskDataForLocalEditTask) &&
              this.rawTasksMap[groupId] &&
              this.rawTasksMap[groupId].key &&
              ((!isEmpty(this.editTaskData) &&
                this.editTaskData.key === groupId) ||
                this.checkIfTaskIsActiveInResourceForm(groupId))
            ) {
              taskDataForLocalEditTask = {
                ...this.rawTasksMap[groupId],
                ...createdData,
                ...localExtraData,
              };
            }
            if (
              recreateTasks &&
              !updateVirtualTasks &&
              this.rawTasksMap[groupId]
            ) {
              const currRecurringTasks = getRecurringTasks(this.tasks, "list", {
                linkedTo: groupId,
                filter: true,
                filterOpts: [isTaskVirtual],
              });

              tasksToRemoveFromList.push(...currRecurringTasks);

              currRecurringTasks.forEach((cR) => {
                const linkedReminders = getReminderTasksLinkedToTaskId(
                  this.tasks,
                  cR.key
                );

                if (!isEmpty(linkedReminders)) {
                  tasksToRemoveFromList.push(...linkedReminders);
                }
              });
              const { tasks: virtualTasks, datesList } =
                createVirtualTasksFromRecurringTasks([
                  {
                    ...this.rawTasksMap[groupId],
                    ...dataToUse,
                    ...localExtraData,
                  },
                ]);

              if (
                isEmpty(taskDataForLocalEditTask) &&
                !isEmpty(this.editTaskData)
              ) {
                taskDataForLocalEditTask = virtualTasks[0];
              }
              recurrenceTasksMapList.push({
                mainTaskId: groupId,
                tasks: datesList,
              });
              tasksToAddLocally.push(...virtualTasks);
            }

            if (
              updateVirtualTasks &&
              this.rawTasksMap[groupId] &&
              !isEmpty(this.recurringTasksMap[groupId])
            ) {
              let clonedDataToUse = cloneDeep(dataToUse);
              clonedDataToUse = removeProps(clonedDataToUse, [
                "linkedTo",
                "recurrence",
                "key",
                "googleEventId",
                "isFirstTask",
                "isFromGoogle",
                "originalDate",
              ]);
              const currRecurringTasks = getRecurringTasks(this.tasks, "list", {
                linkedTo: groupId,
                filter: true,
                filterOpts: [isTaskVirtual],
              });

              currRecurringTasks.forEach((taskData) => {
                const createdData = {
                  ...clonedDataToUse,
                  key: taskData.key,
                  ...getDateAndTimeFromDueDate(
                    taskData.dueAsDate,
                    undefined,
                    false
                  ),
                  ...localExtraData,
                };

                const localUpdatedData = {
                  ...taskData,
                  ...createdData,
                };

                if (
                  isEmpty(taskDataForLocalEditTask) &&
                  !isEmpty(this.editTaskData) &&
                  this.editTaskData.key === taskData.key
                ) {
                  taskDataForLocalEditTask = { ...localUpdatedData };
                }

                if (
                  checkIfTaskHasReminder(taskData) &&
                  this.checkIfParsedTaskDataHasReminderProps(
                    Object.keys(clonedDataToUse)
                  )
                ) {
                  const currRemindersLinked = getReminderTasksLinkedToTaskId(
                    this.tasks,
                    taskData.key
                  );

                  if (!isEmpty(currRemindersLinked)) {
                    tasksToRemoveFromList.push(...currRemindersLinked);
                  }

                  if (
                    checkIfTaskHasReminder(localUpdatedData) &&
                    !localUpdatedData.completed
                  ) {
                    tasksToAddLocally.push(
                      ...createReminderTasks(localUpdatedData)
                    );
                  }
                }
                updateRecurringTasksInLocalList.push({
                  updates: {
                    ...localUpdatedData,
                  },
                  key: taskData.key,
                });
              });
            }
          }
        } else {
          const { editedTasks, addedTasks } = groupData;

          if (!isEmpty(editedTasks)) {
            editedTasks.forEach((editedTaskData) => {
              const dataToUse = isRedoAction
                ? { ...editedTaskData.changedProps, key: editedTaskData.key }
                : editedTaskData;

              const doNotUpdateModified = editedTaskData.doNotUpdateModified;

              if (!refreshDescrText && editedTaskData.updateDescrText)
                refreshDescrText = editedTaskData.descrType;

              if (!refreshHistoryTable && editedTaskData.refreshFormData)
                refreshHistoryTable = true;
              const props = Object.keys(dataToUse);

              const isAreaChangeIncluded = props.includes("project");

              if (
                isAreaChangeIncluded &&
                dataToUse.project &&
                this.rawTasksMap[editedTaskData.key]
              ) {
                const mentionedProjectResources = getProjectResourcesFromText(
                  dataToUse.title
                    ? dataToUse.title
                    : this.rawTasksMap[editedTaskData.key].title
                );

                if (!projectResourcesCatChangeMap[dataToUse.project]) {
                  projectResourcesCatChangeMap[dataToUse.project] = {
                    catId: dataToUse.project,
                    resources: [],
                    isTaskRef: true,
                  };
                }

                if (projectResourcesCatChangeMap[dataToUse.project]) {
                  projectResourcesCatChangeMap[
                    dataToUse.project
                  ].resources.push(...mentionedProjectResources);
                }
              }

              createRestoreTaskUpdatesData(dataToUse, dbUpdates, {
                projects: this.projects,
                propsToRestore: props,
                doNotUpdateModified,
              });
              const createdUpdateObj = {
                ...getUpdatedTaskProps(dataToUse, props),
                key: editedTaskData.key,
              };

              if (!doNotUpdateModified && !createdUpdateObj.modified) {
                createdUpdateObj.modified = getCurrDate();
              }

              if (
                isEmpty(taskDataForLocalEditTask) &&
                ((!isEmpty(this.editTaskData) &&
                  this.editTaskData.key === editedTaskData.key &&
                  this.rawTasksMap[editedTaskData.key] &&
                  this.rawTasksMap[editedTaskData.key].key) ||
                  this.checkIfTaskIsActiveInResourceForm(editedTaskData.key))
              ) {
                taskDataForLocalEditTask = {
                  ...this.rawTasksMap[editedTaskData.key],
                  ...createdUpdateObj,
                };
              }
              googleTasksUpdate.push({ ...createdUpdateObj });
              taskUpdates.push({
                ...createdUpdateObj,
              });
            });
          }

          if (!isEmpty(addedTasks)) {
            tasksToAdd = [...tasksToAdd, ...addedTasks];
          }

          // For normal tasks;
        }
      }

      if (!isEmpty(tasksToAdd)) {
        if (isRedoAction) {
          this.restoreGoogleCalTasks(tasksToAdd.data);
          this.fillTaskDataThenAddInList(tasksToAdd.data, false);
        } else {
          this.removeTasksFromTaskList(tasksToAdd, false);
        }
      }

      this.restoreGoogleCalTasksData(googleTasksUpdate);

      if (!isEmpty(updateMainRecurringGoogleTasks)) {
        this.restoreGoogleCalTasksData(updateMainRecurringGoogleTasks, "raw");
      }

      const refreshList =
        isEmpty(updateRecurringTasksInLocalList) && isEmpty(tasksToAddLocally);

      this.updateTasksInTable(taskUpdates, refreshList);
      addOrRemoveOrUpdateTasksInLocalTasksList({
        tasksToAdd: tasksToAddLocally,
        tasksToRemove: tasksToRemoveFromList,
        tasksToUpdate: updateRecurringTasksInLocalList,
        addMethod: !isEmpty(tasksToAddLocally) ? "push" : "unshift",
        verifyAdd: !!isEmpty(tasksToAddLocally),
      });

      EventEmitter.emit(REFRESH_ACTIVITY_HISTORY);

      changeCatOfProjectResources(Object.values(projectResourcesCatChangeMap));
      this.replaceRecurringTasksInfoInMap({
        list: recurrenceTasksMapList,
      });
      await DatabaseInterface.update(dbUpdates, this.userInfo.uid);
      await this.$nextTick();

      if (this.checkIfTaskEditIsActiveInResourceForm()) {
        let taskKeyToUse = this.getStoredTaskDataInResourceForm();
        if (
          this.checkIfTaskIsActiveInResourceForm(taskDataForLocalEditTask.key)
        ) {
          taskKeyToUse = taskDataForLocalEditTask.key;
        }
        this.refreshTaskDataInResourceForm(taskKeyToUse);
      } else if (
        !isEmpty(this.editTaskData) &&
        !isEmpty(taskDataForLocalEditTask)
      ) {
        const currTaskData = this.tasks.find(
          (t) => t.key === taskDataForLocalEditTask.key
        );

        if (!isEmpty(currTaskData)) {
          const processedTask = processTask(
            getMainTaskRulesAndFillRecurringOpts(taskDataForLocalEditTask)
          );
          this.setTaskData(processedTask);
          await this.$nextTick();
          EventEmitter.emit(
            REFRESH_FORM_DATA,
            true,
            refreshDescrText,
            refreshHistoryTable
          );
        }
      } else if (!isEmpty(this.editTaskData)) {
        EventEmitter.emit(
          REFRESH_FORM_DATA,
          true,
          refreshDescrText,
          refreshHistoryTable
        );
      }
    },

    async restoreNormalTask(normalTaskData, isRedoAction) {
      // const dbUpdates = {};

      if (this.rawTasksMap[normalTaskData.key]) {
        const projectResourcesCatChangeMap = {};
        const currRawData = this.rawTasksMap[normalTaskData.key];
        const tasksToRemove = [];
        const localTasksToRemove = [];
        const googleTasksToRemove = [];
        const googleTasksToUpdate = [];
        const tasksToAdd = [];
        let taskDataForEditForm = {};
        const dataToUse = isRedoAction
          ? { ...normalTaskData.changedProps, key: normalTaskData.key }
          : normalTaskData;

        const props = Object.keys(dataToUse);

        const isAreaChangeIncluded = props.includes("project");

        if (
          isAreaChangeIncluded &&
          dataToUse.project &&
          this.rawTasksMap[normalTaskData.key]
        ) {
          const mentionedProjectResources = getProjectResourcesFromText(
            dataToUse.title
              ? dataToUse.title
              : this.rawTasksMap[normalTaskData.key].title
          );

          if (!projectResourcesCatChangeMap[dataToUse.project]) {
            projectResourcesCatChangeMap[dataToUse.project] = {
              catId: dataToUse.project,
              resources: [],
              isTaskRef: true,
            };
          }

          if (projectResourcesCatChangeMap[dataToUse.project]) {
            projectResourcesCatChangeMap[dataToUse.project].resources.push(
              ...mentionedProjectResources
            );
          }
        }
        const taskDataUpdates = {};
        const recurringTasksMap = {};
        props.forEach((p) => {
          if (RECURRENCE_TASK_PROPS.includes(p) && dataToUse[p] !== undefined) {
            // dbUpdates[`/tasks/${normalTaskData.key}/${p}`] = dataToUse[p];
            taskDataUpdates[p] = dataToUse[p];
          } else if (p === "repeatInfo" && dataToUse[p] !== undefined) {
            taskDataUpdates[p] = dataToUse[p];
          } else if (
            booleanPropsOfTask.includes(p) &&
            typeof dataToUse[p] === "boolean"
          ) {
            taskDataUpdates[p] = dataToUse[p];
          } else if (
            taskRestoreProps.includes(p) &&
            dataToUse[p] !== undefined
          ) {
            if (orderPropsOfTask.includes(p)) {
              taskDataUpdates[p] =
                dataToUse[p] !== undefined ? dataToUse[p] : 0;
            } else {
              taskDataUpdates[p] = dataToUse[p] || "";
            }
          }
        });
        const createdUpdateObj = {
          ...taskDataUpdates,
          key: normalTaskData.key,
        };

        if (currRawData.googleEventId) {
          const updatedObj = {
            ...currRawData,
            ...createdUpdateObj,
          };

          updatedObj.dueAsDate = createDateTimeFromDue(
            updatedObj.due,
            updatedObj.time
          );
          googleTasksToUpdate.push({
            key: currRawData.googleEventId,
            updates: {
              ...createTaskDataForGoogleCal(
                { ...updatedObj, taskKey: currRawData.key },
                {
                  ...currRawData,
                  dueAsDate: createDateTimeFromDue(
                    currRawData.due,
                    currRawData.time
                  ),
                }
              ),
            },
          });
        }

        this.restoreGoogleCalTasksData([
          { key: createdUpdateObj.key, ...createdUpdateObj },
        ]);

        // this.updateTasksInTable([{ ...createdUpdateObj }]);

        const areRecurrenceRulesValid = areTaskRecurrenceRulesValid(
          createdUpdateObj.recurrence
        );

        if (areRecurrenceRulesValid) {
          // if (this.rawTasksMap[normalTaskData.key]) {
          const currTaskData = this.rawTasksMap[normalTaskData.key];

          const { tasks: recurringTasks, datesList: recurringTasksList } =
            createVirtualTasksFromRecurringTasks([
              { ...currTaskData, ...createdUpdateObj },
            ]);

          taskDataForEditForm = recurringTasks[0];
          tasksToAdd.push(...recurringTasks);
          recurringTasksMap.mainTaskId = normalTaskData.key;
          recurringTasksMap.tasksToAdd = recurringTasksList;
          localTasksToRemove.push({ ...currRawData });
          // }
        } else {
          const recurringTasks = getRecurringTasks(this.tasks, "list", {
            linkedTo: normalTaskData.key,
          });

          taskDataForEditForm = processTask(
            getMainTaskRulesAndFillRecurringOpts({
              ...currRawData,
              ...createdUpdateObj,
              key: normalTaskData.key,
            })
          );

          if (!isEmpty(recurringTasks)) {
            recurringTasks.forEach((t) => {
              const linkedReminders = getReminderTasksLinkedToTaskId(
                this.tasks,
                t.key
              );
              const isTaskVirtual = t.isVirtual;
              // const isFromGoogle = t.isFromGoogle;
              tasksToRemove.push(t);
              localTasksToRemove.push(t);

              if (!isEmpty(linkedReminders)) {
                localTasksToRemove.push(...linkedReminders);
              }
              if (!isTaskVirtual) {
                googleTasksToRemove.push(t);
                // if (isFromGoogle) {
                //   googleIdToUse = createGoogleEventIdByDateAndTaskId(
                //     currTaskData.googleEventId.split("_")[0],
                //     currTaskData.dueAsDate
                //   );
                // } else {
                //   googleIdToUse = createGoogleEventIdByDateAndTaskId(
                //     currTaskData.linkedTo,
                //     currTaskData.dueAsDate
                //   );
                // }
              }
            });
          }

          tasksToAdd.push({
            ...currRawData,
            ...createdUpdateObj,
            key: normalTaskData.key,
          });

          const createdReminderTasks = createReminderTasks({
            ...currRawData,
            ...createdUpdateObj,
            key: normalTaskData.key,
          });

          tasksToAdd.push(...createdReminderTasks);
        }

        addOrRemoveOrUpdateTasksInDb({
          tasksToRemove: tasksToRemove,
          tasksToUpdate: [
            {
              key: normalTaskData.key,
              updates: taskDataUpdates,
            },
          ],
        });

        if (!isEmpty(googleTasksToUpdate)) {
          updateTasksInGoogleCal(googleTasksToUpdate);
        }

        addOrRemoveOrUpdateTasksInLocalTasksList({
          tasksToAdd,
          tasksToRemove: localTasksToRemove,
        });

        if (this.checkIfTaskEditIsActiveInResourceForm()) {
          this.refreshTaskDataInResourceForm(taskDataForEditForm.key);
        } else if (!isEmpty(this.editTaskData)) {
          this.setTaskData({ ...taskDataForEditForm });

          await this.$nextTick();
          EventEmitter.emit(REFRESH_FORM_DATA, true);
        } else {
          EventEmitter.emit(REFRESH_ACTIVITY_HISTORY);
        }

        changeCatOfProjectResources(
          Object.values(projectResourcesCatChangeMap)
        );

        if (!isEmpty(recurringTasksMap)) {
          addRecurringTasksInfoInMap(
            recurringTasksMap.mainTaskId,
            recurringTasksMap.tasksToAdd
          );
        } else {
          this.replaceRecurringTasksInfoInMap({
            list: [
              {
                mainTaskId: normalTaskData.key,
                tasks: [],
              },
            ],
          });
        }
      }
    },

    async restoreGoogleCalTasksData(tasksData, listTypeToUse = "processed") {
      if (!Array.isArray(tasksData)) tasksData = [tasksData];
      const restoreTasksDataList = [];
      tasksData.forEach((tD) => {
        let listToUse = this.tasks;

        if (listTypeToUse === "raw") {
          listToUse = this.rawTasks;
        }

        const currTaskData = listToUse.find((t) => t.key === tD.key);
        if (!isEmpty(currTaskData)) {
          if (currTaskData.googleEventId) {
            let googleIdToUse = currTaskData.googleEventId;

            // if (tD.isVirtual) {
            //   if (currTaskData.isFromGoogle) {
            //     googleIdToUse = createGoogleEventIdByDateAndTaskId(
            //       currTaskData.googleEventId.split("_")[0],
            //       currTaskData.dueAsDate
            //     );
            //   } else {
            //     googleIdToUse = createGoogleEventIdByDateAndTaskId(
            //       currTaskData.linkedTo,
            //       currTaskData.dueAsDate
            //     );
            //   }
            // }
            const createdUpdates = tD;

            if (createdUpdates.due) {
              createdUpdates.dueAsDate = createDateTimeFromDue(
                createdUpdates.due,
                createdUpdates.time
              );
            }
            if (isCalenderPropsChanged(createdUpdates, currTaskData)) {
              restoreTasksDataList.push({
                key: googleIdToUse,
                updates: {
                  ...createTaskDataForGoogleCal(
                    { ...createdUpdates, taskKey: currTaskData.key },
                    currTaskData
                  ),
                },
                currData: {
                  ...currTaskData,
                },
              });
            }
          }
        }
      });

      await updateTasksInGoogleCal(restoreTasksDataList, false, true);
    },
    updateTasksInTable(taskList, refreshList = true) {
      let reminderTasksToRemove = [];
      let reminderTasksToAdd = [];
      if (taskList && taskList.length) {
        const updates = [];
        taskList.forEach((taskToUpdate) => {
          delete taskToUpdate.changedProps;
          const taskDataKeys = Object.keys(taskToUpdate);
          const taskKey = taskToUpdate.key;
          if (
            fieldRequriesProcessing(taskDataKeys, fieldWhichRequiresProcessing)
          ) {
            const currTaskData = this.tasks.find(
              (t) => t.key === taskToUpdate.key
            );

            if (!isEmpty(currTaskData)) {
              const rawUpdatedData = {
                ...currTaskData,
                ...taskToUpdate,
              };

              let extraData = {};
              if (taskToUpdate.completed !== undefined) {
                extraData.keepItVisible = !!taskToUpdate.completed;
              }
              taskToUpdate = processTask(
                { ...rawUpdatedData, ...extraData },
                this.projects,
                this.categories
              );

              if (
                (!checkIfTaskHasReminder(currTaskData) ||
                  checkIfTaskHasReminder(taskToUpdate)) &&
                this.checkIfParsedTaskDataHasReminderProps(taskDataKeys)
              ) {
                const currRemindersLinked = getReminderTasksLinkedToTaskId(
                  this.tasks,
                  taskKey
                );

                if (!isEmpty(currRemindersLinked)) {
                  reminderTasksToRemove.push(...currRemindersLinked);
                }

                if (
                  checkIfTaskHasReminder(rawUpdatedData) &&
                  !rawUpdatedData.completed
                ) {
                  reminderTasksToAdd.push(
                    ...createReminderTasks(rawUpdatedData)
                  );
                }
              }
            }
            // taskToUpdate.key
            // const rowData = this.$refs.dataGrid.getRowData(taskToUpdate.key);
          }

          updates.push({ key: taskKey, updates: taskToUpdate });
          // return taskToUpdate;
        });

        // this.$refs.dataGrid.updateRowData(updates, refreshList);

        addOrRemoveOrUpdateTasksInLocalTasksList(
          {
            tasksToUpdate: updates,
            tasksToRemove: reminderTasksToRemove,
            tasksToAdd: reminderTasksToAdd,
            addMethod: !isEmpty(reminderTasksToAdd) ? "push" : "unshift",
            verifyAdd: isEmpty(reminderTasksToAdd),
          },
          refreshList
        );

        if (refreshList) {
          EventEmitter.emit(REFRESH_ACTIVITY_HISTORY);
        }
        // this.$refs.tasksListWrapper.updateListRow(updates, refreshList);
      }
    },

    // Copied Data
    handleDeleteItemsKey(e) {
      const isGridInEditMode = this.$refs.tasksListWrapper.isGridInEditMode();
      if (
        !isDeleteKey(e) ||
        isGridInEditMode ||
        this.openNewForm ||
        this.isResourceInfoViewOpen
      )
        return;

      this.handleDeletionOfTasks(this.selectedTasks);
      this.updateSelectedTasks([]);
    },
    checkIfGridEditingIsActive() {
      const res = this.$refs.tasksListWrapper.isGridInEditMode();
      return res;
    },
    handleEscKey(e) {
      if (!isEscKey(e) || this.openNewForm) return;
      e.stopPropagation?.();
      e.preventDefault?.();
      this.handleDashboardReturnToView(this.currViewData);
    },
    handleHotKeys(e) {
      this.handleEscKey(e);
      this.handleUndoHotKeys(e);
      this.handleRedoActionHotKeys(e);
      this.handleDeleteItemsKey(e);
    },
    listenToDataChange(snapshot) {
      this.setLoader(false);
      const val = snapshot.val();
      if (!val) {
        DatabaseInterface.initUser(this.userId);
      } else {
        this.onDataChange(val);
      }
    },
    restoreGoogleCalTasks(tasks) {
      if (this.isGoogleCalSyncEnabled) {
        restoreTasksInGoogleCal(tasks);
      }
    },
    async openTaskData() {
      await Vue.nextTick();
      const queryData = this.$route.query;
      const inspectTaskData = this.$route.params?.inspectTaskData;

      if (!isEmpty(queryData)) {
        let taskId = queryData.taskId;
        const taskType = queryData.type;
        const cateId = queryData.cateId;
        if (taskId && taskType) {
          let taskToUse = {};
          if (taskType === "recurring") {
            // taskToUse = getRecurringTasks(this.tasks, "list", {
            //   linkedTo: taskId,
            //   filter: true,
            //   filterOpts: [(row) => !!row.isVirtual],
            // })[0];

            taskToUse = this.getFirstVirtualReccuringTask(taskId);
          } else if (taskType === "normal") {
            taskToUse = this.tasks.find((t) => t.key === taskId);
          }

          if (!isEmpty(taskToUse)) {
            this.openTaskInEditForm(taskToUse);
          }
          // if (!isEmpty(taskToUse)) {
          //   const processedTaskData = getMainTaskRulesAndFillRecurringOpts({
          //     ...taskToUse,
          //   });

          //   this.showEditTaskDialog({
          //     taskData: { ...processedTaskData },
          //     selectedTasks: [],
          //   });
          // }
        }

        if (cateId && this.projects[cateId]) {
          EventEmitter.emit(
            SET_SELECTED_NODE_EVENT,
            [cateId],
            true,
            true,
            undefined,
            true,
            undefined,
            true
          );
        }

        this.$router.replace(this.$route.path);
      }

      if (!isEmpty(inspectTaskData)) {
        const {
          id: inspectedTaskId,
          returnInfo,
          viewFrom,
          selectTask,
          openForm,
          date,
          showNewDialog,
        } = inspectTaskData;

        let taskDataToUse = {};

        if (inspectedTaskId && this.rawTasksMap[inspectedTaskId]) {
          if (
            checkIfTaskIsParentRecurringTask(this.rawTasksMap[inspectedTaskId])
          ) {
            taskDataToUse = this.getFirstVirtualReccuringTask(
              inspectedTaskId,
              date
            );
          } else {
            taskDataToUse = this.tasks.find((t) => t.key === inspectedTaskId);
          }
        }

        await this.$nextTick();

        setTimeout(async () => {
          if (!isEmpty(taskDataToUse)) {
            if (showNewDialog) {
              let triggeredFrom = "next-actions";
              if (!this.isInNextActionsMode) {
                triggeredFrom = this.currNav === 3 ? "today" : "cal";
              }
              EventEmitter.emit(OPEN_STATUS_EDIT_MODE, {
                triggeredFrom,
                taskData: { ...taskDataToUse },
                opts: {
                  openProject: true,
                  returnViewInfo: returnInfo,
                },
              });
            }

            await Vue.nextTick();
            if (!openForm) {
              EventEmitter.emit(OPEN_INSPECT_MODE, {
                triggeredFrom: viewFrom || "func",
                taskData: { ...taskDataToUse },
                opts: {
                  openProject: true,
                  returnViewInfo: returnInfo,
                  selectTask,
                },
              });
            } else {
              this.openTaskInEditForm(taskDataToUse, returnInfo, showNewDialog);
            }
          }
        }, 0);

        // this.$router.replace(this.$route.path);
      }
    },
    handleGoogleSyncCmpted() {
      this.handleReturnToView();
      this.openTaskData();
      this.switchToInspectMode?.();
    },
    async handleReturnToView() {
      await Vue.nextTick();

      const viewConfig = this.$route.params.viewConfig;

      this.handleDashboardViewConfig(viewConfig);

      // let closeLoader = true;
      // if (!isEmpty(viewConfig)) {
      //   const {
      //     openForm,
      //     itemIdToOpen,
      //     itemDate,
      //     enableInspectMode,
      //     inspectModeConfig,
      //     itemIdToSelect,
      //     itemIdDateSelect,
      //   } = viewConfig;

      //   if (!openForm && itemIdToSelect) {
      //     if (itemIdToSelect && this.rawTasksMap[itemIdToSelect]) {
      //       let taskIdToUse = itemIdToSelect;
      //       if (
      //         checkIfTaskIsParentRecurringTask(this.rawTasksMap[itemIdToSelect])
      //       ) {
      //         taskIdToUse = this.getFirstVirtualReccuringTask(
      //           itemIdToSelect,
      //           itemIdDateSelect
      //         )?.key;
      //       }

      //       if (taskIdToUse) {
      //         EventEmitter.emit(SET_ID_FOR_SCROLL, taskIdToUse);
      //       }
      //     }
      //   }

      //   if (enableInspectMode && !isEmpty(inspectModeConfig)) {
      //     //

      //     const {
      //       inspectedTaskId,
      //       triggeredFrom,
      //       taskDate,
      //       opts: inspectModeOpts,
      //     } = inspectModeConfig;

      //     let taskDataToUse = {};

      //     if (inspectedTaskId && this.rawTasksMap[inspectedTaskId]) {
      //       if (
      //         checkIfTaskIsParentRecurringTask(
      //           this.rawTasksMap[inspectedTaskId]
      //         )
      //       ) {
      //         taskDataToUse = this.getFirstVirtualReccuringTask(
      //           inspectedTaskId,
      //           taskDate
      //         );
      //       } else {
      //         taskDataToUse = this.tasks.find((t) => t.key === inspectedTaskId);
      //       }
      //     }

      //     if (!isEmpty(taskDataToUse)) {
      //       EventEmitter.emit(OPEN_INSPECT_MODE, {
      //         triggeredFrom: triggeredFrom || "func",
      //         taskData: { ...taskDataToUse },
      //         opts: inspectModeOpts,
      //       });
      //     }
      //   }
      //   if (openForm && itemIdToOpen && this.rawTasksMap[itemIdToOpen]) {
      //     let taskDataToUse = {};
      //     closeLoader = false;
      //     if (
      //       checkIfTaskIsParentRecurringTask(this.rawTasksMap[itemIdToOpen])
      //     ) {
      //       taskDataToUse = this.getFirstVirtualReccuringTask(
      //         itemIdToOpen,
      //         itemDate
      //       );
      //     } else {
      //       taskDataToUse = this.tasks.find((t) => t.key === itemIdToOpen);
      //     }

      //     await Vue.nextTick();

      //     setTimeout(() => {
      //       if (!isEmpty(taskDataToUse)) {
      //         this.openTaskInEditForm(taskDataToUse);
      //       }
      //     }, 0);
      //   }
      // }

      // if (closeLoader) {
      //   this.loaderToggler(false);
      // }
    },
    ...mapActions({
      loaderToggler: "toggleLoader",
    }),
    ...mapActions("editForm", ["showEditTaskDialog"]),
    ...mapActions("listOpts", ["storePriorityTypeOpts", "storeProjectsOpts"]),
    ...mapActions("toast", ["showToast"]),
    ...mapActions("user", ["setUserDetails"]),
    ...mapActions("task", [
      "storeProjects",
      "storeCategories",
      "storeTasks",
      "replaceRecurringTasksInfoInMap",
      "updateSelectedTasks",
    ]),
    // ...mapActions([
    //   "setGroupMode",
    //   "toggleInboxView",
    //   "changeNav",
    // ]),
    ...mapMutations("task", ["updateState"]),
    ...mapActions(["updateRootState"]),
    ...mapActions("resourcesData", ["updateResourcesState"]),
    handleResourcesUpdates(doc) {
      const data = doc.data();
      this.handleResourceDataUpdates(data, () => {
        this.resourcesLoaded = true;
      });
    },
    removeResourcesListener() {
      if (this.resourcesListener) {
        this.resourcesListener();
      }
    },
    async handleRouteChanges() {
      const queryData = this.$route.query;

      if (!isEmpty(this.$route.params) && isEmpty(this.storedRouteParams)) {
        this.storedRouteParams = { ...this.$route.params };
      }

      if (!isEmpty(queryData) && isEmpty(this.storedRouteQueryData)) {
        this.storedRouteQueryData = queryData;
      }
      await this.openResourceItemDataOnNav({
        queryData: !isEmpty(this.storedRouteQueryData)
          ? this.storedRouteQueryData
          : {},
        routeParams: !isEmpty(this.storedRouteParams)
          ? { ...this.storedRouteParams }
          : {},
        returnViewInfo: { ...this.$route.params?.returnViewInfo },
      });

      this.storedRouteParams = undefined;
      this.storedRouteQueryData = undefined;
    },
  },
  created() {
    this.loadedData = false;
  },
  mounted() {
    firebase.auth().onAuthStateChanged(async (user) => {
      this.setLoader(true);
      if (user) {
        initUserResources(user.uid);
        let taskStateUpdates = {};
        let rootStateUpdates = {
          isAreasTipHidden: getAreasTipStatus(),
        };
        await this.patchUserProjectResourceView(user.uid);

        const userAppData = await (
          await DatabaseInterface.get(user.uid).once("value")
        ).val();
        taskStateUpdates = {
          ...taskStateUpdates,
          collapsedGroups:
            userAppData?.view[userAppData.nav]?.collapsedGroups || [],
        };

        if (userAppData?.isProjectsResourceModeEnabled) {
          taskStateUpdates.isProjectsResourceModeEnabled = true;

          rootStateUpdates.currActiveView = "dashboard";
        }

        this.updateState({
          ...taskStateUpdates,
        });
        this.updateRootState({ ...rootStateUpdates });
        this.setUserDetails(user);
        document.addEventListener("keydown", this.handleHotKeys);
        createUserActionsHistory(user.uid);

        await this.$nextTick();
        this.userId = user.uid;
        this.firstRunOfApp = true;
        this.resourcesListener = resourcesCollection.dbStore
          .doc(user.uid)
          .onSnapshot(this.handleResourcesUpdates);
        await this.$nextTick();
        DatabaseInterface.get(user.uid).on("value", this.listenToDataChange);
      }
    });
    EventEmitter.on(REDO_EVENT, this.handleRedoAction);
    EventEmitter.on(UNDO_EVENT, this.handleUndoAction);
    EventEmitter.on(SYNC_COMPLETED, this.handleGoogleSyncCmpted);
    EventEmitter.on(TOGGLE_ORDERED_TASKS, this.handleToggle);
    this.setMentionListeners();
  },
  beforeDestroy() {
    EventEmitter.off(REDO_EVENT, this.handleRedoAction);
    EventEmitter.off(UNDO_EVENT, this.handleUndoAction);
    EventEmitter.off(SYNC_COMPLETED, this.handleGoogleSyncCmpted);
    EventEmitter.off(TOGGLE_ORDERED_TASKS, this.handleToggle);
    document.removeEventListener("keydown", this.handleHotKeys);
    this.removeResourcesListener();
    DatabaseInterface.get(this.userInfo.uid).off("value");
    this.removeMentionListeners();
  },
  watch: {
    dataLoaded(n) {
      if (n && !this.isGoogleCalSyncEnabled) {
        this.handleReturnToView();
        this.openTaskData();
      }
    },
    $route: {
      handler(n, o) {
        if (!isEqual(n, o)) {
          this.handleRouteChanges(n);
          this.handleReturnToView();
        }
      },
      deep: true,
    },
  },
};
</script>
