import EventEmitter from "@/helpers/eventEmitter";
import {
  CONVERT_SUCCESS,
  OPEN_SELECTED_ITEM,
  REFRESH_FORM_DATA,
  TRIGGER_USER_ACTION_EVENT,
} from "@/variables/events";
import { findParentFromPathsByClassName } from "@/utils/hasParentEl";
import { getPathsFromEvent } from "@/utils/events";
import { mapActions } from "vuex";
import { debounce, isEmpty } from "lodash";
import {
  removeObserverFromEl,
  setResizeObserverOnEl,
} from "@/utils/resizeObserver";
import { emptyDescrTypes } from "@/variables/common";
import { mapGetters } from "vuex";
const mixin = {
  mounted() {
    this.debounedResizeEventHandler = debounce(this.handleWindowResize, 250);
  },
  methods: {
    ...mapActions("toast", ["showToast"]),
    ...mapActions("editForm", [
      "closeEditTaskDialog",
      "setTaskData",
      "openEditTaskConfirmBox",
      "setDataForNoteEdit",
      "setDataForResourceEdit",
      "updateCurrTaskData",
    ]),
    ...mapActions("convertModeData", ["setDataForConvertMode"]),
    init() {
      this.setListeners();
    },
    focusOnTitleInput(selectText) {
      setTimeout(() => {
        this.$refs.titleInput?.focusOnEl(selectText);
      });
    },
    async showConfirmBoxForChanges(
      actionEl,
      closeForm = true,
      triggerCloseForm
    ) {
      const actionsWhichRequireFormOpen = [
        "item-change",
        "data-change",
        "open-selected-item",
      ];
      const otherActionsWhichRequireFormOpen = ["open-selected-item"];
      const actionsValsWhichRequireFormOpen = [
        "previous",
        "next",
        "undo",
        "redo",
      ];
      const res = await this.$swal({
        text: "Do you want to save changes or discard them?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Save",
        cancelButtonText: "Discard",
      });

      this.closeFormAfterAction = closeForm;
      if (actionEl) {
        const actionType = actionEl.getAttribute("data-action-type");
        const actionValue = actionEl.getAttribute("data-action-value");

        const preventClose = parseInt(
          actionEl.getAttribute("data-prevent-close")
        );

        // const continueProcess = parseInt(
        //   actionEl.getAttribute("data-continue-process") || "0"
        // );

        const closeFormOnly = parseInt(
          actionEl.getAttribute("data-close-form-only") || "0"
        );

        this.userActionType = actionType;
        this.userActionValue = actionValue;

        if (
          (actionType &&
            actionValue &&
            ((actionsWhichRequireFormOpen.includes(actionType) &&
              actionsValsWhichRequireFormOpen.includes(actionValue)) ||
              otherActionsWhichRequireFormOpen.includes(actionType))) ||
          preventClose
        ) {
          this.closeFormAfterAction = false;
        }

        if (closeFormOnly) {
          this.onlyCloseForm = true;
        }
        this.resumeActionAfterSubmit = true;
      }

      if (triggerCloseForm) {
        this.userActionType = "close-form";
        this.resumeActionAfterSubmit = true;
      }

      if (res.isConfirmed) {
        await this.submitData();
      } else if (res.dismiss === "cancel") {
        this.callActionAfterSubmitSuccess();

        if (!this.closedByEsc && !triggerCloseForm) {
          this.closeDialog();
        }
      } else if (res.dismiss === "backdrop" || res.dismiss === "esc") {
        this.resetUserAction();
      }
    },
    hideChangesFlagOnClick(e) {
      if (!this.isOpen) return;
      const parent = findParentFromPathsByClassName(getPathsFromEvent(e), [
        "descr-editor-wrapper",
        "title-auto-save-input",
      ]);

      if (parent) {
        return;
      }

      if (this.areChangesSaved) this.areChangesSaved = false;
    },
    setDescrText(descrData) {
      this.$refs.editor?.setHTMLData(descrData || "");
    },
    setTitleText(titleData, type = "default") {
      if (type === "default") {
        this.$refs.titleInput.setHTMLData(titleData || "");
      }

      if (type === "resource") {
        this.$refs.resourceTitleInput?.setHTMLData(titleData || "");
      }
    },

    getDescrVal() {
      return this.descrData || "";
    },

    convertHTMLTextToDelta(text) {
      if (this.$refs.editor) {
        return this.$refs.editor.convertHTMLToDelta(text);
      }

      if (this.$refs.titleInput) {
        return this.$refs.titleInput.convertHTMLToDelta(text);
      }
      return {};
    },
    async convertTitleAndSet(dataToConvert, type = "default") {
      await this.$nextTick();
      const convertedDelta = this.convertHTMLTextToDelta(dataToConvert);

      if (!isEmpty(convertedDelta)) {
        if (type === "default") {
          this.titleDelta = [...convertedDelta.ops];
        }

        if (type === "resource") {
          this.resourceTitleDelta = [...convertedDelta.ops];
        }
      }
    },
    handleConvertSuccess() {},
    handleOpenSelectedItem() {},
    setListeners() {
      document.addEventListener("mousedown", this.handleGlobalClick, true);
      document.addEventListener("keydown", this.handleGlobalKeydown);
      EventEmitter.on(CONVERT_SUCCESS, this.handleConvertSuccess);
      EventEmitter.on(REFRESH_FORM_DATA, this.reloadItemData);
      EventEmitter.on(OPEN_SELECTED_ITEM, this.handleOpenSelectedItem);
    },
    removeListeners() {
      document.removeEventListener("mousedown", this.handleGlobalClick);
      document.removeEventListener("keydown", this.handleGlobalKeydown);
      EventEmitter.off(CONVERT_SUCCESS, this.handleConvertSuccess);
      EventEmitter.off(REFRESH_FORM_DATA, this.reloadItemData);
      EventEmitter.off(OPEN_SELECTED_ITEM, this.handleOpenSelectedItem);
    },
    resetUserAction() {
      this.userActionType = "";
      this.userActionValue = "";
    },
    handleGlobalClick(e) {
      this.hideChangesFlagOnClick(e);
      this.checkForEditAndShowConfirmDialog(e);
    },
    handleGlobalKeydown(e) {
      if (
        e.keyCode !== 27 ||
        !this.isOpen ||
        !this.checkIfCheckFlowShouldContinue(e)
      )
        return;

      this.closedByEsc = true;
      this.closeForm();
    },
    triggerActionActionAfterSubmit() {
      EventEmitter.emit(TRIGGER_USER_ACTION_EVENT, {
        type: this.userActionType,
        value: this.userActionValue,
      });
      this.resetUserAction();
      this.closeDialog(true);
    },
    checkForEditAndShowConfirmDialog(e) {
      if (!this.isOpen) return;
      const whileListBtns = ["redo-btn", "undo-btn", "inspect-mode-btn"];
      const parentRes = findParentFromPathsByClassName(
        getPathsFromEvent(e),
        [
          "task-bar-action-btn",
          "import-btn",
          "nav-btn",
          "cal-month-change-btn",
          "cal-day",
          "tree-node",
          "resource-info-pop-up",
          "resource-type-change-btn",
          "interaction",
        ],
        "obj",
        ["data-search-bar"]
      );

      const continueFlow = this.checkIfCheckFlowShouldContinue();

      if (continueFlow && parentRes.found) {
        const parentEl = parentRes.parentEl;

        // const isNodeTogglerBtn = parentEl.classList.includes("e-list-item") &&
        const ignoreBtn = parseInt(parentEl.getAttribute("data-ignore"));
        const taskDataChanged = this.checkIfAnyEditIsMade();

        const isInspectBtn = parentEl.classList.contains("inspect-mode-btn");

        const isNodeTogglerBtn = parentEl.classList.contains("interaction");
        if (isNodeTogglerBtn) return;
        if (isInspectBtn) {
          e.stopImmediatePropagation();
          e.preventDefault();
          e.stopPropagation();
        }

        if (ignoreBtn) {
          return;
        }

        if (taskDataChanged) {
          e.stopPropagation();
          e.preventDefault();
          e.stopImmediatePropagation();

          this.showConfirmBoxForChanges(parentEl);
        } else {
          const classesToCheck = [...parentEl.classList];
          const preventClose = parseInt(
            parentEl.getAttribute("data-prevent-close")
          );

          const continueProcess = parseInt(
            parentEl.getAttribute("data-continue-process") || "0"
          );

          if (continueProcess) {
            this.onlyCloseForm = true;
            this.closeDialog();
            return;
          }
          if (parentEl.classList.contains("resource-info-pop-up")) {
            const resourceId = parentEl.getAttribute("data-action-value");
            if (resourceId) {
              this.triggerOpenResourceInfo(resourceId);
            }

            return;
          }

          if (isInspectBtn) {
            this.closedByEsc = true;
            this.closeDialog();
            return;
          }
          if (
            !classesToCheck.some((className) =>
              whileListBtns.includes(className)
            ) &&
            !preventClose
          ) {
            this.closeDialog(!isInspectBtn);
          }
        }

        return;
      }
    },
    triggerOpenResourceInfo(resrcId) {
      this.refreshData = true;
      EventEmitter.emit(TRIGGER_USER_ACTION_EVENT, {
        type: resrcId ? "open-resource-info" : this.userActionType,
        value: resrcId || this.userActionValue,
      });
    },
    closeDialog() {
      if (this.closeFormAfterAction) {
        this.closeEditTaskDialog();
      }
    },
    removeWindowResizeObserver() {
      // window.removeEventListener("resize", this.debounedResizeEventHandler);
      removeObserverFromEl(document.body);
    },
    setWindowResizeObserver() {
      // window.addEventListener("resize", this.debounedResizeEventHandler);
      setResizeObserverOnEl(
        document.body,
        debounce(this.handleWindowResize, 250)
      );
    },
    handleWindowResize(data) {
      const bodyPosData = data[0].contentRect;
      this.calcAndSetGridHeight(bodyPosData);
    },

    adjustFormOnFormGridStatusChange(isFormGridActive) {
      if (isFormGridActive) {
        this.adjustFormGrid();
      }
    },
    adjustFormGrid() {
      return new Promise((resolve) => {
        setTimeout(async () => {
          const bodyPosData = document.body.getBoundingClientRect();
          await this.calcAndSetGridHeight(bodyPosData);
          resolve();
        }, 0);
      });
    },
    calcAndSetGridHeight(mainWrapperPos) {
      return new Promise((resolve) => {
        const formGridEl = document.querySelector(
          `.form-grid[data-grid-active="true"]`
        );

        if (formGridEl) {
          const formGridWrapperPos = formGridEl.getBoundingClientRect();
          const formTop = formGridWrapperPos.top;
          const newHeightFormHeight = Math.floor(
            mainWrapperPos.height - formTop
          );
          if (newHeightFormHeight > 0) {
            if (newHeightFormHeight > 425) {
              setTimeout(() => {
                formGridEl.style.height = `${newHeightFormHeight}px`;
                resolve(true);
              }, 0);
            } else {
              resolve();
            }
          }
        } else {
          resolve();
        }
      });
    },
    async convertDescrDelta(dataToConvert) {
      await this.$nextTick();
      const convertedDelta =
        this.$refs.editor?.convertHTMLToDelta(dataToConvert);

      if (!isEmpty(convertedDelta)) {
        this.descrDelta = convertedDelta;
      }
    },
    addNewLineInText(text) {
      if (!emptyDescrTypes.includes(text)) {
        text = `${text}<p><br /></p>`;
      }

      return text;
    },
    checkIfEditorShouldBeCollapsed() {
      const editFormSettings = this.editFormSettings;
      if (isEmpty(editFormSettings)) {
        return true;
      }
      const collapsedEditorViews = editFormSettings.editorCollapsedViews || [];
      const currRoute = this.$route.name;

      if (currRoute === "Dashboard") {
        if (this.isProjectsResourceModeEnabled) {
          return collapsedEditorViews.includes("project");
        }
        return collapsedEditorViews.includes("tasks");
      }

      if (currRoute === "Resources") {
        const currResourceMode = this.selectedResourceTypeOpt;
        return collapsedEditorViews.includes(currResourceMode);
      }
    },
  },
  computed: {
    ...mapGetters("resourcesData", {
      selectedResourceTypeOpt: "selectedResourceTypeOpt",
    }),
    ...mapGetters("task", ["isProjectsResourceModeEnabled"]),
    ...mapGetters(["editFormSettings"]),
  },
};

export default mixin;
