import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAppBar,{attrs:{"app":"","color":"var(--primary-color)","flat":"","name":"TaskBar","height":"40px"}},[_c(VRow,{staticClass:"app-bar"},[_c(VCol,{staticClass:"py-0 px-0",attrs:{"cols":"auto"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"action-btn keep-task-selected task-bar-action-btn sidebar-toggle-btn",class:{ 'btn-is-focused': false },attrs:{"text":"","data-ignore":"1"},on:{"click":function($event){return _vm.toggleSidebar()}}},'v-btn',attrs,false),on),[(!_vm.sidebarOpen)?_c(VIcon,{attrs:{"size":"19","color":"white"}},[_vm._v(" "+_vm._s(_vm.activeMenu)+" ")]):_c(VIcon,{attrs:{"size":"19","color":"white"}},[_vm._v("$ctiSidebarClosed")])],1)]}}])},[_c('span',[_vm._v(_vm._s(!_vm.sidebarOpen ? "Show Sidebar" : "Close Sidebar"))])])],1),_c(VCol,{staticClass:"py-0 px-0 mr-1 ml-1",attrs:{"cols":"auto"}},[_c('div',{staticClass:"home-btn-container"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"action-btn keep-task-selected task-bar-action-btn home-btn",attrs:{"text":"","data-action-type":"nav-route-change-by-app-bar","data-action-value":"home"},on:{"click":_vm.handleHomeToggle}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"19","color":"white"}},[_vm._v("$ctiHome")]),_c('span',{staticClass:"label-text d-none d-md-inline-block"},[_vm._v("CTI")])],1)]}}])},[_c('span',[_vm._v(" Home ")])])],1)]),_c(VCol,{staticClass:"pr-0 pl-0 mr-0 py-0",class:{ 'search-expanded': _vm.searchExpanded },attrs:{"cols":"auto","md":"3"}},[_c(VTextField,{ref:"searchInput",class:_vm.searchInputStyles,attrs:{"hide-details":"","clearable":"","clear-icon":"mdi-close-circle","placeholder":_vm.searchPlaceHolder,"type":"text","height":"28","value":_vm.searchVal,"data-ignore":_vm.ignoreSearchBar,"data-action-type":"data-change","data-action-value":"search","data-search-bar":"1"},on:{"click:append":_vm.search,"click:clear":_vm.clearValues,"input":_vm.handleSearch,"keydown":_vm.handleKeydown,"blur":_vm.handleSearchBlur},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [(_vm.isGlobalViewEnabled)?_c(VIcon,{staticClass:"search-icon",attrs:{"size":"20","color":"#fff"}},[_vm._v(" mdi-search-web ")]):_c(VIcon,{staticClass:"search-icon",attrs:{"size":"20","color":"#fff"}},[_vm._v(" mdi-magnify ")]),_c('div',[_c(VMenu,{attrs:{"bottom":"","right":"","offset-y":true,"direction":"bottom"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"search-area-menu-btn",attrs:{"dark":"","icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-menu-down")])],1)]}}])},[_c(VList,[_c(VListItem,{key:"here",class:{ 'primary--text': !_vm.isGlobalViewEnabled },on:{"click":function($event){return _vm.toggleSearchContext($event, 'here')}}},[_c(VListItemIcon,{staticClass:"search-list-icon"},[_c(VIcon,[_vm._v("mdi-magnify")])],1),_c(VListItemTitle,[_vm._v("Search Here")])],1),_c(VListItem,{key:"global",class:{ 'primary--text': _vm.isGlobalViewEnabled },on:{"click":function($event){return _vm.toggleSearchContext($event, 'global')}}},[_c(VListItemIcon,{staticClass:"search-list-icon"},[_c(VIcon,[_vm._v("mdi-search-web")])],1),_c(VListItemTitle,[_vm._v("Search Everywhere")])],1)],1)],1)],1)]},proxy:true}])})],1),_c(VCol,{staticClass:"px-0 pl-2 taskbar-actions-wrapper",attrs:{"cols":"auto","md":"auto"}},[_c(VRow,{staticClass:"flex-nowrap",attrs:{"no-gutters":true}},[_c(VCol,{directives:[{name:"show",rawName:"v-show",value:(_vm.showTaskVisibility),expression:"showTaskVisibility"}],staticClass:"text-center"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"action-btn task-bar-action-btn",class:_vm.activeCleanableBtnClass,attrs:{"text":"","data-ignore":"1"},on:{"click":_vm.handleBroomBtnClick}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":"white"}},[_vm._v(" $ctiEye ")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.broomBtnLabel)+" ")])])],1),_c(VCol,{directives:[{name:"show",rawName:"v-show",value:(_vm.showInspectBtn),expression:"showInspectBtn"}],staticClass:"text-center"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.showInspectBtn),expression:"showInspectBtn"}],staticClass:"action-btn search-btn inspect-mode-btn task-bar-action-btn",class:_vm.activeInspectBtnClass,attrs:{"text":"","data-action-type":"data-change","data-action-value":"inspect"},on:{"mousedown":_vm.inspectModeSwitch}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":"white"}},[_vm._v(" $ctiDocSearch ")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.inspectBtnTooltip)+" ")])])],1),_c(VCol,{staticClass:"text-center"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"action-btn small-icon task-bar-action-btn undo-btn",attrs:{"text":"","data-action-type":"data-change","data-action-value":"undo"},on:{"click":_vm.undo}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":"white"}},[_vm._v(" $ctiUndo ")])],1)]}}])},[_c('span',[_vm._v(" Undo ")])])],1),_c(VCol,{staticClass:"text-center"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"action-btn small-icon task-bar-action-btn redo-btn",attrs:{"text":"","data-action-type":"data-change","data-action-value":"redo"},on:{"click":_vm.redo}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":"white"}},[_vm._v(" $ctiRedo ")])],1)]}}])},[_c('span',[_vm._v(" Redo ")])])],1),_c(VCol,{staticClass:"text-center"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.showItemHandlerBtns),expression:"showItemHandlerBtns"}],staticClass:"action-btn task-bar-action-btn",attrs:{"text":"","data-prevent-close":_vm.preventFormCloseOnDeleteAttr},on:{"click":_vm.addItem}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":"white"}},[_vm._v(" $ctiAdd ")])],1)]}}])},[_c('span',[_vm._v(" Add New "+_vm._s(_vm.viewItemLabel)+" ")])])],1),_c(VCol,{staticClass:"delete-btn text-center"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.showItemHandlerBtns),expression:"showItemHandlerBtns"}],staticClass:"action-btn task-bar-action-btn",attrs:{"text":"","data-prevent-close":_vm.preventFormCloseOnDeleteAttr},on:{"click":_vm.deleteItem}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":"white"}},[_vm._v("mdi-minus")])],1)]}}])},[_c('span',[_vm._v(" Delete Selected "+_vm._s(_vm.removeItemBtnLabel)+" ")])])],1)],1)],1),_c(VSpacer,{staticClass:"d-none d-sm-block"}),_c(VCol,{staticClass:"d-none d-sm-block",attrs:{"cols":"auto"}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"auto"}}),_c(VCol,{staticClass:"py-0 pr-0",attrs:{"cols":"auto"}},[(_vm.userInfo)?_c('div',{staticClass:"d-none d-sm-block"},[_c('SettingsMenu',{attrs:{"color":"white"}})],1):_c('div',{staticClass:"py-0 pr-0"},[_c('router-link',{attrs:{"to":"/"}},[_c(VBtn,{staticClass:"float-right",attrs:{"tile":"","text":"","color":"white"}},[_vm._v(" SIGN IN ")])],1)],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }