import {
  cleanTasks as cleanTaskList,
  addTasks,
  deleteTasks,
  // createAddedTaskDataForAddAction,
  fillTaskData,
  cleanTask,
  callTasksRemove,
  processTask,
  callRefreshList,
  isTaskRecurring,
  getRecurringTasks,
  createDateTimeFromDue,
  addOrRemoveOrUpdateTasksInLocalTasksList,
  addOrRemoveOrUpdateTasksInDb,
  parseRuleDataFromTaskRecurrenceRules,
  createTaskDataFromProvidedData,
  convertTaskKeyForGoogleCal,
  createTaskDataForGoogleCal,
  getDateAndTimeFromDueDate,
  createTaskId,
  createGoogleEventIdByDateAndTaskId,
  createDbUpdateDataFromProvidedData,
  createRecurringDatesFromTaskRules,
  getRecurrenceIdByDateFromRecurrences,
  removeTasksFromRecurringTasksMap,
  excludeDatesInTaskRules,
  createTaskDueDate,
  whiteListDatesFromList,
  addOrUpdateRecurringTasksInRecurringTasksInfoMap,
  isTaskVirtual,
  getPausedReminderDaysVal,
  createNextRepeatTask,
  checkIfTaskHasReminder,
  createReminderTasks,
  getReminderTasksLinkedToTaskId,
  // createNextModeFilters,
  checkIfSelectedTaskCateHasOnlyArea,
  getMainTaskRulesAndFillRecurringOpts,
  processDroppedFlaggedAndUnflaggedTasksForCustomOrder,
  getTasksByCustomOrderAndOrderIndex,
  createDbDataForConvertingRecurringTaskToRealTask,
  getOrderedTasksByProject,
  createDateRangeBasedOnForecastVal,
  createContinuedTaskData,
  getFirstVirtualReccuringTask,
  checkIfTaskIsParentRecurringTask,
  checkIfSelectedTaskFiltersHasCategory,
  checkIfTaskIsRecurring,
  areTaskRecurrenceRulesValid,
  createRecurrenceOptsValuesFromTaskRecurrenceRules,
  filterOutCustomOrderTasks,
  createSearchQueryFilters,
  duplicateTask,
  checkTaskSettingsIsNotEmpty,
  checkTaskIsAllDay,
  isTaskNotCompleted,
  isTaskAfterDateFilterCreator,
  removeTasksFromRecurringTasksMapByDates,
  replaceRecurringTasksInfoInMap,
  createVirtualTasksFromRecurringTasks,
  getTaskTreeView,
  createTaskCategorySelectionOptsList,
  createTaskGroupFiltersFromList,
  checkIfTaskContainsGroupKey,
} from "@/helpers/tasks";

import filterList from "@/core/components/DataTable/lib/pandas/filter";
import {
  addMultiNotesInNotesList,
  removeNotesFromNotesList,
} from "@/mixins/notesHelpersMixin";
import {
  TASK_ACTIONS,
  getUserActions,
  PROJECT_ACTIONS,
  NOTES_ACTIONS,
} from "@/helpers/actionHistory";
import userDetailsMixin from "./userDetailsMixin";
import viewHelpersMixin from "./viewHelpersMixin";
import googleCalHelpersMixin, {
  addOrUpdateOrRemoveTasksInGoogleCal,
  addTasksToGoogleCal,
} from "@/mixins/googleCalHelpersMixins";
import store from "@/store";
import { mapActions } from "vuex";
import { cloneDeep, isEmpty, isEqual, isPlainObject } from "lodash";
import Vue from "vue";
import { mapGetters } from "vuex";
import { createRRuleSet } from "@/helpers/rrulesHelpers";
import {
  addDaysToDate,
  addMonthsToDate,
  addWeeksToDate,
  convertDateToUTCWithLocalTime,
  formatDate,
  getCurrDate,
  getDateValue,
  // isBetweenDates,
  isDateBetweenDates,
  isSameDates,
  isValidDate,
  makeDateFormat,
  makeTimeFormat,
  parseJSONDate,
  subDate,
  subFromDate,
} from "@/helpers/dates";
import createUniqueId from "@/utils/createUniqueId";
import removeProps from "@/utils/removeProps";
import { INTERNAL_DATE_FORMAT, INTERNAL_TIME_FORMAT } from "@/variables/dates";
import {
  addOrRemoveOrUpdateNotesInLocalList,
  cleanNote,
  cleanNotes,
  createNoteDataForAdd,
} from "@/helpers/notes";
import { endOfDay, isSameDay, parseISO } from "date-fns";
import { convertDescrValToHTMLString, emitRefreshList } from "@/helpers/common";
import DatabaseInterface from "@/services/DatabaseInterface";
import { PRIMARY_COLOR } from "@/variables/colors";
import {
  areQlDeltasEqual,
  convertHTMLToQlDelta,
  createOrGetQlInstance,
  removeQlInstance,
} from "@/helpers/quill";
import {
  DEFAULT_TASK_GROUP_FILTER_VALUE,
  INLINE_EDIT_TASK_PROPS,
  REMINDER_TASK_EDIT_PROPS,
  TASK_GROUP_KEY_FOR_FILTER,
  TASK_GROUP_KEY_FOR_GROUP_FILTER,
  TASK_NEXT_ACTION_GROUP_KEY_VALUES_FOR_FILTER,
} from "@/variables/tasks";
import EventEmitter from "@/helpers/eventEmitter";
import {
  OPEN_INSPECT_MODE,
  OPEN_SELECTED_ITEM,
  OPEN_SELECTED_NEW_EDIT_TASK_FORM,
  OPEN_STATUS_EDIT_MODE,
  REFRESH_ACTIVITY_HISTORY,
  REFRESH_FORM_DATA,
  REFRESH_FULL_FORM,
  REFRESH_TASKS_EVENT,
  REFRESH_TASK_DATA_IN_RESOURCE_FORM,
  REFRESH_TREE_EVENT,
  SELECT_TASK_IN_ACTIVITY_GRID,
  SET_ID_FOR_SCROLL,
  SET_SELECTED_NODE_EVENT,
  TOGGLE_RESOURCE_FORM_EVENT,
} from "@/variables/events";
import {
  changeCatOfProjectResources,
  checkIfSelectedResourceCateHasCategoryOnly,
  expandAllParentsOfResource,
  getAllMentionedResourceIdsFromText,
  getProjectResourcesFromText,
  getResourcesByResourceIds,
  getUserResources,
  processResourceData,
  setResourceIdInTree,
} from "@/helpers/resources";
import { decrement, increment } from "@/utils/number";
import { mapMutations } from "vuex";
import isUndefinedVal from "@/utils/isUndefinedVal";
import {
  calModes,
  taskFiltersConfigMap,
  treeSideBarModes,
} from "@/variables/viewConfigs";
import { makeNodeVisible } from "@/helpers/categories";
import {
  findResourceCateDataByRef,
  getResourceCateFromTaskCategories,
} from "@/helpers/resourceCategoriesHelpers";
import { updateResourceCateInResourceCateData } from "./resourcesHelpersMixin";
import GoogleCalHelpers from "@/helpers/googleCalHelpers";
// import { isBetweenInterval } from "@/helpers/dates";

const currDate = new Date();

/**
 * It takes the list of tasks to remove
 * @param {Object[]} tasksToRemove - list of tasks to remove
 * @param {Boolean} recordAction - To record action or not by default its true
 * @returns {Promise}
 */
export async function removeTasksFromTaskList(
  tasksToRemove,
  recordAction = true
) {
  if (!Array.isArray(tasksToRemove)) tasksToRemove = [tasksToRemove];
  const cleanedTasks = tasksToRemove.map((task) =>
    cleanTask(fillTaskData(task))
  );

  await deleteTasks(cleanedTasks, store.getters["user/userInfo"].uid);

  if (recordAction) {
    getUserActions().addTaskAction({
      type: TASK_ACTIONS.BATCH_DELETE,
      data: cleanedTasks,
      on: "task",
    });
  }
}
/**
 * It takes the list of tasks to add
 * @param {Object[]|Object} tasksToAdd - list of tasks to add
 * @param {Boolean} recordAction - To record action or not by default its true
 * @param {Boolean} cleanTasks - To clean tasks before adding default is true
 * @returns {Promise}
 */
export const addMultiTasksInList = async (
  tasksToAdd,
  recordAction = true,
  cleanTasks = true
) => {
  if (!Array.isArray(tasksToAdd)) tasksToAdd = [tasksToAdd];

  if (cleanTasks) tasksToAdd = cleanTaskList(tasksToAdd);

  // tasksToAdd = cleanTasks(tasksToAdd);
  await addTasks(tasksToAdd, store.getters["user/userInfo"].uid);
  if (recordAction) {
    // const tasksData = createAddedTaskDataForAddAction(tasksToAdd);
    let actionToAdd = TASK_ACTIONS.BATCH_ADD;
    if (tasksToAdd.length === 1) {
      actionToAdd = TASK_ACTIONS.ADD;
      tasksToAdd = tasksToAdd[0];
    }
    await getUserActions().addTaskAction({
      type: actionToAdd,
      data: tasksToAdd,
    });
  }
};

/**
 * It takes the list of notes and converts them to tasks
 * @param {Object[]|Object} tasksToConvert - list of notes to convert
 * @returns {Promise}
 */
export const convertNotesToTasks = async (notesToConvert) => {
  if (!Array.isArray(notesToConvert)) notesToConvert = [notesToConvert];
  const notesToRemove = [];
  const tasksToAdd = [];
  notesToConvert.forEach((note) => {
    const { key, ...restData } = note;
    const createdTaskData = fillTaskData({ ...restData, taskType: "task" });
    tasksToAdd.push({ ...createdTaskData });
    notesToRemove.push({ ...restData, key });
  });

  addMultiTasksInList(tasksToAdd, false);
  removeNotesFromNotesList(notesToRemove, false);
};

const sortTaskType = (list, order) => {
  // console.log('sortTaskType', list);
  list.sort((a, b) => {
    const aT = a.taskType;
    const bT = b.taskType;

    if (aT > bT) {
      if (order === "asc") {
        return -1;
      }
      return 1;
    } else if (aT < bT) {
      if (order === "asc") {
        return 1;
      }
      return -1;
    } else {
      return 0;
    }
  });
};

const sortModifiedCol = (a, b, order) => {
  // console.log('sortModifiedCol', a, b);
  let modifiedA = getDateValue(a.modifiedAsDate);
  let modifiedB = getDateValue(b.modifiedAsDate);

  if (!modifiedA) {
    modifiedA = new Date("1970-01-01");
  }
  if (!modifiedB) {
    modifiedB = new Date("1970-01-01");
  }
  if (modifiedA > modifiedB) {
    if (!!order && order === "asc") {
      return 1;
    }
    return -1;
  } else if (modifiedA < modifiedB) {
    if (!!order && order === "asc") {
      return -1;
    }
    return 1;
  } else {
    return 0;
  }
};

const mixin = {
  mixins: [userDetailsMixin, googleCalHelpersMixin, viewHelpersMixin],
  inject: {
    toggleOrderTasksData: {
      from: "toggleData",
      default: {},
    },
  },
  methods: {
    ...mapActions([
      "updateRootState",
      "storeCurrViewData",
      "setSubTasksListGroupFilter",
    ]),
    ...mapActions("resourceInfo", ["openResourceInfoViewer"]),
    ...mapActions({
      loaderToggler: "toggleLoader",
    }),
    ...mapActions("deleteRecurringConfirmDialog", {
      openDeleteRecurringDialog: "openDialog",
    }),
    ...mapMutations("task", ["updateState"]),
    ...mapActions("toast", {
      showToastMsg: "showToast",
    }),
    ...mapActions("editForm", [
      "showEditTaskDialog",
      "showNewEditTaskDialog",
      "closeEditTaskDialog",
      "setTaskData",
    ]),
    ...mapMutations(["setLoader"]),
    ...mapActions("task", [
      "addOrUpdateOrRemoveTasks",
      "removeAllTasks",
      "updateSelectedTasks",
      "setTaskGroupFilter",
    ]),
    ...mapActions("resourceInfo", ["setStoredTaskData"]),
    ...mapActions("task", [
      "clearListFilters",
      "replaceRecurringTasksInfoInMap",
    ]),
    ...mapActions("resourceInfo", ["closeResourceInfoViewer"]),
    ...mapActions("convertModeData", ["setDataForConvertMode"]),
    // async addTaskInTaskList(taskData, recordAction = true, cleanTasks = true) {

    //   const cleanedTasks = cleanTasks(taskData);
    //   await addTasks(cleanedTasks, this.userInfo.uid);
    //   if (recordAction) {
    //     // const tasksData = createAddedTaskDataForAddAction([taskData]);
    //     await getUserActions().addTaskAction({
    //       type: TASK_ACTIONS.BATCH_ADD,
    //       data: cleanedTasks,
    //     });
    //   }
    // },
    /**
     * It takes the list of tasks to add
     * @param {Object[]|Object} tasksToAdd - list of tasks to add
     * @param {Boolean} recordAction - To record action or not by default its true
     * @param {Boolean} cleanTasks - To clean tasks before adding default is true
     * @returns {Promise}
     */
    async addMultiTasksInList(
      tasksToAdd,
      recordAction = true,
      cleanTasks = true
    ) {
      if (!Array.isArray(tasksToAdd)) tasksToAdd = [tasksToAdd];
      addMultiTasksInList(tasksToAdd, recordAction, cleanTasks);
      await Vue.nextTick();
      let reminderTasksToAdd = [];

      tasksToAdd.forEach((t) => {
        reminderTasksToAdd.push(...createReminderTasks(t));
      });
      addOrRemoveOrUpdateTasksInLocalTasksList({
        tasksToAdd: [...reminderTasksToAdd, ...tasksToAdd],
        addMethod: !isEmpty(reminderTasksToAdd) ? "push" : "unshift",
        verifyAdd: isEmpty(reminderTasksToAdd),
      });

      await Vue.nextTick();

      EventEmitter.emit(REFRESH_ACTIVITY_HISTORY);

      // this.addOrUpdateOrRemoveTasksLocally({
      //   tasksToAdd: tasksToAdd.map((task) => processTask(task)),
      // });
      // callRefreshList();
    },
    /**
     * It takes the list of tasks to add and adds them to db with default values if not present
     * @param {Object[]|Object} tasksToAdd - list of tasks to add
     * @param {Boolean} recordAction - To record action or not by default its true
     * @returns {Promise}
     */
    async fillTaskDataThenAddInList(tasksToAdd, recordAction = true) {
      if (!Array.isArray(tasksToAdd)) tasksToAdd = [tasksToAdd];
      let currTasksCount = Object.values(this.rawTasksMap || {}).length || 0;
      tasksToAdd = tasksToAdd.map((task) => {
        const createdData = {
          ...fillTaskData(task),
          order: currTasksCount,
          userPosition: 0,
          modified: "",
        };
        currTasksCount++;
        return createdData;
      });

      await this.addMultiTasksInList(tasksToAdd, recordAction, false);
    },
    /**
     * It takes the list of tasks to remove
     * @param {Object[]} tasksToRemove - list of tasks to remove
     * @param {Boolean} recordAction - To record action or not by default its true
     * @returns {Promise}
     */
    async removeTasksFromTaskList(
      tasksToRemove,
      recordAction,
      refreshList = true
    ) {
      if (!Array.isArray(tasksToRemove)) tasksToRemove = [tasksToRemove];
      removeTasksFromTaskList(tasksToRemove, recordAction);
      const reminderTasksToRemove = [];

      tasksToRemove.forEach((t) => {
        const linkedReminders = getReminderTasksLinkedToTaskId(
          this.tasks,
          t.key
        );

        if (!isEmpty(linkedReminders)) {
          reminderTasksToRemove.push(...linkedReminders);
        }
      });

      if (this.isGoogleCalSyncEnabled) {
        this.removeTaskFromGoogleCal(tasksToRemove);
      }
      await Vue.nextTick();

      callTasksRemove(
        [...tasksToRemove, ...reminderTasksToRemove],
        refreshList
      );
      // this.addOrUpdateOrRemoveTasksLocally({
      //   taskIdsToRemove: tasksToRemove.map((task) => task.key),
      // });
      // callTasksRemove(tasksToRemove);
    },
    async addOrUpdateOrRemoveTasksLocally(data, callEvent = true) {
      let { tasksToUpdate, taskIdsToRemove, tasksToAdd } = data;
      const updates = {};
      if (!isEmpty(tasksToUpdate)) {
        if (!Array.isArray(tasksToUpdate)) {
          tasksToUpdate = [tasksToUpdate];
        }
        updates.tasksToUpdate = tasksToUpdate;
      }

      if (!isEmpty(taskIdsToRemove)) {
        if (!Array.isArray(taskIdsToRemove)) {
          taskIdsToRemove = [taskIdsToRemove];
        }
        updates.taskIdsToRemove = taskIdsToRemove;
      }

      if (!isEmpty(tasksToAdd)) {
        if (!Array.isArray(tasksToAdd)) {
          tasksToAdd = [tasksToAdd];
        }
        updates.tasksToAdd = tasksToAdd;
      }
      this.addOrUpdateOrRemoveTasks({
        ...updates,
      });

      await Vue.nextTick();

      if (callEvent) {
        EventEmitter.emit(REFRESH_TASKS_EVENT);
      }
    },
    async updateTasksInTable(data) {
      this.addOrUpdateOrRemoveTasksLocally({
        tasksToUpdate: data.tasksToUpdate,
        tasksToAdd: data.tasksToAdd,
        taskIdsToRemove: data.taskIdsToRemove,
      });
    },
    async removeAllTasksFromLocalList() {
      this.removeAllTasks();
      await Vue.nextTick();
      callRefreshList();
    },
    async convertNotesToTasks(notesToConvert) {
      if (!Array.isArray(notesToConvert)) notesToConvert = [notesToConvert];
      const notesToRemove = [];
      const tasksToAdd = [];
      const tasksToAddInGoogle = [];
      let currTasksCount = Object.keys(this.rawTasksMap || {}).length || 0;
      notesToConvert.forEach((note) => {
        const { key, ...restData } = note;
        const createdTaskData = fillTaskData({
          ...restData,
          taskType: "task",
          description: convertDescrValToHTMLString(restData.descr) || "",
          created: restData.created,
          modified: restData.modified,
        });

        createdTaskData.order = currTasksCount;
        createdTaskData.userPosition = 0;
        createdTaskData.customPosition = currTasksCount;
        createdTaskData.customPositionIndex = 0;
        if (createdTaskData.due !== "none") {
          const googleData = createTaskDataForGoogleCal({
            ...createdTaskData,
            dueAsDate: createDateTimeFromDue(
              createdTaskData.due,
              createdTaskData.time
            ),
          });
          tasksToAddInGoogle.push({
            key: createdTaskData.key,
            data: {
              ...googleData,
              taskKey: createdTaskData.key,
            },
          });
        }
        tasksToAdd.push({ ...createdTaskData });
        notesToRemove.push(cleanNote({ ...restData, key }));
        currTasksCount++;
      });
      addOrRemoveOrUpdateNotesInLocalList({
        notesToRemove: [...notesToRemove],
      });

      getUserActions().addNoteAction({
        type: NOTES_ACTIONS.CONVERTED_TO_TASKS,
        data: {
          tasksToAddData: tasksToAdd,
          notesToRemoveData: notesToRemove,
        },
      });
      await removeNotesFromNotesList(notesToRemove, false);
      await addMultiTasksInList(tasksToAdd, false);
      if (this.isGoogleCalSyncEnabled) {
        addOrUpdateOrRemoveTasksInGoogleCal(
          {
            tasksToAdd: tasksToAddInGoogle,
          },
          false
        );
      }

      // await Vue.nextTick();
      // this.addOrUpdateOrRemoveTasks({
      //   tasksToAdd: tasksToAdd.map((taskData) => processTask(taskData)),
      // });
    },
    async removeRecurringOrNormalTasks(tasksToProcess, recordAction = true) {
      const selectedTasks = tasksToProcess;
      // let tasksToRemove = [];
      // let tasksToUpdate = [];
      let dbRemoveTasksList = [];
      let dbTasksUpdateList = [];
      let googleTasksToRemove = [];
      // let localTasksUpdateList = [];
      let localTasksRemoveList = [];

      const removeTasksFromRecurringTasksMapList = [];
      let remindersToRemove = [];
      // let googleTasksUpdateList = [];
      // let undoTasksList = [];

      // let recurringTasksMap = {};
      let groupedTasksMap = {};
      let restoreRecurringOrNormalTasksDataMap = {};
      let googleRecurringEventUpdateListMap = {};
      let closeForm = false;
      if (isEmpty(selectedTasks)) {
        return;
      }
      selectedTasks.forEach((task) => {
        const isRecurring = isTaskRecurring(task);
        const linkedTo = task.linkedTo;
        let groupID = "normal";
        let groupGoogleEventId = "";
        if (isRecurring && linkedTo && this.rawTasksMap[linkedTo]) {
          groupID = linkedTo;
          groupGoogleEventId = this.rawTasksMap[linkedTo].googleEventId;
        }

        if (!groupedTasksMap[groupID]) {
          groupedTasksMap[groupID] = {
            key: groupID,
            children: [],
            googleEventId: groupGoogleEventId,
          };
        }
        groupedTasksMap[groupID].children.push(task);
      });

      for (const groupIDKey in groupedTasksMap) {
        if (groupIDKey !== "normal") {
          if (!restoreRecurringOrNormalTasksDataMap[groupIDKey]) {
            restoreRecurringOrNormalTasksDataMap[groupIDKey] = [];
          }
          const childTasks = groupedTasksMap[groupIDKey].children;
          const mainTaskRawData = this.rawTasksMap[groupIDKey];
          const mainTaskRecurrenceRules = mainTaskRawData.recurrence;
          let mainTaskDataUpdate = {};
          let removeChildrenTasksIds = [];
          const { ruleSet: parsedRuleSet } =
            parseRuleDataFromTaskRecurrenceRules({
              recurrence: mainTaskRecurrenceRules,
              dueAsDate: createDateTimeFromDue(
                mainTaskRawData.due,
                mainTaskRawData.time
              ),
            });

          if (!isEmpty(childTasks)) {
            childTasks.forEach((childT) => {
              const isVirtualTask = childT.isVirtual;
              let currTaskDueDate = childT.dueAsDate;
              if (!currTaskDueDate) {
                currTaskDueDate = createDateTimeFromDue(
                  childT.due,
                  childT.time
                );
              }

              if (
                !closeForm &&
                this.isEditFormOpen &&
                !isEmpty(this.editTaskData) &&
                this.editTaskData.key === childT.key
              ) {
                closeForm = true;
              }

              const linkedReminders = getReminderTasksLinkedToTaskId(
                this.tasks,
                childT.key
              );

              let currRawData = {};
              let taskDataToStore = {};
              localTasksRemoveList.push(childT);
              removeChildrenTasksIds.push(childT);
              dbRemoveTasksList.push(childT);

              if (!isEmpty(linkedReminders)) {
                remindersToRemove.push(...linkedReminders);
              }
              if (!isVirtualTask) {
                currRawData = this.rawTasksMap[childT.key];
                taskDataToStore = { ...currRawData };
              } else {
                currRawData = cloneDeep(this.rawTasksMap[groupIDKey]);
                const createDateData = getDateAndTimeFromDueDate(
                  currTaskDueDate,
                  undefined,
                  false
                );
                currRawData = {
                  ...currRawData,
                  ...createDateData,
                };

                if (currRawData.isFromGoogle && currRawData.googleEventId) {
                  currRawData.googleEventId =
                    createGoogleEventIdByDateAndTaskId(
                      currRawData.googleEventId.split("_")[0],
                      currTaskDueDate
                    );
                } else {
                  currRawData.googleEventId =
                    createGoogleEventIdByDateAndTaskId(
                      groupIDKey,
                      currTaskDueDate
                    );
                  currRawData.isFromGoogle = false;
                }
                taskDataToStore = {
                  key: childT.key,
                  isCalendarOnly: !!childT.isCalendarOnly,
                  isFirstTask: !!childT.isFirstTask,
                  ...createDateData,
                  googleEventId: currRawData.googleEventId,
                  isVirtual: true,
                };

                if (currRawData.googleEventId) {
                  taskDataToStore.googleCalendarId =
                    GoogleCalHelpers.getStoredCalId();
                }
              }

              excludeDatesInTaskRules([currTaskDueDate], parsedRuleSet);

              restoreRecurringOrNormalTasksDataMap[groupIDKey].push(
                taskDataToStore
              );

              // if (isValidDate(currTaskDueDate)) {
              //   parsedRuleSet.exdate(converDateToUTC(currTaskDueDate));
              // }
              if (this.isGoogleCalSyncEnabled) {
                // console.debug("DATA TO SEND", dataToSend);

                if (
                  !googleRecurringEventUpdateListMap[
                    mainTaskRawData.googleEventId
                  ]
                ) {
                  googleRecurringEventUpdateListMap[
                    mainTaskRawData.googleEventId
                  ] = {
                    childrenUpdates: [],
                    mainEventUpdates: {},
                  };
                }
                googleRecurringEventUpdateListMap[
                  mainTaskRawData.googleEventId
                ].childrenUpdates.push({
                  currData: {
                    dueAsDate: createDateTimeFromDue(
                      currRawData.due,
                      currRawData.time
                    ),
                    taskKey: taskDataToStore.key,
                    googleEventId: currRawData.googleEventId,
                  },
                  newData: createTaskDataForGoogleCal(
                    { status: "cancelled", taskKey: currRawData.key },
                    { ...currRawData }
                  ),
                });
              }
            });
          }

          const convertedRules = parsedRuleSet.valueOf().slice(1);

          if (!isEqual(convertedRules, mainTaskRecurrenceRules)) {
            mainTaskDataUpdate = {
              recurrence: convertedRules,
            };

            dbTasksUpdateList.push({
              key: mainTaskRawData.key,
              updates: {
                recurrence: convertedRules,
              },
            });

            if (mainTaskRawData.googleEventId) {
              if (
                !googleRecurringEventUpdateListMap[
                  mainTaskRawData.googleEventId
                ]
              ) {
                googleRecurringEventUpdateListMap[
                  mainTaskRawData.googleEventId
                ] = {
                  childrenUpdates: [],
                  mainEventUpdates: {},
                };
              }

              googleRecurringEventUpdateListMap[
                mainTaskRawData.googleEventId
              ].mainEventUpdates = createTaskDataForGoogleCal(
                {
                  ...googleRecurringEventUpdateListMap[
                    mainTaskRawData.googleEventId
                  ].mainEventUpdates,
                  ...mainTaskDataUpdate,
                  taskKey: mainTaskRawData.key,
                },
                { ...mainTaskRawData }
              );
            }
          }

          if (!isEmpty(removeChildrenTasksIds)) {
            removeTasksFromRecurringTasksMapList.push({
              mainTaskId: mainTaskRawData.key,
              tasksIds: removeChildrenTasksIds,
            });
          }
          // Add recurring logic
        } else {
          const normalChildTasks = groupedTasksMap[groupIDKey].children;

          if (!restoreRecurringOrNormalTasksDataMap[groupIDKey]) {
            restoreRecurringOrNormalTasksDataMap[groupIDKey] = [];
          }
          if (!isEmpty(normalChildTasks)) {
            normalChildTasks.forEach((childT) => {
              const linkedReminders = getReminderTasksLinkedToTaskId(
                this.tasks,
                childT.key
              );

              if (
                !closeForm &&
                this.isEditFormOpen &&
                !isEmpty(this.editTaskData) &&
                this.editTaskData.key === childT.key
              ) {
                closeForm = true;
              }
              if (!isEmpty(linkedReminders)) {
                remindersToRemove.push(...linkedReminders);
              }
              restoreRecurringOrNormalTasksDataMap[groupIDKey].push(
                cleanTask(fillTaskData(childT))
              );
              localTasksRemoveList.push(childT);
              dbRemoveTasksList.push(childT);
              googleTasksToRemove.push(childT);
            });
          }
          // Add normal task logic
        }
      }

      const dbUpdates = {
        tasksToRemove: dbRemoveTasksList,
        tasksToUpdate: dbTasksUpdateList,
      };
      await addOrRemoveOrUpdateTasksInLocalTasksList({
        tasksToRemove: [...localTasksRemoveList, ...remindersToRemove],
      });

      removeTasksFromRecurringTasksMap(removeTasksFromRecurringTasksMapList);

      if (closeForm) {
        this.closeEditTaskDialog();
      }
      EventEmitter.emit(REFRESH_ACTIVITY_HISTORY);
      addOrRemoveOrUpdateTasksInDb(dbUpdates);
      if (recordAction) {
        getUserActions().addTaskAction({
          type: TASK_ACTIONS.NORMAL_RECURRING_DELETE,
          data: restoreRecurringOrNormalTasksDataMap,
          on: "task",
        });
      }
      if (this.isGoogleCalSyncEnabled) {
        this.updateRecurringAndItsChildEventsFromMap(
          googleRecurringEventUpdateListMap
        );

        addOrUpdateOrRemoveTasksInGoogleCal({
          tasksToRemove: googleTasksToRemove,
        });
      }

      return {
        editedData: restoreRecurringOrNormalTasksDataMap,
      };
    },
    async restoreRemovedNormalOrRecurringTasks(tasksListMapData) {
      // let dbUpdates = {};

      let localTasksToAdd = [];
      let recurrenceTasksMapList = [];
      let dbTasksToAdd = [];
      let dbTasksUpdateList = [];
      let googleTasksToUpdate = [];
      let tasksToAddInGoogle = [];
      let addOrUpdateRecurringTasksInInfoMapList = [];
      let reminderTasksToAdd = [];
      // let localTasksUpdateList = [];
      // let currTasksCount = Object.keys(this.rawTasksMap || {}).length || 0;
      for (const groupId in tasksListMapData) {
        if (groupId !== "normal") {
          let removedTasks = [];
          let addRecurringTasks = false;
          let taskDataToUse = {};
          if (isPlainObject(tasksListMapData[groupId])) {
            addRecurringTasks = tasksListMapData[groupId].removedRecurringTasks;
            taskDataToUse = tasksListMapData[groupId].taskData;
          } else if (Array.isArray(tasksListMapData[groupId])) {
            removedTasks = tasksListMapData[groupId];
          }

          const mainTaskData = this.rawTasksMap[groupId];

          if (!isEmpty(removedTasks) && !addRecurringTasks) {
            const datesToWhiteList = [];
            let recurringChildTasksList = [];
            const { rule: parsedRuleData, ruleSet: parsedRuleSetData } =
              parseRuleDataFromTaskRecurrenceRules({
                ...mainTaskData,
                dueAsDate: createDateTimeFromDue(
                  mainTaskData.due,
                  mainTaskData.time
                ),
              });

            const currExcludedDates = parsedRuleSetData.exdates();
            const newRuleSet = createRRuleSet({ ...parsedRuleData.options });
            removedTasks.forEach((removedTask) => {
              const isTaskVirtual = removedTask.isVirtual;
              let taskDataToUse = removedTask;

              // taskDataToUse.order = currTasksCount;
              // taskDataToUse.userPosition = 0;

              if (!isTaskVirtual) {
                dbTasksToAdd.push(
                  fillTaskData({
                    ...removedTask,
                    // order: currTasksCount,
                    // userPosition: 0,
                  })
                );
                if (removedTask.googleEventId) {
                  googleTasksToUpdate.push({
                    key: removedTask.googleEventId,
                    updates: createTaskDataForGoogleCal(
                      {
                        status: "confirmed",
                        taskKey: removedTask.key,
                      },
                      { ...removedTask }
                    ),
                  });
                }
              } else {
                const createdDate = createTaskDueDate(removedTask.due);
                const convertedDateToUTC =
                  convertDateToUTCWithLocalTime(createdDate);

                datesToWhiteList.push(createdDate);

                recurringChildTasksList.push({
                  date: convertedDateToUTC,
                  key: removedTask.key,
                  updates: {
                    key: removedTask.key,
                    date: convertedDateToUTC,
                  },
                });
                taskDataToUse = fillTaskData({
                  ...this.rawTasksMap[groupId],
                  due: removedTask.due,
                  time: removedTask.time,
                  googleEventId: removedTask.googleEventId || "",
                  linkedTo: groupId,
                  key: removedTask.key,
                  userPosition: 0,
                });

                if (taskDataToUse.googleEventId) {
                  googleTasksToUpdate.push({
                    key: taskDataToUse.googleEventId,
                    updates: createTaskDataForGoogleCal(
                      {
                        status: "confirmed",
                        taskKey: taskDataToUse.key,
                      },
                      taskDataToUse
                    ),
                  });
                }
                taskDataToUse.isVirtual = true;
                taskDataToUse.recurrence = [];
                taskDataToUse.isFirstTask = !!removedTask.isFirstTask;
                taskDataToUse.isCalendarOnly = !!removedTask.isCalendarOnly;
              }
              if (
                checkIfTaskHasReminder(taskDataToUse) &&
                !taskDataToUse.completed
              ) {
                reminderTasksToAdd.push(...createReminderTasks(taskDataToUse));
              }
              // currTasksCount++;
              localTasksToAdd.push({ ...processTask(taskDataToUse) });
            });

            addOrUpdateRecurringTasksInInfoMapList.push({
              mainTaskId: groupId,
              tasks: recurringChildTasksList,
            });
            const newDates = whiteListDatesFromList(
              currExcludedDates,
              datesToWhiteList
            );

            excludeDatesInTaskRules(newDates, newRuleSet);
            const rulesList = newRuleSet.valueOf();
            const finalRuleList = rulesList.slice(1);

            dbTasksUpdateList.push({
              key: mainTaskData.key,
              updates: {
                recurrence: finalRuleList,
              },
            });

            if (mainTaskData.googleEventId) {
              googleTasksToUpdate.push({
                key: mainTaskData.googleEventId,
                updates: createTaskDataForGoogleCal(
                  {
                    recurrence: finalRuleList,
                    taskKey: mainTaskData.key,
                  },
                  mainTaskData
                ),
              });
            }
          } else if (addRecurringTasks && !isEmpty(taskDataToUse)) {
            taskDataToUse.coRelationalId = createUniqueId();
            taskDataToUse.key = groupId;

            if (
              checkIfTaskHasReminder(taskDataToUse) &&
              !taskDataToUse.completed
            ) {
              reminderTasksToAdd.push(...createReminderTasks(taskDataToUse));
            }
            dbTasksToAdd.push({
              ...taskDataToUse,
              // order: currTasksCount,
              // userPosition: 0,
            });

            tasksToAddInGoogle.push({
              key: taskDataToUse.key,
              data: {
                ...createTaskDataForGoogleCal({
                  ...taskDataToUse,
                  dueAsDate: createDateTimeFromDue(
                    taskDataToUse.due,
                    taskDataToUse.time
                  ),
                  taskKey: taskDataToUse.key,
                }),
              },
            });

            const { tasks: virtualTasks, datesList } =
              createVirtualTasksFromRecurringTasks([
                {
                  ...taskDataToUse,
                },
              ]);

            localTasksToAdd.push(...virtualTasks);
            recurrenceTasksMapList.push({
              mainTaskId: groupId,
              tasks: datesList,
            });
          }
        } else {
          const removedTasks = tasksListMapData[groupId];
          if (!isEmpty(removedTasks)) {
            removedTasks.forEach((childT) => {
              localTasksToAdd.push({
                ...childT,
                // order: currTasksCount,
                // userPosition: 0,
              });

              if (checkIfTaskHasReminder(childT) && !childT.completed) {
                reminderTasksToAdd.push(...createReminderTasks(childT));
              }
              dbTasksToAdd.push({
                ...childT,
                // order: currTasksCount,
                // userPosition: 0,
              });
              if (childT.googleEventId) {
                googleTasksToUpdate.push({
                  key: childT.googleEventId,
                  updates: createTaskDataForGoogleCal(
                    {
                      status: "confirmed",
                      taskKey: childT.key,
                    },
                    childT
                  ),
                });
              }
              // currTasksCount++;
            });
          }
        }
      }

      const dbUpdates = {
        tasksToAdd: dbTasksToAdd,
        tasksToUpdate: dbTasksUpdateList,
      };
      addOrRemoveOrUpdateTasksInLocalTasksList({
        tasksToAdd: [...reminderTasksToAdd, ...localTasksToAdd],
        addMethod: !isEmpty(reminderTasksToAdd) ? "push" : "unshift",
        verifyAdd: isEmpty(reminderTasksToAdd),
      });

      EventEmitter.emit(REFRESH_ACTIVITY_HISTORY);
      addOrUpdateRecurringTasksInRecurringTasksInfoMap(
        addOrUpdateRecurringTasksInInfoMapList
      );
      // removeTasksFromRecurringTasksMap(removeTasksFromRecurringTasksMapList);
      await addOrRemoveOrUpdateTasksInDb(dbUpdates);

      if (!isEmpty(recurrenceTasksMapList)) {
        this.replaceRecurringTasksInfoInMap({
          list: recurrenceTasksMapList,
        });
      }

      if (this.isGoogleCalSyncEnabled) {
        addOrUpdateOrRemoveTasksInGoogleCal({
          tasksToUpdate: googleTasksToUpdate,
          tasksToAdd: tasksToAddInGoogle,
        });
      }
    },

    async removeRecurringOrNormalTasksFromMap(groupedTasksMap) {
      let localTasksRemoveList = [];
      let dbRemoveTasksList = [];
      let googleRecurringEventUpdateListMap = {};
      let removeTasksFromRecurringTasksMapList = [];
      let dbTasksUpdateList = [];
      let googleTasksToRemove = [];
      let reminderTasksToRemove = [];
      let recurrenceTasksMapList = [];

      for (const groupIDKey in groupedTasksMap) {
        if (groupIDKey !== "normal") {
          let childTasks = [];
          let removeRecurringTasks = false;

          if (isPlainObject(groupedTasksMap[groupIDKey])) {
            removeRecurringTasks =
              !!groupedTasksMap[groupIDKey].removedRecurringTasks;
          } else if (Array.isArray(groupedTasksMap[groupIDKey])) {
            childTasks = groupedTasksMap[groupIDKey];
          }

          const mainTaskRawData = this.rawTasksMap[groupIDKey];

          if (!removeRecurringTasks) {
            const mainTaskRecurrenceRules = mainTaskRawData.recurrence;
            let mainTaskDataUpdate = {};
            let removeChildrenTasksIds = [];
            const { ruleSet: parsedRuleSet } =
              parseRuleDataFromTaskRecurrenceRules({
                recurrence: mainTaskRecurrenceRules,
                dueAsDate: createDateTimeFromDue(
                  mainTaskRawData.due,
                  mainTaskRawData.time
                ),
              });

            if (!isEmpty(childTasks)) {
              childTasks.forEach((childT) => {
                const isVirtualTask = childT.isVirtual;
                let currTaskDueDate = childT.dueAsDate;
                if (!currTaskDueDate) {
                  currTaskDueDate = createDateTimeFromDue(
                    childT.due,
                    childT.time
                  );
                }

                const linkedReminders = getReminderTasksLinkedToTaskId(
                  this.tasks,
                  childT.key
                );

                let currRawData = {};
                localTasksRemoveList.push(childT);

                if (!isEmpty(linkedReminders)) {
                  reminderTasksToRemove.push(...linkedReminders);
                }
                removeChildrenTasksIds.push(childT);
                dbRemoveTasksList.push(childT);

                if (!isVirtualTask) {
                  currRawData = this.rawTasksMap[childT.key];
                } else {
                  currRawData = {
                    ...childT,
                    dueAsDate: createDateTimeFromDue(childT.due, childT.time),
                  };
                }

                excludeDatesInTaskRules([currTaskDueDate], parsedRuleSet);

                // if (isValidDate(currTaskDueDate)) {
                //   parsedRuleSet.exdate(converDateToUTC(currTaskDueDate));
                // }
                if (this.isGoogleCalSyncEnabled) {
                  // console.debug("DATA TO SEND", dataToSend);

                  if (
                    !googleRecurringEventUpdateListMap[
                      mainTaskRawData.googleEventId
                    ]
                  ) {
                    googleRecurringEventUpdateListMap[
                      mainTaskRawData.googleEventId
                    ] = {
                      childrenUpdates: [],
                      mainEventUpdates: {},
                    };
                  }
                  googleRecurringEventUpdateListMap[
                    mainTaskRawData.googleEventId
                  ].childrenUpdates.push({
                    currData: {
                      dueAsDate: currRawData.dueAsDate,
                      taskKey: currRawData.key,
                      googleEventId: currRawData.googleEventId,
                    },
                    newData: createTaskDataForGoogleCal(
                      { status: "cancelled", taskKey: currRawData.key },
                      { ...currRawData }
                    ),
                  });
                }
              });
            }

            const convertedRules = parsedRuleSet.valueOf().slice(1);

            if (!isEqual(convertedRules, mainTaskRecurrenceRules)) {
              mainTaskDataUpdate = {
                recurrence: convertedRules,
              };

              dbTasksUpdateList.push({
                key: mainTaskRawData.key,
                updates: {
                  recurrence: convertedRules,
                },
              });

              if (mainTaskRawData.googleEventId) {
                if (
                  !googleRecurringEventUpdateListMap[
                    mainTaskRawData.googleEventId
                  ]
                ) {
                  googleRecurringEventUpdateListMap[
                    mainTaskRawData.googleEventId
                  ] = {
                    childrenUpdates: [],
                    mainEventUpdates: {},
                  };
                }

                googleRecurringEventUpdateListMap[
                  mainTaskRawData.googleEventId
                ].mainEventUpdates = createTaskDataForGoogleCal(
                  {
                    ...googleRecurringEventUpdateListMap[
                      mainTaskRawData.googleEventId
                    ].mainEventUpdates,
                    ...mainTaskDataUpdate,
                    taskKey: mainTaskRawData.key,
                  },
                  { ...mainTaskRawData }
                );
              }
            }

            if (!isEmpty(removeChildrenTasksIds)) {
              removeTasksFromRecurringTasksMapList.push({
                mainTaskId: mainTaskRawData.key,
                tasksIds: removeChildrenTasksIds,
              });
            }
          } else {
            dbRemoveTasksList.push({ ...mainTaskRawData });
            googleTasksToRemove.push({ ...mainTaskRawData });
            let config = {
              coRelationalId: mainTaskRawData.coRelationalId,
            };

            if (!this.showClearedTasks) {
              config = {
                ...config,
                filter: true,
                filterOpts: [isTaskNotCompleted],
              };
            }
            const coRelatedTasks = getRecurringTasks(this.tasks, "list", {
              ...config,
            }).slice(1);

            const allRemindersLinkedToMain = getReminderTasksLinkedToTaskId(
              this.tasks,
              mainTaskRawData.key
            );

            if (!isEmpty(allRemindersLinkedToMain)) {
              reminderTasksToRemove.push(...allRemindersLinkedToMain);
            }

            coRelatedTasks.forEach((taskData) => {
              dbRemoveTasksList.push(taskData);
              localTasksRemoveList.push(taskData);
              const localReminderTasksLinked = getReminderTasksLinkedToTaskId(
                this.tasks,
                taskData.key
              );

              if (!isEmpty(localReminderTasksLinked)) {
                reminderTasksToRemove.push(...localReminderTasksLinked);
              }
              if (!taskData.isVirtual && taskData.googleEventId) {
                googleTasksToRemove.push(taskData);
              }
            });

            recurrenceTasksMapList.push({
              mainTaskId: groupIDKey,
              tasks: [],
            });
          }

          // Add recurring logic
        } else {
          const normalChildTasks = groupedTasksMap[groupIDKey];

          if (!isEmpty(normalChildTasks)) {
            normalChildTasks.forEach((childT) => {
              const linkedReminders = getReminderTasksLinkedToTaskId(
                this.tasks,
                childT.key
              );

              localTasksRemoveList.push(childT);

              if (!isEmpty(linkedReminders)) {
                reminderTasksToRemove.push(...linkedReminders);
              }
              dbRemoveTasksList.push(childT);
              googleTasksToRemove.push(childT);
            });
          }
          // Add normal task logic
        }
      }

      const dbUpdates = {
        tasksToRemove: [...dbRemoveTasksList],
        tasksToUpdate: dbTasksUpdateList,
      };
      addOrRemoveOrUpdateTasksInLocalTasksList({
        tasksToRemove: [...localTasksRemoveList, ...reminderTasksToRemove],
      });

      EventEmitter.emit(REFRESH_ACTIVITY_HISTORY);

      removeTasksFromRecurringTasksMap(removeTasksFromRecurringTasksMapList);
      await addOrRemoveOrUpdateTasksInDb(dbUpdates);

      if (!isEmpty(recurrenceTasksMapList)) {
        this.replaceRecurringTasksInfoInMap({
          list: recurrenceTasksMapList,
        });
      }

      if (this.isGoogleCalSyncEnabled) {
        this.updateRecurringAndItsChildEventsFromMap(
          googleRecurringEventUpdateListMap
        );

        addOrUpdateOrRemoveTasksInGoogleCal({
          tasksToRemove: googleTasksToRemove,
        });
      }
    },
    createDateTimeDataForTask(taskData, type) {
      let newTaskDateData = {};
      let dateToUse =
        taskData.due !== "none"
          ? parseISO(taskData.due)
          : parseISO(formatDate(new Date(), INTERNAL_DATE_FORMAT));
      switch (type) {
        case "day":
          dateToUse = addDaysToDate(dateToUse, 1);
          break;
        case "month":
          dateToUse = addMonthsToDate(dateToUse, 1);
          break;
        case "week":
          dateToUse = addWeeksToDate(dateToUse, 1);
          break;
        case "now":
          dateToUse = new Date();
          break;
        case "none":
          dateToUse = null;
      }

      if (isValidDate(dateToUse)) {
        newTaskDateData.due = formatDate(dateToUse, INTERNAL_DATE_FORMAT);
        if (type === "now") {
          newTaskDateData.time = formatDate(dateToUse, INTERNAL_TIME_FORMAT);
        }
      } else {
        newTaskDateData.due = "none";
        newTaskDateData.time = "00:00";

        if (taskData.isCustomPositioned && taskData.due === "none") {
          newTaskDateData.flag = false;
          newTaskDateData.isCustomPositioned = false;
        }

        if (taskData.isContingent) {
          newTaskDateData.flag = false;
          newTaskDateData.isContingent = false;
        }
      }

      return newTaskDateData;
    },
    async updateDateTimeOfNormalOrRecurringTasks(
      tasksToProcess,
      type,
      parseDueDate
    ) {
      const groupedTasksMap = {};
      // const parsedTaskDataToUpdate =
      //   createTaskDataFromProvidedData(dataToUpdate);
      // const parsedDataKeys = Object.keys(parsedTaskDataToUpdate);
      let dbAddTasksList = [];
      let dbUpdateTasksList = [];
      let localTasksRemoveList = [];
      let localTasksUpdateList = [];
      let reminderTasksToAdd = [];
      let reminderTasksToRemove = [];
      let googleAddList = [];
      let googleUpdateList = [];
      let googleRecurringEventUpdateListMap = {};
      let localTasksAddList = [];
      let currTotalTasksCount = Object.keys(this.rawTasksMap || {}).length;
      let existingReminderDates = [];
      const removeRecurringTasksInfoMapList = [];
      let editedTasksMap = {};
      let recurringToSingleList = [];
      let taskKeyForRefreshInResourceForm;
      tasksToProcess.forEach((task) => {
        if (task.isReminderTask) {
          const parentReminderTask = this.tasks.find(
            (t) => t.key === task.reminderLinkedTo
          );

          existingReminderDates.push(task.reminderDate);

          task = { ...parentReminderTask };
        }

        const isRecurring = isTaskRecurring(task);
        const linkedTo = task.linkedTo;
        let groupID = "normal";
        let groupGoogleEventId = "";
        if (isRecurring && linkedTo) {
          groupID = linkedTo;
          groupGoogleEventId = this.rawTasksMap[linkedTo].googleEventId;
        }

        if (!groupedTasksMap[groupID]) {
          groupedTasksMap[groupID] = {
            key: groupID,
            children: [],
            googleEventId: groupGoogleEventId,
          };
        }

        const storedChildIndex = groupedTasksMap[groupID].children.findIndex(
          (t) => t.key === task.key
        );
        if (storedChildIndex === -1) {
          groupedTasksMap[groupID].children.push(task);
        }
      });

      for (const groupIDKey in groupedTasksMap) {
        if (groupIDKey !== "normal") {
          const childTasks = groupedTasksMap[groupIDKey].children;
          const mainTaskRawData = this.rawTasksMap[groupIDKey];
          const mainTaskRecurrenceRules = mainTaskRawData.recurrence;
          let clonedMainTaskRules = mainTaskRawData.recurrence;
          let mainTaskDataUpdate = {};
          let excludedDates = [];
          let rulesChanged = false;
          let removeChildrenTasks = [];
          const editedTasksList = [];
          const sameProjectChildren = [];
          let recreateTasks = false;
          let updateMain = false;
          let editedDataForMainTask = {};
          const { ruleSet: parsedRuleSet } =
            parseRuleDataFromTaskRecurrenceRules({
              recurrence: mainTaskRecurrenceRules,
              dueAsDate: createDateTimeFromDue(
                mainTaskRawData.due,
                mainTaskRawData.time
              ),
            });

          if (!isEmpty(childTasks)) {
            childTasks.forEach((childT) => {
              let currRawData = {};
              let dateToExclude;

              const isVirtualTask = childT.isVirtual;
              if (parseDueDate) {
                childT.dueAsDate = new Date(childT.dueAsDate);
              }

              // const currChildTDueDate = childT.dueAsDate;
              let addToDb = true;
              let removeFromGoogle = false;
              if (!isVirtualTask) {
                currRawData = this.rawTasksMap[childT.key];
                addToDb = false;
              } else {
                currRawData = cloneDeep(this.rawTasksMap[groupIDKey]);
                currRawData = {
                  ...currRawData,
                  ...getDateAndTimeFromDueDate(childT.dueAsDate),
                };

                const storedGoogleCalId = GoogleCalHelpers.getStoredCalId();
                if (currRawData.isFromGoogle) {
                  currRawData.googleEventId =
                    createGoogleEventIdByDateAndTaskId(
                      currRawData.googleEventId.split("_")[0],
                      childT.dueAsDate
                    );

                  currRawData.googleCalendarId = storedGoogleCalId;
                } else {
                  currRawData.googleEventId =
                    createGoogleEventIdByDateAndTaskId(
                      groupIDKey,
                      childT.dueAsDate
                    );

                  currRawData.googleCalendarId = storedGoogleCalId;
                }
                localTasksRemoveList.push(childT);
                removeChildrenTasks.push(childT);
              }

              const parentId = groupIDKey;
              const updatedData = this.createDateTimeDataForTask(
                currRawData,
                type
              );
              const parsedDataKeys = Object.keys(updatedData);
              const combinedData = {
                ...currRawData,
                ...updatedData,
              };

              let newTaskData = cloneDeep(combinedData);
              newTaskData = removeProps(newTaskData, [
                "linkedTo",
                "recurrence",
              ]);

              const { editedData: editedTaskData } =
                createDbUpdateDataFromProvidedData(updatedData, childT);

              let taskKeyOfChild = childT.key;
              if (addToDb) {
                delete newTaskData.googleEventId;
                newTaskData.key = createTaskId();

                taskKeyOfChild = newTaskData.key;
                newTaskData.coRelationalId = currRawData.coRelationalId;
                newTaskData.linkedTo = currRawData.key;
                newTaskData.order = currRawData.order;
                newTaskData.userPosition = currRawData.userPosition;
                newTaskData.customPosition = currRawData.customPosition;
                newTaskData.customPositionIndex =
                  currRawData.customPositionIndex;
                newTaskData = fillTaskData(newTaskData);
                newTaskData.resources = [];
                editedTaskData.key = newTaskData.key;

                if (this.isGoogleCalSyncEnabled) {
                  newTaskData.googleCalendarId =
                    GoogleCalHelpers.getStoredCalId();
                  if (childT.isFromGoogle) {
                    newTaskData.googleEventId =
                      createGoogleEventIdByDateAndTaskId(
                        childT.googleEventId.split("_")[0],
                        childT.dueAsDate
                      );
                  } else {
                    newTaskData.googleEventId =
                      createGoogleEventIdByDateAndTaskId(
                        parentId,
                        childT.dueAsDate
                      );
                  }
                }

                newTaskData.originalDate = isValidDate(childT.dueAsDate)
                  ? childT.dueAsDate
                  : "";

                // if (
                //   dataToUpdate.dueAsDate !== undefined &&
                //   !isEqual(dataToUpdate.dueAsDate, childT.dueAsDate)
                // ) {
                //   dateToExclude = dataToUpdate.dueAsDate;
                //   if (
                //     isValidDate(childT.dueAsDate) &&
                //     !isValidDate(dataToUpdate.dueAsDate)
                //   ) {
                //     dateToExclude = childT.dueAsDate;
                //   }
                // }

                if (newTaskData.project === mainTaskRawData.project) {
                  sameProjectChildren.push(newTaskData.key);
                }
                dbAddTasksList.push(newTaskData);

                if (
                  checkIfTaskHasReminder(newTaskData) &&
                  !newTaskData.completed
                ) {
                  reminderTasksToAdd.push(...createReminderTasks(newTaskData));
                }
                localTasksAddList.push(newTaskData);
                editedTasksList.push({
                  ...editedTaskData,
                  key: newTaskData.key,
                });
                currTotalTasksCount++;
                // Add new task
              } else {
                let updateInDb = true;
                const { updatedTaskData } = createDbUpdateDataFromProvidedData(
                  updatedData,
                  childT
                );
                newTaskData.coRelationalId = currRawData.coRelationalId;
                newTaskData.linkedTo = parentId;
                newTaskData = {
                  ...newTaskData,
                  ...updatedTaskData,
                };

                if (parsedDataKeys.includes("due") && childT.completed) {
                  updateInDb = false;
                }

                if (updateInDb) {
                  editedTaskData.key = childT.key;
                  editedTasksList.push({
                    ...editedTaskData,
                    key: childT.key,
                  });
                  dbUpdateTasksList.push({
                    key: childT.key,
                    updates: { ...updatedData },
                  });
                  localTasksUpdateList.push({
                    key: childT.key,
                    updates: newTaskData,
                  });

                  if (
                    this.checkIfParsedTaskDataHasReminderProps(parsedDataKeys)
                  ) {
                    const reminderTasks = getReminderTasksLinkedToTaskId(
                      this.tasks,
                      childT.key
                    );

                    if (!isEmpty(reminderTasks)) {
                      reminderTasksToRemove.push(...reminderTasks);
                    }

                    if (
                      checkIfTaskHasReminder(newTaskData) &&
                      !newTaskData.completed
                    ) {
                      reminderTasksToAdd.push(
                        ...createReminderTasks(newTaskData)
                      );
                    }
                  }
                } else {
                  removeFromGoogle = true;
                  newTaskData = removeProps(newTaskData, [
                    "frequency",
                    "linkedTo",
                    "recurrence",
                    "coRelationalId",
                    "googleEventId",
                    "key",
                  ]);

                  newTaskData.completed = false;
                  const newTaskDataForDb = fillTaskData(newTaskData);

                  taskKeyOfChild = newTaskDataForDb.key;
                  if (
                    this.isGoogleCalSyncEnabled &&
                    newTaskDataForDb.due !== "none"
                  ) {
                    dateToExclude = createDateTimeFromDue(
                      newTaskDataForDb.due,
                      newTaskDataForDb.time
                    );
                    newTaskDataForDb.googleEventId = convertTaskKeyForGoogleCal(
                      newTaskDataForDb.key
                    );

                    newTaskDataForDb.googleCalendarId =
                      GoogleCalHelpers.getStoredCalId();
                    googleAddList.push({
                      key: newTaskDataForDb.googleEventId,
                      data: createTaskDataForGoogleCal({
                        ...newTaskDataForDb,
                        dueAsDate: createDateTimeFromDue(
                          newTaskDataForDb.due,
                          newTaskDataForDb.time
                        ),
                        taskKey: newTaskDataForDb.key,
                      }),
                    });
                  }

                  newTaskDataForDb.order = currTotalTasksCount;
                  newTaskDataForDb.userPosition = 0;
                  newTaskDataForDb.customPosition = currTotalTasksCount;
                  newTaskDataForDb.customPositionIndex = 0;

                  dbAddTasksList.push(newTaskDataForDb);

                  localTasksAddList.push(newTaskDataForDb);

                  if (checkIfTaskHasReminder(newTaskDataForDb)) {
                    reminderTasksToAdd.push(
                      ...createReminderTasks(newTaskDataForDb)
                    );
                  }

                  recurringToSingleList.push(newTaskDataForDb);
                  currTotalTasksCount++;
                }

                // Update Task
              }

              if (
                !taskKeyForRefreshInResourceForm &&
                !isEmpty(this.storedTaskDataInResourceForm) &&
                this.storedTaskDataInResourceForm.key === childT.key
              ) {
                taskKeyForRefreshInResourceForm = taskKeyOfChild;
              }

              excludedDates.push(childT.dueAsDate);
              let updatedDueDate;
              let currChildTDueDate = childT.dueAsDate;

              if (updatedData.due && updatedData.time) {
                updatedDueDate = createDateTimeFromDue(
                  updatedData.due,
                  updatedData.time
                );
              } else if (updatedData.due) {
                updatedDueDate = createDateTimeFromDue(
                  updatedData.due,
                  newTaskData.time
                );
              }

              if (
                updatedDueDate !== undefined &&
                !isEqual(updatedDueDate, currChildTDueDate)
              ) {
                dateToExclude = updatedDueDate;

                if (
                  isValidDate(currChildTDueDate) &&
                  isValidDate(updatedDueDate) &&
                  !isSameDay(updatedDueDate, currChildTDueDate)
                ) {
                  dateToExclude = updatedDueDate;
                } else if (
                  isValidDate(currChildTDueDate) &&
                  !isValidDate(updatedDueDate)
                ) {
                  dateToExclude = currChildTDueDate;
                } else if (
                  isValidDate(updatedDueDate) &&
                  !isValidDate(currChildTDueDate)
                ) {
                  dateToExclude = updatedDueDate;
                }
              }

              if (isValidDate(dateToExclude)) {
                excludedDates.push(dateToExclude);
              }

              // excludedDates.forEach((d) => {
              //   if (isValidDate(d)) {
              //     parsedRuleSet.exdate(converDateToUTC(d, false));
              //   }
              // });

              if (this.isGoogleCalSyncEnabled) {
                const dataToSend = {
                  ...newTaskData,
                  dueAsDate: createDateTimeFromDue(
                    newTaskData.due,
                    newTaskData.time
                  ),
                };
                // console.debug("DATA TO SEND", dataToSend);
                const dataForGoogleCal = {
                  ...createTaskDataForGoogleCal(
                    {
                      ...dataToSend,
                      taskKey: dataToSend.key,
                    },
                    childT
                  ),
                };

                if (
                  !googleRecurringEventUpdateListMap[
                    mainTaskRawData.googleEventId
                  ]
                ) {
                  googleRecurringEventUpdateListMap[
                    mainTaskRawData.googleEventId
                  ] = {
                    childrenUpdates: [],
                    mainEventUpdates: {},
                  };
                }
                googleRecurringEventUpdateListMap[
                  mainTaskRawData.googleEventId
                ].childrenUpdates.push({
                  currData: {
                    dueAsDate: childT.dueAsDate,
                    taskKey: newTaskData.key,
                    googleEventId:
                      newTaskData.googleEventId || currRawData.googleEventId,
                  },
                  newData: removeFromGoogle
                    ? createTaskDataForGoogleCal(
                        { status: "cancelled", taskKey: newTaskData.key },
                        { ...newTaskData }
                      )
                    : dataForGoogleCal,
                });
              }
            });
          }

          excludeDatesInTaskRules(excludedDates, parsedRuleSet);
          // excludedDates.forEach((d) => {
          //   if (isValidDate(d)) {
          //     parsedRuleSet.exdate(converDateToUTC(d, false));
          //   }
          // });

          const convertedRules = parsedRuleSet.valueOf().slice(1);

          if (!isEqual(convertedRules, mainTaskRecurrenceRules)) {
            mainTaskDataUpdate = {
              recurrence: convertedRules,
            };

            rulesChanged = true;
            clonedMainTaskRules = convertedRules;
            // dbUpdateTasksList.push({
            //   key: mainTaskRawData.key,
            //   updates: {
            //     recurrence: convertedRules,
            //   },
            // });

            if (mainTaskRawData.googleEventId) {
              if (
                !googleRecurringEventUpdateListMap[
                  mainTaskRawData.googleEventId
                ]
              ) {
                googleRecurringEventUpdateListMap[
                  mainTaskRawData.googleEventId
                ] = {
                  childrenUpdates: [],
                  mainEventUpdates: {},
                };
              }

              googleRecurringEventUpdateListMap[
                mainTaskRawData.googleEventId
              ].mainEventUpdates = createTaskDataForGoogleCal(
                {
                  ...googleRecurringEventUpdateListMap[
                    mainTaskRawData.googleEventId
                  ].mainEventUpdates,
                  ...mainTaskDataUpdate,
                  taskKey: mainTaskRawData.key,
                },
                { ...mainTaskRawData }
              );
            }
          }

          if (rulesChanged || !isEmpty(removeChildrenTasks)) {
            const recurrences = createRecurringDatesFromTaskRules({
              ...mainTaskRawData,
              recurrence: clonedMainTaskRules,
            });

            const firstRecurrence = recurrences[0];

            const firstTaskData = getRecurrenceIdByDateFromRecurrences(
              firstRecurrence,
              mainTaskRawData.key
            );

            let recurringTasksToUpdate = [];
            if (
              !isEmpty(removeChildrenTasks) &&
              !isEmpty(sameProjectChildren)
            ) {
              recreateTasks = true;
              updateMain = true;

              const dataToUpdate = {
                order: mainTaskRawData.order,
                userPosition:
                  mainTaskRawData.userPosition + sameProjectChildren.length,
              };
              mainTaskDataUpdate = {
                ...mainTaskDataUpdate,
                ...dataToUpdate,
              };

              editedDataForMainTask = {
                order: mainTaskRawData.order,
                userPosition: mainTaskRawData.userPosition,
                changedProps: { ...dataToUpdate },
                key: mainTaskRawData.key,
              };

              const currRecurringTasks = getRecurringTasks(this.tasks, "list", {
                linkedTo: mainTaskRawData.key,
                filter: true,
                filterOpts: [isTaskVirtual],
              });

              if (!isEmpty(currRecurringTasks)) {
                currRecurringTasks.forEach((currTask) => {
                  const createdDataUpdate = {
                    ...dataToUpdate,
                  };
                  if (
                    !isEmpty(firstTaskData) &&
                    firstTaskData.key === currTask.key
                  ) {
                    createdDataUpdate.isCalendarOnly = false;
                  }
                  recurringTasksToUpdate.push({
                    key: currTask.key,
                    updates: {
                      ...createdDataUpdate,
                    },
                  });
                });
              }
            } else {
              if (!isEmpty(firstTaskData)) {
                recurringTasksToUpdate.push({
                  key: firstTaskData.key,
                  updates: {
                    isCalendarOnly: false,
                  },
                });
              }
            }

            localTasksUpdateList.push(...recurringTasksToUpdate);

            // const firstTask = this.tasks.find(
            //   (t) =>
            //     t.linkedTo === clonedMainTaskData.key &&
            //     t.isVirtual &&
            //     isSameDay(t.dueAsDate, firstRecurrence)
            // );

            // if (!isEmpty(firstTaskData)) {
            //   const currTaskData = this.tasks.find(
            //     (t) => t.key === firstTaskData.key
            //   );
            //   if (!isEmpty(currTaskData)) {
            //     localTasksUpdateList.push({
            //       key: currTaskData.key,
            //       updates: {
            //         ...currTaskData,
            //         isCalendarOnly: false,
            //       },
            //     });
            //   }
            // }

            // console.time("ID");

            // console.timeEnd("ID");
          }

          if (!isEmpty(mainTaskDataUpdate)) {
            dbUpdateTasksList.push({
              key: mainTaskRawData.key,
              updates: {
                ...mainTaskDataUpdate,
              },
            });
          }

          if (!editedTasksMap[groupIDKey]) {
            editedTasksMap[groupIDKey] = {
              editedTasks: editedTasksList,
              updateMain,
              recreateTasks,
              editedData: editedDataForMainTask,
            };
          }

          if (!isEmpty(removeChildrenTasks)) {
            const taskIdsToRemove = removeChildrenTasks.map((t) => t.key);
            removeRecurringTasksInfoMapList.push({
              mainTaskId: mainTaskRawData.key,
              tasksIds: taskIdsToRemove,
            });
          }
          // Add recurring logic
        } else {
          const normalChildTasks = groupedTasksMap[groupIDKey].children;
          let editedTasksList = [];
          let addedTasksList = [];
          if (!isEmpty(normalChildTasks)) {
            // const parsedDataKeys = Object.keys(updatedData);
            normalChildTasks.forEach((childT) => {
              let addToDb = false;

              const currRawData = this.rawTasksMap[childT.key];

              const updatedData = this.createDateTimeDataForTask(
                currRawData,
                type
              );

              const parsedDataKeys = Object.keys(updatedData);
              let finalDataObj = {
                ...currRawData,
                ...updatedData,
              };

              if (parsedDataKeys.includes("due") && childT.completed) {
                addToDb = true;
              }

              if (!isEmpty(currRawData)) {
                const reminderTasks = getReminderTasksLinkedToTaskId(
                  this.tasks,
                  childT.key
                );

                if (!isEmpty(reminderTasks)) {
                  reminderTasksToRemove.push(...reminderTasks);
                }

                let taskKeyOfChild = childT.key;
                if (addToDb) {
                  let addInGoogle = false;
                  finalDataObj.key = createUniqueId();

                  taskKeyOfChild = finalDataObj.key;
                  finalDataObj.order = currTotalTasksCount;
                  finalDataObj.userPosition = 0;
                  finalDataObj.customPosition = currTotalTasksCount;
                  finalDataObj.customPositionIndex = 0;
                  finalDataObj.flag =
                    finalDataObj.due && finalDataObj.due !== "none";

                  if (
                    this.isGoogleCalSyncEnabled &&
                    finalDataObj.due !== "none"
                  ) {
                    addInGoogle = true;
                    finalDataObj.googleEventId = convertTaskKeyForGoogleCal(
                      finalDataObj.key
                    );

                    finalDataObj.googleCalendarId =
                      GoogleCalHelpers.getStoredCalId();
                  }
                  finalDataObj = fillTaskData(finalDataObj);
                  finalDataObj.completed = false;
                  if (addInGoogle) {
                    googleAddList.push({
                      key: finalDataObj.googleEventId,
                      data: createTaskDataForGoogleCal({
                        ...finalDataObj,
                        dueAsDate: createDateTimeFromDue(
                          finalDataObj.due,
                          finalDataObj.time
                        ),
                        taskKey: finalDataObj.key,
                      }),
                    });
                  }
                  addedTasksList.push(cleanTask(cloneDeep(finalDataObj)));
                  dbAddTasksList.push(finalDataObj);
                  localTasksAddList.push(finalDataObj);

                  if (checkIfTaskHasReminder(finalDataObj)) {
                    reminderTasksToAdd.push(
                      ...createReminderTasks(finalDataObj)
                    );
                  }
                  currTotalTasksCount++;
                  // Add new task
                } else {
                  const { editedData: editedTaskData } =
                    createDbUpdateDataFromProvidedData(updatedData, childT);

                  editedTasksList.push({
                    ...editedTaskData,
                    key: childT.key,
                  });
                  dbUpdateTasksList.push({
                    key: childT.key,
                    updates: { ...updatedData },
                  });

                  localTasksUpdateList.push({
                    key: childT.key,
                    updates: finalDataObj,
                  });

                  if (
                    checkIfTaskHasReminder(finalDataObj) &&
                    !finalDataObj.completed
                  ) {
                    reminderTasksToAdd.push(
                      ...createReminderTasks(finalDataObj)
                    );
                  }

                  if (currRawData.googleEventId) {
                    googleUpdateList.push({
                      key: currRawData.googleEventId,
                      updates: {
                        ...createTaskDataForGoogleCal(
                          {
                            ...finalDataObj,
                            dueAsDate: createDateTimeFromDue(
                              finalDataObj.due,
                              finalDataObj.time
                            ),
                            taskKey: currRawData.key,
                          },
                          childT
                        ),
                      },
                    });
                  }
                  // Update Task
                }

                if (
                  !taskKeyForRefreshInResourceForm &&
                  !isEmpty(this.storedTaskDataInResourceForm) &&
                  this.storedTaskDataInResourceForm.key === childT.key
                ) {
                  taskKeyForRefreshInResourceForm = taskKeyOfChild;
                }
              }
            });
          }

          if (!editedTasksMap["normal"]) {
            editedTasksMap["normal"] = {
              editedTasks: editedTasksList,
              addedTasks: addedTasksList,
            };
          }
          // Add normal task logic
        }
      }

      if (!isEmpty(recurringToSingleList)) {
        if (!editedTasksMap.normal) {
          editedTasksMap.normal = {
            addedTasks: recurringToSingleList,
          };
        } else {
          editedTasksMap.normal.addedTasks = [
            ...editedTasksMap.normal.addedTasks,
            ...recurringToSingleList,
          ];
        }
      }

      getUserActions().addTaskAction({
        type: TASK_ACTIONS.NORMAL_RECURRING_EDIT,
        data: {
          ...editedTasksMap,
        },
        on: "task",
      });

      await addOrRemoveOrUpdateTasksInLocalTasksList(
        {
          tasksToAdd: [...localTasksAddList, ...reminderTasksToAdd],
          tasksToUpdate: localTasksUpdateList,
          tasksToRemove: [...localTasksRemoveList, ...reminderTasksToRemove],
          verifyAdd: isEmpty(reminderTasksToAdd),
          addMethod: !isEmpty(reminderTasksToAdd) ? "push" : "unshift",
        },
        false
      );
      removeTasksFromRecurringTasksMap(removeRecurringTasksInfoMapList);

      await Vue.nextTick();

      const selectedRows = this.createTaskSelectionList(tasksToProcess);

      // tasksToProcess.forEach((t) => {
      //   let taskDataToUse = { ...t };
      //   if (taskDataToUse.isReminderTask) {
      //     const allRemindersOfParent = getReminderTasksLinkedToTaskId(
      //       this.tasks,
      //       taskDataToUse.reminderLinkedTo
      //     );

      //     if (!isEmpty(allRemindersOfParent)) {
      //       const reminderTaskData = allRemindersOfParent.find((t) =>
      //         isSameDates(t.reminderDate, taskDataToUse.reminderDate)
      //       );

      //       if (!isEmpty(reminderTaskData)) {
      //         taskDataToUse = { ...reminderTaskData };
      //       }
      //     }
      //   } else {
      //     const updatedTaskData = this.tasks.find(
      //       (t) => t.key === taskDataToUse.key
      //     );

      //     if (!isEmpty(updatedTaskData)) {
      //       taskDataToUse = { ...updatedTaskData };
      //     }
      //   }

      //   selectedRows.push(taskDataToUse);
      // });

      EventEmitter.emit(SET_ID_FOR_SCROLL, selectedRows, true);
      EventEmitter.emit(SELECT_TASK_IN_ACTIVITY_GRID);
      EventEmitter.emit(REFRESH_TASKS_EVENT);
      EventEmitter.emit(REFRESH_ACTIVITY_HISTORY);

      if (this.checkIfTaskFormIsOpened()) {
        EventEmitter.emit(REFRESH_FULL_FORM);
      }
      addOrRemoveOrUpdateTasksInDb({
        tasksToAdd: dbAddTasksList,
        tasksToUpdate: dbUpdateTasksList,
      });

      addOrUpdateOrRemoveTasksInGoogleCal({
        tasksToAdd: googleAddList,
        tasksToUpdate: googleUpdateList,
      });
      this.updateRecurringAndItsChildEventsFromMap(
        googleRecurringEventUpdateListMap
      );

      if (taskKeyForRefreshInResourceForm) {
        this.refreshTaskDataInResourceForm(taskKeyForRefreshInResourceForm);
      }
    },
    async updateRecurringOrNormalTasks(
      tasksToProcess,
      dataToUpdate,
      parseDueDate,
      useRaw
      // refreshForm
    ) {
      const groupedTasksMap = {};
      const { projectUpdateData, ...dataForTask } = dataToUpdate;
      const parsedTaskDataToUpdate =
        createTaskDataFromProvidedData(dataForTask);
      const parsedDataKeys = Object.keys(parsedTaskDataToUpdate);
      let dbAddTasksList = [];
      let dbUpdateTasksList = [];
      let localTasksRemoveList = [];
      let localTasksUpdateList = [];
      let googleAddList = [];
      let googleUpdateList = [];
      let googleRecurringEventUpdateListMap = {};
      let localTasksAddList = [];
      let reminderTaskList = [];
      let currTotalTasksCount = Object.keys(this.rawTasksMap || {}).length;
      const removeRecurringTasksInfoMapList = [];
      let editedTasksMap = {};
      let recurringToSingleList = [];
      let projectDbData = {};
      let projectEditData = {};
      let existingReminderDates = [];
      let refreshAreaForm = false;

      let taskKeyForRefreshInResourceForm;
      // let changeAreaInTree = false;
      let areaId;
      const projectResourcesForCatChangeList = [];
      const qlInstance = createOrGetQlInstance();
      if (!isEmpty(projectUpdateData)) {
        projectDbData = projectUpdateData.dbUpdates;
        projectEditData = projectUpdateData.editedData;
      }

      tasksToProcess.forEach((task) => {
        if (task.isReminderTask) {
          const parentReminderTask = this.tasks.find(
            (t) => t.key === task.reminderLinkedTo
          );

          existingReminderDates.push(task.reminderDate);

          task = { ...parentReminderTask };
        }

        const isRecurring = isTaskRecurring(task);
        const linkedTo = task.linkedTo;
        let groupID = "normal";
        let groupGoogleEventId = "";
        if (isRecurring && linkedTo) {
          groupID = linkedTo;
          groupGoogleEventId = this.rawTasksMap[linkedTo].googleEventId;
        }

        if (!groupedTasksMap[groupID]) {
          groupedTasksMap[groupID] = {
            key: groupID,
            children: [],
            googleEventId: groupGoogleEventId,
          };
        }

        const storedChildIndex = groupedTasksMap[groupID].children.findIndex(
          (t) => t.key === task.key
        );
        if (storedChildIndex === -1) {
          groupedTasksMap[groupID].children.push(task);
        }

        // if (isRecurring && (isVirtual || linkedTo)) {
        //   if (!groupedTasksMap[linkedTo]) {
        //     groupedTasksMap[linkedTo] = {
        //       key: "",
        //       children: [],
        //       googleEventId: "",
        //     };
        //   }

        //   const storedChildIndex = groupedTasksMap[linkedTo].children.find(
        //     (t) => t.key === task.key
        //   );

        //   if (storedChildIndex === -1) {
        //     groupedTasksMap[linkedTo].children.push(task);
        //   }
        // } else if (isEmpty(task.recurrence)) {
        //   if (!groupedTasksMap["normal"]) {
        //     groupedTasksMap["normal"] = {
        //       key: "",
        //       children: [],
        //       googleEventId: "",
        //     };
        //   }
        // }
      });

      for (const groupIDKey in groupedTasksMap) {
        if (groupIDKey !== "normal") {
          const childTasks = groupedTasksMap[groupIDKey].children;
          const mainTaskRawData = this.rawTasksMap[groupIDKey];
          const mainTaskRecurrenceRules = mainTaskRawData.recurrence;
          let clonedMainTaskRules = mainTaskRawData.recurrence;

          let mainTaskDataUpdate = {};
          let excludedDates = [];
          let rulesChanged = false;
          let removeChildrenTasks = [];
          const editedTasksList = [];
          const sameProjectChildren = [];
          let recreateTasks = false;
          let updateMain = false;
          let editedDataForMainTask = {};
          const { ruleSet: parsedRuleSet } =
            parseRuleDataFromTaskRecurrenceRules({
              recurrence: mainTaskRecurrenceRules,
              dueAsDate: createDateTimeFromDue(
                mainTaskRawData.due,
                mainTaskRawData.time
              ),
            });

          if (!isEmpty(childTasks)) {
            childTasks.forEach((childT) => {
              let currRawData = {};
              let dateToExclude;
              let localExtraDataForCompletedTask = {};
              const isVirtualTask = childT.isVirtual;

              if (parseDueDate) {
                childT.dueAsDate = new Date(childT.dueAsDate);
              }

              const currChildTDueDate = childT.dueAsDate;
              let addToDb = true;
              let removeFromGoogle = false;
              if (!isVirtualTask) {
                currRawData = this.rawTasksMap[childT.key];
                addToDb = false;
              } else {
                currRawData = cloneDeep(this.rawTasksMap[groupIDKey]);
                currRawData = {
                  ...currRawData,
                  ...getDateAndTimeFromDueDate(childT.dueAsDate),
                };

                const googleCalId = GoogleCalHelpers.getStoredCalId();
                if (currRawData.isFromGoogle) {
                  if (currRawData.googleEventId) {
                    currRawData.googleEventId =
                      createGoogleEventIdByDateAndTaskId(
                        currRawData.googleEventId.split("_")[0],
                        childT.dueAsDate
                      );
                    currRawData.googleCalendarId = googleCalId;
                  }
                } else {
                  currRawData.googleEventId =
                    createGoogleEventIdByDateAndTaskId(
                      groupIDKey,
                      childT.dueAsDate
                    );

                  currRawData.googleCalendarId = googleCalId;
                }
                localTasksRemoveList.push(childT);
                removeChildrenTasks.push(childT);
              }

              const parentId = groupIDKey;

              let combinedData = {
                ...currRawData,
                ...parsedTaskDataToUpdate,
              };

              let isAreaChanged = combinedData.project !== currRawData.project;

              let taskTitleToUse;
              if (combinedData.isContingent && !combinedData.completed) {
                combinedData = {
                  ...combinedData,
                  ...getDateAndTimeFromDueDate(null),
                };
              } else {
                combinedData.isContingent = false;
              }

              if (!childT.completed && combinedData.completed) {
                localExtraDataForCompletedTask = {
                  keepItVisible: true,
                };
              }
              let newTaskData = cloneDeep(combinedData);
              newTaskData = removeProps(newTaskData, [
                "linkedTo",
                "recurrence",
              ]);

              const { editedData: editedTaskData } =
                createDbUpdateDataFromProvidedData(dataToUpdate, childT);
              let taskKeyOfChild = childT.key;
              if (addToDb) {
                delete newTaskData.googleEventId;
                newTaskData.key = createTaskId();

                taskKeyOfChild = newTaskData.key;
                newTaskData.coRelationalId = currRawData.coRelationalId;
                newTaskData.linkedTo = currRawData.key;
                newTaskData.order = currRawData.order;
                newTaskData.userPosition = currRawData.userPosition;
                newTaskData.customPosition = currRawData.customPosition;
                newTaskData.customPositionIndex =
                  currRawData.customPositionIndex;
                newTaskData = fillTaskData(newTaskData);
                newTaskData.resources = [];
                editedTaskData.key = newTaskData.key;
                taskTitleToUse = newTaskData.title;
                if (this.isGoogleCalSyncEnabled) {
                  newTaskData.googleCalendarId =
                    GoogleCalHelpers.getStoredCalId();
                  if (childT.isFromGoogle) {
                    newTaskData.googleEventId =
                      createGoogleEventIdByDateAndTaskId(
                        childT.googleEventId.split("_")[0],
                        childT.dueAsDate
                      );
                  } else {
                    newTaskData.googleEventId =
                      createGoogleEventIdByDateAndTaskId(
                        parentId,
                        childT.dueAsDate
                      );
                  }
                }

                newTaskData.originalDate = isValidDate(childT.dueAsDate)
                  ? childT.dueAsDate
                  : "";

                // if (
                //   dataToUpdate.dueAsDate !== undefined &&
                //   !isEqual(dataToUpdate.dueAsDate, childT.dueAsDate)
                // ) {
                //   dateToExclude = dataToUpdate.dueAsDate;
                //   if (
                //     isValidDate(childT.dueAsDate) &&
                //     !isValidDate(dataToUpdate.dueAsDate)
                //   ) {
                //     dateToExclude = childT.dueAsDate;
                //   }
                // }

                if (newTaskData.project === mainTaskRawData.project) {
                  sameProjectChildren.push(newTaskData.key);
                }
                dbAddTasksList.push(newTaskData);
                localTasksAddList.push({
                  ...newTaskData,
                  ...localExtraDataForCompletedTask,
                });

                if (
                  this.checkIfParsedTaskDataHasReminderProps(parsedDataKeys)
                ) {
                  const currReminderTasks = getReminderTasksLinkedToTaskId(
                    this.tasks,
                    childT.key
                  );

                  if (!isEmpty(currReminderTasks)) {
                    localTasksRemoveList.push(...currReminderTasks);
                  }

                  if (
                    checkIfTaskHasReminder(newTaskData) &&
                    !newTaskData.completed
                  ) {
                    reminderTaskList.push(...createReminderTasks(newTaskData));
                  }
                }
                editedTasksList.push({
                  ...editedTaskData,
                  key: newTaskData.key,
                });
                currTotalTasksCount++;
                // Add new task
              } else {
                let updateInDb = true;
                const { updatedTaskData } = createDbUpdateDataFromProvidedData(
                  dataToUpdate,
                  childT
                );
                newTaskData.coRelationalId = currRawData.coRelationalId;
                newTaskData.linkedTo = parentId;
                newTaskData = {
                  ...newTaskData,
                  ...updatedTaskData,
                };

                taskTitleToUse = newTaskData.title;
                if (parsedDataKeys.includes("due") && childT.completed) {
                  updateInDb = false;
                }

                if (updateInDb) {
                  editedTaskData.key = childT.key;
                  editedTasksList.push({
                    ...editedTaskData,
                    key: childT.key,
                  });
                  dbUpdateTasksList.push({
                    key: childT.key,
                    updates: { ...parsedTaskDataToUpdate },
                  });
                  localTasksUpdateList.push({
                    key: childT.key,
                    updates: {
                      ...newTaskData,
                      ...localExtraDataForCompletedTask,
                    },
                  });

                  if (
                    this.checkIfParsedTaskDataHasReminderProps(parsedDataKeys)
                  ) {
                    const currReminderTasks = getReminderTasksLinkedToTaskId(
                      this.tasks,
                      childT.key
                    );

                    if (!isEmpty(currReminderTasks)) {
                      localTasksRemoveList.push(...currReminderTasks);
                    }

                    if (
                      checkIfTaskHasReminder(newTaskData) &&
                      !newTaskData.completed
                    ) {
                      reminderTaskList.push(
                        ...createReminderTasks(newTaskData)
                      );
                    }
                  }
                } else {
                  removeFromGoogle = true;
                  newTaskData = removeProps(newTaskData, [
                    "frequency",
                    "linkedTo",
                    "recurrence",
                    "coRelationalId",
                    "googleEventId",
                    "key",
                  ]);

                  newTaskData.completed = false;
                  const newTaskDataForDb = fillTaskData(newTaskData);

                  taskKeyOfChild = newTaskDataForDb.key;
                  if (
                    this.isGoogleCalSyncEnabled &&
                    newTaskDataForDb.due !== "none"
                  ) {
                    dateToExclude = createDateTimeFromDue(
                      newTaskDataForDb.due,
                      newTaskDataForDb.time
                    );
                    newTaskDataForDb.googleEventId = convertTaskKeyForGoogleCal(
                      newTaskDataForDb.key
                    );
                    newTaskDataForDb.googleCalendarId =
                      GoogleCalHelpers.getStoredCalId();
                    googleAddList.push({
                      key: newTaskDataForDb.googleEventId,
                      data: createTaskDataForGoogleCal({
                        ...newTaskDataForDb,
                        dueAsDate: createDateTimeFromDue(
                          newTaskDataForDb.due,
                          newTaskDataForDb.time
                        ),
                      }),
                    });
                  }

                  if (
                    this.checkIfParsedTaskDataHasReminderProps(parsedDataKeys)
                  ) {
                    const currReminderTasks = getReminderTasksLinkedToTaskId(
                      this.tasks,
                      childT.key
                    );

                    if (!isEmpty(currReminderTasks)) {
                      localTasksRemoveList.push(...currReminderTasks);
                    }

                    if (
                      checkIfTaskHasReminder(newTaskDataForDb) &&
                      !newTaskDataForDb.completed
                    ) {
                      reminderTaskList.push(
                        ...createReminderTasks(newTaskDataForDb)
                      );
                    }
                  }

                  newTaskDataForDb.order = currTotalTasksCount;
                  newTaskDataForDb.customPosition = currTotalTasksCount;
                  newTaskDataForDb.customPositionIndex = 0;
                  newTaskDataForDb.userPosition = 0;
                  dbAddTasksList.push(newTaskDataForDb);

                  localTasksAddList.push({
                    ...newTaskDataForDb,
                    ...localExtraDataForCompletedTask,
                  });

                  recurringToSingleList.push(newTaskDataForDb);
                  currTotalTasksCount++;
                }

                // Update Task
              }

              if (
                !taskKeyForRefreshInResourceForm &&
                !isEmpty(this.storedTaskDataInResourceForm) &&
                this.storedTaskDataInResourceForm.key === childT.key
              ) {
                taskKeyForRefreshInResourceForm = taskKeyOfChild;
              }
              if (isAreaChanged) {
                if (taskTitleToUse) {
                  const convertedNewValue = convertHTMLToQlDelta(
                    taskTitleToUse,
                    qlInstance
                  );

                  const resourcesList = getAllMentionedResourceIdsFromText(
                    convertedNewValue,
                    false
                  );

                  const projectResources = getResourcesByResourceIds(
                    resourcesList,
                    [
                      (resourceData) => {
                        return resourceData.type === "project";
                      },
                    ]
                  );

                  projectResourcesForCatChangeList.push(...projectResources);
                }

                if (
                  !refreshAreaForm &&
                  !isEmpty(this.editTaskData) &&
                  this.editTaskData.key === childT.key &&
                  this.editTaskData.project
                ) {
                  areaId = this.editTaskData.project;
                  refreshAreaForm = true;
                }
              }

              excludedDates.push(childT.dueAsDate);
              if (
                dataToUpdate.dueAsDate !== undefined &&
                !isEqual(dataToUpdate.dueAsDate, currChildTDueDate)
              ) {
                dateToExclude = dataToUpdate.dueAsDate;

                if (
                  isValidDate(currChildTDueDate) &&
                  isValidDate(dataToUpdate.dueAsDate) &&
                  !isSameDay(dataToUpdate.dueAsDate, currChildTDueDate)
                ) {
                  dateToExclude = dataToUpdate.dueAsDate;
                } else if (
                  isValidDate(currChildTDueDate) &&
                  !isValidDate(dataToUpdate.dueAsDate)
                ) {
                  dateToExclude = currChildTDueDate;
                } else if (
                  isValidDate(dataToUpdate.dueAsDate) &&
                  !isValidDate(currChildTDueDate)
                ) {
                  dateToExclude = isValidDate(dataToUpdate.dueAsDate);
                }
              }

              if (isValidDate(dateToExclude)) {
                excludedDates.push(dateToExclude);
              }

              // excludedDates.forEach((d) => {
              //   if (isValidDate(d)) {
              //     parsedRuleSet.exdate(converDateToUTC(d, false));
              //   }
              // });

              if (this.isGoogleCalSyncEnabled) {
                const dataToSend = {
                  ...newTaskData,
                  dueAsDate: createDateTimeFromDue(
                    newTaskData.due,
                    newTaskData.time
                  ),
                };
                // console.debug("DATA TO SEND", dataToSend);
                const dataForGoogleCal = {
                  ...createTaskDataForGoogleCal(
                    {
                      ...dataToSend,
                      taskKey: dataToSend.key,
                    },
                    childT
                  ),
                };

                if (
                  !googleRecurringEventUpdateListMap[
                    mainTaskRawData.googleEventId
                  ]
                ) {
                  googleRecurringEventUpdateListMap[
                    mainTaskRawData.googleEventId
                  ] = {
                    childrenUpdates: [],
                    mainEventUpdates: {},
                  };
                }
                googleRecurringEventUpdateListMap[
                  mainTaskRawData.googleEventId
                ].childrenUpdates.push({
                  currData: {
                    dueAsDate: childT.dueAsDate,
                    taskKey: newTaskData.key,
                    googleEventId:
                      newTaskData.googleEventId || currRawData.googleEventId,
                  },
                  newData: removeFromGoogle
                    ? createTaskDataForGoogleCal(
                        { status: "cancelled", taskKey: newTaskData.key },
                        { ...newTaskData }
                      )
                    : dataForGoogleCal,
                });
              }
            });
          }

          excludeDatesInTaskRules(excludedDates, parsedRuleSet);
          // excludedDates.forEach((d) => {
          //   if (isValidDate(d)) {
          //     parsedRuleSet.exdate(converDateToUTC(d, false));
          //   }
          // });

          const convertedRules = parsedRuleSet.valueOf().slice(1);

          if (!isEqual(convertedRules, mainTaskRecurrenceRules)) {
            mainTaskDataUpdate = {
              recurrence: convertedRules,
            };

            rulesChanged = true;
            clonedMainTaskRules = convertedRules;
            // dbUpdateTasksList.push({
            //   key: mainTaskRawData.key,
            //   updates: {
            //     recurrence: convertedRules,
            //   },
            // });

            if (mainTaskRawData.googleEventId) {
              if (
                !googleRecurringEventUpdateListMap[
                  mainTaskRawData.googleEventId
                ]
              ) {
                googleRecurringEventUpdateListMap[
                  mainTaskRawData.googleEventId
                ] = {
                  childrenUpdates: [],
                  mainEventUpdates: {},
                };
              }

              googleRecurringEventUpdateListMap[
                mainTaskRawData.googleEventId
              ].mainEventUpdates = createTaskDataForGoogleCal(
                {
                  ...googleRecurringEventUpdateListMap[
                    mainTaskRawData.googleEventId
                  ].mainEventUpdates,
                  ...mainTaskDataUpdate,
                  taskKey: mainTaskRawData.key,
                },
                { ...mainTaskRawData }
              );
            }
          }

          if (rulesChanged || !isEmpty(removeChildrenTasks)) {
            const recurrences = createRecurringDatesFromTaskRules({
              ...mainTaskRawData,
              recurrence: clonedMainTaskRules,
            });

            const firstRecurrence = recurrences[0];

            const firstTaskData = getRecurrenceIdByDateFromRecurrences(
              firstRecurrence,
              mainTaskRawData.key
            );

            let recurringTasksToUpdate = [];
            if (
              !isEmpty(removeChildrenTasks) &&
              !isEmpty(sameProjectChildren)
            ) {
              recreateTasks = true;
              updateMain = true;

              const dataToUpdate = {
                order: mainTaskRawData.order,
                userPosition:
                  mainTaskRawData.userPosition + sameProjectChildren.length,
              };
              mainTaskDataUpdate = {
                ...mainTaskDataUpdate,
                ...dataToUpdate,
              };

              editedDataForMainTask = {
                order: mainTaskRawData.order,
                userPosition: mainTaskRawData.userPosition,
                changedProps: { ...dataToUpdate },
                key: mainTaskRawData.key,
              };

              const currRecurringTasks = getRecurringTasks(this.tasks, "list", {
                linkedTo: mainTaskRawData.key,
                filter: true,
                filterOpts: [isTaskVirtual],
              });

              if (!isEmpty(currRecurringTasks)) {
                currRecurringTasks.forEach((currTask) => {
                  const createdDataUpdate = {
                    ...dataToUpdate,
                  };

                  if (!currTask.completed && createdDataUpdate.completed) {
                    createdDataUpdate.keepItVisible = true;
                  }
                  if (
                    !isEmpty(firstTaskData) &&
                    firstTaskData.key === currTask.key
                  ) {
                    createdDataUpdate.isCalendarOnly = false;
                  }

                  recurringTasksToUpdate.push({
                    key: currTask.key,
                    updates: {
                      ...createdDataUpdate,
                    },
                  });
                });
              }
            } else {
              if (!isEmpty(firstTaskData)) {
                recurringTasksToUpdate.push({
                  key: firstTaskData.key,
                  updates: {
                    isCalendarOnly: false,
                  },
                });
              }
            }

            localTasksUpdateList.push(...recurringTasksToUpdate);

            // const firstTask = this.tasks.find(
            //   (t) =>
            //     t.linkedTo === clonedMainTaskData.key &&
            //     t.isVirtual &&
            //     isSameDay(t.dueAsDate, firstRecurrence)
            // );

            // if (!isEmpty(firstTaskData)) {
            //   const currTaskData = this.tasks.find(
            //     (t) => t.key === firstTaskData.key
            //   );
            //   if (!isEmpty(currTaskData)) {
            //     localTasksUpdateList.push({
            //       key: currTaskData.key,
            //       updates: {
            //         ...currTaskData,
            //         isCalendarOnly: false,
            //       },
            //     });
            //   }
            // }

            // console.time("ID");

            // console.timeEnd("ID");
          }

          if (!isEmpty(mainTaskDataUpdate)) {
            dbUpdateTasksList.push({
              key: mainTaskRawData.key,
              updates: {
                ...mainTaskDataUpdate,
              },
            });
          }

          if (!editedTasksMap[groupIDKey]) {
            editedTasksMap[groupIDKey] = {
              editedTasks: editedTasksList,
              updateMain,
              recreateTasks,
              editedData: editedDataForMainTask,
            };
          }

          if (!isEmpty(removeChildrenTasks)) {
            const taskIdsToRemove = removeChildrenTasks.map((t) => t.key);
            removeRecurringTasksInfoMapList.push({
              mainTaskId: mainTaskRawData.key,
              tasksIds: taskIdsToRemove,
            });
          }
          // Add recurring logic
        } else {
          const normalChildTasks = groupedTasksMap[groupIDKey].children;
          let editedTasksList = [];
          let addedTasksList = [];
          if (!isEmpty(normalChildTasks)) {
            const parsedDataKeys = Object.keys(parsedTaskDataToUpdate);
            normalChildTasks.forEach((childT) => {
              let addToDb = false;
              if (parsedDataKeys.includes("due") && childT.completed) {
                addToDb = true;
              }
              let localExtraDataForCompletedTask = {};

              const currRawData = this.rawTasksMap[childT.key];
              let finalDataObj = {
                ...currRawData,
                ...parsedTaskDataToUpdate,
              };

              const isAreaChanged =
                finalDataObj.project !== currRawData.project;

              if (!childT.completed && finalDataObj.completed) {
                localExtraDataForCompletedTask = {
                  keepItVisible: true,
                };
              }
              if (!isEmpty(currRawData)) {
                let taskKeyOfChild = childT.key;
                if (addToDb) {
                  let addInGoogle = false;
                  finalDataObj.key = createUniqueId();

                  taskKeyOfChild = finalDataObj.key;
                  finalDataObj.order = currTotalTasksCount;
                  finalDataObj.userPosition = 0;
                  finalDataObj.customPosition = currTotalTasksCount;
                  finalDataObj.customPositionIndex = 0;

                  finalDataObj.flag =
                    finalDataObj.due && finalDataObj.due !== "none";

                  if (
                    this.isGoogleCalSyncEnabled &&
                    finalDataObj.due !== "none"
                  ) {
                    addInGoogle = true;
                    finalDataObj.googleEventId = convertTaskKeyForGoogleCal(
                      finalDataObj.key
                    );

                    finalDataObj.googleCalendarId =
                      GoogleCalHelpers.getStoredCalId();
                  }
                  finalDataObj = fillTaskData(finalDataObj);
                  finalDataObj.completed = false;
                  if (addInGoogle) {
                    googleAddList.push({
                      key: finalDataObj.googleEventId,
                      data: createTaskDataForGoogleCal({
                        ...finalDataObj,
                        dueAsDate: createDateTimeFromDue(
                          finalDataObj.due,
                          finalDataObj.time
                        ),
                        taskKey: finalDataObj.key,
                      }),
                    });
                  }
                  addedTasksList.push(cleanTask(cloneDeep(finalDataObj)));
                  dbAddTasksList.push(finalDataObj);
                  localTasksAddList.push({
                    ...finalDataObj,
                    ...localExtraDataForCompletedTask,
                  });

                  if (checkIfTaskHasReminder(finalDataObj)) {
                    reminderTaskList.push(
                      ...createReminderTasks(cleanTask(cloneDeep(finalDataObj)))
                    );
                  }

                  currTotalTasksCount++;
                  // Add new task
                } else {
                  let taskDataToUse = childT;

                  if (useRaw && !isEmpty(this.rawTasksMap[childT.key])) {
                    const rawTaskData = { ...this.rawTasksMap[childT.key] };
                    taskDataToUse = {
                      ...rawTaskData,
                      dueAsDate: createDateTimeFromDue(
                        rawTaskData.due,
                        rawTaskData.time
                      ),
                    };
                  }
                  const { editedData: editedTaskData } =
                    createDbUpdateDataFromProvidedData(
                      dataToUpdate,
                      taskDataToUse
                    );

                  editedTasksList.push({
                    ...editedTaskData,
                    key: childT.key,
                  });
                  dbUpdateTasksList.push({
                    key: childT.key,
                    updates: { ...parsedTaskDataToUpdate },
                  });

                  localTasksUpdateList.push({
                    key: childT.key,
                    updates: {
                      ...finalDataObj,
                      ...localExtraDataForCompletedTask,
                    },
                  });

                  if (
                    this.checkIfParsedTaskDataHasReminderProps(parsedDataKeys)
                  ) {
                    const currReminderTasks = getReminderTasksLinkedToTaskId(
                      this.tasks,
                      childT.key
                    );

                    if (!isEmpty(currReminderTasks)) {
                      localTasksRemoveList.push(...currReminderTasks);
                    }
                    if (
                      checkIfTaskHasReminder(finalDataObj) &&
                      !finalDataObj.completed
                    ) {
                      reminderTaskList.push(
                        ...createReminderTasks(
                          cleanTask(cloneDeep(finalDataObj))
                        )
                      );
                    }
                  }

                  if (currRawData.googleEventId) {
                    googleUpdateList.push({
                      key: currRawData.googleEventId,
                      updates: {
                        ...createTaskDataForGoogleCal(
                          {
                            ...finalDataObj,
                            dueAsDate: createDateTimeFromDue(
                              finalDataObj.due,
                              finalDataObj.time
                            ),
                            taskKey: taskDataToUse.key,
                          },
                          childT
                        ),
                      },
                    });
                  }
                  // Update Task
                }
                if (
                  !taskKeyForRefreshInResourceForm &&
                  !isEmpty(this.storedTaskDataInResourceForm) &&
                  this.storedTaskDataInResourceForm.key === childT.key
                ) {
                  taskKeyForRefreshInResourceForm = taskKeyOfChild;
                }
              }

              if (isAreaChanged) {
                const convertedNewValue = convertHTMLToQlDelta(
                  finalDataObj.title,
                  qlInstance
                );

                const resourcesList = getAllMentionedResourceIdsFromText(
                  convertedNewValue,
                  false
                );

                const projectResources = getResourcesByResourceIds(
                  resourcesList,
                  [
                    (resourceData) => {
                      return resourceData.type === "project";
                    },
                  ]
                );

                if (
                  !refreshAreaForm &&
                  !isEmpty(this.editTaskData) &&
                  this.editTaskData.key === childT.key &&
                  this.editTaskData.project
                ) {
                  areaId = this.editTaskData.project;
                  refreshAreaForm = true;
                }
                projectResourcesForCatChangeList.push(...projectResources);
              }
            });
          }

          if (!editedTasksMap["normal"]) {
            editedTasksMap["normal"] = {
              editedTasks: editedTasksList,
              addedTasks: addedTasksList,
            };
          }
          // Add normal task logic
        }
      }

      if (!isEmpty(recurringToSingleList)) {
        if (!editedTasksMap.normal) {
          editedTasksMap.normal = {
            addedTasks: recurringToSingleList,
          };
        } else {
          editedTasksMap.normal.addedTasks = [
            ...editedTasksMap.normal.addedTasks,
            ...recurringToSingleList,
          ];
        }
      }
      // eslint-disable-next-line no-unused-vars
      let userActionType = "task";
      let userBatchActions = {
        type: TASK_ACTIONS.NORMAL_RECURRING_EDIT,
        data: {
          ...editedTasksMap,
        },
        on: "task",
      };

      if (!isEmpty(projectEditData)) {
        userActionType = "all";
        userBatchActions = [
          {
            type: PROJECT_ACTIONS.EDIT,
            data: projectEditData,
            on: "project",
          },
          {
            ...userBatchActions,
          },
        ];
      }

      if (userActionType === "all") {
        getUserActions().addBatchAction({
          data: userBatchActions,
          on: "all",
        });
      } else {
        getUserActions().addTaskAction(userBatchActions);
      }

      // getUserActions().addTaskAction({
      //   type: TASK_ACTIONS.NORMAL_RECURRING_EDIT,
      //   data: {
      //     ...editedTasksMap,
      //   },
      //   on: "task",
      // });

      if (!isEmpty(projectDbData)) {
        DatabaseInterface.update(projectDbData, this.userInfo.uid);
      }

      if (this.checkIfTaskFormIsOpened()) {
        refreshAreaForm = true;

        // EventEmitter.emit(REFRESH_FULL_FORM);
      }
      await addOrRemoveOrUpdateTasksInLocalTasksList(
        {
          tasksToAdd: [...localTasksAddList, ...reminderTaskList],
          tasksToUpdate: localTasksUpdateList,
          tasksToRemove: localTasksRemoveList,
          addMethod: !isEmpty(reminderTaskList) ? "push" : "unshift",
          verifyAdd: isEmpty(reminderTaskList),
        },
        false
      );

      removeTasksFromRecurringTasksMap(removeRecurringTasksInfoMapList);

      addOrRemoveOrUpdateTasksInDb({
        tasksToAdd: dbAddTasksList,
        tasksToUpdate: dbUpdateTasksList,
      });

      if (!isEmpty(projectResourcesForCatChangeList)) {
        changeCatOfProjectResources([
          {
            resources: projectResourcesForCatChangeList,
            catId: dataToUpdate.project,
            isTaskRef: true,
          },
        ]);
      }

      await this.$nextTick();

      let selectedRows = [];

      tasksToProcess.forEach((t) => {
        let taskDataToUse = { ...t };
        if (taskDataToUse.isReminderTask) {
          const allRemindersOfParent = getReminderTasksLinkedToTaskId(
            this.tasks,
            taskDataToUse.reminderLinkedTo
          );

          if (!isEmpty(allRemindersOfParent)) {
            const taskReminderDate = parseDueDate
              ? new Date(taskDataToUse.reminderDate)
              : taskDataToUse.reminderDate;
            const reminderTaskData = allRemindersOfParent.find((t) =>
              isSameDates(t.reminderDate, taskReminderDate)
            );

            if (!isEmpty(reminderTaskData)) {
              taskDataToUse = { ...reminderTaskData };
            }
          }
        } else {
          const updatedTaskData = this.tasks.find(
            (t) => t.key === taskDataToUse.key
          );

          if (!isEmpty(updatedTaskData)) {
            taskDataToUse = { ...updatedTaskData };
          }
        }

        selectedRows.push(taskDataToUse);
      });

      if (this.$route.name === "Dashboard") {
        EventEmitter.emit(SET_ID_FOR_SCROLL, selectedRows, true);
      }

      EventEmitter.emit(
        SELECT_TASK_IN_ACTIVITY_GRID,
        selectedRows,
        undefined,
        undefined,
        true
      );
      EventEmitter.emit(REFRESH_TASKS_EVENT);

      EventEmitter.emit(REFRESH_ACTIVITY_HISTORY);

      addOrUpdateOrRemoveTasksInGoogleCal({
        tasksToAdd: googleAddList,
        tasksToUpdate: googleUpdateList,
      });
      this.updateRecurringAndItsChildEventsFromMap(
        googleRecurringEventUpdateListMap
      );

      await this.$nextTick();

      if (taskKeyForRefreshInResourceForm) {
        this.refreshTaskDataInResourceForm(taskKeyForRefreshInResourceForm);
      }
      if (refreshAreaForm) {
        this.setTaskAreaInFormAndRefresh(areaId);
        // this.setTaskData({
        //   project: areaId,
        // });
        // EventEmitter.emit(REFRESH_FULL_FORM);
      }

      removeQlInstance();
    },
    setAreaInTree(areaId, openForm) {
      EventEmitter.emit(
        SET_SELECTED_NODE_EVENT,
        [areaId],
        true,
        true,
        undefined,
        true,
        openForm,
        true
      );
    },
    checkIfParsedTaskDataHasReminderProps(dataKeys) {
      let valueExists = false;
      if (!isEmpty(dataKeys)) {
        valueExists = REMINDER_TASK_EDIT_PROPS.some((p) =>
          dataKeys.includes(p)
        );
        if (!valueExists) {
          valueExists = dataKeys.includes("due");
        }

        if (!valueExists) {
          valueExists = dataKeys.includes("reminderDays");
        }
      }

      return valueExists;
    },

    async convertNormalOrRecurringTasksToNotes(tasksToConvert, deselectTasks) {
      if (!Array.isArray(tasksToConvert)) tasksToConvert = [tasksToConvert];
      const tasksToRemove = [];
      const notesToAdd = [];

      const allNotesDirectories = store.getters["note/directories"] || {};
      const allNotes = store.getters["note/rawNotes"] || [];
      tasksToConvert.forEach((task) => {
        const { key, ...restData } = task;

        let taskDataToUse = { ...restData };

        if (taskDataToUse.isReminderTask) {
          const parentTaskData = this.tasks.find(
            (t) => t.key === taskDataToUse.reminderLinkedTo
          );

          taskDataToUse = { ...parentTaskData };
        }

        const createdNoteData = createNoteDataForAdd(
          {
            ...taskDataToUse,
            descr: convertDescrValToHTMLString(taskDataToUse.description) || "",
            created: taskDataToUse.created,
            modified: taskDataToUse.modified,
          },
          allNotes,
          allNotesDirectories
        );
        notesToAdd.push({ ...createdNoteData });
        tasksToRemove.push({ ...taskDataToUse, key });
      });

      const cleanedNotes = cleanNotes(notesToAdd);
      addMultiNotesInNotesList(notesToAdd, false);
      const { editedData: removedTasksEditData } =
        await this.removeRecurringOrNormalTasks(tasksToConvert, false);

      const userActions = {
        tasksToRemoveData: removedTasksEditData,
        notesToAddData: cleanedNotes,
      };

      getUserActions().addTaskAction({
        type: TASK_ACTIONS.CONVERTED_TO_NOTES,
        data: userActions,
      });
      if (deselectTasks) {
        store.dispatch("task/updateSelectedTasks", []);
      }
    },
    addTaskAsNote(taskData) {
      this.setDataForConvertMode({
        dataList: [{ ...taskData }],
        typeToUse: "note",
      });
    },
    async addNewTaskEntry(data, storeAction = true) {
      let date = "none";
      let time = "00:00";
      let dbUpdates = {};
      let changeProjectResourcesCat = false;
      // let projectEditData = {};
      const defaultTaskPriority = "priority";
      if (data.dueAsDate) {
        date = makeDateFormat(data.dueAsDate);
        time = makeTimeFormat(data.dueAsDate);
      }
      let addToGoogle = false;

      const currAllTasksCount = Object.keys(this.rawTasksMap || {}).length || 0;
      const taskData = {
        title: data.title,
        key: data.key || createTaskId(),
        created: getCurrDate(),
        modified: "",
        due: date,
        completed: false,
        status: "incomplete",
        flag: date !== "none",
        description: data.description || "",
        order: data.order !== undefined ? data.order : currAllTasksCount,
        userPosition: data.userPosition !== undefined ? data.userPosition : 0,
        time,
        cleared: false,
        project: "",
        isContingent: data.isContingent || false,
        objective: data.objective || "",
        reminderDays: data.reminderDays || null,
        pausedReminderDays: getPausedReminderDaysVal(data.pausedReminderDays),
        positionChanged: data.positionChanged || false,
        resources: getAllMentionedResourceIdsFromText(data.title),
        isCustomPositioned: data.isCustomPositioned || false,
        customPosition: data.customPosition || currAllTasksCount,
        customPositionIndex: data.customPositionIndex || 0,
      };

      taskData.priority = data.priority;

      if (data.taskType) {
        taskData.taskType = data.taskType;
      }

      if (data.project && this.projects[data.project]) {
        changeProjectResourcesCat = true;
        taskData.project = data.project;
        const projectPriority = this.projects[data.project].priority || "";

        if (projectPriority && !taskData.priority) {
          taskData.priority = projectPriority;
        }

        if (!taskData.priority) {
          taskData.priority = defaultTaskPriority;
        }

        // let position = 0;
        // let updatedTasksListOfProject =
        //   this.projects[taskData.project].tasks || [];

        // if (Array.isArray(updatedTasksListOfProject)) {
        //   updatedTasksListOfProject = [
        //     ...updatedTasksListOfProject,
        //     taskData.key,
        //   ];
        // }
        // if (this.projects[taskData.project].tasks) {
        //   position = this.projects[taskData.project].tasks.length;
        // }

        // dbUpdates[`/projects/${taskData.project}/tasks/${position}`] =
        //   taskData.key;

        // projectEditData = {
        //   changedProps: {
        //     tasks: updatedTasksListOfProject,
        //   },
        //   tasks: this.projects[data.project].tasks || [],
        //   key: taskData.project,
        // };

        // Disabled for now (Setting area/project's priority using a dialog )
        // if (!projectPriority && taskData.priority) {
        //   dbUpdates[`/projects/${data.project}/priority`] = taskData.priority;

        //   if (!isEmpty(projectEditData) && projectEditData.changedProps) {
        //     projectEditData.priority = "";
        //     projectEditData.changedProps.priority = taskData.priority;
        //   }
        // }
      } else {
        taskData.priority = "purpose";
      }

      if (this.isGoogleCalSyncEnabled && isValidDate(data.dueAsDate)) {
        taskData.googleEventId = convertTaskKeyForGoogleCal(taskData.key);
        taskData.googleCalendarId = GoogleCalHelpers.getStoredCalId();
        addToGoogle = true;
      }

      dbUpdates[`/tasks/${taskData.key}`] = taskData;
      await DatabaseInterface.update(dbUpdates, this.userInfo.uid);

      if (changeProjectResourcesCat) {
        const mentionedProjectResources = getProjectResourcesFromText(
          taskData.title
        );

        if (!isEmpty(mentionedProjectResources)) {
          changeCatOfProjectResources([
            {
              resources: mentionedProjectResources,
              catId: taskData.project,
              isTaskRef: true,
            },
          ]);
        }
      }

      let userActions = {
        type: TASK_ACTIONS.BATCH_ADD,
        data: [taskData],
        on: "task",
      };

      // if (!isEmpty(projectEditData)) {
      //   userActions = [
      //     {
      //       type: PROJECT_ACTIONS.EDIT,
      //       data: projectEditData,
      //       on: "project",
      //     },
      //     {
      //       ...userActions,
      //     },
      //   ];
      // }

      if (storeAction) {
        if (Array.isArray(userActions)) {
          getUserActions().addBatchAction({
            data: userActions,
            on: "all",
          });
        } else {
          getUserActions().addTaskAction(userActions);
        }
      }

      if (addToGoogle) {
        this.addTasksToGoogleCal({
          key: taskData.key,
          data: {
            ...createTaskDataForGoogleCal({
              ...taskData,
              dueAsDate: createDateTimeFromDue(taskData.due, taskData.time),
              taskKey: taskData.key,
            }),
          },
        });
      }

      return taskData;
    },
    showAlertForTitleNotValid() {
      this.$swal({
        titleText: "Oops!",
        html: "Task title cannot be empty",
        icon: "error",
        confirmButtonColor: PRIMARY_COLOR,
        iconColor: PRIMARY_COLOR,
      });
    },
    async handleTasksConvertedToNotes(actionData, isRedoAction) {
      const { tasksToRemoveData, notesToAddData } = actionData;
      if (!isEmpty(tasksToRemoveData)) {
        if (isRedoAction) {
          this.removeRecurringOrNormalTasksFromMap(tasksToRemoveData);
          await addMultiNotesInNotesList(notesToAddData, false);
        } else {
          this.restoreRemovedNormalOrRecurringTasks(tasksToRemoveData);
          await removeNotesFromNotesList(notesToAddData, false);
        }

        await Vue.nextTick();

        emitRefreshList(true, "notes");
      }
    },
    async completeCurrTaskAndCreateNextRepeatTask({
      key,
      updates,
      editedData,
      updatedRowData,
      updateSelectedTasks = true,
      refreshList,
      checkEditForm,
    }) {
      const currRawTaskData = this.rawTasksMap[key];

      if (!isEmpty(currRawTaskData)) {
        const nextRepeatTask = createNextRepeatTask(
          {
            ...currRawTaskData,
            dueAsDate: createDateTimeFromDue(
              currRawTaskData.due,
              currRawTaskData.time
            ),
          },
          {
            due: currRawTaskData.due,
            time: currRawTaskData.time,
          }
        );

        const dbUpdates = {
          ...updates,
          [`/tasks/${nextRepeatTask.key}`]: nextRepeatTask,
        };

        DatabaseInterface.update(dbUpdates, this.userInfo.uid);

        const userActionsBatch = [
          {
            type: "edit",
            data: {
              ...editedData,
              key,
            },
          },
          {
            type: TASK_ACTIONS.ADD,
            data: {
              ...nextRepeatTask,
            },
          },
        ];
        getUserActions().addTaskAction({
          type: TASK_ACTIONS.ACTIONS_BATCH,
          data: userActionsBatch,
        });

        if (updateSelectedTasks) {
          if (this.$refs.dataGrid) {
            this.$refs.dataGrid.setRowIdsForScroll([nextRepeatTask.key]);
          }
        }

        const localTasksUpdateConfig = {
          tasksToAdd: [{ ...nextRepeatTask }],
          tasksToUpdate: [{ key, updates: updatedRowData }],
        };

        let createdReminderTasks = [];

        if (checkIfTaskHasReminder(nextRepeatTask)) {
          createdReminderTasks.push(...createReminderTasks(nextRepeatTask));
        }

        if (!isEmpty(createdReminderTasks)) {
          localTasksUpdateConfig.tasksToAdd = [
            ...localTasksUpdateConfig.tasksToAdd,
            ...createdReminderTasks,
          ];
        }

        if (checkIfTaskHasReminder(updatedRowData)) {
          const currReminderTasks = getReminderTasksLinkedToTaskId(
            this.tasks,
            updatedRowData.key
          );

          if (!isEmpty(currReminderTasks)) {
            localTasksUpdateConfig.tasksToRemove = [...currReminderTasks];
          }
        }

        await addOrRemoveOrUpdateTasksInLocalTasksList({
          ...localTasksUpdateConfig,
        });

        if (this.isGoogleCalSyncEnabled) {
          addTasksToGoogleCal([
            {
              key: nextRepeatTask.key,
              data: createTaskDataForGoogleCal({
                ...nextRepeatTask,
                dueAsDate: createDateTimeFromDue(
                  nextRepeatTask.due,
                  nextRepeatTask.time
                ),
                taskKey: nextRepeatTask.key,
              }),
            },
          ]);
        }

        if (refreshList) {
          this.refreshList();
        }

        if (checkEditForm) {
          await Vue.nextTick();

          if (this.checkIfTaskIsActiveInResourceForm(currRawTaskData.key)) {
            this.refreshTaskDataInResourceForm(nextRepeatTask.key);
          } else if (
            !isEmpty(this.editTaskData) &&
            this.editTaskData.key === currRawTaskData.key
          ) {
            this.setTaskDataInForm({ ...nextRepeatTask });
          }
        }

        return {
          repeatedTaskData: { ...nextRepeatTask },
        };
        // await this.$nextTick();

        // this.$refs.dataGrid.scrollToRow(nextRepeatTask.key, true, true);
      }
    },
    checkRowTaskDataChanged(dataToUpdate, currData) {
      const qlInstance = createOrGetQlInstance();
      const isDataChanged = INLINE_EDIT_TASK_PROPS.some((propData) => {
        if (dataToUpdate[propData.field] !== undefined) {
          if (propData.field === "title") {
            const oldConveredData = convertHTMLToQlDelta(
              currData[propData.field],
              qlInstance
            );
            const updatedConveredData = convertHTMLToQlDelta(
              dataToUpdate[propData.field],
              qlInstance
            );
            return !areQlDeltasEqual(oldConveredData, updatedConveredData);
          } else if (propData.field === "pausedReminderDays") {
            const currPausedReminderDays = dataToUpdate[propData.field] || [];
            const oldPausedReminderDays = currData[propData.field] || [];

            return !isEqual(currPausedReminderDays, oldPausedReminderDays);
          } else {
            return !isEqual(
              dataToUpdate[propData.field],
              currData[propData.field]
            );
          }
        }
      });
      removeQlInstance();
      return isDataChanged;
    },

    processTasksOfNextActionsMode(
      list,
      sortBy,
      isInResourceMode,
      resourceEdit,
      isInForm,
      _,
      providedTaskGroupFilter
    ) {
      // console.log("SSSSSS", {
      //   list,
      //   sortBy,
      //   isInResourceMode,
      //   resourceEdit,
      //   isInForm,
      //   _,
      //   providedTaskGroupFilter,
      // });
      const isAreaSelected = sortBy.find((sort) => sort.column === "project");
      const taskTypeColSortData = sortBy.find((s) => s.column === "taskType");
      const showAllTasks = this.showAllOrderedTasks;

      const selectedTaskGroupFilter =
        providedTaskGroupFilter || this.selectedTaskGroupFilter;
      const areaWiseSortedTasks = {};
      const areaWiseSortedTasksGroups = [];
      const collator = new Intl.Collator([], { numeric: true });
      const searchQuery = this.searchQuery?.trim()?.toLowerCase();
      const isInCategoryMode =
        checkIfSelectedTaskFiltersHasCategory(this.filters) ||
        (!isEmpty(this.selectedOptsList) &&
          !checkIfSelectedTaskCateHasOnlyArea(this.selectedOptsList));
      if (!isEmpty(list)) {
        if (
          isInResourceMode ||
          (isEmpty(this.selectedOptsList) && !this.isSearchKeywordValid)
        ) {
          list.sort((a, b) => {
            const orderedA = a.areaWiseOrder;
            const orderedB = b.areaWiseOrder;
            return collator.compare(orderedA, orderedB);
          });
        }

        if (
          !isInResourceMode &&
          (isInCategoryMode ||
            checkIfSelectedTaskCateHasOnlyArea(this.selectedOptsList))
        ) {
          list.sort((a, b) => {
            const orderedA = a.areaWiseOrder;
            const orderedB = b.areaWiseOrder;
            return collator.compare(orderedA, orderedB);
          });
        }

        list.forEach((t) => {
          if (t["CTICustomGroup"] !== "1") {
            t.sortOrder = "1";
            return;
          }

          const orderPropValue = t["ReviewGroup"];
          if (!areaWiseSortedTasks[orderPropValue]) {
            areaWiseSortedTasks[orderPropValue] = [];
            areaWiseSortedTasksGroups.push(orderPropValue);
          }
          if (!areaWiseSortedTasks[orderPropValue].includes(t.key)) {
            areaWiseSortedTasks[orderPropValue].push(t.key);
            t.orderedTaskNumber = areaWiseSortedTasks[orderPropValue].length;
            t.isFirstOrderedTask = t.orderedTaskNumber === 1;
            t.orderedReviewTask = `${t.ReviewGroup}-${t.orderedTaskNumber}`;
            t.sortOrder = "0";
          }
        });

        if (selectedTaskGroupFilter) {
          const taskGroupFilters = createTaskGroupFiltersFromList(
            selectedTaskGroupFilter
          );
          list = filterList(list, taskGroupFilters);
        }

        if (
          !isInResourceMode &&
          !isInForm &&
          isEmpty(this.selectedOptsList) &&
          !checkIfSelectedTaskFiltersHasCategory(this.filters) &&
          !this.isSearchKeywordValid &&
          !showAllTasks
        ) {
          list = list.filter((t) => {
            return t.isFirstOrderedTask;
          });

          const modifiedColSortData = sortBy.find(
            (sort) => sort.column === "modifiedAsDate"
          );

          if (!isEmpty(modifiedColSortData)) {
            // Sort by modified if enabled
            list.sort((a, b) => {
              // Sort by Area if enabled
              if (!isEmpty(isAreaSelected)) {
                const firstVal = a.orderedReviewTask;
                const secondVal = b.orderedReviewTask;

                if (!firstVal || !secondVal) {
                  return 0;
                }

                if (isAreaSelected.order === "desc") {
                  return secondVal.localeCompare(firstVal);
                }

                return firstVal.localeCompare(secondVal);
              }
              return sortModifiedCol(a, b, modifiedColSortData?.order);
            });
          } else {
            // Sort By Area
            list.sort((a, b) => {
              const firstVal = a.orderedReviewTask;
              const secondVal = b.orderedReviewTask;

              if (!firstVal || !secondVal) {
                return 0;
              }

              if (!isEmpty(isAreaSelected) && isAreaSelected.order === "desc") {
                return secondVal.localeCompare(firstVal);
              }

              return firstVal.localeCompare(secondVal);
            });
          }

          if (!isEmpty(taskTypeColSortData)) {
            sortTaskType(list, taskTypeColSortData.order);
          }

          // list = this.filterTasksBasedOnSelectedFilters(
          //   list,
          //   this.selectedTaskFilter,
          //   this.selectedTaskDateFilter
          // );
          return list;
        }

        list.sort((a, b) => {
          // eslint-disable-next-line
          if (showAllTasks || !!isAreaSelected) {
            const firstVal = a.orderedReviewTask;
            const secondVal = b.orderedReviewTask;

            if (!firstVal || !secondVal) {
              return 0;
            }

            if (isAreaSelected?.order === "desc") {
              return collator.compare(secondVal, firstVal);
            }

            return collator.compare(firstVal, secondVal);
          }
          const prevOrderedVal = a.orderedTaskNumber;
          const nextOrderedVal = b.orderedTaskNumber;
          if (prevOrderedVal > nextOrderedVal) {
            return 1;
          } else if (prevOrderedVal < nextOrderedVal) {
            return -1;
          } else {
            return 0;
          }
        });
      }
      if (
        isInResourceMode ||
        (isInCategoryMode && !this.isSearchKeywordValid)
      ) {
        let sortList = true;

        if (showAllTasks) {
          // Do not sort list
          sortList = false;
        } else if (isInResourceMode && resourceEdit) {
          // don't to anything
        } else {
          list = list.filter((t) => {
            return t.isFirstOrderedTask || t.CTICustomGroup !== "1";
          });
        }

        // list = list.filter((t) => t.isCustomPositioned )
        if (sortList && isEmpty(isAreaSelected)) {
          const modifiedColSortData = sortBy.find(
            (sort) => sort.column === "modifiedAsDate"
          );

          list.sort((p, n) => {
            if (!p.isCustomPositioned || !n.isCustomPositioned) {
              return 0;
            }

            return sortModifiedCol(p, n, modifiedColSortData?.order);
          });
        }
      }

      if (!isEmpty(taskTypeColSortData)) {
        sortTaskType(list, taskTypeColSortData.order);
      }

      // Disabled this filter because filter bar in task tree view was disabled
      // if (!isInResourceMode) {
      //   list = this.filterTasksBasedOnSelectedFilters(
      //     list,
      //     this.selectedTaskFilter,
      //     this.selectedTaskDateFilter
      //   );
      // }

      if (searchQuery && searchQuery !== "*") {
        const searchFilters = createSearchQueryFilters([], searchQuery, true);
        list = filterList(list, searchFilters);
      }

      return list;
    },
    filterTasksBasedOnSelectedFilters(
      taskList,
      selectedModifiedFilter,
      selectedDateFilter
    ) {
      const normalisedFilter = selectedModifiedFilter?.trim() || "";
      const normalisedDateFilter = selectedDateFilter?.trim() || "";
      const currDateOnly = parseISO(formatDate(currDate, INTERNAL_DATE_FORMAT));
      if (
        (!normalisedFilter && !normalisedDateFilter) ||
        this.isSearchKeywordValid
      ) {
        return taskList;
      }

      const groupedMap = {
        date: [],
        modified: [],
      };

      taskList.forEach((t) => {
        if (t.due !== "none") {
          groupedMap.date.push(t);
        } else {
          groupedMap.modified.push(t);
        }
      });

      let finalTaskList = [];

      let startDate,
        dateRange = [];
      if (normalisedFilter) {
        startDate = subFromDate(
          currDateOnly,
          taskFiltersConfigMap[normalisedFilter].subtractType
        );
      }
      dateRange = createDateRangeBasedOnForecastVal(normalisedDateFilter);

      if (
        !isEmpty(groupedMap.date) &&
        normalisedDateFilter &&
        !isEmpty(dateRange)
      ) {
        groupedMap.date = [
          ...groupedMap.date.filter((t) => {
            return dateRange.includes(t.due);
          }),
        ];
        // finalTaskList.push(
        //   ...groupedMap.date.filter((t) => {
        //     return dateRange.includes(t.due);
        //   })
        // );
      }

      if (!isEmpty(groupedMap.modified) && normalisedFilter) {
        const filterList = groupedMap.modified.filter((t) => {
          let parsedModified = parseJSONDate(t.modified);

          if (isValidDate(parsedModified)) {
            parsedModified = parseISO(
              formatDate(parsedModified, INTERNAL_DATE_FORMAT)
            );
          }

          return (
            isValidDate(parsedModified) &&
            isDateBetweenDates(parsedModified, startDate, currDateOnly)
          );
        });

        groupedMap.modified = [...filterList];
        // finalTaskList.push(...filterList);
      }

      finalTaskList.push(...groupedMap.date);
      finalTaskList.push(...groupedMap.modified);

      // const createdFilter = createNextModeFilters(
      //   normalisedFilter,
      //   selectedDateFilter
      // );

      return finalTaskList;
      // return taskList.filter(createdFilter);
    },
    openTaskInEditForm(
      taskData,
      returnViewInfo,
      showNewDialog,
      clearAreaCheck
    ) {
      const processedTaskData = getMainTaskRulesAndFillRecurringOpts({
        ...taskData,
      });

      if (showNewDialog) {
        if (clearAreaCheck) {
          this.updateState({
            ignoreAreaCheck: false,
          });
        }
        this.showNewEditTaskDialog({
          taskData: { ...processedTaskData },
          selectedTasks: [],
          returnViewInfo,
        });
      } else {
        this.showEditTaskDialog({
          taskData: { ...processedTaskData },
          selectedTasks: [],
          returnViewInfo,
        });
      }
    },
    getFirstVirtualReccuringTask(taskId, taskDate) {
      return getFirstVirtualReccuringTask(this.tasks, taskId, taskDate);
      // const filtersToUse = [(row) => !!row.isVirtual];

      // if (isValidDate(taskDate)) {
      //   filtersToUse.push((row) => isEqual(row.dueAsDate, taskDate));
      // }

      // return getRecurringTasks(this.tasks, "list", {
      //   linkedTo: taskId,
      //   filter: true,
      //   filterOpts: [...filtersToUse],
      // })[0];
    },
    async processTaskDroppedInCustomOrder({
      draggedTasks,
      dropRowData,
      isLastRow,
      groupByField,
      tasksList,
    }) {
      this.setLoader(true);
      if (isEmpty(dropRowData)) {
        dropRowData = {
          isEmptyRow: true,
        };
      }
      const {
        groupedEditedTaskData,
        tasksToUpdateInLocalList,
        tasksToAddInLocaList,
        tasksToRemoveFromLocalList,
        tasksToUpdateInGoogle,
        googleRecurringEventUpdateListMap,
        tasksToAddInDb,
        tasksToUpdateInDb,
      } = this.createDataForTaskDroppedInCustomOrder({
        draggedTasks,
        dropRowData,
        isLastRow,
        groupByField,
        tasksList,
      });

      if (!isEmpty(groupedEditedTaskData)) {
        getUserActions().addTaskAction({
          data: groupedEditedTaskData,
          type: TASK_ACTIONS.NORMAL_RECURRING_EDIT,
        });
      }

      addOrRemoveOrUpdateTasksInLocalTasksList({
        tasksToUpdate: tasksToUpdateInLocalList,
        tasksToAdd: tasksToAddInLocaList,
        tasksToRemove: tasksToRemoveFromLocalList,
      });
      addOrRemoveOrUpdateTasksInDb({
        tasksToUpdate: tasksToUpdateInDb,
        tasksToAdd: tasksToAddInDb,
      });
      addOrUpdateOrRemoveTasksInGoogleCal({
        tasksToUpdate: tasksToUpdateInGoogle,
      });

      this.updateRecurringAndItsChildEventsFromMap(
        googleRecurringEventUpdateListMap
      );
      await Vue.nextTick();
      this.setLoader(false);
      return true;
    },

    async processTaskDroppedInFlaggedAndUnFlaggedGroups(
      draggedRows,
      dropRowData,
      groupBy
    ) {
      this.setLoader(true);
      const editedTasksData = [];
      const tasksToAddInDb = [];
      const tasksToUpdateInDb = [];
      const tasksToRemoveFromLocalList = [];
      const tasksToUpdateInGoogle = [];
      const tasksToAddInLocaList = [];
      const tasksToUpdateInLocalList = [];
      let groupedEditedTaskData = {};
      let recurringTasksToUpdateWithExcludedDates = {};
      let recurringTasksForVirtualTasksUpdate = {};
      const googleRecurringEventUpdateListMap = {};
      let groupKeyData = groupBy;

      if (!isEmpty(groupKeyData) && isPlainObject(groupKeyData)) {
        groupKeyData = groupKeyData.key;
      }

      const customOrderTasks = filterOutCustomOrderTasks(
        draggedRows,
        groupKeyData
      );

      if (!isEmpty(customOrderTasks) && !isEmpty(dropRowData)) {
        let dataToUpdate = {
          due: "none",
          time: "00:00",
          flag: false,
          isCustomPositioned: false,
          modified: getCurrDate(),
        };

        if (dropRowData[groupKeyData] === "2") {
          dataToUpdate.flag = true;
        }
        customOrderTasks.forEach((draggedRow) => {
          if (draggedRow.key) {
            if (isTaskVirtual(draggedRow)) {
              const mainTaskData = this.rawTasksMap[draggedRow.linkedTo];
              if (!isEmpty(mainTaskData)) {
                if (!recurringTasksForVirtualTasksUpdate[mainTaskData.key]) {
                  tasksToUpdateInDb.push({
                    key: mainTaskData.key,
                    updates: {
                      ...dataToUpdate,
                    },
                  });
                  recurringTasksForVirtualTasksUpdate[mainTaskData.key] = {
                    ...dataToUpdate,
                  };
                }
              } else {
                if (
                  !recurringTasksToUpdateWithExcludedDates[mainTaskData.key]
                ) {
                  recurringTasksToUpdateWithExcludedDates[mainTaskData.key] = {
                    excludedDates: [],
                    childrenUpdates: [],
                    childrenEditedData: [],
                  };
                }

                const parsedDate = createDateTimeFromDue(
                  dataToUpdate.due,
                  dataToUpdate.time
                );
                const res = createDbDataForConvertingRecurringTaskToRealTask(
                  { ...draggedRow, dueAsDate: parsedDate },
                  {
                    ...draggedRow,
                    dueAsDate: parsedDate,
                    ...dataToUpdate,
                  },
                  false,
                  false
                );

                if (!isEmpty(res.dataToAddInDb)) {
                  tasksToAddInDb.push({
                    ...res.dataToAddInDb,
                  });
                  tasksToAddInLocaList.push({
                    ...res.dataToAddInLocalList,
                  });
                  tasksToRemoveFromLocalList.push(draggedRow);
                  recurringTasksToUpdateWithExcludedDates[
                    mainTaskData.key
                  ].excludedDates.push(...res.excludedDates);

                  recurringTasksToUpdateWithExcludedDates[
                    mainTaskData.key
                  ].childrenUpdates.push(res.dataForGoogle);
                  res.editedTaskData.changedProps = {
                    ...res.editedTaskData.changedProps,
                    ...dataToUpdate,
                  };

                  recurringTasksToUpdateWithExcludedDates[
                    mainTaskData.key
                  ].childrenEditedData.push(res.editedTaskData);
                }
              }
            } else {
              tasksToUpdateInLocalList.push({
                key: draggedRow.key,
                updates: {
                  ...dataToUpdate,
                  key: draggedRow.key,
                },
              });

              editedTasksData.push({
                key: draggedRow.key,
                isCustomPositioned: draggedRow.isCustomPositioned || false,
                time: draggedRow.time,
                due: draggedRow.due,
                flag: draggedRow.flag || false,
                changedProps: {
                  ...dataToUpdate,
                },
              });

              tasksToUpdateInDb.push({
                key: draggedRow.key,
                updates: {
                  ...dataToUpdate,
                },
              });
            }
          }
        });
      }

      for (const recurringTaskIdKey in recurringTasksToUpdateWithExcludedDates) {
        const recurringTaskData = this.rawTasksMap[recurringTaskIdKey];
        const excludedDates =
          recurringTasksToUpdateWithExcludedDates[recurringTaskIdKey]
            .excludedDates || [];
        const childrenUpdates =
          recurringTasksToUpdateWithExcludedDates[recurringTaskIdKey]
            .childrenUpdates;
        const childrenEditedData =
          recurringTasksToUpdateWithExcludedDates[recurringTaskIdKey]
            .childrenEditedData || [];
        const { ruleSet: parsedRuleSet } = parseRuleDataFromTaskRecurrenceRules(
          {
            recurrence: recurringTaskData.recurrence,
            dueAsDate: createDateTimeFromDue(
              recurringTaskData.due,
              recurringTaskData.time
            ),
          }
        );

        excludeDatesInTaskRules(excludedDates, parsedRuleSet);
        const convertedRules = parsedRuleSet.valueOf().slice(1);
        tasksToUpdateInDb.push({
          key: recurringTaskData.key,
          updates: {
            recurrence: convertedRules,
          },
        });

        const recurrences = createRecurringDatesFromTaskRules({
          ...recurringTaskData,
          recurrence: convertedRules,
        });
        const firstRecurrence = recurrences[0];
        const firstTaskData = getRecurrenceIdByDateFromRecurrences(
          firstRecurrence,
          recurringTaskData.key
        );

        if (!isEmpty(firstTaskData)) {
          const currTaskData = this.tasks.find(
            (t) => t.key === firstTaskData.key
          );
          if (!isEmpty(currTaskData)) {
            tasksToUpdateInLocalList.push({
              key: currTaskData.key,
              updates: {
                isCalendarOnly: false,
              },
            });
          }
        }

        if (this.isGoogleCalSyncEnabled && recurringTaskData.googleEventId) {
          if (
            !googleRecurringEventUpdateListMap[recurringTaskData.googleEventId]
          ) {
            googleRecurringEventUpdateListMap[recurringTaskData.googleEventId] =
              {
                childrenUpdates: [],
                mainEventUpdates: {},
              };
          }
          googleRecurringEventUpdateListMap[
            recurringTaskData.googleEventId
          ].mainEventUpdates = {
            ...createTaskDataForGoogleCal(
              {
                recurrence: convertedRules,
                taskKey: recurringTaskData.key,
              },
              { ...recurringTaskData }
            ),
          };

          googleRecurringEventUpdateListMap[
            recurringTaskData.googleEventId
          ].childrenUpdates = [...childrenUpdates];
          // tasksToUpdateInGoogle.push({
          //   key: recurringTaskData.googleEventId,
          //   updates: createTaskDataForGoogleCal(
          //     {
          //       recurrence: convertedRules,
          //     },
          //     recurringTaskData
          //   ),
          // });

          // childrenUpdates.forEach((childUpdates) => {
          //   this.findRecurringEventByIdOrDateAndUpdateIt(
          //     childUpdates,
          //     recurringTaskIdKey
          //   );
          // });
        }

        groupedEditedTaskData[recurringTaskIdKey] = {
          editedTasks: [...childrenEditedData],
        };
      }

      for (const mainTaskId in recurringTasksForVirtualTasksUpdate) {
        const dataToUpdate = recurringTasksForVirtualTasksUpdate[mainTaskId];
        if (this.rawTasksMap[mainTaskId]) {
          const recurringTasksToUpdate = this.recurringTasksMap[mainTaskId];
          if (!isEmpty(recurringTasksToUpdate)) {
            recurringTasksToUpdate.forEach((t) => {
              tasksToUpdateInLocalList.push({
                key: t.key,
                updates: { ...dataToUpdate },
              });
            });
          }
          groupedEditedTaskData[mainTaskId] = {
            updateMain: true,
            editedData: {
              time: this.rawTasksMap[mainTaskId].time,
              due: this.rawTasksMap[mainTaskId].due,
              flag: this.rawTasksMap[mainTaskId].flag || false,
              isCustomPositioned:
                this.rawTasksMap[mainTaskId].isCustomPositioned || false,
              changedProps: {
                ...dataToUpdate,
              },
              key: mainTaskId,
            },
            recreateTasks: true,
          };
        }
      }

      if (!isEmpty(editedTasksData)) {
        groupedEditedTaskData["normal"] = {
          editedTasks: [...editedTasksData],
        };
      }

      if (!isEmpty(groupedEditedTaskData)) {
        getUserActions().addTaskAction({
          data: groupedEditedTaskData,
          type: TASK_ACTIONS.NORMAL_RECURRING_EDIT,
        });
      }

      addOrRemoveOrUpdateTasksInLocalTasksList({
        tasksToUpdate: tasksToUpdateInLocalList,
        tasksToAdd: tasksToAddInLocaList,
        tasksToRemove: tasksToRemoveFromLocalList,
      });
      addOrUpdateOrRemoveTasksInGoogleCal({
        tasksToUpdate: tasksToUpdateInGoogle,
      });

      this.updateRecurringAndItsChildEventsFromMap(
        googleRecurringEventUpdateListMap
      );
      this.setLoader(false);

      await this.$nextTick();

      addOrRemoveOrUpdateTasksInDb({
        tasksToUpdate: tasksToUpdateInDb,
        tasksToAdd: tasksToAddInDb,
      });

      return true;
    },

    createDataForTaskDroppedInCustomOrder({
      draggedTasks,
      dropRowData,
      isLastRow,
      groupByField,
      tasksList,
    }) {
      let onlyUpdateDraggedRows = false;
      let dropPositionIndexToUse = 0;
      let customPositionToUse = 0;
      let groupedEditedTaskData = {};
      const tasksToUpdateInDb = [];
      const tasksToAddInDb = [];
      const tasksToAddInLocaList = [];
      const tasksToUpdateInLocalList = [];
      const tasksToRemoveFromLocalList = [];
      const editedTasksData = [];
      let recurringTasksToUpdateWithExcludedDates = {};
      const tasksToUpdateInGoogle = [];
      let currRowsOnPosition = [];
      const dropTargetOrder = dropRowData.customPosition || 0;
      let dropTargetUserPosition = dropRowData.customPositionIndex || 0;
      let currGroupBy = groupByField;
      const googleRecurringEventUpdateListMap = {};

      let taskIdForFormRefresh;
      if (!isEmpty(currGroupBy) && isPlainObject(currGroupBy)) {
        currGroupBy = currGroupBy.key;
      }

      // draggedTasks = filterOutFlaggedAndUnFlaggedDraggedTasks(
      //   draggedTasks,
      //   currGroupBy
      // );
      if (!isEmpty(dropRowData)) {
        if (dropRowData.isEmptyRow) {
          onlyUpdateDraggedRows = true;
        } else {
          const { taskIdsToFilter } =
            processDroppedFlaggedAndUnflaggedTasksForCustomOrder(
              draggedTasks,
              currGroupBy,
              dropTargetOrder
            );

          customPositionToUse = dropTargetOrder;
          if (!isLastRow) {
            dropPositionIndexToUse = dropTargetUserPosition;
          } else {
            dropPositionIndexToUse = increment(dropTargetUserPosition, 1);
          }

          currRowsOnPosition = getTasksByCustomOrderAndOrderIndex(
            tasksList,
            dropTargetOrder,
            dropPositionIndexToUse,
            taskIdsToFilter
          );
        }

        let dropPositionIndex = dropPositionIndexToUse;
        draggedTasks.forEach((d) => {
          const {
            updates = {},
            editedData: draggedRowEditedData = {},
            ...draggedRow
          } = d;

          let finalUpdateData = { ...updates };

          if (draggedRow.key) {
            const cleanedData = cleanTask(finalUpdateData);
            const dataToUpdate = {
              ...cleanedData,
              isCustomPositioned: true,
              isContingent: false,
              customPosition: customPositionToUse,
              customPositionIndex: dropPositionIndex,
              flag: true,
              modified: getCurrDate(),
              ...getDateAndTimeFromDueDate(""),
            };

            const taskKeyOfRow = draggedRow.key;
            if (isTaskVirtual(draggedRow)) {
              const mainTaskData = this.rawTasksMap[draggedRow.linkedTo];
              if (!isEmpty(mainTaskData)) {
                if (
                  !recurringTasksToUpdateWithExcludedDates[mainTaskData.key]
                ) {
                  recurringTasksToUpdateWithExcludedDates[mainTaskData.key] = {
                    excludedDates: [],
                    childrenUpdates: [],
                    childrenEditedData: [],
                  };
                }

                const parsedDate = parseJSONDate(draggedRow.dueAsDate);
                const res = createDbDataForConvertingRecurringTaskToRealTask(
                  { ...draggedRow, dueAsDate: parsedDate },
                  {
                    ...draggedRow,
                    ...dataToUpdate,
                    dueAsDate: createDateTimeFromDue(
                      dataToUpdate.due,
                      dataToUpdate.time
                    ),
                  },
                  false,
                  false,
                  true
                );

                if (!isEmpty(res.dataToAddInDb)) {
                  tasksToAddInDb.push({
                    ...res.dataToAddInDb,
                  });
                  tasksToAddInLocaList.push({
                    ...res.dataToAddInLocalList,
                  });
                  tasksToRemoveFromLocalList.push(draggedRow);
                  recurringTasksToUpdateWithExcludedDates[
                    mainTaskData.key
                  ].excludedDates.push(...res.excludedDates);

                  recurringTasksToUpdateWithExcludedDates[
                    mainTaskData.key
                  ].childrenUpdates.push(res.dataForGoogle);
                  res.editedTaskData.changedProps = {
                    ...res.editedTaskData.changedProps,
                    ...dataToUpdate,
                  };

                  recurringTasksToUpdateWithExcludedDates[
                    mainTaskData.key
                  ].childrenEditedData.push(res.editedTaskData);

                  if (
                    !taskIdForFormRefresh &&
                    ((!isEmpty(this.editTaskData) &&
                      this.editTaskData.key === taskKeyOfRow.key) ||
                      this.checkIfTaskIsActiveInResourceForm(taskKeyOfRow.key))
                  ) {
                    taskIdForFormRefresh = res.dataToAddInDb.key;
                  }
                }
              }
            } else {
              tasksToUpdateInLocalList.push({
                key: draggedRow.key,
                updates: {
                  ...dataToUpdate,
                  key: draggedRow.key,
                },
              });

              if (this.isGoogleCalSyncEnabled && draggedRow.googleEventId) {
                tasksToUpdateInGoogle.push({
                  key: draggedRow.googleEventId,
                  updates: {
                    ...createTaskDataForGoogleCal(
                      {
                        ...draggedRow,
                        ...dataToUpdate,
                        dueAsDate: createDateTimeFromDue(
                          dataToUpdate.due,
                          dataToUpdate.time
                        ),
                        taskKey: draggedRow.key,
                      },
                      draggedRow
                    ),
                  },
                });
              }

              let editData = {
                ...draggedRowEditedData,
                key: draggedRow.key,
                isCustomPositioned: draggedRow.isCustomPositioned || false,
                customPosition: draggedRow.customPosition || 0,
                customPositionIndex: draggedRow.customPositionIndex || 0,
                isContingent: draggedRow.isContingent || false,
                flag: draggedRow.flag || false,
                changedProps: {
                  ...dataToUpdate,
                },
              };

              if (isValidDate(parseJSONDate(draggedRow.dueAsDate))) {
                editData = {
                  ...editData,
                  ...getDateAndTimeFromDueDate(new Date(draggedRow.dueAsDate)),
                };
              }

              editedTasksData.push({
                ...editData,
              });

              tasksToUpdateInDb.push({
                key: draggedRow.key,
                updates: {
                  ...dataToUpdate,
                },
              });
            }
            if (
              !taskIdForFormRefresh &&
              ((!isEmpty(this.editTaskData) &&
                this.editTaskData.key === draggedRow.key) ||
                this.checkIfTaskIsActiveInResourceForm(draggedRow.key))
            ) {
              taskIdForFormRefresh = taskKeyOfRow;
            }
            dropPositionIndex++;
          }
        });

        if (!onlyUpdateDraggedRows) {
          // let newPositionIndex = dropPositionIndexToUse + draggedTasks.length;
          const res = this.createDataForOtherTasksMovedInCustomOrder({
            tasksOnPosition: currRowsOnPosition,
            dropTargetOrder,
            startIndex: dropPositionIndexToUse + draggedTasks.length,
          });
          editedTasksData.push(...res.editedData);
          tasksToUpdateInDb.push(...res.tasksToUpdateInDb);
          tasksToUpdateInLocalList.push(...res.tasksToUpdateInLocalList);

          if (!taskIdForFormRefresh && res.taskIdForFormRefresh) {
            taskIdForFormRefresh = res.taskIdForFormRefresh;
          }
        }

        for (const recurringTaskIdKey in recurringTasksToUpdateWithExcludedDates) {
          const recurringTaskData = this.rawTasksMap[recurringTaskIdKey];
          // const currReccurences = recurringTaskData.recurrence || [];
          const excludedDates =
            recurringTasksToUpdateWithExcludedDates[recurringTaskIdKey]
              .excludedDates || [];
          const childrenUpdates =
            recurringTasksToUpdateWithExcludedDates[recurringTaskIdKey]
              .childrenUpdates;
          const childrenEditedData =
            recurringTasksToUpdateWithExcludedDates[recurringTaskIdKey]
              .childrenEditedData || [];
          const { ruleSet: parsedRuleSet } =
            parseRuleDataFromTaskRecurrenceRules({
              recurrence: recurringTaskData.recurrence,
              dueAsDate: createDateTimeFromDue(
                recurringTaskData.due,
                recurringTaskData.time
              ),
            });

          excludeDatesInTaskRules(excludedDates, parsedRuleSet);
          const convertedRules = parsedRuleSet.valueOf().slice(1);
          tasksToUpdateInDb.push({
            key: recurringTaskData.key,
            updates: {
              recurrence: convertedRules,
            },
          });

          const recurrences = createRecurringDatesFromTaskRules({
            ...recurringTaskData,
            recurrence: convertedRules,
          });

          const firstRecurrence = recurrences[0];
          const firstTaskData = getRecurrenceIdByDateFromRecurrences(
            firstRecurrence,
            recurringTaskData.key
          );

          if (!isEmpty(firstTaskData)) {
            const currTaskData = this.tasks.find(
              (t) => t.key === firstTaskData.key
            );
            if (!isEmpty(currTaskData)) {
              tasksToUpdateInLocalList.push({
                key: currTaskData.key,
                updates: {
                  isCalendarOnly: false,
                },
              });
            }
          }

          if (this.isGoogleCalSyncEnabled && recurringTaskData.googleEventId) {
            if (
              !googleRecurringEventUpdateListMap[
                recurringTaskData.googleEventId
              ]
            ) {
              googleRecurringEventUpdateListMap[
                recurringTaskData.googleEventId
              ] = {
                childrenUpdates: [],
                mainEventUpdates: {},
              };
            }
            googleRecurringEventUpdateListMap[
              recurringTaskData.googleEventId
            ].mainEventUpdates = {
              ...createTaskDataForGoogleCal(
                {
                  recurrence: convertedRules,
                  taskKey: recurringTaskData.key,
                },
                { ...recurringTaskData }
              ),
            };

            googleRecurringEventUpdateListMap[
              recurringTaskData.googleEventId
            ].childrenUpdates = [...childrenUpdates];
            // tasksToUpdateInGoogle.push({
            //   key: recurringTaskData.googleEventId,
            //   updates: {
            //     recurrence: convertedRules,
            //   },
            // });

            // childrenUpdates.forEach((childUpdates) => {
            //   this.findRecurringEventByIdOrDateAndUpdateIt(
            //     childUpdates,
            //     recurringTaskIdKey
            //   );
            // });
          }

          groupedEditedTaskData[recurringTaskIdKey] = {
            editedTasks: [...childrenEditedData],
          };
        }

        if (!isEmpty(editedTasksData)) {
          groupedEditedTaskData["normal"] = {
            editedTasks: [...editedTasksData],
          };
        }
      }

      return {
        groupedEditedTaskData,
        tasksToUpdateInLocalList,
        tasksToAddInLocaList,
        tasksToRemoveFromLocalList,
        tasksToAddInDb,
        tasksToUpdateInDb,
        tasksToUpdateInGoogle,
        taskIdForFormRefresh,
        googleRecurringEventUpdateListMap,
      };
    },
    async handleUnschedulingOfNormalAndRecurringTasks(tasksToProcess) {
      let areaWiseTasksMap = {};
      let tasksWithoutArea = [];
      let groupedEditedTaskData = {};
      const tasksToUpdateInLocalList = [];
      const tasksToAddInLocaList = [];
      const tasksToRemoveFromLocalList = [];
      const tasksToAddInDb = [];
      const tasksToUpdateInDb = [];
      let scheduledTasksGoogleList = [];
      let googleRecurringEventUpdateListMap = {};

      let taskKeyForRefreshInResourceForm;
      tasksToProcess.forEach((t) => {
        if (isUndefinedVal(t.project)) {
          tasksWithoutArea.push(t);
        } else {
          if (!areaWiseTasksMap[t.project]) {
            areaWiseTasksMap[t.project] = [];
          }
          areaWiseTasksMap[t.project].push(t);
        }
        // fsfsfsf
      });

      for (const area in areaWiseTasksMap) {
        const taskList = areaWiseTasksMap[area];

        const reminderTasks = taskList.reduce((acc, t) => {
          const allReminderTasks = getReminderTasksLinkedToTaskId(
            this.tasks,
            t.key
          );
          acc.push(...allReminderTasks);
          return acc;
        }, []);

        const orderedTasksByProject = getOrderedTasksByProject(
          this.tasks,
          area
        );

        let dropRowTargetData = orderedTasksByProject[0];

        if (isEmpty(dropRowTargetData)) {
          dropRowTargetData = {
            isEmptyRow: true,
          };
        }
        const createdData = this.createDataForTaskDroppedInCustomOrder({
          draggedTasks: taskList,
          dropRowData: dropRowTargetData,
          isLastRow: false,
          groupByField: "CTICustomGroup",
          tasksList: orderedTasksByProject,
        });

        if (
          !taskKeyForRefreshInResourceForm &&
          createdData.taskIdForFormRefresh
        ) {
          taskKeyForRefreshInResourceForm = createdData.taskIdForFormRefresh;
        }
        scheduledTasksGoogleList = createdData.tasksToUpdateInGoogle;

        googleRecurringEventUpdateListMap = {
          ...createdData.googleRecurringEventUpdateListMap,
        };

        // dasdsadasdssd;
        for (const groupKey in createdData.groupedEditedTaskData) {
          if (groupedEditedTaskData[groupKey]) {
            const currGroupEditData = groupedEditedTaskData[groupKey];
            const newGroupEditData =
              createdData.groupedEditedTaskData[groupKey];

            const newEditedTasks = newGroupEditData.editedTasks || [];
            const newAddedTasks = newGroupEditData.addedTasks || [];
            if (groupKey !== "normal") {
              const newEditedTasks = newGroupEditData.editedTasks || [];
              // const newMainEditedData = newGroupEditData.editedData || {};
              if (!isEmpty(currGroupEditData.editedTasks)) {
                currGroupEditData.editedTasks.push(...newEditedTasks);
              } else {
                currGroupEditData.editedTasks = [...newEditedTasks];
              }
            } else {
              if (!isEmpty(groupedEditedTaskData[groupKey].editedTasks)) {
                groupedEditedTaskData[groupKey].editedTasks.push(
                  ...newEditedTasks
                );
              } else {
                groupedEditedTaskData[groupKey].editedTasks = [
                  ...newEditedTasks,
                ];
              }

              if (!isEmpty(groupedEditedTaskData[groupKey].addedTasks)) {
                groupedEditedTaskData[groupKey].addedTasks.push(
                  ...newAddedTasks
                );
              } else {
                groupedEditedTaskData[groupKey].addedTasks = [...newAddedTasks];
              }
            }
          } else {
            groupedEditedTaskData = {
              ...groupedEditedTaskData,
              ...createdData.groupedEditedTaskData,
            };
          }
        }

        tasksToUpdateInLocalList.push(...createdData.tasksToUpdateInLocalList);
        tasksToAddInLocaList.push(...createdData.tasksToAddInLocaList);
        tasksToRemoveFromLocalList.push(
          ...createdData.tasksToRemoveFromLocalList
        );
        tasksToRemoveFromLocalList.push(...reminderTasks);
        tasksToAddInDb.push(...createdData.tasksToAddInDb);
        tasksToUpdateInDb.push(...createdData.tasksToUpdateInDb);
      }

      const tasksWithoutAreaUpdateRes =
        this.createDataForUpdatingDateTimeOfNormalAndRecurringTasks({
          tasksToProcess: [...tasksWithoutArea],
          type: "none",
          parseDueDate: false,
        });

      if (
        !taskKeyForRefreshInResourceForm &&
        tasksWithoutAreaUpdateRes.taskKeyForFormRefresh
      ) {
        taskKeyForRefreshInResourceForm =
          tasksWithoutAreaUpdateRes.taskKeyForFormRefresh;
      }

      if (!isEmpty(tasksWithoutAreaUpdateRes.tasksToAddInDb)) {
        tasksToAddInDb.push(...tasksWithoutAreaUpdateRes.tasksToAddInDb);
      }

      if (!isEmpty(tasksWithoutAreaUpdateRes.tasksToUpdateInDb)) {
        tasksToUpdateInDb.push(...tasksWithoutAreaUpdateRes.tasksToUpdateInDb);
      }

      const tasksWithoutAreaGroupEditData =
        tasksWithoutAreaUpdateRes.groupedEditData;
      for (const groupKey in tasksWithoutAreaGroupEditData) {
        if (groupedEditedTaskData[groupKey]) {
          const currGroupEditData = groupedEditedTaskData[groupKey];
          const newGroupEditData = tasksWithoutAreaGroupEditData[groupKey];
          const newEditedMainData = newGroupEditData.editedData;

          const newEditedTasks = newGroupEditData.editedTasks || [];
          const newAddedTasks = newGroupEditData.addedTasks || [];
          if (groupKey !== "normal") {
            // const newMainEditedData = newGroupEditData.editedData || {};
            if (!isEmpty(currGroupEditData.editedTasks)) {
              currGroupEditData.editedTasks.push(...newEditedTasks);
            } else {
              currGroupEditData.editedTasks = [...newEditedTasks];
            }

            if (!isEmpty(newEditedMainData)) {
              if (!isEmpty(currGroupEditData.editedData)) {
                currGroupEditData.editedData = {
                  ...currGroupEditData.editedData,
                  ...newEditedMainData,
                };
              } else {
                currGroupEditData.editedData = { ...newEditedMainData };
              }
            }

            currGroupEditData.updateMain = newGroupEditData.updateMain;
            currGroupEditData.recreateTasks = newGroupEditData.recreateTasks;
            currGroupEditData.updateVirtualTasks =
              newGroupEditData.updateVirtualTasks;
          } else {
            if (!isEmpty(groupedEditedTaskData[groupKey].editedTasks)) {
              groupedEditedTaskData[groupKey].editedTasks.push(
                ...newEditedTasks
              );
            } else {
              groupedEditedTaskData[groupKey].editedTasks = [...newEditedTasks];
            }

            if (!isEmpty(groupedEditedTaskData[groupKey].addedTasks)) {
              groupedEditedTaskData[groupKey].addedTasks.push(...newAddedTasks);
            } else {
              groupedEditedTaskData[groupKey].addedTasks = [...newAddedTasks];
            }
          }
        } else {
          groupedEditedTaskData = {
            ...groupedEditedTaskData,
            ...tasksWithoutAreaGroupEditData,
          };
        }
      }

      addOrRemoveOrUpdateTasksInDb({
        tasksToAdd: tasksToAddInDb,
        tasksToUpdate: tasksToUpdateInDb,
      });
      await addOrRemoveOrUpdateTasksInLocalTasksList({
        tasksToUpdate: [
          ...tasksToUpdateInLocalList,
          ...tasksWithoutAreaUpdateRes.tasksToUpdateInLocalList,
        ],
        tasksToAdd: [
          ...tasksToAddInLocaList,
          ...tasksWithoutAreaUpdateRes.tasksToAddInLocalList,
        ],
        tasksToRemove: [
          ...tasksToRemoveFromLocalList,
          ...tasksWithoutAreaUpdateRes.tasksToRemoveFromLocalList,
        ],
        verifyAdd: tasksWithoutAreaUpdateRes.verifyAddInLocalList,
        addMethod: tasksWithoutAreaUpdateRes.addMethodForLocalList,
      });
      removeTasksFromRecurringTasksMap(
        tasksWithoutAreaUpdateRes.removeRecurringTasksInfoMapList
      );

      await Vue.nextTick();
      const selectedRows = this.createTaskSelectionList(tasksToProcess);
      EventEmitter.emit(SET_ID_FOR_SCROLL, selectedRows, true);
      EventEmitter.emit(
        SELECT_TASK_IN_ACTIVITY_GRID,
        selectedRows,
        undefined,
        undefined,
        true
      );
      EventEmitter.emit(REFRESH_TASKS_EVENT);
      EventEmitter.emit(REFRESH_ACTIVITY_HISTORY);

      if (this.checkIfTaskFormIsOpened()) {
        EventEmitter.emit(REFRESH_FULL_FORM);
      }

      if (!isEmpty(groupedEditedTaskData)) {
        getUserActions().addTaskAction({
          type: TASK_ACTIONS.NORMAL_RECURRING_EDIT,
          data: {
            ...groupedEditedTaskData,
          },
          on: "task",
        });
      }

      this.updateRecurringAndItsChildEventsFromMap(
        googleRecurringEventUpdateListMap
      );

      //Actual Change

      Vue.nextTick(() => {
        if (taskKeyForRefreshInResourceForm) {
          this.refreshTaskDataInResourceForm(taskKeyForRefreshInResourceForm);
        }
        addOrUpdateOrRemoveTasksInGoogleCal({
          tasksToAdd: tasksWithoutAreaUpdateRes.tasksToAddInGoogle,
          tasksToUpdate: [
            ...tasksWithoutAreaUpdateRes.tasksToUpdateInGoogle,
            ...scheduledTasksGoogleList,
          ],
        });
        this.updateRecurringAndItsChildEventsFromMap(
          tasksWithoutAreaUpdateRes.googleRecurringEventUpdateListMap
        );
      });
    },
    createDataForUpdatingDateTimeOfNormalAndRecurringTasks({
      tasksToProcess,
      type,
      parseDueDate,
    }) {
      const groupedTasksMap = {};
      let dbAddTasksList = [];
      let dbUpdateTasksList = [];
      let localTasksRemoveList = [];
      let localTasksUpdateList = [];
      let reminderTasksToAdd = [];
      let reminderTasksToRemove = [];
      let googleAddList = [];
      let googleUpdateList = [];
      let googleRecurringEventUpdateListMap = {};
      let localTasksAddList = [];
      let currTotalTasksCount = Object.keys(this.rawTasksMap || {}).length;
      const removeRecurringTasksInfoMapList = [];
      let editedTasksMap = {};
      let recurringToSingleList = [];
      let taskKeyForFormRefresh;

      tasksToProcess.forEach((task) => {
        const isRecurring = isTaskRecurring(task);
        const linkedTo = task.linkedTo;
        let groupID = "normal";
        let groupGoogleEventId = "";
        if (isRecurring && linkedTo) {
          groupID = linkedTo;
          groupGoogleEventId = this.rawTasksMap[linkedTo].googleEventId;
        }

        if (!groupedTasksMap[groupID]) {
          groupedTasksMap[groupID] = {
            key: groupID,
            children: [],
            googleEventId: groupGoogleEventId,
          };
        }

        const storedChildIndex = groupedTasksMap[groupID].children.findIndex(
          (t) => t.key === task.key
        );
        if (storedChildIndex === -1) {
          groupedTasksMap[groupID].children.push(task);
        }
      });

      for (const groupIDKey in groupedTasksMap) {
        if (groupIDKey !== "normal") {
          const childTasks = groupedTasksMap[groupIDKey].children;
          const mainTaskRawData = this.rawTasksMap[groupIDKey];
          const mainTaskRecurrenceRules = mainTaskRawData.recurrence;
          let clonedMainTaskRules = mainTaskRawData.recurrence;
          let mainTaskDataUpdate = {};
          let excludedDates = [];
          let rulesChanged = false;
          let removeChildrenTasks = [];
          const editedTasksList = [];
          const sameProjectChildren = [];
          let recreateTasks = false;
          let updateMain = false;
          let editedDataForMainTask = {};
          const { ruleSet: parsedRuleSet } =
            parseRuleDataFromTaskRecurrenceRules({
              recurrence: mainTaskRecurrenceRules,
              dueAsDate: createDateTimeFromDue(
                mainTaskRawData.due,
                mainTaskRawData.time
              ),
            });

          if (!isEmpty(childTasks)) {
            childTasks.forEach((childT) => {
              let currRawData = {};
              let dateToExclude;

              const isVirtualTask = childT.isVirtual;

              let taskKeyOfChild = childT.key;
              if (parseDueDate) {
                childT.dueAsDate = new Date(childT.dueAsDate);
              }

              // const currChildTDueDate = childT.dueAsDate;
              let addToDb = true;
              let removeFromGoogle = false;
              if (!isVirtualTask) {
                currRawData = this.rawTasksMap[childT.key];
                addToDb = false;
              } else {
                currRawData = cloneDeep(this.rawTasksMap[groupIDKey]);
                currRawData = {
                  ...currRawData,
                  ...getDateAndTimeFromDueDate(childT.dueAsDate),
                };
                let setGoogleCatId = false;
                if (currRawData.isFromGoogle) {
                  currRawData.googleEventId =
                    createGoogleEventIdByDateAndTaskId(
                      currRawData.googleEventId.split("_")[0],
                      childT.dueAsDate
                    );
                  setGoogleCatId = true;
                } else {
                  currRawData.googleEventId =
                    createGoogleEventIdByDateAndTaskId(
                      groupIDKey,
                      childT.dueAsDate
                    );
                  setGoogleCatId = true;
                }

                if (setGoogleCatId) {
                  currRawData.googleCalendarId =
                    GoogleCalHelpers.getStoredCalId();
                }
                localTasksRemoveList.push(childT);
                removeChildrenTasks.push(childT);
              }

              const parentId = groupIDKey;

              const updatedData = this.createDateTimeDataForTask(
                currRawData,
                type
              );
              const parsedDataKeys = Object.keys(updatedData);
              const combinedData = {
                ...currRawData,
                ...updatedData,
              };

              let newTaskData = cloneDeep(combinedData);
              newTaskData = removeProps(newTaskData, [
                "linkedTo",
                "recurrence",
              ]);

              const { editedData: editedTaskData } =
                createDbUpdateDataFromProvidedData(updatedData, childT);

              if (addToDb) {
                delete newTaskData.googleEventId;
                newTaskData.key = createTaskId();
                newTaskData.coRelationalId = currRawData.coRelationalId;
                newTaskData.linkedTo = currRawData.key;
                newTaskData.order = currRawData.order;
                newTaskData.userPosition = currRawData.userPosition;
                newTaskData.customPosition = currRawData.customPosition;
                newTaskData.customPositionIndex =
                  currRawData.customPositionIndex;
                newTaskData = fillTaskData(newTaskData);
                newTaskData.resources = [];
                editedTaskData.key = newTaskData.key;
                taskKeyOfChild = newTaskData.key;
                if (this.isGoogleCalSyncEnabled) {
                  newTaskData.googleCalendarId =
                    GoogleCalHelpers.getStoredCalId();
                  if (childT.isFromGoogle) {
                    newTaskData.googleEventId =
                      createGoogleEventIdByDateAndTaskId(
                        childT.googleEventId.split("_")[0],
                        childT.dueAsDate
                      );
                  } else {
                    newTaskData.googleEventId =
                      createGoogleEventIdByDateAndTaskId(
                        parentId,
                        childT.dueAsDate
                      );
                  }
                }

                newTaskData.originalDate = isValidDate(childT.dueAsDate)
                  ? childT.dueAsDate
                  : "";

                // if (
                //   dataToUpdate.dueAsDate !== undefined &&
                //   !isEqual(dataToUpdate.dueAsDate, childT.dueAsDate)
                // ) {
                //   dateToExclude = dataToUpdate.dueAsDate;
                //   if (
                //     isValidDate(childT.dueAsDate) &&
                //     !isValidDate(dataToUpdate.dueAsDate)
                //   ) {
                //     dateToExclude = childT.dueAsDate;
                //   }
                // }

                if (newTaskData.project === mainTaskRawData.project) {
                  sameProjectChildren.push(newTaskData.key);
                }
                dbAddTasksList.push(newTaskData);

                if (
                  checkIfTaskHasReminder(newTaskData) &&
                  !newTaskData.completed
                ) {
                  reminderTasksToAdd.push(...createReminderTasks(newTaskData));
                }
                localTasksAddList.push(newTaskData);
                editedTasksList.push({
                  ...editedTaskData,
                  key: newTaskData.key,
                });
                currTotalTasksCount++;
                // Add new task
              } else {
                let updateInDb = true;
                const { updatedTaskData } = createDbUpdateDataFromProvidedData(
                  updatedData,
                  childT
                );
                newTaskData.coRelationalId = currRawData.coRelationalId;
                newTaskData.linkedTo = parentId;
                newTaskData = {
                  ...newTaskData,
                  ...updatedTaskData,
                };

                if (parsedDataKeys.includes("due") && childT.completed) {
                  updateInDb = false;
                }

                if (updateInDb) {
                  editedTaskData.key = childT.key;
                  editedTasksList.push({
                    ...editedTaskData,
                    key: childT.key,
                  });
                  dbUpdateTasksList.push({
                    key: childT.key,
                    updates: { ...updatedData },
                  });
                  localTasksUpdateList.push({
                    key: childT.key,
                    updates: newTaskData,
                  });

                  if (
                    this.checkIfParsedTaskDataHasReminderProps(parsedDataKeys)
                  ) {
                    const reminderTasks = getReminderTasksLinkedToTaskId(
                      this.tasks,
                      childT.key
                    );

                    if (!isEmpty(reminderTasks)) {
                      reminderTasksToRemove.push(...reminderTasks);
                    }

                    if (
                      checkIfTaskHasReminder(newTaskData) &&
                      !newTaskData.completed
                    ) {
                      reminderTasksToAdd.push(
                        ...createReminderTasks(newTaskData)
                      );
                    }
                  }
                } else {
                  removeFromGoogle = true;
                  newTaskData = removeProps(newTaskData, [
                    "frequency",
                    "linkedTo",
                    "recurrence",
                    "coRelationalId",
                    "googleEventId",
                    "key",
                  ]);

                  newTaskData.completed = false;
                  const newTaskDataForDb = fillTaskData(newTaskData);

                  taskKeyOfChild = newTaskDataForDb.key;
                  if (
                    this.isGoogleCalSyncEnabled &&
                    newTaskDataForDb.due !== "none"
                  ) {
                    dateToExclude = createDateTimeFromDue(
                      newTaskDataForDb.due,
                      newTaskDataForDb.time
                    );
                    newTaskDataForDb.googleEventId = convertTaskKeyForGoogleCal(
                      newTaskDataForDb.key
                    );

                    newTaskDataForDb.googleCalendarId =
                      GoogleCalHelpers.getStoredCalId();

                    googleAddList.push({
                      key: newTaskDataForDb.googleEventId,
                      data: createTaskDataForGoogleCal({
                        ...newTaskDataForDb,
                        dueAsDate: createDateTimeFromDue(
                          newTaskDataForDb.due,
                          newTaskDataForDb.time
                        ),
                        taskKey: newTaskDataForDb.key,
                      }),
                    });
                  }

                  newTaskDataForDb.order = currTotalTasksCount;
                  newTaskDataForDb.userPosition = 0;
                  newTaskDataForDb.customPosition = currTotalTasksCount;
                  newTaskDataForDb.customPositionIndex = 0;

                  dbAddTasksList.push(newTaskDataForDb);

                  localTasksAddList.push(newTaskDataForDb);

                  if (checkIfTaskHasReminder(newTaskDataForDb)) {
                    reminderTasksToAdd.push(
                      ...createReminderTasks(newTaskDataForDb)
                    );
                  }

                  recurringToSingleList.push(newTaskDataForDb);
                  currTotalTasksCount++;
                }

                // Update Task
              }

              if (
                !taskKeyForFormRefresh &&
                ((!isEmpty(this.editTaskData) &&
                  this.editTaskData.key === childT.key) ||
                  this.checkIfTaskIsActiveInResourceForm(childT.key))
              ) {
                taskKeyForFormRefresh = taskKeyOfChild;
              }
              excludedDates.push(childT.dueAsDate);
              let updatedDueDate;
              let currChildTDueDate = childT.dueAsDate;

              if (updatedData.due && updatedData.time) {
                updatedDueDate = createDateTimeFromDue(
                  updatedData.due,
                  updatedData.time
                );
              } else if (updatedData.due) {
                updatedDueDate = createDateTimeFromDue(
                  updatedData.due,
                  newTaskData.time
                );
              }

              if (
                updatedDueDate !== undefined &&
                !isEqual(updatedDueDate, currChildTDueDate)
              ) {
                dateToExclude = updatedDueDate;

                if (
                  isValidDate(currChildTDueDate) &&
                  isValidDate(updatedDueDate) &&
                  !isSameDay(updatedDueDate, currChildTDueDate)
                ) {
                  dateToExclude = updatedDueDate;
                } else if (
                  isValidDate(currChildTDueDate) &&
                  !isValidDate(updatedDueDate)
                ) {
                  dateToExclude = currChildTDueDate;
                } else if (
                  isValidDate(updatedDueDate) &&
                  !isValidDate(currChildTDueDate)
                ) {
                  dateToExclude = updatedDueDate;
                }
              }

              if (isValidDate(dateToExclude)) {
                excludedDates.push(dateToExclude);
              }

              if (this.isGoogleCalSyncEnabled) {
                const dataToSend = {
                  ...newTaskData,
                  dueAsDate: createDateTimeFromDue(
                    newTaskData.due,
                    newTaskData.time
                  ),
                };
                // console.debug("DATA TO SEND", dataToSend);
                const dataForGoogleCal = {
                  ...createTaskDataForGoogleCal(
                    {
                      ...dataToSend,
                      taskKey: dataToSend.key,
                    },
                    childT
                  ),
                };

                if (
                  !googleRecurringEventUpdateListMap[
                    mainTaskRawData.googleEventId
                  ]
                ) {
                  googleRecurringEventUpdateListMap[
                    mainTaskRawData.googleEventId
                  ] = {
                    childrenUpdates: [],
                    mainEventUpdates: {},
                  };
                }
                googleRecurringEventUpdateListMap[
                  mainTaskRawData.googleEventId
                ].childrenUpdates.push({
                  currData: {
                    dueAsDate: childT.dueAsDate,
                    taskKey: newTaskData.key,
                    googleEventId:
                      newTaskData.googleEventId || currRawData.googleEventId,
                  },
                  newData: removeFromGoogle
                    ? createTaskDataForGoogleCal(
                        { status: "cancelled", taskKey: newTaskData.key },
                        { ...newTaskData }
                      )
                    : dataForGoogleCal,
                });
              }
            });
          }

          excludeDatesInTaskRules(excludedDates, parsedRuleSet);

          const convertedRules = parsedRuleSet.valueOf().slice(1);

          if (!isEqual(convertedRules, mainTaskRecurrenceRules)) {
            mainTaskDataUpdate = {
              recurrence: convertedRules,
            };

            rulesChanged = true;
            clonedMainTaskRules = convertedRules;

            if (mainTaskRawData.googleEventId) {
              if (
                !googleRecurringEventUpdateListMap[
                  mainTaskRawData.googleEventId
                ]
              ) {
                googleRecurringEventUpdateListMap[
                  mainTaskRawData.googleEventId
                ] = {
                  childrenUpdates: [],
                  mainEventUpdates: {},
                };
              }

              googleRecurringEventUpdateListMap[
                mainTaskRawData.googleEventId
              ].mainEventUpdates = createTaskDataForGoogleCal(
                {
                  ...googleRecurringEventUpdateListMap[
                    mainTaskRawData.googleEventId
                  ].mainEventUpdates,
                  ...mainTaskDataUpdate,
                  taskKey: mainTaskRawData.key,
                },
                { ...mainTaskRawData }
              );
            }
          }

          if (rulesChanged || !isEmpty(removeChildrenTasks)) {
            const recurrences = createRecurringDatesFromTaskRules({
              ...mainTaskRawData,
              recurrence: clonedMainTaskRules,
            });

            const firstRecurrence = recurrences[0];

            const firstTaskData = getRecurrenceIdByDateFromRecurrences(
              firstRecurrence,
              mainTaskRawData.key
            );

            let recurringTasksToUpdate = [];
            if (
              !isEmpty(removeChildrenTasks) &&
              !isEmpty(sameProjectChildren)
            ) {
              recreateTasks = true;
              updateMain = true;

              const dataToUpdate = {
                order: mainTaskRawData.order,
                userPosition:
                  mainTaskRawData.userPosition + sameProjectChildren.length,
              };
              mainTaskDataUpdate = {
                ...mainTaskDataUpdate,
                ...dataToUpdate,
              };

              editedDataForMainTask = {
                order: mainTaskRawData.order,
                userPosition: mainTaskRawData.userPosition,
                changedProps: { ...dataToUpdate },
                key: mainTaskRawData.key,
              };

              const currRecurringTasks = getRecurringTasks(this.tasks, "list", {
                linkedTo: mainTaskRawData.key,
                filter: true,
                filterOpts: [isTaskVirtual],
              });

              if (!isEmpty(currRecurringTasks)) {
                currRecurringTasks.forEach((currTask) => {
                  const createdDataUpdate = {
                    ...dataToUpdate,
                  };
                  if (
                    !isEmpty(firstTaskData) &&
                    firstTaskData.key === currTask.key
                  ) {
                    createdDataUpdate.isCalendarOnly = false;
                  }
                  recurringTasksToUpdate.push({
                    key: currTask.key,
                    updates: {
                      ...createdDataUpdate,
                    },
                  });
                });
              }
            } else {
              if (!isEmpty(firstTaskData)) {
                recurringTasksToUpdate.push({
                  key: firstTaskData.key,
                  updates: {
                    isCalendarOnly: false,
                  },
                });
              }
            }

            localTasksUpdateList.push(...recurringTasksToUpdate);
          }

          if (!isEmpty(mainTaskDataUpdate)) {
            dbUpdateTasksList.push({
              key: mainTaskRawData.key,
              updates: {
                ...mainTaskDataUpdate,
              },
            });
          }

          if (!editedTasksMap[groupIDKey]) {
            editedTasksMap[groupIDKey] = {
              editedTasks: editedTasksList,
              updateMain,
              recreateTasks,
              editedData: editedDataForMainTask,
            };
          }

          if (!isEmpty(removeChildrenTasks)) {
            const taskIdsToRemove = removeChildrenTasks.map((t) => t.key);
            removeRecurringTasksInfoMapList.push({
              mainTaskId: mainTaskRawData.key,
              tasksIds: taskIdsToRemove,
            });
          }
          // Add recurring logic
        } else {
          const normalChildTasks = groupedTasksMap[groupIDKey].children;
          let editedTasksList = [];
          let addedTasksList = [];
          if (!isEmpty(normalChildTasks)) {
            // const parsedDataKeys = Object.keys(updatedData);
            normalChildTasks.forEach((childT) => {
              let addToDb = false;

              const currRawData = this.rawTasksMap[childT.key];

              const updatedData = this.createDateTimeDataForTask(
                currRawData,
                type
              );

              const parsedDataKeys = Object.keys(updatedData);
              let finalDataObj = {
                ...currRawData,
                ...updatedData,
              };

              if (parsedDataKeys.includes("due") && childT.completed) {
                addToDb = true;
              }

              if (!isEmpty(currRawData)) {
                const reminderTasks = getReminderTasksLinkedToTaskId(
                  this.tasks,
                  childT.key
                );

                if (!isEmpty(reminderTasks)) {
                  reminderTasksToRemove.push(...reminderTasks);
                }

                let taskKeyOfChild = childT.key;
                if (addToDb) {
                  let addInGoogle = false;
                  finalDataObj.key = createUniqueId();
                  finalDataObj.order = currTotalTasksCount;
                  finalDataObj.userPosition = 0;
                  finalDataObj.customPosition = currTotalTasksCount;
                  finalDataObj.customPositionIndex = 0;
                  finalDataObj.flag =
                    finalDataObj.due && finalDataObj.due !== "none";
                  taskKeyOfChild = finalDataObj.key;
                  if (
                    this.isGoogleCalSyncEnabled &&
                    finalDataObj.due !== "none"
                  ) {
                    addInGoogle = true;
                    finalDataObj.googleEventId = convertTaskKeyForGoogleCal(
                      finalDataObj.key
                    );

                    finalDataObj.googleCalendarId =
                      GoogleCalHelpers.getStoredCalId();
                  }
                  finalDataObj = fillTaskData(finalDataObj);
                  finalDataObj.completed = false;
                  if (addInGoogle) {
                    googleAddList.push({
                      key: finalDataObj.googleEventId,
                      data: createTaskDataForGoogleCal({
                        ...finalDataObj,
                        dueAsDate: createDateTimeFromDue(
                          finalDataObj.due,
                          finalDataObj.time
                        ),
                        taskKey: finalDataObj.key,
                      }),
                    });
                  }
                  addedTasksList.push(cleanTask(cloneDeep(finalDataObj)));
                  dbAddTasksList.push(finalDataObj);
                  localTasksAddList.push(finalDataObj);

                  if (checkIfTaskHasReminder(finalDataObj)) {
                    reminderTasksToAdd.push(
                      ...createReminderTasks(finalDataObj)
                    );
                  }
                  currTotalTasksCount++;
                  // Add new task
                } else {
                  const { editedData: editedTaskData } =
                    createDbUpdateDataFromProvidedData(updatedData, childT);

                  editedTasksList.push({
                    ...editedTaskData,
                    key: childT.key,
                  });
                  dbUpdateTasksList.push({
                    key: childT.key,
                    updates: { ...updatedData },
                  });

                  localTasksUpdateList.push({
                    key: childT.key,
                    updates: finalDataObj,
                  });

                  if (
                    checkIfTaskHasReminder(finalDataObj) &&
                    !finalDataObj.completed
                  ) {
                    reminderTasksToAdd.push(
                      ...createReminderTasks(finalDataObj)
                    );
                  }

                  if (currRawData.googleEventId) {
                    googleUpdateList.push({
                      key: currRawData.googleEventId,
                      updates: {
                        ...createTaskDataForGoogleCal(
                          {
                            ...finalDataObj,
                            dueAsDate: createDateTimeFromDue(
                              finalDataObj.due,
                              finalDataObj.time
                            ),
                            taskKey: childT.key,
                          },
                          childT
                        ),
                      },
                    });
                  }
                  // Update Task
                }

                if (
                  !taskKeyForFormRefresh &&
                  ((!isEmpty(this.editTaskData) &&
                    this.editTaskData.key === childT.key) ||
                    this.checkIfTaskIsActiveInResourceForm(childT.key))
                ) {
                  taskKeyForFormRefresh = taskKeyOfChild;
                }
              }
            });
          }

          if (!editedTasksMap["normal"]) {
            editedTasksMap["normal"] = {
              editedTasks: editedTasksList,
              addedTasks: addedTasksList,
            };
          }
          // Add normal task logic
        }
      }

      if (!isEmpty(recurringToSingleList)) {
        if (!editedTasksMap.normal) {
          editedTasksMap.normal = {
            addedTasks: recurringToSingleList,
          };
        } else {
          editedTasksMap.normal.addedTasks = [
            ...editedTasksMap.normal.addedTasks,
            ...recurringToSingleList,
          ];
        }
      }

      return {
        groupedEditData: { ...editedTasksMap },
        tasksToAddInLocalList: [...localTasksAddList, ...reminderTasksToAdd],
        tasksToUpdateInLocalList: localTasksUpdateList,
        tasksToRemoveFromLocalList: [
          ...localTasksRemoveList,
          ...reminderTasksToRemove,
        ],
        verifyAddInLocalList: isEmpty(reminderTasksToAdd),
        addMethodForLocalList: !isEmpty(reminderTasksToAdd)
          ? "push"
          : "unshift",
        tasksToAddInDb: dbAddTasksList,
        tasksToUpdateInDb: dbUpdateTasksList,
        tasksToAddInGoogle: googleAddList,
        tasksToUpdateInGoogle: googleUpdateList,
        googleRecurringEventUpdateListMap,
        removeRecurringTasksInfoMapList,
        taskKeyForFormRefresh,
      };
    },

    createDataForOtherTasksMovedInCustomOrder({
      tasksOnPosition,
      dropTargetOrder,
      startIndex,
    }) {
      let newPositionIndex = startIndex;
      const tasksToUpdateInDb = [];
      const tasksToUpdateInLocalList = [];
      const editedTasksData = [];

      let taskIdForFormRefresh;
      tasksOnPosition.forEach((t) => {
        if (t.key) {
          const dataToUpdate = {
            isCustomPositioned: true,
            customPosition: dropTargetOrder,
            customPositionIndex: newPositionIndex,
          };

          tasksToUpdateInLocalList.push({
            key: t.key,
            updates: {
              ...dataToUpdate,
            },
          });

          editedTasksData.push({
            key: t.key,
            customPosition: t.customPosition || 0,
            customPositionIndex: t.customPositionIndex || 0,
            isCustomPositioned: t.isCustomPositioned || false,
            changedProps: {
              ...dataToUpdate,
            },
            doNotUpdateModified: true,
          });

          tasksToUpdateInDb.push({
            key: t.key,
            updates: {
              ...dataToUpdate,
            },
          });

          if (
            !taskIdForFormRefresh &&
            ((!isEmpty(this.editTaskData) && this.editTaskData.key === t.key) ||
              this.checkIfTaskIsActiveInResourceForm(t.key))
          ) {
            taskIdForFormRefresh = t.key;
          }
        }

        newPositionIndex++;
        // }
      });

      return {
        editedData: editedTasksData,
        tasksToUpdateInDb,
        tasksToUpdateInLocalList,
        taskIdForFormRefresh,
      };
    },

    async completeAndCreateNextContinuedTask(
      responseData,
      {
        rowId,
        currRawTaskData,
        providedDbUpdates,
        editedData,
        updatedRowData,
        checkEditForm,
        refreshList,
      }
    ) {
      if (
        (!responseData.isConfirmed && responseData.dismiss === "backdrop") ||
        responseData.dismiss === "esc"
      ) {
        return;
      }
      let onlyCompleteCurrentTask = false;
      let newCreatedTask = {};
      let dbUpdates = { ...providedDbUpdates };
      let reminderTasksToRemove = [];
      let reminderTasksToAdd = [];
      let userActionsBatch = {};

      const isTaskActiveInResourceForm =
        this.checkIfTaskIsActiveInResourceForm(rowId);
      const isCurrTaskOpenedInEditForm =
        checkEditForm &&
        ((!isEmpty(this.editTaskData) && this.editTaskData.key === rowId) ||
          isTaskActiveInResourceForm);
      if (!responseData.isConfirmed && responseData.dismiss === "cancel") {
        onlyCompleteCurrentTask = true;
      }

      setTimeout(async () => {
        userActionsBatch = {
          ...editedData,
          key: rowId,
        };

        reminderTasksToRemove = getReminderTasksLinkedToTaskId(
          this.tasks,
          currRawTaskData.key
        );
        if (!onlyCompleteCurrentTask) {
          newCreatedTask = createContinuedTaskData({
            ...currRawTaskData,
            title: currRawTaskData.title,
            description: "",
            dueAsDate: createDateTimeFromDue(
              currRawTaskData.due,
              currRawTaskData.time
            ),
          });

          dbUpdates = {
            ...dbUpdates,
            [`/tasks/${newCreatedTask.key}`]: newCreatedTask,
          };

          if (checkIfTaskHasReminder(newCreatedTask)) {
            reminderTasksToAdd.push(...createReminderTasks(newCreatedTask));
          }
          userActionsBatch = [
            {
              type: "edit",
              data: {
                ...userActionsBatch,
              },
            },
            {
              type: TASK_ACTIONS.ADD,
              data: {
                ...newCreatedTask,
              },
            },
          ];
        }

        DatabaseInterface.update(dbUpdates, this.userInfo.uid);
        let actionToStore = TASK_ACTIONS.EDIT;
        if (Array.isArray(userActionsBatch)) {
          actionToStore = TASK_ACTIONS.ACTIONS_BATCH;
        }
        getUserActions().addTaskAction({
          type: actionToStore,
          data: userActionsBatch,
        });
        this.triggerEditForRow =
          !onlyCompleteCurrentTask && !isCurrTaskOpenedInEditForm;

        if (!isCurrTaskOpenedInEditForm) {
          this.setTaskIdsForScroll(
            !onlyCompleteCurrentTask ? newCreatedTask.key : updatedRowData.key
          );
        }

        await addOrRemoveOrUpdateTasksInLocalTasksList({
          tasksToAdd: !onlyCompleteCurrentTask
            ? [{ ...newCreatedTask }, ...reminderTasksToAdd]
            : [],
          tasksToUpdate: [{ key: rowId, updates: updatedRowData }],
          tasksToRemove: !isEmpty(reminderTasksToRemove)
            ? reminderTasksToRemove
            : [],
        });
        if (this.isGoogleCalSyncEnabled) {
          if (!onlyCompleteCurrentTask && newCreatedTask.due !== "none") {
            addTasksToGoogleCal([
              {
                key: newCreatedTask.key,
                data: createTaskDataForGoogleCal({
                  ...newCreatedTask,
                  dueAsDate: createDateTimeFromDue(
                    newCreatedTask.due,
                    newCreatedTask.time
                  ),
                  taskKey: newCreatedTask.key,
                }),
              },
            ]);
          }
        }

        if (refreshList) {
          this.refreshList();
        }

        if (isCurrTaskOpenedInEditForm) {
          await this.$nextTick();

          if (isTaskActiveInResourceForm) {
            this.refreshTaskDataInResourceForm(
              !onlyCompleteCurrentTask ? newCreatedTask.key : rowId
            );
          } else {
            let taskDataToSet = {};
            if (!onlyCompleteCurrentTask) {
              taskDataToSet = { ...newCreatedTask };
            } else {
              taskDataToSet = this.tasks.find((t) => t.key === rowId);
            }

            if (!isEmpty(taskDataToSet)) {
              await Vue.nextTick();
              this.setTaskDataInForm(taskDataToSet);
            }
          }
        }
      }, 10);
    },
    async completeContinuedTask({
      key,
      updates,
      editedData,
      updatedRowData,
      checkEditForm,
      refreshList,
    }) {
      const currRawTaskData = this.rawTasksMap[key];

      if (!isEmpty(currRawTaskData)) {
        const confirmRes = await this.$swal({
          text: "Do you wish to add a next step?",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        });

        setTimeout(() => {
          this.completeAndCreateNextContinuedTask(confirmRes, {
            rowId: key,
            providedDbUpdates: updates,
            editedData,
            updatedRowData,
            currRawTaskData,
            checkEditForm,
            refreshList,
          });
        }, 0);
      }
    },
    async setTaskDataInForm(taskData) {
      const processedTaskData = processTask(
        getMainTaskRulesAndFillRecurringOpts(taskData)
      );
      this.setTaskData(processedTaskData);

      await Vue.nextTick();
      EventEmitter.emit(REFRESH_FORM_DATA);
    },
    async selectTaskInGrid(taskData, triggerRefresh, gridName) {
      if (!isEmpty(taskData)) {
        const { key, date } = taskData;
        let taskIdToUse = key;
        if (checkIfTaskIsParentRecurringTask(this.rawTasksMap[key])) {
          taskIdToUse = this.getFirstVirtualReccuringTask(key, date)?.key;
        }
        if (taskIdToUse) {
          this.$refs[gridName].selectTask(taskIdToUse);

          if (triggerRefresh) {
            await Vue.nextTick();
            this.$refs[gridName].refreshList();
          }

          EventEmitter.emit(TOGGLE_RESOURCE_FORM_EVENT, true);

          // this.$refs.historyTable.refreshList();
        }
      }
    },

    processTaskDataForEditForm(taskData) {
      let dataToSet = { ...taskData };
      if (dataToSet.isReminderTask && dataToSet.reminderLinkedTo) {
        const taskData = this.tasks.find(
          (t) => t.key === dataToSet.reminderLinkedTo
        );

        if (!isEmpty(taskData)) {
          dataToSet = { ...taskData };
        }
      }
      const isRecurringTask = checkIfTaskIsRecurring(dataToSet);
      let dataIsValid = false;
      let showTaskInForm = false;
      if (isRecurringTask) {
        let taskDataForRulesParsing = {};
        const isVirtual = dataToSet.isVirtual;
        const linkedTo = dataToSet.linkedTo;
        const tasksRulesAreValid = areTaskRecurrenceRulesValid(
          dataToSet.recurrence
        );
        if (isVirtual) {
          taskDataForRulesParsing = this.rawTasksMap[dataToSet.linkedTo];

          showTaskInForm = !dataToSet.isFirstTask && !!dataToSet.isCalendarOnly;
        } else if (linkedTo || tasksRulesAreValid) {
          const keyToUse = linkedTo || dataToSet.key;
          if (this.rawTasksMap[keyToUse]) {
            taskDataForRulesParsing = this.rawTasksMap[keyToUse];
          }
        }

        if (!isEmpty(taskDataForRulesParsing) && taskDataForRulesParsing.key) {
          dataIsValid = true;
          dataToSet = {
            ...dataToSet,
            ...createRecurrenceOptsValuesFromTaskRecurrenceRules({
              ...taskDataForRulesParsing,
              dueAsDate: createDateTimeFromDue(
                taskDataForRulesParsing.due,
                taskDataForRulesParsing.time
              ),
            }),
          };
        }
      } else {
        if (
          this.rawTasksMap[dataToSet.key] &&
          this.rawTasksMap[dataToSet.key].key
        ) {
          dataIsValid = true;
        }
      }

      return {
        isValid: dataIsValid,
        showTaskInForm,
        processedTaskData: dataToSet,
      };
      // if (!dataIsValid) {
      //   this.showToastMsg("Invalid task data");
      //   return;
      // }

      // if (showTaskInForm) {
      //   addOrRemoveOrUpdateTasksInLocalTasksList(
      //     {
      //       tasksToUpdate: [
      //         {
      //           key: dataToSet.key,
      //           updates: { showInForm: true },
      //         },
      //       ],
      //       doNotProcessTaskForUpdate: true,
      //     },
      //     false
      //   );
      // }

      // this.showEditTaskDialog({
      //   taskData: dataToSet,
      //   selectedTasks: [],
      // });
    },
    duplicateTask(tasksToUse) {
      if (!isEmpty(tasksToUse)) {
        let taskDataToUse = cloneDeep(
          tasksToUse[decrement(tasksToUse.length, 1)]
        );

        if (!isEmpty(taskDataToUse)) {
          taskDataToUse = removeProps(taskDataToUse, [
            "recurrence",
            "linkedTo",
            "coRelationalId",
            "frequency",
            "recurrenceCount",
            "repeatMonthDate",
            "recurrenceRepeatDay",
            "key",
            "repeatInfo",
          ]);

          taskDataToUse.userPosition = increment(taskDataToUse.userPosition, 1);
          taskDataToUse.customPositionIndex = increment(
            taskDataToUse.customPositionIndex,
            1
          );
          taskDataToUse.description = "";
          taskDataToUse = duplicateTask(taskDataToUse, taskDataToUse.order);
          taskDataToUse.dueAsDate = new Date();
          taskDataToUse.duplicated = true;
          delete taskDataToUse.key;
          this.addNewTask({ ...taskDataToUse }, false);
        }
      }
    },
    addNewTask(data, useDefaultTaskType = true) {
      const dataToUse = { ...data };

      if (useDefaultTaskType) {
        dataToUse.taskType = "task";
      }
      this.$refs.dataGrid.addNewRow({ ...dataToUse });
    },
    addToggleOrderedTasksWatcher() {
      this.$watch("toggleOrderTasksData", this.handleOrderedTasksToggle, {
        deep: true,
      });
    },
    async handleOrderedTasksToggle() {
      if (this.$options.refreshGridOnToggle) {
        await Vue.nextTick();
        this.changeGroupAndSort?.();
        this.refreshList();
      }
    },

    /**
     * Open provided task data in edit form
     * @param {object} params
     * @param {{[string]:*}} params.taskData Task Data to open in edit form
     * @param {{[string]:*}} params.gridApi Api of Grid
     */
    async openTaskDataInEditForm({ taskData, gridApi }) {
      if (this.$route.name === "Resources") {
        this.openTaskInAreasView({ ...taskData }, true);
        return;
      }

      const isProjectsModeEnabled = this.isProjectsResourceModeEnabled;
      const processedTaskDataRes = this.processTaskDataForEditForm({
        ...taskData,
      });

      const taskDataToUse = processedTaskDataRes.processedTaskData;

      if (!processedTaskDataRes.isValid) {
        this.showToast("Invalid task data");
        return;
      }

      if (processedTaskDataRes.showTaskInForm) {
        addOrRemoveOrUpdateTasksInLocalTasksList(
          {
            tasksToUpdate: [
              {
                key: taskDataToUse.key,
                updates: { showInForm: true },
              },
            ],
            doNotProcessTaskForUpdate: true,
          },
          false
        );
      }

      if (gridApi) {
        gridApi.setRowIdsForScroll(taskDataToUse.key);
      }

      await Vue.nextTick();

      let triggeredFrom = "next-actions";

      if (isProjectsModeEnabled) {
        triggeredFrom = "projects-mode";
      } else if (!this.isInNextActionsMode) {
        triggeredFrom = this.currNav === 3 ? "today" : "cal";
      }

      this.hideTempVisibleTasks();

      this.updateRootState({
        itemInspectEnabled: true,
      });

      if (!this.isNewEditFormOpened) {
        const currViewInfo = this.createCurrViewData({
          taskId: taskDataToUse.key,
          enableContingentFilter: this.showAllOrderedTasks,
        });

        this.storeCurrViewData(currViewInfo);
        EventEmitter.emit(OPEN_STATUS_EDIT_MODE, {
          triggeredFrom,
          taskData: { ...taskDataToUse },
          opts: {
            openProject: true,
            openTask: true,
            // returnViewInfo: currViewInfo,
          },
        });
      } else {
        EventEmitter.emit(
          OPEN_SELECTED_NEW_EDIT_TASK_FORM,
          getMainTaskRulesAndFillRecurringOpts({ ...taskData })
        );
      }
    },

    /**
     * Open Task in Areas View
     * @param {{[string]:*}} taskData Task Data
     * @param {boolean} openForm Open Task Form or not
     */
    async openTaskInAreasView(taskData, openForm) {
      const dbUpdates = {
        notesView: false,
        activeNavView: "dashboard",
      };

      this.hideTempVisibleTasks();
      await DatabaseInterface.update(dbUpdates, this.userInfo.uid);
      this.closeResourceInfoViewer();

      let modeData = {};

      modeData.type = checkIfSelectedResourceCateHasCategoryOnly(
        this.selectedResrcCategories
      )
        ? "grid"
        : "form";

      if (modeData.type === "form") {
        modeData.resourceIdToOpen = this.selectedResrcCategories[0];
      }

      modeData = {
        ...modeData,

        ...this.getCurrViewData(),
      };

      let viewItemId, viewItemDate;

      const taskDataViewed = { ...taskData };
      if (
        taskDataViewed.isVirtual &&
        this.rawTasksMap[taskDataViewed.linkedTo]
      ) {
        viewItemId = taskDataViewed.linkedTo;
        viewItemDate = taskDataViewed.dueAsDate;
      } else if (!taskDataViewed.isVirtual) {
        viewItemId = taskDataViewed.key;
      }

      this.updateRootState({
        itemInspectEnabled: true,
      });
      this.$router.replace({
        name: "Dashboard",
        params: {
          inspectTaskData: {
            id: viewItemId,
            date: viewItemDate,
            viewFrom: "resources",
            selectTask: true,
            returnInfo: {
              to: "Resources",
              modeData,
              taskData: {
                key: viewItemId,
                date: viewItemDate,
              },
            },
            openForm,
            showNewDialog: true,
          },
        },
      });
    },

    // getCurrViewData() {
    //   let currViewData = {};

    //   if (this.$route.name === "Dashboard") {
    //     let currNav = this.currNav;

    //     const enableGlobalView = this.isGlobalViewEnabled;
    //     const enableInboxView = this.isInboxViewEnabled;
    //     const showClearedTasks = this.showClearedTasks;
    //     currViewData = {
    //       currNav: currNav,
    //       enableGlobalView,
    //       enableInboxView,
    //       showClearedTasks,
    //     };
    //     if (calModes.includes(this.currNav)) {
    //       currViewData.selectedDates = this.selectedDatesList;
    //       currViewData.selectedCalForecastType = this.selectedCalForecastType;
    //     }

    //     if (treeSideBarModes.includes(this.currNav)) {
    //       currViewData.selectedOptsList = this.selectedOptsList;
    //     }
    //   }

    //   return currViewData;
    // },
    async exitFromInspectMode(viewData) {
      const {
        checkForReminderTask,
        returnToViewInfo,
        taskIdToSelect,
        reminderTaskInfo,
      } = viewData;
      let navigateToRoute = false,
        routeName,
        routeParams = {};

      let localUpdates = {
        searchVal: "",
      };
      const taskStateConfig = {
        inspectModeEnabled: false,
        nav: viewData.viewOnRelease,
        selectedDates: viewData.selectedDatesOnRelease,
        selectedOptsList: viewData.setEmptyCateList
          ? []
          : viewData.selectedOptsListOnRelease,
        groupMode: viewData.groupModeOnRelease,
        globalViewEnabled: viewData.goBackToGlobalView,
        isUserFromDifferentTreeMode: false,
        currInspectedTaskId: "",
        inspectTriggeredFrom: "",
      };

      if (viewData.showContingentTasks) {
        taskStateConfig.showContingentTasks = true;
      }

      if (!isEmpty(viewData.taskFilters)) {
        taskStateConfig.filters = viewData.taskFilters;
      }

      if (!isEmpty(viewData.additionalTaskFilters)) {
        taskStateConfig.additionalTaskFilters = viewData.additionalTaskFilters;
      }
      const dbUpdates = {
        showCompleted: false,
        inspectModeEnabled: false,
        isProjectsResourceModeEnabled: false,
      };

      if (this.showClearedTasks) {
        dbUpdates.showCompleted = true;
      }
      let viewToGo = viewData.viewOnRelease;

      if (!isEmpty(returnToViewInfo)) {
        dbUpdates["activeNavView"] = returnToViewInfo.to;
        navigateToRoute = true;
        routeName = returnToViewInfo.to;

        routeParams = {
          viewConfig: {
            modeData: returnToViewInfo.modeData,
            taskData: returnToViewInfo.taskData,
          },
        };

        //
      }

      if (this.isInspectModeEnabled) {
        taskStateConfig.showClearedTasks = this.showClearedTasks;
      }

      taskStateConfig.isFilterBarOptsEnabled = false;

      if (taskIdToSelect) {
        EventEmitter.emit(SET_ID_FOR_SCROLL, taskIdToSelect);
      }
      if (checkForReminderTask && !isEmpty(reminderTaskInfo)) {
        let taskIdToUse = reminderTaskInfo.linkedId;
        const allReminderTasks = getReminderTasksLinkedToTaskId(
          this.tasks,
          reminderTaskInfo.linkedId
        );

        if (!isEmpty(allReminderTasks)) {
          const selectedDateReminderTask = allReminderTasks.find((t) =>
            isSameDates(t.reminderDate, reminderTaskInfo.reminderDate)
          );

          if (!isEmpty(selectedDateReminderTask)) {
            taskIdToUse = selectedDateReminderTask.key;
          }
        }

        EventEmitter.emit(SET_ID_FOR_SCROLL, taskIdToUse);
      }

      this.updateState({
        ...taskStateConfig,
      });
      this.clearListFilters();
      if (!isEmpty(dbUpdates)) {
        DatabaseInterface.update(dbUpdates, this.userInfo.uid);
      }
      localUpdates.currNav = viewToGo;

      localUpdates.itemInspectEnabled = false;
      this.updateRootState({ ...localUpdates });

      if (navigateToRoute && routeName) {
        this.$router.replace({
          name: routeName,
          params: {
            ...routeParams,
          },
        });
      }
    },

    // createCurrViewData({
    //   inspectModeEnabledFrom,
    //   inspectModeOpts,
    //   taskId,
    //   taskData,
    // } = {}) {
    //   let createdData = {};

    //   if (this.$route.name === "Dashboard") {
    //     const returnViewInfo = {};

    //     let viewItemId, viewItemDate;
    //     const currViewedId = taskId;

    //     if (!isEmpty(this.selectedTaskList)) {
    //       let itemSelectDate;
    //       let itemIdToSelect;

    //       const selectedTask = this.selectedTaskList[0];

    //       itemIdToSelect = selectedTask.key;

    //       if (
    //         selectedTask.isVirtual &&
    //         this.rawTasksMap[selectedTask.linkedTo]
    //       ) {
    //         itemIdToSelect = selectedTask.linkedTo;
    //         itemSelectDate = selectedTask.dueAsDate;
    //       }

    //       if (itemIdToSelect) {
    //         returnViewInfo.itemIdToSelect = itemIdToSelect;
    //         returnViewInfo.itemIdDateSelect = itemSelectDate;
    //       }
    //     }
    //     if (this.isInspectModeEnabled) {
    //       returnViewInfo.enableInspectMode = true;

    //       let taskData = this.tasks.find(
    //         (t) => t.key === this.currInspectedTaskId
    //       );

    //       if (!isEmpty(taskData)) {
    //         let taskIdToInspect = taskData.key;
    //         let taskDate;
    //         if (taskData.isVirtual && this.rawTasksMap[taskData.linkedTo]) {
    //           taskIdToInspect = taskData.linkedTo;
    //           taskDate = taskData.dueAsDate;
    //         }

    //         if (taskIdToInspect) {
    //           returnViewInfo.inspectModeConfig = {
    //             inspectedTaskId: taskIdToInspect,
    //             taskDate,
    //             triggeredFrom: inspectModeEnabledFrom,
    //             opts: inspectModeOpts,
    //           };
    //         }
    //       }
    //     }

    //     if (this.isNewEditFormOpened) {
    //       let itemIdToOpen = this.editTaskData.key;
    //       if (
    //         checkIfTaskIsRecurring(this.editTaskData) &&
    //         this.editTaskData.isVirtual &&
    //         this.rawTasksMap[this.editTaskData.linkedTo]
    //       ) {
    //         itemIdToOpen = this.editTaskData.linkedTo;

    //         returnViewInfo.itemDate = this.editTaskData.dueAsDate;
    //       }

    //       returnViewInfo.itemIdToOpen = itemIdToOpen;
    //       returnViewInfo.openForm = true;
    //     }

    //     if (currViewedId) {
    //       const taskDataViewed = this.tasks.find((t) => t.key === currViewedId);
    //       if (!isEmpty(taskDataViewed)) {
    //         if (
    //           taskDataViewed.isVirtual &&
    //           this.rawTasksMap[taskDataViewed.linkedTo]
    //         ) {
    //           viewItemId = taskDataViewed.linkedTo;
    //           viewItemDate = taskDataViewed.dueAsDate;
    //         } else if (!taskDataViewed.isVirtual) {
    //           viewItemId = taskDataViewed.key;
    //         }
    //       }
    //     }
    //     createdData = {
    //       ...returnViewInfo,
    //       viewData: {
    //         ...this.getCurrViewData(),
    //       },

    //       linkedItemData: {
    //         key: viewItemId,
    //         date: viewItemDate,
    //       },
    //     };
    //   }

    //   if (this.$route.name === "Notes") {
    //     let selectedNoteId,
    //       viewConfig = {};
    //     if (!isEmpty(this.selectedNotes)) {
    //       selectedNoteId = this.selectedNotes[0].key;
    //     }
    //     // currViewType = "note";
    //     // currEditingId = this.$refs.grid.getCurrEditingRowId();

    //     if (this.isEditFormOpen) {
    //       const itemIdToOpen = this.editNoteData.key;

    //       viewConfig.itemIdToOpen = itemIdToOpen;
    //       viewConfig.openForm = true;
    //     }
    //     if (selectedNoteId) {
    //       viewConfig.itemIdToSelect = selectedNoteId;
    //     }

    //     createdData = {
    //       ...viewConfig,
    //     };
    //   }
    //   if (this.$route.name === "Resources") {
    //     const modeData = {};

    //     modeData.type = checkIfSelectedResourceCateHasCategoryOnly(
    //       this.selectedResrcCategories
    //     )
    //       ? "grid"
    //       : "form";

    //     if (modeData.type === "form") {
    //       modeData.resourceIdToOpen = this.selectedResrcCategories[0];
    //     }

    //     let viewItemId, viewItemDate;

    //     if (!isEmpty(taskData)) {
    //       const taskDataViewed = { ...taskData };
    //       if (
    //         taskDataViewed.isVirtual &&
    //         this.rawTasksMap[taskDataViewed.linkedTo]
    //       ) {
    //         viewItemId = taskDataViewed.linkedTo;
    //         viewItemDate = taskDataViewed.dueAsDate;
    //       } else if (!taskDataViewed.isVirtual) {
    //         viewItemId = taskDataViewed.key;
    //       }
    //     }
    //     createdData = {
    //       modeData,
    //       taskData: {
    //         key: viewItemId,
    //         date: viewItemDate,
    //       },
    //     };
    //   }

    //   return Object.freeze({
    //     ...createdData,
    //   });
    // },
    async handleDashboardViewConfig(viewConfig) {
      let closeLoader = true;
      if (!isEmpty(viewConfig)) {
        const {
          openForm,
          itemIdToOpen,
          itemDate,
          enableInspectMode,
          inspectModeConfig,
          itemIdToSelect,
          itemIdDateSelect,
          viewData,
          doNotClearViewData,
        } = viewConfig;

        let isResourceModeOpened = false;

        const isProjectsModeEnabled = this.isProjectsResourceModeEnabled;

        let finalTaskIdToUse;
        // let resourceIdToOpen;

        let dbUpdates = {};
        let localUpdates = {};
        let rootStateUpdates = {};
        let resourceStateUpdates = {};
        let makeCategoryNodeVisible = false;
        let resourceModeData = {};
        let openResourceForm = false;
        let resourceIdToUse = false;
        let selectTaskInList = false;
        let closeResourceSelectedNodes = false;
        let currResourceSelectedCategories;

        let resourceSelectedCategoryData = {};

        if (isProjectsModeEnabled) {
          currResourceSelectedCategories = this.selectedResrcCategories || [];
          if (this.isInNextActionsMode) {
            const selectedCategories = getResourceCateFromTaskCategories(
              this.selectedOptsList
            );

            resourceSelectedCategoryData = {
              mode: "project",
              selection: selectedCategories,
            };
            closeResourceSelectedNodes = isEmpty(selectedCategories);
          }
        }
        if (!isEmpty(viewData)) {
          let navToUse = viewData.currNav;

          if (treeSideBarModes.includes(navToUse) && viewData.enableInboxView) {
            navToUse = 1;
          }
          dbUpdates.nav = navToUse;

          if (viewData.selectedDates !== undefined) {
            dbUpdates["/view/0/selectedDates"] = viewData.selectedDates || [];
          }
          if (viewData.selectedCalForecastType !== undefined) {
            dbUpdates["/view/0/selectedForecastType"] =
              viewData.selectedCalForecastType || "";
          }

          if (viewData.selectedOptsList !== undefined) {
            makeCategoryNodeVisible = true;
            dbUpdates[`/view/1/selectedOptsList`] =
              viewData.selectedOptsList || [];
          }
          if (viewData.enableGlobalView) {
            dbUpdates.globalView = true;
          }

          if (viewData.enableProjectsMode !== undefined) {
            dbUpdates.isProjectsResourceModeEnabled =
              viewData.enableProjectsMode;

            localUpdates.isProjectsResourceModeEnabled =
              viewData.enableProjectsMode;

            if (!isEmpty(viewData.resourceModeData)) {
              resourceModeData = { ...viewData.resourceModeData };
            }
          }
          if (viewData.enableInboxView) {
            dbUpdates.inboxView = true;
          }

          if (viewData.showContingentTasks) {
            localUpdates.showContingentTasks = true;
          }

          if (!isEmpty(viewData.taskFilters)) {
            localUpdates.filters = viewData.taskFilters;
          }

          if (!isEmpty(viewData.additionalTaskFilters)) {
            localUpdates.additionalTaskFilters = viewData.additionalTaskFilters;
          }

          if (!isEmpty(viewData?.resourceFiltersList)) {
            resourceStateUpdates.filterResources =
              viewData?.resourceFiltersList;
          }
        }

        if (viewData.currTaskGroupFilter) {
          localUpdates.selectedTaskGroupFilter = viewData.currTaskGroupFilter;
        }

        if (viewData.subTasksListGroupFilter) {
          rootStateUpdates.subTasksListGroupFilter =
            viewData.subTasksListGroupFilter;
        }

        // currViewData.taskFilters = !isEmpty(this.taskFiltersMap)
        //   ? this.taskFiltersMap
        //   : {};
        // currViewData.additionalTaskFilter
        //   if(!isEmpty(viewData.))
        // }

        if (openForm) {
          localUpdates.ignoreAreaCheck = true;
        }

        this.updateState({ ...localUpdates });

        this.updateRootState({
          ...rootStateUpdates,
        });
        this.setResourceStateData({ ...resourceStateUpdates });
        if (!isEmpty(dbUpdates)) {
          DatabaseInterface.update(dbUpdates, this.userInfo.uid);
        }

        if (!isEmpty(resourceSelectedCategoryData)) {
          await getUserResources().set(
            resourceSelectedCategoryData,
            "selectedCategories"
          );
        }

        if (
          currResourceSelectedCategories !== undefined &&
          closeResourceSelectedNodes
        ) {
          const resourceCateUpdatesList = currResourceSelectedCategories.reduce(
            (accu, cate) => {
              if (this.rawResourceCategories[cate]) {
                accu.push({
                  updates: { open: false },
                  editedData: {},
                  key: cate,
                });
              }
              return accu;
            },
            []
          );

          await updateResourceCateInResourceCateData(resourceCateUpdatesList);
        }

        if (!isEmpty(resourceModeData)) {
          isResourceModeOpened = true;
          const { type, resourceIdToOpen: resourceId } = resourceModeData;
          let localTaskDataToUse = {};

          if (!isEmpty(this.rawTasksMap[itemIdToSelect])) {
            if (
              checkIfTaskIsParentRecurringTask(this.rawTasksMap[itemIdToSelect])
            ) {
              localTaskDataToUse = this.getFirstVirtualReccuringTask(
                itemIdToOpen,
                itemDate
              );
            } else {
              localTaskDataToUse = this.tasks.find(
                (t) => t.key === itemIdToSelect
              );
            }
          }

          if (
            type === "form" &&
            resourceId &&
            this.rawResourcesMap[resourceId]
          ) {
            openResourceForm = true;
            resourceIdToUse = resourceId;

            // if (!isEmpty(taskData)) {
            //   taskDataToUse = { ...taskData };
            // }
          } else if (type === "grid") {
            selectTaskInList = true;
            // if (!isEmpty(taskData)) {
            //   taskDataToUse = { ...taskData };
            // }
          }

          if (openForm && resourceIdToUse) {
            const resourceData = this.processedResources.find(
              (r) => r.key === resourceIdToUse
            );
            // if (!isEmpty(resourceData)) {
            //   resourceToOpen = { ...resourceData };
            // }

            this.openResourceInForm({
              resourceData: { ...resourceData },
              linkedItemData: { ...localTaskDataToUse },
              returnInfoToPass: {},
            });
          } else if (selectTaskInList && !isEmpty(localTaskDataToUse)) {
            this.setTaskGroupFilterForTasksList(localTaskDataToUse);
            EventEmitter.emit(
              SELECT_TASK_IN_ACTIVITY_GRID,
              localTaskDataToUse.key,
              true,
              true
            );
          }
        }

        // if (resourceIdToOpen && allResourcesMap[resourceIdToOpen]) {
        //   const processedResource = processResourceData({
        //     ...allResourcesMap[resourceIdToOpen],
        //   });
        //   await setResourceIdInTree(processedResource);
        //   expandAllParentsOfResource(processedResource);
        //   if (!this.isResourceEditorOpen) {
        //     this.openResourceInfoViewer({
        //       data: { ...processedResource },
        //     });
        //   } else {
        //     EventEmitter.emit(OPEN_SELECTED_ITEM, processedResource);
        //   }
        // }

        await this.$nextTick();
        if (!openForm && itemIdToSelect && !isResourceModeOpened) {
          if (itemIdToSelect && this.rawTasksMap[itemIdToSelect]) {
            let finalTaskId = itemIdToSelect;
            let taskDataToUse = processTask({
              ...this.rawTasksMap[itemIdToSelect],
            });
            if (
              checkIfTaskIsParentRecurringTask(this.rawTasksMap[itemIdToSelect])
            ) {
              taskDataToUse = this.getFirstVirtualReccuringTask(
                itemIdToSelect,
                itemIdDateSelect
              );
              finalTaskId = taskDataToUse?.key;
            }

            finalTaskIdToUse = finalTaskId;
            if (finalTaskIdToUse) {
              this.setTaskGroupFilterForTasksList(taskDataToUse);
              EventEmitter.emit(SET_ID_FOR_SCROLL, finalTaskIdToUse);
            }
          }
        }

        if (
          enableInspectMode &&
          !isEmpty(inspectModeConfig) &&
          !isResourceModeOpened
        ) {
          //
          makeCategoryNodeVisible = false;
          const {
            inspectedTaskId,
            triggeredFrom,
            taskDate,
            opts: inspectModeOpts,
          } = inspectModeConfig;

          let taskDataToUse = {};

          if (inspectedTaskId && this.rawTasksMap[inspectedTaskId]) {
            if (
              checkIfTaskIsParentRecurringTask(
                this.rawTasksMap[inspectedTaskId]
              )
            ) {
              taskDataToUse = this.getFirstVirtualReccuringTask(
                inspectedTaskId,
                taskDate
              );
            } else {
              taskDataToUse = this.tasks.find((t) => t.key === inspectedTaskId);
            }
          }

          if (!isEmpty(taskDataToUse)) {
            EventEmitter.emit(OPEN_INSPECT_MODE, {
              triggeredFrom: triggeredFrom || "func",
              taskData: { ...taskDataToUse },
              opts: inspectModeOpts,
            });
          }
        }
        if (
          openForm &&
          itemIdToOpen &&
          this.rawTasksMap[itemIdToOpen] &&
          !isResourceModeOpened
        ) {
          let taskDataToUse = {};
          closeLoader = false;
          if (
            checkIfTaskIsParentRecurringTask(this.rawTasksMap[itemIdToOpen])
          ) {
            taskDataToUse = this.getFirstVirtualReccuringTask(
              itemIdToOpen,
              itemDate
            );
          } else {
            taskDataToUse = this.tasks.find((t) => t.key === itemIdToOpen);
          }

          await Vue.nextTick();

          setTimeout(() => {
            if (!isEmpty(taskDataToUse)) {
              this.openTaskInEditForm(taskDataToUse, undefined, true, true);
              // this.openTaskDataInEditForm({
              //   taskData: taskDataToUse,
              //   doNotEnableInspect: true,
              // });
            }
          }, 0);
        }

        if (
          makeCategoryNodeVisible &&
          !isEmpty(viewData.selectedOptsList) &&
          !isResourceModeOpened
        ) {
          makeNodeVisible(viewData.selectedOptsList[0].id);
        }

        if (!doNotClearViewData) {
          this.storeCurrViewData({});
          this.updateRootState({
            itemInspectEnabled: false,
          });
        }
      }

      if (closeLoader) {
        this.loaderToggler(false);
      }
    },
    handleDashboardReturnToView(returnViewData) {
      if (isEmpty(returnViewData)) return;
      const { inspectModeReturnData } = returnViewData;

      if (!isEmpty(inspectModeReturnData)) {
        this.exitFromInspectMode(inspectModeReturnData);
        return;
      }

      let dbUpdates = {};
      let viewConfig = {};
      let routeName = "";
      if (
        returnViewData.to !== undefined ||
        returnViewData.goBackToResourceMode
      ) {
        const { to, ...restConfig } = returnViewData;
        let isSameRoute = false;
        routeName = returnViewData.goBackToResourceMode ? "Resources" : to;
        viewConfig = { ...restConfig };
        if (to === "Resources" || returnViewData.goBackToResourceMode) {
          dbUpdates = {
            notesView: false,
            activeNavView: "resources",
          };
        } else if (to === "Notes") {
          dbUpdates = {
            notesView: true,
            activeNavView: "notes",
          };
        } else {
          isSameRoute = true;
        }
        // }

        if (!isEmpty(dbUpdates)) {
          DatabaseInterface.update(dbUpdates, this.userInfo.uid);
        }

        if (isSameRoute) {
          this.handleDashboardViewConfig(returnViewData);
        } else {
          this.$router.replace({
            name: routeName,
            params: {
              viewConfig: {
                ...viewConfig,
              },
            },
          });
        }
      } else {
        this.handleDashboardViewConfig(returnViewData);
      }
    },

    /**
     * Create catgeory options list from the provided category ids
     * @param {string[]} selectedIDs List of selected category ids
     * @returns {{[string]:*}[]}
     */
    createSelectedCateOptsList(selectedIDs) {
      // const selectedList = [];

      // const categoriesKeys = Object.keys(this.categories);
      // const projectsKeys = Object.keys(this.projects);
      // selectedIDs.forEach((selectedId) => {
      //   if (categoriesKeys.includes(selectedId)) {
      //     selectedList.push({
      //       id: selectedId,
      //       projectId: "all",
      //       subCategoryId: "all",
      //       type: "category",
      //     });
      //   } else if (projectsKeys.includes(selectedId)) {
      //     if (this.projects[selectedId].type === "project") {
      //       const itemData = {
      //         id: selectedId,
      //         subCategoryId: "all",
      //         categoryId: this.projects[selectedId].category,
      //         type: "project",
      //       };

      //       if (this.projects[selectedId].subcategory) {
      //         itemData.subCategoryId = this.projects[selectedId].subcategory;
      //       }
      //       selectedList.push(itemData);
      //     } else {
      //       selectedList.push({
      //         id: selectedId,
      //         projectId: "all",
      //         categoryId: this.projects[selectedId].category,
      //         type: "sub-category",
      //       });
      //     }
      //   }
      // });

      return createTaskCategorySelectionOptsList(selectedIDs);
      // }
    },

    /**
     * Create task selection list from provided tasks
     * @param {{[string]:*}[]} tasksToProcess List of tasks
     * @returns {{[string]:*}[]}
     */
    createTaskSelectionList(tasksToProcess) {
      let selectedRows = [];

      tasksToProcess.forEach((t) => {
        let taskDataToUse = { ...t };
        if (taskDataToUse.isReminderTask) {
          const allRemindersOfParent = getReminderTasksLinkedToTaskId(
            this.tasks,
            taskDataToUse.reminderLinkedTo
          );

          if (!isEmpty(allRemindersOfParent)) {
            const reminderTaskData = allRemindersOfParent.find((t) =>
              isSameDates(t.reminderDate, taskDataToUse.reminderDate)
            );

            if (!isEmpty(reminderTaskData)) {
              taskDataToUse = { ...reminderTaskData };
            }
          }
        } else {
          const updatedTaskData = this.tasks.find(
            (t) => t.key === taskDataToUse.key
          );

          if (!isEmpty(updatedTaskData)) {
            taskDataToUse = { ...updatedTaskData };
          }
        }

        selectedRows.push(taskDataToUse);
      });

      return selectedRows;
    },

    checkIfTaskFormIsOpened() {
      return (
        this.$route.name === "Dashboard" &&
        this.isNewEditFormOpened &&
        !isEmpty(this.editTaskData)
      );
    },
    checkIfTaskIsOpenedInForm() {
      return this.checkIfTaskFormIsOpened() && !isEmpty(this.editTaskData.key);
    },
    checkIfAreaIsOpenedInForm() {
      return (
        this.checkIfTaskFormIsOpened() &&
        !isEmpty(this.editTaskData.project) &&
        isEmpty(this.editTaskData.key)
      );
    },
    checkIfStatusBtnIsVisibleInAreaColumn() {
      const isInDashboard = this.$route.name === "Dashboard";
      return (
        (!isEmpty(this.taskSettings?.statusBtnPosition) &&
          this.taskSettings?.statusBtnPosition === "in-area") ||
        (isEmpty(this.taskSettings?.statusBtnPosition) &&
          calModes.includes(this.currNav) &&
          this.isGlobalViewEnabled) ||
        (!checkTaskSettingsIsNotEmpty(this.taskSettings) &&
          isInDashboard &&
          this.isInCalModes)
      );
    },
    checkIfEditBtnEnabled() {
      return (
        (checkTaskSettingsIsNotEmpty(this.taskSettings) &&
          !!this.taskSettings?.showEditBtn) ||
        this.checkIfStatusBtnIsVisibleInAreaColumn(this.taskSettings)
      );
    },

    async removeCurrAndFollowRecurringTasks(currTaskData) {
      this.loaderToggler(true);

      try {
        let reminderTasksToRemove = [];
        let mainTaskGoogleUpdate = {};
        const reminderTasksLinkedToMain = getReminderTasksLinkedToTaskId(
          this.tasks,
          currTaskData.key
        );

        if (!isEmpty(reminderTasksLinkedToMain)) {
          reminderTasksToRemove.push(...reminderTasksLinkedToMain);
        }

        const allRecurringTasks = getRecurringTasks(this.rawTasks, "list", {
          coRelationalId: currTaskData.coRelationalId,
        });

        const mainTaskData = allRecurringTasks[0];

        const closeForm =
          this.isEditFormOpen &&
          !isEmpty(this.editTaskData) &&
          this.editTaskData.key === currTaskData.key;

        const mainTaskId = mainTaskData.key;
        const mainTaskGoogleId = mainTaskData.googleEventId;
        const mainTaskDueDate = createDateTimeFromDue(
          mainTaskData.due,
          mainTaskData.time
        );
        let endDate = currTaskData.dueAsDate;
        if (checkTaskIsAllDay(endDate)) {
          endDate = subDate(endDate, { days: 1 });
        } else {
          endDate = subDate(endDate, { days: 1 });
          endDate = endOfDay(endDate);
        }

        const { rule, ruleSet: parsedRuleSet } =
          parseRuleDataFromTaskRecurrenceRules({
            recurrence: mainTaskData.recurrence,
            dueAsDate: mainTaskDueDate,
          });
        const parsedRuleOptions = rule.options;

        const excludedDates = parsedRuleSet.exdates();
        const rulesOptsToUse = {
          ...parsedRuleOptions,
          dtstart: createTaskDueDate(mainTaskData.due),
          until: endDate,
        };

        const newRuleSetCreatedForMainTask = createRRuleSet(rulesOptsToUse);

        excludeDatesInTaskRules(
          excludedDates,
          newRuleSetCreatedForMainTask,
          false
        );

        const rulesList = newRuleSetCreatedForMainTask.valueOf();

        const convertedRules = rulesList.slice(1);
        const oldRecurrenceRules = mainTaskData.recurrence;

        if (mainTaskGoogleId) {
          mainTaskGoogleUpdate = {
            key: mainTaskGoogleId,
            updates: {
              ...createTaskDataForGoogleCal(
                {
                  recurrence: convertedRules,
                  taskKey: mainTaskData.key,
                },
                {
                  ...mainTaskData,
                }
              ),
            },
          };
        }

        const userActions = {
          type: TASK_ACTIONS.NORMAL_RECURRING_EDIT,
          data: {
            [mainTaskId]: {
              updateMain: true,
              editedData: {
                changedProps: {
                  recurrence: convertedRules,
                },
                recurrence: oldRecurrenceRules,
              },
              recreateTasks: true,
            },
          },
          on: "task",
        };

        const mainTaskDataDbUpdate = {
          key: mainTaskId,
          updates: {
            recurrence: convertedRules,
          },
        };

        const recurringTasksAfterCurrTaskDate = getRecurringTasks(
          this.tasks,
          "list",
          {
            coRelationalId: currTaskData.coRelationalId,
            filter: true,
            filterOpts: [
              isTaskNotCompleted,
              isTaskAfterDateFilterCreator(
                currTaskData.dueAsDate,
                "dueAsDate",
                "afterOrEqual"
              ),
            ],
          }
        );

        recurringTasksAfterCurrTaskDate.forEach((r) => {
          const linkedReminderTasks = getReminderTasksLinkedToTaskId(
            this.tasks,
            r.key
          );

          if (!isEmpty(linkedReminderTasks)) {
            reminderTasksToRemove.push(...linkedReminderTasks);
          }
        });

        const updatedRecurrences = createRecurringDatesFromTaskRules({
          ...getDateAndTimeFromDueDate(mainTaskDueDate),
          recurrence: convertedRules,
        });

        addOrRemoveOrUpdateTasksInDb(
          {
            tasksToUpdate: [mainTaskDataDbUpdate],
            tasksToRemove: recurringTasksAfterCurrTaskDate,
          },
          undefined,
          this.userInfo.uid
        );
        getUserActions().addTaskAction(userActions);
        removeTasksFromRecurringTasksMapByDates(mainTaskId, updatedRecurrences);

        await addOrRemoveOrUpdateTasksInLocalTasksList({
          tasksToUpdate: [
            {
              key: mainTaskId,
              updates: {
                ...this.rawTasksMap[mainTaskId],
                recurrence: convertedRules,
              },
            },
          ],
          tasksToRemove: [
            ...recurringTasksAfterCurrTaskDate,
            ...reminderTasksToRemove,
          ],
        });

        if (!isEmpty(mainTaskGoogleUpdate)) {
          this.updateTasksInGoogleCal(mainTaskGoogleUpdate);
        }

        if (closeForm) {
          this.closeEditTaskDialog();
        }
      } catch (error) {
        console.log("ERR", error);
      } finally {
        this.loaderToggler(false);
      }

      return true;
    },

    async removeAllRecurringTasks(currTaskData) {
      this.loaderToggler(true);
      try {
        const coRelationalId = currTaskData.coRelationalId;
        let dbRemoveTaskList = [];

        let googleRemoveTaskList = [];
        const isVirtualTask = currTaskData.isVirtual;
        const linkedTo = currTaskData.linkedTo;
        let mainTaskData = {};
        let reminderTasksToRemove = [];
        if (isVirtualTask || linkedTo) {
          mainTaskData = this.rawTasksMap[linkedTo];
        } else if (areTaskRecurrenceRulesValid(currTaskData.recurrence)) {
          mainTaskData = { ...this.rawTaskData[currTaskData.key] };
        }
        const closeForm =
          this.isEditFormOpen &&
          !isEmpty(this.editTaskData) &&
          this.editTaskData.key === currTaskData.key;
        if (!isEmpty(mainTaskData)) {
          let config = {
            coRelationalId,
          };

          if (!this.showClearedTasks) {
            config = {
              ...config,
              filter: true,
              filterOpts: [isTaskNotCompleted],
            };
          }
          const coRelatedTasks = getRecurringTasks(this.tasks, "list", {
            ...config,
          });

          const allRemindersLinkedToMain = getReminderTasksLinkedToTaskId(
            this.tasks,
            mainTaskData.key
          );

          if (!isEmpty(allRemindersLinkedToMain)) {
            reminderTasksToRemove.push(...allRemindersLinkedToMain);
          }

          dbRemoveTaskList.push(mainTaskData);

          googleRemoveTaskList.push(mainTaskData);
          coRelatedTasks.forEach((taskData) => {
            dbRemoveTaskList.push(taskData);

            const localReminderTasksLinked = getReminderTasksLinkedToTaskId(
              this.tasks,
              taskData.key
            );

            if (!isEmpty(localReminderTasksLinked)) {
              reminderTasksToRemove.push(...localReminderTasksLinked);
            }
            if (!taskData.isVirtual && taskData.googleEventId) {
              googleRemoveTaskList.push(taskData);
            }
          });

          const createdTaskId = createUniqueId();
          getUserActions().addTaskAction({
            type: TASK_ACTIONS.NORMAL_RECURRING_DELETE,
            data: {
              [createdTaskId]: {
                removedRecurringTasks: true,
                taskData: { ...mainTaskData },
              },
            },
            on: "task",
          });
          await addOrRemoveOrUpdateTasksInLocalTasksList({
            tasksToRemove: [...dbRemoveTaskList, ...reminderTasksToRemove],
          });

          replaceRecurringTasksInfoInMap(mainTaskData.key, []);

          // Db code
          await addOrRemoveOrUpdateTasksInDb(
            {
              tasksToRemove: dbRemoveTaskList,
            },
            undefined,
            this.userInfo.uid
          );

          this.addOrUpdateOrRemoveTasksInGoogleCal({
            tasksToRemove: googleRemoveTaskList,
          });

          if (closeForm) {
            this.closeEditTaskDialog();
          }
        }
      } catch (error) {
        console.log("ERROR", error);
      } finally {
        this.loaderToggler(false);
      }
    },

    handleDeletionOfTasks(selectedTasks) {
      if (
        selectedTasks.length === 1 &&
        checkIfTaskIsRecurring(selectedTasks[0])
      ) {
        this.openDeleteRecurringDialog({
          taskData: selectedTasks[0],
        });
      } else {
        this.removeRecurringOrNormalTasks(selectedTasks);
      }
    },
    async hideTempVisibleTasks() {
      const tempVisibleTasks = this.tasks.filter((t) => !!t.keepItVisible);
      const taskUpdateList = tempVisibleTasks.map((t) => {
        return {
          key: t.key,
          updates: {
            keepItVisible: false,
          },
        };
      });

      await addOrRemoveOrUpdateTasksInLocalTasksList(
        {
          tasksToUpdate: taskUpdateList,
        },
        false
      );
    },

    setTaskAreaInFormAndRefresh(areaId) {
      this.setTaskData({
        project: areaId,
      });
      EventEmitter.emit(REFRESH_FORM_DATA);
    },

    checkIfDueDateDayAndDateEnabled() {
      const isDueDateDayAndTimeFormatEnabled = !!(
        this.taskSettings?.dueDateDisplay === "day-date"
      );

      return !this.isInCalModes && isDueDateDayAndTimeFormatEnabled;
    },
    ...mapMutations("resourcesData", {
      setResourceStateData: "setData",
    }),

    async refreshTaskDataInResourceForm(taskKey) {
      const updatedTaskData = this.tasks.find((t) => t.key === taskKey);

      if (!isEmpty(updatedTaskData)) {
        const processedTaskData = processTask(
          getMainTaskRulesAndFillRecurringOpts(updatedTaskData)
        );

        this.setStoredTaskData(processedTaskData);
        await Vue.nextTick();
        EventEmitter.emit(REFRESH_TASK_DATA_IN_RESOURCE_FORM, true);
      }
    },

    checkIfTaskIsActiveInResourceForm(taskKey) {
      return (
        !isEmpty(this.storedTaskDataInResourceForm) &&
        this.storedTaskDataInResourceForm.key === taskKey
      );
    },

    checkIfTaskEditIsActiveInResourceForm() {
      return !isEmpty(this.storedTaskDataInResourceForm);
    },

    getStoredTaskDataInResourceForm() {
      return this.storedTaskDataInResourceForm;
    },

    processTasksOfNextActionsModeAfterGrouping(
      taskList,
      providedTaskGroupFilter
    ) {
      const selectedTaskGroupFilter =
        providedTaskGroupFilter || this.selectedTaskGroupFilter;

      if (!isEmpty(selectedTaskGroupFilter)) {
        taskList = filterList(
          taskList,
          createTaskGroupFiltersFromList(selectedTaskGroupFilter)
        );
      }

      return taskList;
    },

    changeSubTasksGroupFilter() {
      return new Promise((resolve) => {
        let taskGroupFilter = DEFAULT_TASK_GROUP_FILTER_VALUE;
        if (
          this.$route.name === "Dashboard" &&
          !this.isProjectsResourceModeEnabled &&
          this.isNewEditFormOpened &&
          !isEmpty(this.editTaskData)
        ) {
          if (this.editTaskData.key) {
            let taskDataToUse = {};
            const isGroupKeyExists = checkIfTaskContainsGroupKey(
              TASK_GROUP_KEY_FOR_GROUP_FILTER
            );
            if (isGroupKeyExists) {
              taskDataToUse = { ...this.editTaskData };
            } else {
              taskDataToUse = this.tasks.find(
                (t) => t.key === this.editTaskData.key
              );
            }

            if (!isEmpty(taskDataToUse) && taskDataToUse.project) {
              const extractedVal =
                TASK_NEXT_ACTION_GROUP_KEY_VALUES_FOR_FILTER[
                  this.editTaskData[TASK_GROUP_KEY_FOR_GROUP_FILTER]
                ];

              if (extractedVal) {
                taskGroupFilter = extractedVal;
              }
            }
          }
        }

        if (
          this.$route.name === "Resources" ||
          this.isProjectsResourceModeEnabled
        ) {
          if (
            !isEmpty(this.selectedTaskDataForResourceForm) &&
            this.selectedTaskDataForResourceForm.key
          ) {
            const storedTaskData = this.tasks.find(
              (t) => t.key === this.selectedTaskDataForResourceForm.key
            );

            if (!isEmpty(storedTaskData) && storedTaskData.project) {
              const extractedVal =
                TASK_NEXT_ACTION_GROUP_KEY_VALUES_FOR_FILTER[
                  storedTaskData[TASK_GROUP_KEY_FOR_GROUP_FILTER]
                ];

              if (extractedVal) {
                taskGroupFilter = extractedVal;
              }
            }
          }
        }

        this.setSubTasksListGroupFilter(taskGroupFilter);

        setTimeout(() => resolve());
      });
    },

    setTaskGroupFilterForTasksList(taskData, table = "sub") {
      let taskGroupFilter = "scheduled";
      if (!isEmpty(taskData)) {
        const extractedVal =
          TASK_NEXT_ACTION_GROUP_KEY_VALUES_FOR_FILTER[
            taskData[TASK_GROUP_KEY_FOR_GROUP_FILTER]
          ];

        if (extractedVal) {
          taskGroupFilter = extractedVal;
        }
      }

      if (table === "sub") {
        this.setSubTasksListGroupFilter(taskGroupFilter);
      } else {
        this.setTaskGroupFilter(taskGroupFilter);
      }
    },

    processTaskListForGroupFilter(list, currGroupFilter) {
      // if (currGroupFilter === DEFAULT_TASK_GROUP_FILTER_VALUE) {
      //   list = [];
      // }

      return list;
    },
  },
  computed: {
    ...mapGetters(["isGoogleCalSyncEnabled", "taskSettings"]),
    ...mapGetters("resourceInfo", {
      isResourceEditorOpen: "isOpen",

      selectedTaskDataForResourceForm: "selectedTaskData",
    }),
    ...mapGetters("task", [
      "tasks",
      "rawTasksMap",
      "projects",
      "categories",
      "selectedTaskFilter",
      "selectedTaskDateFilter",
      "selectedOptsList",
      "searchQuery",
      "filters",
      "currNav",
      "isInboxViewEnabled",
      "isGlobalViewEnabled",
      "showClearedTasks",
      "isInspectModeEnabled",
      "rawTasks",
      "selectedCalForecastType",
      "isProjectsResourceModeEnabled",
      "selectedTaskGroupFilter",
    ]),
    ...mapGetters(["subTasksListGroupFilter"]),
    ...mapGetters("task", {
      selectedTaskList: "selectedTasks",
      selectedDatesList: "selectedDates",
    }),
    ...mapGetters("editForm", {
      editTaskData: "taskData",
      editNoteData: "noteData",
      isEditFormOpen: "isOpen",
      isNewEditFormOpened: "openNewForm",
    }),
    ...mapGetters("resourcesData", {
      selectedCategories: "selectedCategories",
      processedResources: "resources",
      rawResourcesMap: "rawResourcesMap",
      rawResourceCategories: "categories",
    }),
    ...mapGetters("resourcesData", {
      selectedResrcCategories: "selectedCategories",
    }),
    ...mapGetters("resourceInfo", {
      storedTaskDataInResourceForm: "storedTaskData",
    }),
    ...mapGetters(["viewedItemKey"]),
    isSearchKeywordValid() {
      let searchQuery = this.searchQuery;

      searchQuery = searchQuery && searchQuery.trim();

      return !isEmpty(searchQuery) && searchQuery !== "*";
    },

    showAllOrderedTasks() {
      return this.toggleOrderTasksData?.showAllTasks || false;
    },

    isInNextActionsMode() {
      return (
        treeSideBarModes.includes(this.currNav) && !this.isInboxViewEnabled
      );
    },

    isInCalModes() {
      return calModes.includes(this.currNav) && !this.isGlobalViewEnabled;
    },

    isInContingentModes() {
      return (
        this.isInCalModes ||
        (this.isInNextActionsMode && !this.isGlobalViewEnabled)
      );
    },
    isInInboxViewOrGlobalEnabled() {
      return (
        this.isGlobalViewEnabled ||
        (treeSideBarModes.includes(this.currNav) && this.isInboxViewEnabled)
      );
    },
    onlyAreaIsSelected() {
      return (
        this.isInNextActionsMode &&
        checkIfSelectedTaskCateHasOnlyArea(this.selectedOptsList)
      );
    },
  },
};

export default mixin;
